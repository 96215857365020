import apis from "../../../../apis/client";

export const createPlan = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.plan.createPlan(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const updatePlan = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.plan.updatePlan(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getPlan = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.plan.getPlan(payload);

      if (data) {
        // dispatch(setPlan(data.data));
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getAllPlan = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.plan.getAllPlan(payload);

      if (data) {
        dispatch(setAllPlan(data.data));
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getAdminPlan = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.plan.getAdminPlan(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const deletePlan = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.plan.deletePlan(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

export const setPlan = (data) => {
  return {
    type: "SET_PLAN",
    payload: data,
  };
};

export const setAllPlan = (data) => {
  return {
    type: "SET_ALLPLAN",
    payload: data,
  };
};
