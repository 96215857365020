import React from "react";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import imageVector from "assets/images/custom/Vector5.png";
import Fade from "react-reveal/Fade";

const CardText = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <section className="relative">
      <Image src={imageVector} alt="image" className="homebanner-c" />
      <div className="container mx-auto  relative zindex9 lg:pb-16 md:pb-16 pb-8 lg:pt-12 md:pt-12 pt-8 lg:px-12 md:px-8 px-5">
        <div className="text-center ">
          <h2
            id="4976480268"
            className="dynamicStyle lg:text-4xl md:text-2xl text-2xl uppercase lheight text-primary   font-semibold pl-2"
          >
            {data ? data["4976480268"] : "What We Do"}
          </h2>
          <Fade bottom>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-12 mt-6 lg:gap-8 md:gap-8 gap-6">
              <div className="bg-white cust-shadow rounded-lg p-5">
                <h2
                  id="1213524592"
                  className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary  font-semibold pl-2"
                >
                  {data ? data["1213524592"] : "Educate"}
                </h2>
                <p
                  id="0111982049"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["0111982049"] : "no-data",
                  }}
                  className="dynamicStyle fsize16 text-dark sm-fsize14 col-62 lg:leading-7 md:leading-7 leading-6"
                ></p>
              </div>
              <div className="bg-white cust-shadow rounded-lg p-5">
                <h2
                  id="2415028268"
                  className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary  font-semibold pl-2"
                >
                  {data ? data["2415028268"] : "Educate"}
                </h2>
                <p
                  id="6787662183"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["6787662183"] : "no-data",
                  }}
                  className=" dynamicStyle fsize16 text-dark sm-fsize14 col-62 lg:leading-7 md:leading-7 leading-6"
                ></p>
              </div>
              <div className="bg-white cust-shadow rounded-lg p-5">
                <h2
                  id="9289452004"
                  className="dynamicStyle lg:text-2xl md:text-xl text-lg lheight text-primary  font-semibold pl-2"
                >
                  {data ? data["9289452004"] : "Educate"}
                </h2>
                <p
                  id="2638899178"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["2638899178"] : "no-data",
                  }}
                  className="dynamicStyle fsize16 text-dark sm-fsize14 col-62 lg:leading-7 md:leading-7 leading-6"
                ></p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default CardText;
