import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  FormErrorMessage,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Radio,
  RadioGroup,
  Progress,
  Badge,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import FeatherIcon from "feather-icons-react";
import {
  FaRegThumbsUp,
  FaEye,
  FaPhoneAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { CheckIcon, CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Navigation } from "swiper/modules";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getTrainerById,
  setIsEditProfile,
  setSelectedTrainerData,
} from "../../../../redux/custom/trainer/trainer-actions";
import {
  createPost,
  getPost,
  getSinglePost,
  updatePollVote,
  deletePost,
  updatePost,
} from "../../../../redux/custom/social/post/post-actions";
import {
  createComment,
  deleteComment,
  getComment,
  getSingleComment,
} from "../../../../redux/custom/social/comment/comment-actions";
import {
  createReply,
  deleteReply,
  getReply,
  getSingleReply,
} from "../../../../redux/custom/social/reply/reply-actions";
import { getEmoji } from "../../../../redux/custom/social/emoji/emoji-actions";
import {
  togglePostLike,
  toggleCommentLike,
  toggleReplyLike,
} from "../../../../redux/custom/social/like/like-actions";
import {
  SaveBasicProfileDetails,
  getUserByToken,
} from "../../../../redux/auth/auth-action";
import {
  updateTestimonial,
  deleteTestimonial,
} from "../../../../redux/custom/testimonial/testimonial-actions";
import UploaderBox from "components/UploaderBox";
import EditProfile from "./TrainerEditProfile";
import Facebook from "../../../../assets/images/custom/fb.png";
import Youtube from "../../../../assets/images/custom/youTube.png";
import X from "../../../../assets/images/custom/x.png";
import Instagram from "../../../../assets/images/custom/instagram.png";
import { DeleteIcon } from "@chakra-ui/icons";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import { useFormik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import ShareSection from "pages/allSections/components/ShareProfileSection";
import { toast } from "react-toastify";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const UserProfile = () => {
  const dispatch = useDispatch();

  let { SelectedTrainerData: selectedTrainer } = useSelector(
    (state) => state.trainer
  );
  console.log(selectedTrainer);

  // const [selectedTrainer, setSelectedTrainer] = useState({});
  const [isCommentOpen, setIsCommentOpen] = useState("");
  const [commentMessage, setCommentMessage] = useState("");
  const [isReplyOpen, setIsReplyOpen] = useState("");
  const [replyMessage, setReplyMessage] = useState("");

  const [postAction, setPostAction] = useState("CREATE");

  const [postLoading, setPostLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);

  const [fetchCommentLoading, setFetchCommentLoading] = useState(false);
  const [fetchReplyLoading, setFetchReplyLoading] = useState(false);

  // let selectedTrainerId = useSelector((state) => state.trainer.SelectedTrainer);
  let userData = useSelector((state) => state.AuthDetails.user);

  let isEditProfile = useSelector((state) => state.trainer.IsEditProfile);

  let postData = useSelector((state) => state.post.Post);
  let emojiData = useSelector((state) => state.emoji.Emoji);
  let commentData = useSelector((state) => state.comment.Comment);
  let replyData = useSelector((state) => state.reply.Reply);

  const getImage = (image) => {
    if (image.includes("data:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };

  const handleComment = (post) => {
    if (isCommentOpen === post._id) {
      setIsCommentOpen("");
    } else {
      setIsCommentOpen(post._id);
      getPostComment(post);
    }
  };

  const handleReply = (comment, post = {}) => {
    if (isReplyOpen === comment._id) {
      setIsReplyOpen("");
    } else {
      setIsReplyOpen(comment._id);
      getCommentReply(comment);
    }
  };

  const getPostComment = async (post) => {
    try {
      let payload = {
        postId: post._id,
        userId: userData._id,
      };
      setFetchCommentLoading(true);
      const data = await dispatch(getComment(payload));
      setFetchCommentLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchCommentLoading(false);
    }
  };

  const getCommentReply = async (comment, post = {}) => {
    try {
      let payload = {
        commentId: comment._id,
        userId: userData._id,
      };
      setFetchReplyLoading(true);
      const data = await dispatch(getReply(payload));
      setFetchReplyLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchReplyLoading(false);
    }
  };

  const handleSubmitComment = async (post) => {
    try {
      if (!commentMessage.trim()) return;
      let payload = {
        postId: post._id,
        message: commentMessage,
      };
      setCommentLoading(true);
      const data = await dispatch(createComment(payload));
      setCommentLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        getPostComment(post);
        setCommentMessage("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCommentLoading(false);
    }
  };
  const handleSubmitReply = async (comment, post) => {
    try {
      if (!replyMessage.trim()) return;
      let payload = {
        postId: post._id,
        commentId: comment._id,
        message: replyMessage,
      };
      setReplyLoading(true);
      const data = await dispatch(createReply(payload));
      setReplyLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        handleSingleComment(comment, post);
        getCommentReply(comment);
        setReplyMessage("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReplyLoading(false);
    }
  };

  const [postPage, setPostPage] = useState(0);
  const [postPerPage, setPostPerPage] = useState(1000);

  function calculateAge(birthDate) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    // If the birthday hasn't occurred yet this year, subtract 1 from age
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    return age;
  }

  useEffect(() => {
    getSelectedTrainerData();
    fetchPost();
    fetchEmoji();
  }, []);

  const fetchEmoji = async () => {
    try {
      const data = await dispatch(getEmoji());
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPost = async () => {
    try {
      let payload = {
        offset: postPage,
        limit: postPerPage,
        userId: userData._id,
        trainerId: userData.trainerId,
      };

      const data = await dispatch(getPost(payload));
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedTrainerData = async () => {
    try {
      if (userData && userData.trainerId) {
        const data = await dispatch(
          getTrainerById({
            trainerId: userData.trainerId,
          })
        );
        if (
          data &&
          data.code === 200 &&
          data.data.result &&
          data.data.result.length > 0
        ) {
          await dispatch(setSelectedTrainerData(data.data.result[0]));
          // setSelectedTrainer(data.data.result[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLikedEmoji = (element, emojiData) => {
    let emoji = emojiData.result.filter(
      (emoji) => element.likes[0].emojiId === emoji._id
    );

    return (
      <div className="flex justify-start">
        <Image
          src={`${process.env.REACT_APP_STORAGE_URL}${
            emoji && emoji.length > 0 && emoji[0].media ? emoji[0].media : null
          }`}
          alt={
            emoji && emoji.length > 0 && emoji[0].name
              ? emoji[0].name
              : "No Emoji"
          }
          className="w-5 h-5 rounded-full cursor-pointer"
          fallbackSrc="https://nexusclustersg.blob.core.windows.net/server01/fit2go/fit2go/emoji/media-nAG3vqDUIVt7qdCBfLB4w-1706883516"
        />
      </div>
    );
  };

  const buttonLabels = [
    "Gym Trainer",
    "2 years experience",
    "Cardiovascular",
    "Functional",
    "Yoga",
    "Gym Trainer",
    "Functional",
    "Yoga",
    "2 years experience",
    "Cardiovascular",
    "Gym Trainer",
    "2 years experience",
    "Yoga",
    "Cardiovascular",
    "Functional",
  ];

  const timeSlots = [
    "10.00am-12.30pm",
    "3.00am-5.30pm",
    "3.00am-5.30pm",
    "11.00am-12.30pm",
    "3.00am-5.30pm",
    // Add more time slots as needed
  ];

  const langaugeSlots = [
    "Hindi",
    "English",
    "Spanish",
    "Singaporean",
    // Add more time slots as needed
  ];

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const {
    isOpen: isOpenPost,
    onOpen: onOpenPost,
    onClose: onClosePost,
  } = useDisclosure();
  const btnRef = React.useRef();

  const [displayedComments, setDisplayedComments] = useState(3);

  const loadMoreContents = () => {
    setDisplayedComments(displayedComments + 3);
  };
  const setIsEditProfileHandler = () => {
    dispatch(setIsEditProfile(!isEditProfile));
  };

  const handlePostLike = async (post, emoji) => {
    try {
      let payload = {
        postId: post._id,
        emojiId: emoji._id,
      };
      const data = await dispatch(togglePostLike(payload));
      if (data && data.code === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCommentLike = async (comment, emoji) => {
    try {
      let payload = {
        commentId: comment._id,
        emojiId: emoji._id,
      };
      const data = await dispatch(toggleCommentLike(payload));
      if (data && data.code === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleReplyLike = async (reply, emoji) => {
    try {
      let payload = {
        replyId: reply._id,
        emojiId: emoji._id,
      };
      const data = await dispatch(toggleReplyLike(payload));
      if (data && data.code === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSinglePost = async (post) => {
    try {
      let payload = {
        userId: userData._id,
        trainerId: userData.trainerId,
        postId: post._id,
      };
      const data = await dispatch(getSinglePost(payload));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSingleComment = async (comment, post) => {
    try {
      let payload = {
        userId: userData._id,
        postId: post._id,
        commentId: comment._id,
      };
      const data = await dispatch(getSingleComment(payload));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSingleReply = async (reply, comment, post) => {
    try {
      let payload = {
        userId: userData._id,
        postId: post._id,
        commentId: comment._id,
        replyId: reply._id,
      };
      const data = await dispatch(getSingleReply(payload));
    } catch (error) {
      console.log(error);
    }
  };

  const initialPollOptions = [
    {
      message: "",
    },
    {
      message: "",
    },
  ];

  const [pollOptions, setPollOptions] = useState(initialPollOptions);

  const initialValues = {
    caption: "",
    mediaType: "Text",
    media: null,
    message: "",
    pollOptions: pollOptions,

    mediaPreview: null,
    postId: "",
  };

  const validationRulesText = Yup.object({
    caption: Yup.string()
      .trim()
      // .min(3, "Caption must be at least 3 characters")
      .max(500, "Caption must be at most 500 characters")
      .required("Caption is required"),
    message: Yup.string()
      .trim()
      .min(3, "Description must be at least 3 characters")
      .max(1000, "Description must be at most 1000 characters")
      .required("Description is required"),
    // mediaType: Yup.string().required("Post type is required"),
    // media: Yup.mixed().required("Media is required"),
    // media: Yup.mixed()
    //   .when("$exist", {
    //     is: (exist) => exist,
    //     then: Yup.mixed().required("Media is required"),
    //     otherwise: Yup.mixed(),
    //   })
    //   .test(
    //     "fileSize",
    //     "File size is too large",
    //     (value) => value && value.size <= 10485760
    //   )
    //   .test(
    //     "fileType",
    //     "Invalid file type",
    //     (value) => value && value.type.startsWith("image/")
    //   ),
  });

  const validationRulesImage = Yup.object({
    caption: Yup.string()
      .trim()
      // .min(3, "Caption must be at least 3 characters")
      .max(500, "Caption must be at most 500 characters"),
    // .required("Caption is required"),
    media: Yup.mixed()
      .required("Image is required")
      .test("fileSize", "File size is too large (Max: 5MB)", (value) => {
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileType", "Invalid file type", (value) => {
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      }),
  });

  const validationRulesVideo = Yup.object({
    caption: Yup.string()
      .trim()
      // .min(3, "Caption must be at least 3 characters")
      .max(500, "Caption must be at most 500 characters"),
    // .required("Caption is required"),
    media: Yup.mixed()
      .required("Video is required")
      .test("fileSize", "File size is too large (Max: 20MB)", (value) => {
        return (
          value &&
          ((value.size && value.size <= 20971520) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileType", "Invalid file type", (value) => {
        return (
          value &&
          ((value.type && value.type.startsWith("video/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      }),
  });

  let validationRulesPoll = Yup.object({
    caption: Yup.string()
      .trim()
      // .min(3, "Caption must be at least 3 characters")
      .max(500, "Caption must be at most 500 characters")
      .required("Caption is required"),
    // pollOptions: Yup.array().of(
    //   Yup.object().shape({
    //     mesage: Yup.string().required("Options is required"),
    //   })
    // ),
    // "pollOptions.0.message": Yup.string()
    //   .trim()
    //   .min(3, "Options must be at least 3 characters")
    //   .max(100, "Options must be at most 100 characters")
    //   .required("Options is required"),
    // "pollOptions.1.message": Yup.string()
    //   .trim()
    //   .min(3, "Options must be at least 3 characters")
    //   .max(100, "Options must be at most 100 characters")
    //   .required("Options is required"),
  });

  // const addFieldToSchema = (schema, fieldName, fieldType) => {
  //   return schema.shape({
  //     ...schema.fields,
  //     [fieldName]: fieldType,
  //   });
  // };

  // const handleAddField = () => {
  //   const newFieldName = `pollOptions.${
  //     Object.keys(validationRulesPoll.fields).length
  //   }.message`; // Generate a unique field name
  //   const newFieldType = Yup.string()
  //     .trim()
  //     .min(3, "Options must be at least 3 characters")
  //     .max(100, "Options must be at most 100 characters")
  //     .required("Options is required"); // Define validation rules for the new field
  //   validationRulesPoll = addFieldToSchema(
  //     validationRulesPoll,
  //     newFieldName,
  //     newFieldType
  //   ); // Add the new field to the schema
  //   console.log("Schema updated:", validationRulesPoll); // Log the updated schema
  // };

  const handleOnSubmit = async (values) => {
    let payload = new FormData();

    if (formik.values.caption) {
      payload.append("caption", formik.values.caption);
    }
    if (formik.values.mediaType) {
      payload.append("mediaType", formik.values.mediaType);
    }
    if (formik.values.message) {
      payload.append("message", formik.values.message);
    }
    if (userData.trainerId) {
      payload.append("trainerId", userData.trainerId);
    }
    if (formik.values.media && typeof formik.values.media !== "string") {
      payload.append("media", formik.values.media);
    }
    if (
      postAction === "CREATE" &&
      formik.values.pollOptions &&
      formik.values.pollOptions.length > 0
    ) {
      payload.append("pollOptions", JSON.stringify(formik.values.pollOptions));
    }
    if (postAction === "EDIT" && formik.values.postId) {
      payload.append("postId", formik.values.postId);
    }
    console.log(payload);
    try {
      let data;
      setPostLoading(true);
      if (postAction === "EDIT" && formik.values.postId) {
        data = await dispatch(updatePost(payload));
      } else {
        data = await dispatch(createPost(payload));
      }
      setPostLoading(false);
      if (data && data.code === 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: `Post ${
        //     postAction === "EDIT" ? "updated" : "created"
        //   } successfully`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(
          `Post ${postAction === "EDIT" ? "updated" : "created"} successfully`
        );
        formik.handleReset();
        fetchPost();
        onClosePost();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    } finally {
      setPostLoading(false);
    }
  };

  const handlePostEdit = async (post) => {
    formik.handleReset();
    setPostAction("EDIT");
    await setFieldHandler("postId", post._id);

    if (post.caption) {
      await setFieldHandler("caption", post.caption);
    }
    if (post.mediaType) {
      await setFieldHandler("mediaType", post.mediaType);
    }
    if (post.message) {
      await setFieldHandler("message", post.message);
    }
    if (post.media) {
      await setFieldHandler("mediaPreview", post.media);
      await setFieldHandler("media", post.media);
    }

    onOpenPost();
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const setFieldHandler = async (field, value) => {
    await formik.setFieldValue(field, value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: () => {
      if (formik.values.mediaType === "Text") {
        return validationRulesText;
      }
      if (formik.values.mediaType === "Image") {
        return validationRulesImage;
      }
      if (formik.values.mediaType === "Video") {
        return validationRulesVideo;
      }
      if (formik.values.mediaType === "Poll") {
        return validationRulesPoll;
      }
    },
    validateOnChange: true,
  });

  console.log(formik.errors);

  const handleDeletePost = (post) => {
    try {
      Swal.fire({
        title: "warning",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2bc370",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const deletePostData = await dispatch(
            deletePost({ postId: post._id, trainerId: userData.trainerId })
          );
          if (deletePostData && deletePostData.code === 200) {
            await fetchPost();
            // Swal.fire({
            //   title: "Success",
            //   text: `Post deleted successfully`,
            //   icon: "success",
            //   timer: "2500",
            // });
            toast.success(`Post deleted successfully`);
          } else {
            if (deletePostData.message) {
              // Swal.fire({
              //   title: "Error",
              //   text: deletePostData.message,
              //   icon: "error",
              // });
              toast.error(deletePostData.message);
            }
          }
        }
      });
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const handleAddMoreOptions = () => {
    // handleAddField();
    const newPollOptions = [...pollOptions, { message: "" }];
    console.log(newPollOptions);
    setPollOptions(newPollOptions);
  };

  const handleDeleteMoreOptions = (index) => {
    const newPollOptions = pollOptions.filter((_, i) => i !== index);
    setPollOptions(newPollOptions);
  };

  const calculatePollPercentage = (voteCount, totalVotes) => {
    if (totalVotes > 0) {
      return Math.round((voteCount / totalVotes) * 100);
    }
    return 0;
  };

  const getPollSelected = (pollOptions) => {
    let pollData = pollOptions.filter(
      (pollOption) => pollOption.userSelected === true
    );

    return pollData && pollData.length > 0 ? pollData[0]._id : "";
  };

  const handlePollVote = async (pollOptionId, post) => {
    let payload = {
      pollOptionId: pollOptionId,
      postId: post._id,
    };

    try {
      let data = await dispatch(updatePollVote(payload));
      if (data && data.code === 200) {
        handleSinglePost(post);
      }
    } catch (error) {
      console.log("Failed to update poll");
    }
  };

  const ProgressComponent = (
    voteCount,
    totalVotes,
    selectedOption,
    pollOption
  ) => {
    let pollPercentage = calculatePollPercentage(voteCount, totalVotes);
    return (
      <div className="flex items-center justify-between mt-2 w-full gap-4">
        <Progress
          // className="w-full poll-bg-ff5c62"
          className={`w-full poll-bg-ff5c62 ${
            selectedOption === pollOption._id ? "border-secondary" : ""
          }`}
          height="32px"
          value={pollPercentage}
        ></Progress>
        <span className="w-16 font-semibold">{pollPercentage}%</span>
      </div>
    );
  };

  const handleUserStatus = async (status) => {
    try {
      let payload = new FormData();
      if (userData._id) {
        payload.append("userId", userData._id);
      }
      if (status) {
        payload.append("status", status);
      }
      const data = await dispatch(SaveBasicProfileDetails(payload));
      if (data && data.code === 200) {
        await dispatch(getUserByToken({ token: userData.token }));
        await dispatch(getSelectedTrainerData());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTestimonialStatus = async (testimonialId, testimonialStatus) => {
    try {
      let payload = {
        testimonialId: testimonialId,
        status: testimonialStatus,
      };
      const data = await dispatch(updateTestimonial(payload));
      if (data && data.code === 200) {
        await dispatch(getSelectedTrainerData());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteTestimonial = async (testimonalData) => {
    try {
      let payload = {
        testimonialId: testimonalData._id,
      };
      const data = await dispatch(deleteTestimonial(payload));
      if (data && data.code === 200) {
        await dispatch(getSelectedTrainerData());
        toast.success(`Review deleted successfully`);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Something went wrong!");
    }
  };

  const handleDeleteReply = async (reply, comment, post) => {
    try {
      let payload = {
        replyId: reply._id,
      };
      setReplyLoading(true);
      const data = await dispatch(deleteReply(payload));
      setReplyLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        handleSingleComment(comment, post);
        getCommentReply(comment);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReplyLoading(false);
    }
  };

  const handleDeleteComment = async (comment, post) => {
    try {
      let payload = {
        commentId: comment._id,
      };
      setCommentLoading(true);
      const data = await dispatch(deleteComment(payload));
      setCommentLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        getPostComment(post);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCommentLoading(false);
    }
  };

  return (
    <>
      {isEditProfile ? (
        <EditProfile />
      ) : (
        <div className="lg:p-6 md:p-6 lg:border md:border rounded-3px">
          {/* profile  */}
          <section>
            <div className="w-full ">
              <Image
                src={`${process.env.REACT_APP_STORAGE_URL}${
                  selectedTrainer.userId &&
                  selectedTrainer.userId.length > 0 &&
                  selectedTrainer.userId[0].coverImage
                }`}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}fit2go/undefined/primaryLogo-DpYo0uWB2xJu3JgeKKl92-1701935536`}
                alt="user-background"
                className="lg:rounded-lg md:rounded-lg w-full object-cover h-300px "
              />
            </div>
            <div className="lg:flex md:flex block gap-8 items-end justify-between minus3rem-mtop ">
              <div className="lg:flex md:flex items-end gap-4 lg:ml-8">
                <div className="relative h-32 w-32 mx-auto lg:pt-0 md:pt-4 pt-0">
                  <Image
                    src={`${process.env.REACT_APP_STORAGE_URL}${
                      selectedTrainer.userId &&
                      selectedTrainer.userId.length > 0 &&
                      selectedTrainer.userId[0].userImage
                    }`}
                    fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                    alt="profile"
                    className="mx-auto object-cover relative z-10 rounded-full border6px-fff h-32 w-32"
                  />
                  {selectedTrainer.userId &&
                  selectedTrainer.userId.length > 0 &&
                  selectedTrainer.userId[0].status &&
                  selectedTrainer.userId[0].status === "Offline" ? (
                    <FeatherIcon
                      icon="x-circle"
                      className="bg-white text-gray  rounded-full border-2  absolute bottom-4 zindex99 right-2  w-6 h-6 "
                    ></FeatherIcon>
                  ) : (
                    <p className="border-2 block absolute bottom-4 zindex99 right-2 bg-success w-5 h-5 rounded-full"></p>
                  )}
                </div>
                <div className="lg:mb-4 md:mb-4 lg:text-left md:text-left text-center">
                  <div className="flex items-center gap-4 lg:justify-start md:justify-start justify-center">
                    <h2 className="fsize24 font-semibold">
                      {`${
                        selectedTrainer.userId &&
                        selectedTrainer.userId.length > 0 &&
                        selectedTrainer.userId[0].firstName
                          ? selectedTrainer.userId[0].firstName
                          : ""
                      } ${
                        selectedTrainer.userId &&
                        selectedTrainer.userId.length > 0 &&
                        selectedTrainer.userId[0].lastName
                          ? selectedTrainer.userId[0].lastName
                          : ""
                      }`}
                    </h2>

                    <select
                      className="mini-select"
                      value={
                        selectedTrainer.userId &&
                        selectedTrainer.userId.length > 0 &&
                        selectedTrainer.userId[0].status
                          ? selectedTrainer.userId[0].status
                          : "Online"
                      }
                      onChange={(e) => handleUserStatus(e.target.value)}
                    >
                      <option value="Online">Available</option>
                      <option value="Offline">Offline</option>
                    </select>
                  </div>
                  <div className="lg:flex justify-center items-center gap-1">
                    <p className="uppercase clr-9c9c9c fsize16 sm-fsize14  font-medium">
                      {/* {selectedTrainer.userId &&
                    selectedTrainer.userId.length > 0 &&
                    selectedTrainer.userId[0].role} */}
                      {selectedTrainer.location
                        ? selectedTrainer.location
                        : "Singapore"}
                    </p>
                    <div className="hr1 lg:block md:hidden hidden"></div>
                    <div className="lg:flex md:flex items-center lg:gap-0 gap-4">
                      <p className="uppercase clr-9c9c9c fsize16 sm-fsize14  font-medium">
                        {selectedTrainer.testimonials &&
                        selectedTrainer.testimonials.length > 0
                          ? selectedTrainer.testimonials.length
                          : 0}{" "}
                        Review
                      </p>
                      <p className="uppercase flex items-center justify-center gap-2 lg:ml-4 clr-9c9c9c fsize16 sm-fsize14  font-medium">
                        <FaEye className="clr-ec1c24" />{" "}
                        {selectedTrainer.viewCount
                          ? selectedTrainer.viewCount
                          : 0}{" "}
                        Profile views
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-row md:flex-col lg:justify-center md:justify-center gap-4 lg:mb-4">
                <Button
                  width="120px"
                  md-width="120px"
                  height="50px"
                  variant="outline"
                  colorScheme="primary"
                  className="lg:mt-8 md:mt-0 mt-6 fsize14"
                  onClick={() => {
                    setPostAction("CREATE");
                    formik.handleReset();
                    onOpenPost();
                  }}
                >
                  CREATE POST
                </Button>

                <Modal isOpen={isOpenPost} onClose={onClosePost}>
                  <ModalOverlay />
                  <ModalContent>
                    <form onSubmit={formik.handleSubmit}>
                      <ModalHeader>{postAction} POST</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <FormControl
                          isInvalid={
                            !!formik.errors.mediaType &&
                            formik.touched.mediaType
                          }
                        >
                          <FormLabel className="fsize14">Select Type</FormLabel>
                          <Select
                            disabled={postAction === "EDIT"}
                            name="mediaType"
                            value={formik.values.mediaType}
                            onChange={formik.handleChange}
                          >
                            <option value="Text">Text</option>
                            <option value="Image">Image</option>
                            <option value="Video">Video</option>
                            <option value="Poll">Poll</option>
                          </Select>
                          {formik.touched.mediaType &&
                            formik.errors.mediaType && (
                              <FormErrorMessage>
                                {formik.errors.mediaType}
                              </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl
                          mt={4}
                          isInvalid={
                            !!formik.errors.caption && formik.touched.caption
                          }
                        >
                          <FormLabel className="fsize14">Caption</FormLabel>
                          <Textarea
                            name="caption"
                            value={formik.values.caption}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Type here"
                          />
                          {formik.touched.caption && formik.errors.caption && (
                            <FormErrorMessage>
                              {formik.errors.caption}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        {formik.values.mediaType === "Text" && (
                          <FormControl
                            mt={4}
                            isInvalid={
                              !!formik.errors.message && formik.touched.message
                            }
                          >
                            <FormLabel className="fsize14">
                              Description
                            </FormLabel>
                            {/* <Textarea
                              name="message"
                              value={formik.values.message}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                              placeholder="Type here"
                            /> */}
                            <ReactQuill
                              // theme="bubble"
                              // readOnly
                              value={formik.values.message}
                              onChange={async (value) => {
                                console.log(value);
                                await setFieldHandler("message", value);
                              }}
                              // onChange={formik.handleChange}
                            />
                            {formik.touched.message &&
                              formik.errors.message && (
                                <FormErrorMessage>
                                  {formik.errors.message}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        )}

                        {formik.values.mediaType === "Poll" && (
                          <>
                            {pollOptions.map((pollOption, index) => (
                              <div key={index}>
                                <FormControl
                                  mt={4}
                                  isInvalid={
                                    formik.errors.pollOptions &&
                                    formik.errors.pollOptions[index] &&
                                    formik.errors.pollOptions[index].message &&
                                    formik.touched.pollOptions &&
                                    formik.touched.pollOptions[index] &&
                                    formik.touched.pollOptions[index].message
                                  }
                                >
                                  <div>
                                    <div className="mb-4">
                                      <FormLabel className="fsize14 ">
                                        <div className="flex justify-between">
                                          Options {index + 1}{" "}
                                          {index > 1 && (
                                            <p
                                              onClick={() => {
                                                handleDeleteMoreOptions(index);
                                              }}
                                              className="text-primary  cursor-pointer fsize12 underline text-right font-semibold"
                                            >
                                              Delete
                                            </p>
                                          )}
                                        </div>
                                      </FormLabel>

                                      <Input
                                        id={`pollOptions.${index}.message`}
                                        name={`pollOptions.${index}.message`}
                                        value={
                                          formik.values.pollOptions[index] &&
                                          formik.values.pollOptions[index]
                                            .message
                                            ? formik.values.pollOptions[index]
                                                .message
                                            : ""
                                        }
                                        onChange={formik.handleChange}
                                        onBlur={handleTrim}
                                        placeholder={`Message ${index + 1}`}
                                      />

                                      {formik.touched.pollOptions &&
                                        formik.touched.pollOptions[index] &&
                                        formik.touched.pollOptions[index]
                                          .message &&
                                        formik.errors.pollOptions &&
                                        formik.errors.pollOptions[index] &&
                                        formik.errors.pollOptions[index]
                                          .message && (
                                          <FormErrorMessage>
                                            {formik.errors.pollOptions &&
                                              formik.errors.pollOptions[
                                                index
                                              ] &&
                                              formik.errors.pollOptions[index]
                                                .message}
                                          </FormErrorMessage>
                                        )}
                                    </div>
                                  </div>
                                </FormControl>
                              </div>
                            ))}
                            {pollOptions && pollOptions.length < 5 && (
                              <p
                                onClick={handleAddMoreOptions}
                                className="text-primary mt-1 cursor-pointer fsize12 underline text-right font-semibold"
                              >
                                Add More
                              </p>
                            )}
                          </>
                        )}

                        {(formik.values.mediaType === "Image" ||
                          formik.values.mediaType === "Video") && (
                          <FormControl
                            mt={4}
                            isInvalid={
                              !!formik.errors.media && formik.touched.media
                            }
                          >
                            <div className="mt-4 relative">
                              {formik.values.mediaType === "Image" ? (
                                <>
                                  {!formik.values.mediaPreview && (
                                    <UploaderBox
                                      accept="image/*"
                                      onFileSelect={async (file) =>
                                        setFieldHandler("media", file)
                                      }
                                      onPreview={async (prev) =>
                                        setFieldHandler("mediaPreview", prev)
                                      }
                                    />
                                  )}
                                  {formik.values.mediaPreview && (
                                    <>
                                      <Image
                                        src={getImage(
                                          formik.values.mediaPreview
                                        )}
                                        className="object-contain w-full h-200px"
                                      />
                                      <FeatherIcon
                                        onClick={() => {
                                          setFieldHandler("media", null);
                                          setFieldHandler("mediaPreview", null);
                                        }}
                                        icon="x"
                                        className="border bg-fff rounded-full absolute top-1 right-1 cursor-pointer"
                                        size={16}
                                      />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {!formik.values.mediaPreview && (
                                    <UploaderBox
                                      accept="video/*"
                                      onFileSelect={async (file) =>
                                        setFieldHandler("media", file)
                                      }
                                      onPreview={async (prev) =>
                                        setFieldHandler("mediaPreview", prev)
                                      }
                                    />
                                  )}
                                  {formik.values.mediaPreview && (
                                    <>
                                      <video
                                        controls
                                        src={getImage(
                                          formik.values.mediaPreview
                                        )}
                                      />
                                      <FeatherIcon
                                        onClick={() => {
                                          setFieldHandler("media", null);
                                          setFieldHandler("mediaPreview", null);
                                        }}
                                        icon="x"
                                        className="border  bg-fff rounded-full absolute top-1 right-1 cursor-pointer"
                                        size={16}
                                      />
                                      {/* <p
                                          onClick={() => {
                                            setFieldHandler("media", null);
                                            setFieldHandler(
                                              "mediaPreview",
                                              null
                                            );
                                          }}
                                        >
                                          X
                                        </p> */}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            {formik.touched.media && formik.errors.media && (
                              <FormErrorMessage>
                                {formik.errors.media}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        )}
                      </ModalBody>

                      <ModalFooter className="w-full flex justify-center ">
                        <Button
                          isLoading={postLoading}
                          spinner={
                            <ThreeDots
                              visible={true}
                              color="white"
                              radius={3}
                            />
                          }
                          type="submit"
                          colorScheme="primary"
                          className="w-full"
                          mr={3}
                        >
                          {postAction === "EDIT" ? "UPDATE" : "POST"}
                        </Button>
                        <Button
                          colorScheme="primary"
                          className="w-full"
                          variant="outline"
                          onClick={onClosePost}
                        >
                          CANCEL
                        </Button>
                      </ModalFooter>
                    </form>
                  </ModalContent>
                </Modal>
                <Button
                  width="120px"
                  height="50px"
                  colorScheme="primary"
                  className="lg:mt-8 md:mt-0 mt-6 fsize14"
                  onClick={() => setIsEditProfileHandler()}
                >
                  EDIT
                </Button>
              </div>
            </div>
          </section>

          {/* usercard */}
          <section className="lg:mt-12 md:mt-12 mt-8">
            <div className="w-full lg:flex md:flex ">
              <div className="w-full">
                <div className="lg:hidden md:hidden block text-right mb-2">
                  <p
                    className="text-gray flex justify-end items-center fsize18 gap-1"
                    ref={btnRef}
                    colorScheme="teal"
                    onClick={onOpenDrawer}
                  >
                    {/* <div className="flex  gap-2">
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={Youtube}
                          alt="youtube"
                          className="w-8 h-8"
                        />
                      </a>
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={Instagram}
                          alt="instagram"
                          className="w-8 h-8"
                        />
                      </a>
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={Facebook}
                          alt="facebook"
                          className="w-8 h-8"
                        />
                      </a>
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image src={X} alt="x" className="w-8 h-8" />
                      </a>
                    </div> */}
                  </p>

                  <Drawer
                    isOpen={isOpenDrawer}
                    placement="right"
                    onClose={onCloseDrawer}
                    finalFocusRef={btnRef}
                  >
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>Categories</DrawerHeader>
                      <DrawerBody className="sm-drawer-padding">
                        <div className="w-full ">
                          <div className="flex flex-col gap-4">
                            <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                              <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                                specialisation
                              </h2>
                              <div className="py-2 px-2">
                                <div className="flex flex-wrap gap-4">
                                  {buttonLabels.map((label, index) => (
                                    <Button
                                      key={index}
                                      className="fsize14 bg-fff"
                                    >
                                      {label}
                                    </Button>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {/* <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                          <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                            prefferred time
                          </h2>
                          <div className="py-2 px-2">
                            <div className="flex flex-wrap gap-4">
                              {timeSlots.map((timeSlot, index) => (
                                <Button
                                  key={index}
                                  variant="outline"
                                  colorScheme="primary"
                                  className="fsize12px bg-fff"
                                >
                                  {timeSlot}
                                </Button>
                              ))}
                            </div>
                          </div>
                        </div> */}
                            <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                              <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                                language
                              </h2>
                              <div className="py-2 px-2">
                                <div className="flex flex-wrap gap-4">
                                  {langaugeSlots.map((langaugeSlots, index) => (
                                    <Button
                                      key={index}
                                      variant="outline"
                                      colorScheme="primary"
                                      className="fsize12px bg-fff"
                                    >
                                      {langaugeSlots}
                                    </Button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </div>
                <Tabs variant="soft-rounded" colorScheme="green">
                  <div className="flex justify-between items-center bottom-border-ebebeb">
                    <TabList className=" pb-2">
                      <Tab className="lg:px-4  rounded-sm">About me</Tab>
                      <Tab className="lg:px-4   rounded-sm">Activity</Tab>
                      <Tab className="lg:px-4   rounded-sm">Reviews</Tab>
                    </TabList>
                    {/* <div className="flex mob-hidden gap-2">
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={Youtube}
                          alt="youtube"
                          className="w-8 h-8"
                        />
                      </a>
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={Instagram}
                          alt="instagram"
                          className="w-8 h-8"
                        />
                      </a>
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={Facebook}
                          alt="facebook"
                          className="w-8 h-8"
                        />
                      </a>
                      <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image src={X} alt="x" className="w-8 h-8" />
                      </a>
                    </div> */}
                  </div>

                  <TabPanels>
                    <TabPanel className="px-0px">
                      <div className="flex flex-col gap-4">
                        <div className="rounded-lg bg-f4f4f4  p-2">
                          <p className="text-black px-2 py-1.5 uppercase font-semibold">
                            Why hire me!
                          </p>
                          <div className="bg-white  rounded-lg">
                            <p className="clr-515151 px-2 py-1 fsize14 lh-26px sm-lh-22px">
                              {selectedTrainer && selectedTrainer.about
                                ? selectedTrainer.about
                                : "no-data"}
                            </p>
                          </div>
                        </div>

                        <div className="rounded-lg bg-f4f4f4  p-2">
                          <p className="text-black px-2 py-1.5 uppercase font-semibold">
                            Contact Details
                          </p>
                          <div className="rounded-lg bg-white">
                            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 p-2">
                              <div>
                                <h2 className="font-bold text-gray fsize12 uppercase">
                                  Email ID
                                </h2>
                                <div className=" mt-2">
                                  <p className="inline-flex items-center gap-2 fsize12 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                    <MdMail className="fsize16" />{" "}
                                    {selectedTrainer &&
                                      selectedTrainer.userId &&
                                      selectedTrainer.userId.length > 0 &&
                                      selectedTrainer.userId[0].email}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <h2 className="font-bold text-gray fsize12 uppercase">
                                  Phone Number
                                </h2>
                                <div className=" mt-2">
                                  <p className=" inline-flex items-center gap-2 fsize12 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                    <FaPhoneAlt /> +{" "}
                                    {`${
                                      selectedTrainer &&
                                      selectedTrainer.userId &&
                                      selectedTrainer.userId.length > 0 &&
                                      selectedTrainer.userId[0].countryCode
                                    } ${
                                      selectedTrainer &&
                                      selectedTrainer.userId &&
                                      selectedTrainer.userId.length > 0 &&
                                      selectedTrainer.userId[0].mobile
                                    } `}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <h2 className="font-bold text-gray fsize12 uppercase">
                                  Location
                                </h2>
                                <div className=" mt-2">
                                  <p className="inline-flex items-center gap-2 fsize12 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                    <FaMapMarkerAlt />
                                    {selectedTrainer.location}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <h2 className="pl-2 font-bold text-gray fsize12 uppercase">
                                  share
                                </h2>
                                <div className=" mt-2">
                                  <ShareSection
                                    selectedTrainer={selectedTrainer}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="rounded-lg bg-f4f4f4  p-2">
                          <p className="text-black px-2 py-1.5 uppercase font-semibold">
                            Other Details
                          </p>
                          <div className="rounded-lg bg-white">
                            <div
                              className="grid lg:grid-cols-2 md:grid-cols-1
                       grid-cols-1 gap-4 p-2"
                            >
                              <div>
                                <h2 className="font-bold text-gray fsize12 uppercase">
                                  Preferred Location
                                </h2>
                                <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                  {selectedTrainer &&
                                  selectedTrainer.trainingLocation &&
                                  selectedTrainer.trainingLocation.length >
                                    0 ? (
                                    selectedTrainer.trainingLocation.map(
                                      (tl) => (
                                        <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                          {tl}
                                        </p>
                                      )
                                    )
                                  ) : (
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      No Preferred Location
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-2">
                                <div>
                                  <h2 className="font-bold text-gray fsize12 uppercase">
                                    Training Type
                                  </h2>
                                  <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                    {selectedTrainer &&
                                    selectedTrainer.trainingType &&
                                    selectedTrainer.trainingType.length > 0 ? (
                                      selectedTrainer.trainingType.map((ty) => (
                                        <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                          {ty}
                                        </p>
                                      ))
                                    ) : (
                                      <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                        No Training Type
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div>
                                  <h2 className="font-bold text-gray fsize12 uppercase">
                                    Experience
                                  </h2>
                                  <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      {selectedTrainer &&
                                      selectedTrainer.experience
                                        ? selectedTrainer.experience
                                        : "No Experience"}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <h2 className="font-bold text-gray fsize12 uppercase">
                                    Age
                                  </h2>
                                  <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      {selectedTrainer &&
                                      selectedTrainer.userId &&
                                      selectedTrainer.userId.length > 0 &&
                                      selectedTrainer.userId[0].dob
                                        ? calculateAge(
                                            selectedTrainer.userId[0].dob
                                          )
                                        : "No Age"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedTrainer.qualification &&
                          selectedTrainer.qualification.length > 0 && (
                            <div className="rounded-lg bg-f4f4f4  p-2">
                              <p className="text-black px-2 py-1.5 uppercase font-semibold">
                                Qualification
                              </p>
                              <div className="rounded-lg bg-white">
                                <div
                                  className="grid lg:grid-cols-2 md:grid-cols-1
 grid-cols-1 gap-4 p-2"
                                >
                                  {selectedTrainer.qualification &&
                                  selectedTrainer.qualification.length > 0
                                    ? selectedTrainer.qualification.map((e) => {
                                        return (
                                          <div className="flex gap-2">
                                            <div>
                                              <Image
                                                src={
                                                  e.media
                                                    ? getImage(e.media)
                                                    : null
                                                }
                                                fallbackSrc="https://nimbusclustersg.blob.core.windows.net/server01/fit2go/asset/graduate.png"
                                                alt="logo"
                                                className="w-16 h-16 object-cover rounded-sm"
                                              />
                                            </div>
                                            <div>
                                              <h2 className="font-bold text-black uppercase">
                                                {e.typeName}
                                              </h2>
                                              <p className="fsize12 font-semibold text-black">
                                                {e.type}
                                              </p>
                                              <span className="fsize12 pr-2 text-gray">
                                                <b className="text-black">
                                                  Start
                                                </b>{" "}
                                                :{" "}
                                                {new Date(
                                                  e.startDate
                                                ).toLocaleDateString("en-IN")}
                                              </span>

                                              <span className="fsize12 text-gray">
                                                <b className="text-black">
                                                  End
                                                </b>{" "}
                                                :{" "}
                                                {new Date(
                                                  e.endDate
                                                ).toLocaleDateString("en-IN")}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      })
                                    : ""}
                                </div>
                              </div>
                            </div>
                          )}
                        {selectedTrainer.certification &&
                          selectedTrainer.certification.length > 0 && (
                            <div className="rounded-lg bg-f4f4f4  p-2">
                              <p className="text-black px-2 py-1.5 uppercase font-semibold">
                                certifcation
                              </p>
                              <div className="bg-white rounded-lg p-2">
                                <Swiper
                                  spaceBetween={25}
                                  slidesPerView={3.2}
                                  observer={true}
                                  observeParents={true}
                                  modules={[Navigation]}
                                  className="mySwiper"
                                  autoplay={{
                                    delay: "1000",
                                  }}
                                  loop
                                  navigation={{
                                    nextEl: ".image-swiper-button-next",
                                    prevEl: ".image-swiper-button-prev",
                                  }}
                                  breakpoints={{
                                    1536: {
                                      slidesPerView: 3.3,
                                      spaceBetween: 25,
                                    },
                                    1280: {
                                      slidesPerView: 3.3,
                                      spaceBetween: 25,
                                    },
                                    1024: {
                                      slidesPerView: 3.3,
                                      spaceBetween: 25,
                                    },
                                    991: {
                                      slidesPerView: 2.8,
                                      spaceBetween: 25,
                                    },
                                    767: {
                                      slidesPerView: 2,
                                      spaceBetween: 25,
                                    },
                                    640: {
                                      slidesPerView: 1.2,
                                      spaceBetween: 25,
                                    },
                                    425: {
                                      slidesPerView: 1.2,
                                      spaceBetween: 25,
                                    },
                                    320: {
                                      slidesPerView: 1.2,
                                      spaceBetween: 25,
                                    },
                                  }}
                                >
                                  {selectedTrainer.certification &&
                                  selectedTrainer.certification.length > 0
                                    ? selectedTrainer.certification.map((e) => {
                                        return (
                                          <SwiperSlide>
                                            <div className="flex flex-col">
                                              <h2 className="fsize16 text-black uppercase font-semibold">
                                              <a
                                                href={
                                                  e.media
                                                    ? getImage(e.media)
                                                    : ""
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <p className="flex gap-1 items-center">
                                                {e.courseName}
                                                  <ExternalLinkIcon
                                                    w={4}
                                                    h={4}
                                                    color="black"
                                                  />
                                                </p>
                                              </a>
                                              </h2>
                                             
                                                <p className="mt-1 fsize12 clr-6f6f6f">
                                                  {e.orgName}
                                                 
                                                </p>
                                              {/* <div className="w-full mt-2">
                                                <Image
                                                  fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                                  src={`${
                                                    process.env
                                                      .REACT_APP_STORAGE_URL
                                                  }${
                                                    selectedTrainer.certification &&
                                                    selectedTrainer
                                                      .certification.length >
                                                      0 &&
                                                    selectedTrainer
                                                      .certification[0].media
                                                  }`}
                                                  alt="card"
                                                  className="w-full rounded-md object-contain h-32"
                                                />
                                              </div> */}
                                            </div>
                                          </SwiperSlide>
                                        );
                                      })
                                    : ""}
                                </Swiper>
                              </div>
                            </div>
                          )}
                      </div>
                    </TabPanel>
                    <TabPanel className="px-0px w-full">
                      <div className="flex flex-col gap-4">
                        {postData &&
                        postData.result &&
                        postData.result.length > 0 ? (
                          postData.result.map((post) => (
                            <>
                              <div className="rounded-lg bg-f4f4f4  p-2">
                                <div className="flex items-center gap-2 justify-between lg:mb-0 md:mb-0 mb-2">
                                  <div className="flex items-center gap-2">
                                    <div className="lg:pt-0 md:pt-4 pt-0">
                                      <Image
                                        fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                        src={`${
                                          process.env.REACT_APP_STORAGE_URL
                                        }${
                                          post.userId &&
                                          post.userId.length > 0 &&
                                          post.userId[0] &&
                                          post.userId[0].userImage
                                            ? post.userId[0].userImage
                                            : null
                                        }`}
                                        alt="profile"
                                        className="mx-auto object-cover relative z-10 rounded-full border3px-fff lg:h-16 md:h-16  lg:w-16 md:w-16 w-12 h-12"
                                      />
                                    </div>
                                    <div className="text-left flex flex-col gap-1 justify-between">
                                      <h2 className="fsize18 sm-fsize16 font-semibold">
                                        {post.userId &&
                                        post.userId.length > 0 &&
                                        post.userId[0] &&
                                        post.userId[0].firstName
                                          ? post.userId[0].firstName
                                          : ""}{" "}
                                        {post.userId &&
                                        post.userId.length > 0 &&
                                        post.userId[0] &&
                                        post.userId[0].lastName
                                          ? post.userId[0].lastName
                                          : ""}
                                        {/* {post?.createdAt &&
                                          new Date(
                                            post.createdAt
                                          ).toLocaleDateString("en-IN")} */}
                                      </h2>

                                      <p className="clr-9c9c9c fsize14 md-fsize18px   font-medium">
                                        {post.trainerId &&
                                        post.trainerId.length > 0 &&
                                        post.trainerId[0] &&
                                        post.trainerId[0].location
                                          ? post.trainerId[0].location
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <Menu width="30">
                                    <MenuButton
                                      px={0}
                                      py={2}
                                      transition="all 0.2s"
                                      borderRadius="md"
                                      borderWidth="0px"
                                    >
                                      <FeatherIcon icon="more-vertical" />
                                    </MenuButton>
                                    <MenuList>
                                      {post.mediaType !== "Poll" && (
                                        <MenuItem
                                          onClick={() => {
                                            handlePostEdit(post);
                                          }}
                                        >
                                          Edit
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={() => handleDeletePost(post)}
                                      >
                                        Delete
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                </div>
                                <p className="fsize16 p-1 text-black">
                                  {post.caption ? post.caption : ""}
                                </p>
                                {post.mediaType && post.mediaType === "Text" ? (
                                  <div className="bg-white  m-1">
                                    <p className="px-2 py-2 fsize14 w-full maxheight500 object-contain overflow-auto">
                                      {/* {post.message ? post.message : ""} */}
                                      <ReactQuill
                                        theme="bubble"
                                        readOnly
                                        value={post.message}
                                      />
                                    </p>
                                  </div>
                                ) : post.mediaType === "Image" ? (
                                  <div className="bg-white  m-1">
                                    <Image
                                      src={`${
                                        process.env.REACT_APP_STORAGE_URL
                                      }${post.media ? post.media : null}`}
                                      fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                      alt="card-images"
                                      className="w-full maxheight500 object-contain"
                                    />
                                  </div>
                                ) : post.mediaType === "Video" ? (
                                  <div className="bg-white  m-1 flex justify-center">
                                    <video
                                      height="360"
                                      controls
                                      src={`${
                                        process.env.REACT_APP_STORAGE_URL
                                      }${post.media ? post.media : null}`}
                                      className="w-full"
                                    />
                                  </div>
                                ) : post.mediaType === "Poll" ? (
                                  <div className="bg-white p-4 m-1">
                                    <RadioGroup
                                      isDisabled={
                                        getPollSelected(post.pollOptions)
                                          ? true
                                          : false
                                      }
                                      value={
                                        post.pollOptions &&
                                        post.pollOptions.length > 0
                                          ? getPollSelected(post.pollOptions)
                                          : ""
                                      }
                                      onChange={(pollOptionId) =>
                                        handlePollVote(pollOptionId, post)
                                      }
                                    >
                                      {post.pollOptions &&
                                        post.pollOptions.length > 0 &&
                                        post.pollOptions.map((pollOption) => (
                                          <div className="relative prog mb-4">
                                            <Radio
                                              // isDisabled={
                                              //   !pollOption.userSelected
                                              // }
                                              className="radio"
                                              colorScheme="primary"
                                              value={pollOption._id}
                                            >
                                              {pollOption.message
                                                ? pollOption.message
                                                : ""}
                                            </Radio>
                                            {ProgressComponent(
                                              pollOption.voteCount,
                                              post.totalVotes,
                                              getPollSelected(post.pollOptions),
                                              pollOption
                                            )}
                                          </div>
                                        ))}
                                    </RadioGroup>
                                  </div>
                                ) : (
                                  "No Media Found"
                                )}

                                <p className="py-2 lg:px-8 md:px-8 px-4 flex gap-8">
                                  <div className="flex items-center gap-1 cursor-pointer">
                                    <Popover placement="top-start">
                                      <PopoverTrigger>
                                        <h2 px={0} py={0} className="btnn">
                                          {post.likes &&
                                          post.likes.length > 0 &&
                                          post.likes[0] &&
                                          post.likes[0].emojiId &&
                                          emojiData &&
                                          emojiData.result &&
                                          emojiData.result.length > 0 ? (
                                            getLikedEmoji(post, emojiData)
                                          ) : (
                                            <div className="flex gap-1 items-center fsize12 cursor-pointer">
                                              <FeatherIcon
                                                icon="thumbs-up"
                                                colorScheme="primary"
                                                size={14}
                                              />{" "}
                                              Likes
                                            </div>
                                          )}
                                        </h2>
                                      </PopoverTrigger>
                                      <PopoverContent
                                        className="fitcontent"
                                        border={0}
                                      >
                                        <PopoverBody padding={0} border={0}>
                                          {/* <EmojiPicker
                                          reactionsDefaultOpen={true}
                                          onReactionClick={handleReaction}
                                          className="w-full"
                                        /> */}
                                          <div className="flex p1020 gap-2">
                                            {emojiData &&
                                            emojiData.result &&
                                            emojiData.result.length > 0
                                              ? emojiData.result.map(
                                                  (emoji) => (
                                                    <div>
                                                      <Image
                                                        onClick={async () => {
                                                          let success =
                                                            await handlePostLike(
                                                              post,
                                                              emoji
                                                            );

                                                          if (success) {
                                                            await handleSinglePost(
                                                              post
                                                            );
                                                          }
                                                        }}
                                                        src={`${
                                                          process.env
                                                            .REACT_APP_STORAGE_URL
                                                        }${
                                                          emoji.media
                                                            ? emoji.media
                                                            : null
                                                        }`}
                                                        alt={emoji.name}
                                                        className="w-5 h-5 rounded-full cursor-pointer"
                                                      />
                                                    </div>
                                                  )
                                                )
                                              : "No Emoji"}
                                          </div>
                                        </PopoverBody>
                                      </PopoverContent>
                                    </Popover>
                                    <p className="fsize12">
                                      {post.totalLikes ? post.totalLikes : ""}
                                    </p>
                                  </div>
                                  <div
                                    className="flex gap-1 items-center fsize12 cursor-pointer"
                                    onClick={() => handleComment(post)}
                                  >
                                    <FeatherIcon
                                      icon="message-circle"
                                      colorScheme="primary"
                                      size={14}
                                    />{" "}
                                    Comments
                                    <div>
                                      {post.totalComments
                                        ? post.totalComments
                                        : ""}
                                    </div>
                                  </div>
                                </p>
                                {fetchCommentLoading ? (
                                  <div className="flex justify-center">
                                    {isCommentOpen &&
                                      isCommentOpen === post._id && (
                                        <ThreeDots
                                          visible={true}
                                          color="#ed1b24"
                                          radius={3}
                                        />
                                      )}
                                  </div>
                                ) : (
                                  <>
                                    {isCommentOpen &&
                                      isCommentOpen === post._id && (
                                        <>
                                          <div className="p-1">
                                            <InputGroup size="md">
                                              <Input
                                                pr="7rem"
                                                placeholder="Enter comment"
                                                className="bg-fff fsize14"
                                                value={commentMessage}
                                                onChange={(e) =>
                                                  setCommentMessage(
                                                    e.target.value
                                                  )
                                                }
                                                maxLength={250}
                                                onKeyDown={(e) =>
                                                  e.key === "Enter"
                                                    ? handleSubmitComment(post)
                                                    : ""
                                                }
                                              />
                                              <InputRightElement width="6.5rem">
                                                <Button
                                                  isLoading={commentLoading}
                                                  spinner={
                                                    <ThreeDots
                                                      visible={true}
                                                      color="#ed1b24"
                                                      radius={3}
                                                    />
                                                  }
                                                  h="1.75rem"
                                                  size="sm"
                                                  fontSize={12}
                                                  mr={1}
                                                  onClick={() =>
                                                    handleSubmitComment(post)
                                                  }
                                                >
                                                  COMMENT
                                                </Button>
                                              </InputRightElement>
                                            </InputGroup>
                                          </div>
                                          {commentData &&
                                            commentData.result &&
                                            commentData.result.length > 0 &&
                                            commentData.result.map(
                                              (comment) => (
                                                <div className="p-1 lg:flex md:flex flex-start w-full gap-3">
                                                  <div className="w-full">
                                                    <div className="bg-f4f4f4 p-2 rounded-lg">
                                                      <div className="flex gap-2">
                                                        <Image
                                                          src={`${
                                                            process.env
                                                              .REACT_APP_STORAGE_URL
                                                          }${
                                                            comment &&
                                                            comment.userId &&
                                                            comment.userId[0] &&
                                                            comment.userId[0]
                                                              .userImage
                                                              ? comment
                                                                  .userId[0]
                                                                  .userImage
                                                              : null
                                                          }`}
                                                          fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                                          alt="logo"
                                                          className="mt-1 w-8 h-8 rounded-full "
                                                        />
                                                        <div className="w-full">
                                                          <div className="flex justify-between  bg-fbfbfb p-2 rounded-md ">
                                                            <div className=" ">
                                                              <h2 className="font-semibold fsize16 sm-fsize14 uppercase">
                                                                {comment.userId &&
                                                                comment.userId
                                                                  .length > 0 &&
                                                                comment
                                                                  .userId[0] &&
                                                                comment
                                                                  .userId[0]
                                                                  .firstName
                                                                  ? comment
                                                                      .userId[0]
                                                                      .firstName
                                                                  : ""}{" "}
                                                                {comment.userId &&
                                                                comment.userId
                                                                  .length > 0 &&
                                                                comment
                                                                  .userId[0] &&
                                                                comment
                                                                  .userId[0]
                                                                  .lastName
                                                                  ? comment
                                                                      .userId[0]
                                                                      .lastName
                                                                  : ""}
                                                              </h2>
                                                              <p className="fsize14 sm-fsize12 mb-2 ">
                                                                {
                                                                  comment.message
                                                                }
                                                              </p>
                                                            </div>

                                                            <DeleteIcon
                                                              onClick={async () => {
                                                                Swal.fire({
                                                                  title:
                                                                    "warning",
                                                                  text: "Are you sure?",
                                                                  icon: "warning",
                                                                  showCancelButton: true,
                                                                  confirmButtonColor:
                                                                    "#2bc370",
                                                                  cancelButtonColor:
                                                                    "#d33",
                                                                  confirmButtonText:
                                                                    "Yes, delete it!",
                                                                }).then(
                                                                  async (
                                                                    result
                                                                  ) => {
                                                                    if (
                                                                      result.isConfirmed
                                                                    ) {
                                                                      await handleDeleteComment(
                                                                        comment,
                                                                        post
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                              className="fsize14 mt-1 mr-1 cursor-pointer"
                                                            />
                                                          </div>
                                                          {/* <div className="flex items-center gap-4 p-1">
                                                        <div className="flex gap-1 text-gray items-center fsize12 cursor-pointer">
                                                          <FeatherIcon
                                                            icon="thumbs-up"
                                                            size={12}
                                                          />{" "}
                                                          (1)
                                                        </div>
                                                        <div className="flex gap-1 text-gray items-center fsize12 cursor-pointer">
                                                          <FeatherIcon
                                                            icon="message-circle"
                                                            size={12}
                                                          />{" "}
                                                          (0)
                                                        </div>
                                                      </div> */}
                                                          <div className=" gap-4 px-2 pt-2">
                                                            <p className="flex gap-8">
                                                              <div className="flex items-center gap-1 cursor-pointer">
                                                                <Popover placement="top-start">
                                                                  <PopoverTrigger>
                                                                    <h2
                                                                      px={0}
                                                                      py={0}
                                                                      className="btnn"
                                                                    >
                                                                      {comment.likes &&
                                                                      comment
                                                                        .likes
                                                                        .length >
                                                                        0 &&
                                                                      comment
                                                                        .likes[0] &&
                                                                      comment
                                                                        .likes[0]
                                                                        .emojiId &&
                                                                      emojiData &&
                                                                      emojiData.result &&
                                                                      emojiData
                                                                        .result
                                                                        .length >
                                                                        0 ? (
                                                                        getLikedEmoji(
                                                                          comment,
                                                                          emojiData
                                                                        )
                                                                      ) : (
                                                                        <div className="flex gap-1 items-center fsize12 cursor-pointer">
                                                                          <FeatherIcon
                                                                            icon="thumbs-up"
                                                                            colorScheme="primary"
                                                                            size={
                                                                              14
                                                                            }
                                                                          />{" "}
                                                                          Likes
                                                                        </div>
                                                                      )}
                                                                    </h2>
                                                                  </PopoverTrigger>
                                                                  <PopoverContent
                                                                    className="fitcontent"
                                                                    border={0}
                                                                  >
                                                                    <PopoverBody
                                                                      padding={
                                                                        0
                                                                      }
                                                                      border={0}
                                                                    >
                                                                      <div className="flex p1020 gap-2">
                                                                        {emojiData &&
                                                                        emojiData.result &&
                                                                        emojiData
                                                                          .result
                                                                          .length >
                                                                          0
                                                                          ? emojiData.result.map(
                                                                              (
                                                                                emoji
                                                                              ) => (
                                                                                <div>
                                                                                  <Image
                                                                                    onClick={async () => {
                                                                                      let success =
                                                                                        await handleCommentLike(
                                                                                          comment,
                                                                                          emoji
                                                                                        );

                                                                                      if (
                                                                                        success
                                                                                      ) {
                                                                                        await handleSingleComment(
                                                                                          comment,
                                                                                          post
                                                                                        );
                                                                                      }
                                                                                    }}
                                                                                    src={`${
                                                                                      process
                                                                                        .env
                                                                                        .REACT_APP_STORAGE_URL
                                                                                    }${
                                                                                      emoji.media
                                                                                        ? emoji.media
                                                                                        : null
                                                                                    }`}
                                                                                    alt={
                                                                                      emoji.name
                                                                                    }
                                                                                    className="w-5 w-5"
                                                                                  />
                                                                                </div>
                                                                              )
                                                                            )
                                                                          : "No Emoji"}
                                                                      </div>
                                                                    </PopoverBody>
                                                                  </PopoverContent>
                                                                </Popover>
                                                                <p className="fsize12">
                                                                  {comment.totalLikes
                                                                    ? comment.totalLikes
                                                                    : ""}
                                                                </p>
                                                              </div>

                                                              <div
                                                                className="flex gap-1 items-center fsize12 cursor-pointer"
                                                                onClick={() =>
                                                                  handleReply(
                                                                    comment,
                                                                    post
                                                                  )
                                                                }
                                                              >
                                                                <FeatherIcon
                                                                  icon="message-circle"
                                                                  colorScheme="primary"
                                                                  size={14}
                                                                />{" "}
                                                                Reply{" "}
                                                                <div>
                                                                  {comment.totalReplies >
                                                                  0
                                                                    ? comment.totalReplies
                                                                    : 0}
                                                                </div>
                                                              </div>
                                                            </p>

                                                            {fetchReplyLoading ? (
                                                              <div className="flex justify-center">
                                                                {isReplyOpen &&
                                                                  isReplyOpen ===
                                                                    comment._id && (
                                                                    <ThreeDots
                                                                      visible={
                                                                        true
                                                                      }
                                                                      color="#ed1b24"
                                                                      radius={3}
                                                                    />
                                                                  )}
                                                              </div>
                                                            ) : (
                                                              <>
                                                                {isReplyOpen &&
                                                                  isReplyOpen ===
                                                                    comment._id && (
                                                                    <>
                                                                      <div className="pt-4 pl-2">
                                                                        <InputGroup size="md">
                                                                          <Input
                                                                            pr="7rem"
                                                                            placeholder="Enter comment"
                                                                            className="bg-fff fsize14"
                                                                            value={
                                                                              replyMessage
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              setReplyMessage(
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            }
                                                                            maxLength={
                                                                              250
                                                                            }
                                                                            onKeyDown={(
                                                                              e
                                                                            ) =>
                                                                              e.key ===
                                                                              "Enter"
                                                                                ? handleSubmitReply(
                                                                                    comment,
                                                                                    post
                                                                                  )
                                                                                : ""
                                                                            }
                                                                          />
                                                                          <InputRightElement width="4.5rem">
                                                                            <Button
                                                                              isLoading={
                                                                                replyLoading
                                                                              }
                                                                              spinner={
                                                                                <ThreeDots
                                                                                  visible={
                                                                                    true
                                                                                  }
                                                                                  color="#ed1b24"
                                                                                  radius={
                                                                                    3
                                                                                  }
                                                                                />
                                                                              }
                                                                              h="1.75rem"
                                                                              size="sm"
                                                                              fontSize={
                                                                                10
                                                                              }
                                                                              mr={
                                                                                1
                                                                              }
                                                                              onClick={() =>
                                                                                handleSubmitReply(
                                                                                  comment,
                                                                                  post
                                                                                )
                                                                              }
                                                                            >
                                                                              REPLY
                                                                            </Button>
                                                                          </InputRightElement>
                                                                        </InputGroup>
                                                                      </div>
                                                                      {replyData &&
                                                                        replyData.result &&
                                                                        replyData
                                                                          .result
                                                                          .length >
                                                                          0 &&
                                                                        replyData.result.map(
                                                                          (
                                                                            reply
                                                                          ) => (
                                                                            <div className="pt-2 pl-0 lg:flex md:flex flex-start w-full gap-3">
                                                                              <div className="w-full">
                                                                                <div className="bg-f4f4f4 p-2 rounded-lg">
                                                                                  <div className="flex gap-2 pl-2">
                                                                                    <Image
                                                                                      src={`${
                                                                                        process
                                                                                          .env
                                                                                          .REACT_APP_STORAGE_URL
                                                                                      }${
                                                                                        reply &&
                                                                                        reply.userId &&
                                                                                        reply
                                                                                          .userId[0] &&
                                                                                        reply
                                                                                          .userId[0]
                                                                                          .userImage
                                                                                          ? reply
                                                                                              .userId[0]
                                                                                              .userImage
                                                                                          : null
                                                                                      }`}
                                                                                      fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                                                                      alt="logo"
                                                                                      className="mt-1 w-8 h-8 rounded-full "
                                                                                    />
                                                                                    <div className="w-full ">
                                                                                      <div className="bg-fbfbfb flex gap-2 w-full justify-between p-2 rounded-md">
                                                                                        <div>
                                                                                          <h2 className="font-semibold fsize14 sm-fsize12 uppercase">
                                                                                            {reply.userId &&
                                                                                            reply
                                                                                              .userId
                                                                                              .length >
                                                                                              0 &&
                                                                                            reply
                                                                                              .userId[0] &&
                                                                                            reply
                                                                                              .userId[0]
                                                                                              .firstName
                                                                                              ? reply
                                                                                                  .userId[0]
                                                                                                  .firstName
                                                                                              : ""}{" "}
                                                                                            {reply.userId &&
                                                                                            reply
                                                                                              .userId
                                                                                              .length >
                                                                                              0 &&
                                                                                            reply
                                                                                              .userId[0] &&
                                                                                            reply
                                                                                              .userId[0]
                                                                                              .lastName
                                                                                              ? reply
                                                                                                  .userId[0]
                                                                                                  .lastName
                                                                                              : ""}
                                                                                          </h2>
                                                                                          <p className="fsize12 mb-2 ">
                                                                                            {
                                                                                              reply.message
                                                                                            }
                                                                                          </p>
                                                                                        </div>
                                                                                        <DeleteIcon
                                                                                          onClick={async () => {
                                                                                            Swal.fire(
                                                                                              {
                                                                                                title:
                                                                                                  "warning",
                                                                                                text: "Are you sure?",
                                                                                                icon: "warning",
                                                                                                showCancelButton: true,
                                                                                                confirmButtonColor:
                                                                                                  "#2bc370",
                                                                                                cancelButtonColor:
                                                                                                  "#d33",
                                                                                                confirmButtonText:
                                                                                                  "Yes, delete it!",
                                                                                              }
                                                                                            ).then(
                                                                                              async (
                                                                                                result
                                                                                              ) => {
                                                                                                if (
                                                                                                  result.isConfirmed
                                                                                                ) {
                                                                                                  await handleDeleteReply(
                                                                                                    reply,
                                                                                                    comment,
                                                                                                    post
                                                                                                  );
                                                                                                }
                                                                                              }
                                                                                            );
                                                                                          }}
                                                                                          className="fsize14 mt-1 mr-1 cursor-pointer"
                                                                                        />
                                                                                      </div>
                                                                                      <div className="flex items-center gap-4 pt-2 pl-1">
                                                                                        <Popover placement="top-start">
                                                                                          <PopoverTrigger>
                                                                                            <h2
                                                                                              px={
                                                                                                0
                                                                                              }
                                                                                              py={
                                                                                                0
                                                                                              }
                                                                                              className="btnn"
                                                                                            >
                                                                                              {reply.likes &&
                                                                                              reply
                                                                                                .likes
                                                                                                .length >
                                                                                                0 &&
                                                                                              reply
                                                                                                .likes[0] &&
                                                                                              reply
                                                                                                .likes[0]
                                                                                                .emojiId &&
                                                                                              emojiData &&
                                                                                              emojiData.result &&
                                                                                              emojiData
                                                                                                .result
                                                                                                .length >
                                                                                                0 ? (
                                                                                                getLikedEmoji(
                                                                                                  reply,
                                                                                                  emojiData
                                                                                                )
                                                                                              ) : (
                                                                                                <div className="flex gap-1 items-center fsize12 cursor-pointer">
                                                                                                  <FeatherIcon
                                                                                                    icon="thumbs-up"
                                                                                                    colorScheme="primary"
                                                                                                    size={
                                                                                                      14
                                                                                                    }
                                                                                                  />{" "}
                                                                                                  Likes
                                                                                                </div>
                                                                                              )}
                                                                                            </h2>
                                                                                          </PopoverTrigger>
                                                                                          <PopoverContent
                                                                                            className="fitcontent"
                                                                                            border={
                                                                                              0
                                                                                            }
                                                                                          >
                                                                                            <PopoverBody
                                                                                              padding={
                                                                                                0
                                                                                              }
                                                                                              border={
                                                                                                0
                                                                                              }
                                                                                            >
                                                                                              <div className="flex p1020 gap-2">
                                                                                                {emojiData &&
                                                                                                emojiData.result &&
                                                                                                emojiData
                                                                                                  .result
                                                                                                  .length >
                                                                                                  0
                                                                                                  ? emojiData.result.map(
                                                                                                      (
                                                                                                        emoji
                                                                                                      ) => (
                                                                                                        <div>
                                                                                                          <Image
                                                                                                            onClick={async () => {
                                                                                                              let success =
                                                                                                                await handleReplyLike(
                                                                                                                  reply,
                                                                                                                  emoji
                                                                                                                );

                                                                                                              if (
                                                                                                                success
                                                                                                              ) {
                                                                                                                await handleSingleReply(
                                                                                                                  reply,
                                                                                                                  comment,
                                                                                                                  post
                                                                                                                );
                                                                                                              }
                                                                                                            }}
                                                                                                            src={`${
                                                                                                              process
                                                                                                                .env
                                                                                                                .REACT_APP_STORAGE_URL
                                                                                                            }${
                                                                                                              emoji.media
                                                                                                                ? emoji.media
                                                                                                                : null
                                                                                                            }`}
                                                                                                            alt={
                                                                                                              emoji.name
                                                                                                            }
                                                                                                            className="w-5 h-5 rounded-full cursor-pointer"
                                                                                                          />
                                                                                                        </div>
                                                                                                      )
                                                                                                    )
                                                                                                  : "No Emoji"}
                                                                                              </div>
                                                                                            </PopoverBody>
                                                                                          </PopoverContent>
                                                                                        </Popover>
                                                                                        <div>
                                                                                          <p className="fsize12">
                                                                                            {reply.totalLikes
                                                                                              ? reply.totalLikes
                                                                                              : ""}
                                                                                          </p>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          )
                                                                        )}
                                                                    </>
                                                                  )}
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          ))
                        ) : (
                          <div className="text-center">
                            <p>No Post</p>
                          </div>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel className="px-0px w-full">
                      <div className="pt-0">
                        <div className="flex justify-end">
                          {/* <h1 className="font-semibold uppercase fsize20 sm-fsize16">
                            [4,129 ratings] 5000 students
                          </h1> */}
                          <p className="flex items-center font-semibold uppercase fsize18 sm-fsize16">
                            {`[${selectedTrainer.rating}]`}{" "}
                            <ReactStars
                              count={1}
                              value={1}
                              size={20}
                              edit={false}
                              activeColor="#ffd700"
                            />
                          </p>
                        </div>
                        <div className="pt-1">
                          {selectedTrainer.testimonials &&
                          selectedTrainer.testimonials.length > 0 ? (
                            selectedTrainer.testimonials.map((e) => {
                              return (
                                <div key={e._id}>
                                  <div className="py-2 shadow-lg p-3 rounded-lg mb-4">
                                    <div className="lg:flex md:flex flex-start w-full gap-3">
                                      <div className="w-full">
                                        <div className="bg-f4f4f4 p-2 rounded-lg">
                                          <div className="flex justify-between">
                                            <div className="flex items-start gap-2">
                                              <Image
                                                fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                                src={`${
                                                  process.env
                                                    .REACT_APP_STORAGE_URL
                                                }${
                                                  e.userId &&
                                                  e.userId.length > 0 &&
                                                  e.userId[0].userImage
                                                }`}
                                                alt="logo"
                                                className="w-10 h-10 rounded-full "
                                              />
                                              <div className="flex flex-col">
                                                <div className="lg:flex  items-center gap-2">
                                                  <h2 className="font-semibold fsize16 uppercase">
                                                    {/* {e.name ? e.name : "Unknown"} */}
                                                    {e.userId &&
                                                    e.userId.length > 0 &&
                                                    e.userId[0].firstName
                                                      ? `${e.userId[0].firstName} `
                                                      : ""}
                                                    {e.userId &&
                                                    e.userId.length > 0 &&
                                                    e.userId[0].lastName
                                                      ? `${e.userId[0].lastName}`
                                                      : ""}
                                                  </h2>
                                                  <div className="flex items-center gap-1">
                                                    <span className="fsize6 text-gray">
                                                      &#9679;
                                                    </span>
                                                    <p className="text-gray fsize12 mr-2 pt-1">
                                                      {e.createdAt &&
                                                        new Date(
                                                          e.createdAt
                                                        ).toLocaleDateString(
                                                          "en-IN",
                                                          {
                                                            month: "long",
                                                            day: "numeric",
                                                            year: "numeric",
                                                          }
                                                        )}
                                                    </p>

                                                    {e.status !== "Pending" && (
                                                      <Badge
                                                        colorScheme={
                                                          e.status ===
                                                          "Approved"
                                                            ? "green"
                                                            : "red"
                                                        }
                                                      >
                                                        {e.status}
                                                      </Badge>
                                                    )}
                                                  </div>
                                                </div>

                                                <ReactStars
                                                  count={5}
                                                  value={
                                                    e.rating ? e.rating : 0
                                                  }
                                                  size={12}
                                                  edit={false}
                                                  activeColor="#ffd700"
                                                />
                                              </div>
                                            </div>

                                            <DeleteIcon
                                              onClick={async () => {
                                                Swal.fire({
                                                  title: "warning",
                                                  text: "Are you sure?",
                                                  icon: "warning",
                                                  showCancelButton: true,
                                                  confirmButtonColor: "#2bc370",
                                                  cancelButtonColor: "#d33",
                                                  confirmButtonText:
                                                    "Yes, delete it!",
                                                }).then(async (result) => {
                                                  if (result.isConfirmed) {
                                                    await handleDeleteTestimonial(
                                                      e
                                                    );
                                                  }
                                                });
                                              }}
                                              className="fsize14 mt-1 mr-1 cursor-pointer"
                                            />
                                          </div>
                                          <p className="fsize14 mt-1">
                                            {e.testimonial
                                              ? e.testimonial
                                              : "No testimonial"}
                                          </p>
                                        </div>
                                        {e.status && e.status === "Pending" && (
                                          <>
                                            <div className="flex items-center gap-4 mt-2">
                                              <Button
                                                leftIcon={<CheckIcon />}
                                                colorScheme="green"
                                                variant="outline"
                                                height={8}
                                                className="fsize12"
                                                onClick={() => {
                                                  handleTestimonialStatus(
                                                    e._id,
                                                    "Approved"
                                                  );
                                                }}
                                              >
                                                Accept
                                              </Button>
                                              <Button
                                                leftIcon={<CloseIcon />}
                                                colorScheme="red"
                                                variant="outline"
                                                height={8}
                                                className="fsize12"
                                                onClick={() => {
                                                  handleTestimonialStatus(
                                                    e._id,
                                                    "Rejected"
                                                  );
                                                }}
                                              >
                                                Reject
                                              </Button>
                                              {/* <p className="fsize12">Reply (0)</p> */}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                // <div className="mb-6 lg:flex md:flex flex-start w-full gap-3">
                                //   <div className="w-full">
                                //     <div className="bg-f4f4f4 p-2 rounded-lg">
                                //       <div className="flex justify-between">
                                //         <div className="flex items-center gap-2">
                                //           <img
                                //             src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                //             alt="logo"
                                //             className="w-6 h-6 rounded-full "
                                //           />
                                //           <h2 className="font-semibold fsize16 sm-fsize14 uppercase">
                                //             {e.name}
                                //           </h2>
                                //           <div className="flex items-center gap-1">
                                //             <span className="fsize6 text-gray">
                                //               &#9679;
                                //             </span>
                                //             <p className="text-gray fsize12 mr-2 pt-1">
                                //               Feb 12, 2024
                                //             </p>
                                //           </div>
                                //         </div>
                                //         <p>{e.rating} Rating</p>
                                //       </div>
                                //       <p className="fsize14 mt-1">
                                //         {e.testimonial}
                                //       </p>
                                //     </div>
                                //     <div className="flex items-center gap-8 mt-2">
                                //       <div className="flex gap-2">
                                //         <FaRegThumbsUp />
                                //         <p className="fsize12">Helpful(0)</p>
                                //       </div>
                                //       {/* <p className="fsize12">Reply (0)</p> */}
                                //     </div>
                                //   </div>
                                // </div>
                              );
                            })
                          ) : (
                            <p className="text-center">No Reviews</p>
                          )}
                        </div>
                        {/* {selectedTrainer.testimonials &&
                          selectedTrainer.testimonials.length > 0 && (
                            <h2
                              onClick={loadMoreContents}
                              className="cursor-pointer text-primary uppercase fsize16 md:fsize14 sm-fsize14 underline"
                            >
                              LOAD MORE COMMENTS
                            </h2>
                          )} */}
                      </div>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default UserProfile;
