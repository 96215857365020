const initialState = {
  Plan: {},
  AllPlan: {},
};

const Plan = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PLAN":
      return { ...state, Plan: action.payload };
    case "SET_ALLPLAN":
      return { ...state, AllPlan: action.payload };
    default:
      return state;
  }
};

export default Plan;
