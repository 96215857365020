import API from "../../repositoryFactory";

const createPlan = "create";
const getPlan = "get";
const getAllPlan = "getall";
const getAdminPlan = "getAdmin";
const updatePlan = "update";
const deletePlan = "delete";

const reportRoute = (route) => {
  return `fit2go/subscription/plan/${route}`;
};

export default {
  createPlan(payload) {
    return API.post(reportRoute(createPlan), payload);
  },
  getPlan(payload) {
    return API.post(reportRoute(getPlan), payload);
  },
  getAllPlan(payload) {
    return API.post(reportRoute(getAllPlan), payload);
  },
  getAdminPlan(payload) {
    return API.post(reportRoute(getAdminPlan), payload);
  },
  updatePlan(payload) {
    return API.post(reportRoute(updatePlan), payload);
  },
  deletePlan(payload) {
    return API.post(reportRoute(deletePlan), payload);
  },
};
