import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { pixabaySearch, verifyOtp } from '../redux/cms/cms-actions';
import OtpInput from 'react-otp-input';
import { createOtp } from '../redux/cms/cms-actions';
import { Button } from '@chakra-ui/react';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
// const otpDialog = () => {
export default props => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpReceived, setIsOtpReceived] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log(otp);

  async function mount() {
    console.log(props, 'props');
    if (isOtpSent && !isOtpReceived && !isAuthenticated) {
      console.log(props);
      let data = await dispatch(createOtp(props));
      if (data) setIsOtpReceived(true);
      console.log(data);
    }

    if (otp.length === 6 && isOtpReceived && !isAuthenticated) {
      let payload = {
        otp: otp,
        token: props.token,
      };
      let { data } = await dispatch(verifyOtp(payload));
      console.log(data);
      if (data.error && (data.error === 403 || data.error === 401)) {
        console.log(data.messsage);
        Swal.fire({
          title: 'OTP!',
          text: `${data.messsage}`,
          icon: 'error',
          confirmButtonColor: '#2bc370',
        });
        setOtp('');
      } else if (data.success) {
        setIsAuthenticated(true);
        setIsOtpReceived(false);

        localStorage.setItem('cmsToken', data.token);
        let tempurl = window.location.href.split('/');
        let url = window.location.href.replace(
          '/' + tempurl[tempurl.length - 1],
          ''
        );
        window.location.href = url;
        console.log(url, 'url');
      } else {
        Swal.fire({
          title: 'OTP!',
          text: 'Authentication Failed',
          icon: 'error',
          confirmButtonColor: '#2bc370',
        });
        setOtp('');
        console.log('Authentication Failed');
      }
    }
  }
  mount();
  return (
    <div className="px-8 py-6">
      <div className="c-otpInput c-inputsize flex gap-2  justify-center items-center my-4">
        {!isOtpSent ? (
          <Button
            colorScheme="primary"
            px="10"
            py="6"
            onClick={() => setIsOtpSent(true)}
          >
            {' '}
            Send OTP
          </Button>
        ) : (
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            separator={<span>-</span>}
            renderInput={props => <input {...props} />}
          />
        )}
      </div>
    </div>
  );
};

// export default otpDialog;
