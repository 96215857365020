import apis from "../../../apis/client";

export const createFitnessCategory = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.fitnessCategory.createFitnessCategory(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const updateFitnessCategory = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.fitnessCategory.updateFitnessCategory(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getFitnessCategory = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.fitnessCategory.getFitnessCategory(payload);

      if (data) {
        dispatch(setFitnessCategory(data.data));
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const deleteFitnessCategory = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.fitnessCategory.deleteFitnessCategory(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

export const setFitnessCategory = (data) => {
  return {
    type: "SET_FITNESS_CATEGORY",
    payload: data,
  };
};
