import React from "react";
import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";
import Fade from "react-reveal/Fade";

const HeroSection13 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section>
      <div className="bg-secondary">
        <div className="lg:flex md:flex sm-reverse lg:px-0 md:px-4 px-5 items-center lg:py-0 md:py-10 py-6">
          <div className="lg:w-3/5 md:w-3/5 lg:px-20 md:pr-4 md:pl-8">
            <Fade left>
              <div className="flex items-center gap-4 mb-2">
                <p
                  className="text-white fsize12  uppercase dynamicStyle"
                  id="4708772321"
                >
                  {data ? data["4708772321"] : "EDUCATE"}
                </p>
                <div className="red-circle"></div>
                <p
                  className="text-white fsize12  uppercase dynamicStyle"
                  id="9794799088"
                >
                  {data ? data["9794799088"] : "CONNECT"}
                </p>
                <div className="red-circle"></div>
                <p
                  className="text-white fsize12  uppercase dynamicStyle"
                  id="2420461269"
                >
                  {data ? data["2420461269"] : "MOTIVATE"}
                </p>
              </div>
              <h2
                id="6085812859"
                className="lg:text-3xl md:text-2xl uppercase text-end  text-white text-xl font-semibold dynamicStyle"
              >
                {data
                  ? data["6085812859"]
                  : "TRANSFORM YOUR BODY WITH STRENGTH AND CONDITIONING"}
              </h2>
              <p
                id="2298368083"
                dangerouslySetInnerHTML={{
                  __html: data ? data["2298368083"] : "no-data",
                }}
                className="text-white fsize16 md-fsize14 sm-fsize14 lg:leading-7 md:leading-7 leading-6 opacity-70 lg:mt-4 md:mt-4 mt-2 dynamicStyle"
              ></p>
              <div className="flex items-center flex-wrap lg:gap-4 md:gap-4 gap-2 mt-8">
                {/* <a href="/"> */}
                <p
                  id="1213118231"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["1213118231"] : "no-data",
                  }}
                  className="bgg py-2 px-4 rounded-full text-white fsize16 sm-fsize12 dynamicStyle"
                ></p>
                {/* </a> */}
                {/* <a href="/"> */}
                <p
                  id="4332201590"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["4332201590"] : "no-data",
                  }}
                  className="bgg py-2 px-4 rounded-full text-white fsize16 sm-fsize12 dynamicStyle"
                ></p>
                {/* </a> */}
                {/* <a href="/"> */}
                <p
                  id="4408655741"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["4408655741"] : "no-data",
                  }}
                  className="bgg py-2 px-4 rounded-full text-white fsize16 sm-fsize12 dynamicStyle"
                ></p>
                {/* </a> */}
              </div>
            </Fade>
          </div>

          <div
            className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full dynamicStyle2"
            id="7795915857"
          >
            <Image
              src={data ? getImage(data["7795915857"]) : ""}
              alt="heroImage"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              className=" object-cover w-full sm-h250px minh450px object-contain "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection13;
