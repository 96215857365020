import API from "../repositoryFactory";

const addTrainer = "addTrainer";
const getTrainer = "getTrainer";
const updateTrainer = "updateTrainer";
const deleteTrainer = "deleteTrainer";

//Email
const trainerPaymentSuccessEmail = "trainerPaymentSuccessEmail";
const trainerPaymentFailedEmail = "trainerPaymentFailedEmail";

//Qualification
const createQualification = "CreateQualification";
const editQualification = "EditQualification";
const deleteQualification = "DeleteQualification";

//Certification
const createCertification = "CreateCertification";
const editCertification = "EditCertification";
const deleteCertification = "DeleteCertification";

//Media Upload
const uploadMedia = "uploadMedia";

const getTrainerLocations = "getTrainerLocations";
const UpdateUserView = "UpdateUserView";

const trainerRoute = (route) => {
  return `fit2go/trainer/${route}`;
};

export default {
  addTrainer(payload) {
    return API.post(trainerRoute(addTrainer), payload);
  },
  getTrainer(payload) {
    return API.post(trainerRoute(getTrainer), payload);
  },
  getTrainerById(payload) {
    return API.post(trainerRoute(getTrainer), payload);
  },
  updateTrainer(payload) {
    return API.post(trainerRoute(updateTrainer), payload);
  },
  deleteTrainer(payload) {
    return API.post(trainerRoute(deleteTrainer), payload);
  },
  getTrainerLocations(payload) {
    return API.post(trainerRoute(getTrainerLocations), payload);
  },

  //Email
  trainerPaymentSuccessEmail(payload) {
    return API.post(trainerRoute(trainerPaymentSuccessEmail), payload);
  },
  trainerPaymentFailedEmail(payload) {
    return API.post(trainerRoute(trainerPaymentFailedEmail), payload);
  },

  //Qualification
  createQualification(payload) {
    return API.post(trainerRoute(createQualification), payload);
  },
  editQualification(payload) {
    return API.post(trainerRoute(editQualification), payload);
  },
  deleteQualification(payload) {
    return API.post(trainerRoute(deleteQualification), payload);
  },

  //Certification
  createCertification(payload) {
    return API.post(trainerRoute(createCertification), payload);
  },
  editCertification(payload) {
    return API.post(trainerRoute(editCertification), payload);
  },
  deleteCertification(payload) {
    return API.post(trainerRoute(deleteCertification), payload);
  },

  //Media Upload
  uploadMedia(payload) {
    return API.post(trainerRoute(uploadMedia), payload);
  },

  UpdateUserView(payload) {
    return API.post(trainerRoute(UpdateUserView), payload);
  },
};
