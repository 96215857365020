import React, { Fragment, useEffect, useState } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import ScrollToTop from "./components/ScrollToTop";
//router
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { otherLogin, getUserByToken } from "./redux/auth/auth-action.js";
import Master from "./pages/allSections/Master";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { getProject } from "./redux/userDetails/user-actions.js";

//apis
import { getAllMenu } from "./redux/menu/menu-actions.js";
import { getBrandProfile } from "./redux/brandDetails/bd-actions.js";
import { getCms, getSections } from "./redux/cms/cms-actions.js";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Appointment from "pages/allSections/mainProfile/components/Appointment";
import BookAppointment from "pages/allSections/components/BookAppointment";
import { getGateway } from "redux/payment/gateway-actions";
import { toast } from "react-toastify";
import Invoice from "pages/allSections/components/Invoice";
import { connectToWS } from "redux/chat/chat-websocket";

const App = () => {
  const dispatch = useDispatch();

  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let getRoutesNames = useSelector((state) => state.cms.routeNames);
  let getOtherLogin = useSelector((state) => state.AuthDetails.otherLogin);
  // const [dynamicRoutes, setDynamicRoutes] = useState(['test', 'example']);
  // let menuData = useSelector(state => state.menu.menu);
  const [primaryColor, setPrimaryColor] = useState("#7a4189");
  const [secondaryColor, setSecondaryColor] = useState("#7a4189");
  const [tertiaryColor, setTertiaryColor] = useState("#7a4189");
  console.log(brandProfile, "brand");

  const catchLogin = async () => {
    try {
      dispatch(otherLogin(""));
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      const decodedToken = token ? JSON.parse(decodeURIComponent(token)) : null;

      if (decodedToken) {
        const data = await dispatch(getUserByToken({ token: decodedToken }));

        if (data && data.code === 200) {
          // Swal.fire({
          //   title: "Success",
          //   text: `Loggedin successfully`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success("Loggedin successfully");
          window.history.replaceState(null, "", "/home");
        } else {
          if (data.message) {
            // Swal.fire({
            //   title: "Error",
            //   text: data.message,
            //   icon: "error",
            // });
            toast.error(data.message);
          }
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    async function mount() {
      await dispatch(getAllMenu());
      await dispatch(getProject());
      await dispatch(
        getBrandProfile({
          projectId: process.env.REACT_APP_ID,
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
        })
      );
      await dispatch(getCms());
      await dispatch(getSections());
      await dispatch(getBrandProfile());
      await dispatch(getGateway());

      if (getOtherLogin) {
        catchLogin();
      }

      const fontToLoad = brandProfile ? brandProfile.primaryFont : "sans-serif";
      const fontLink = document.createElement("link");

      if (fontToLoad) {
        fontLink.rel = "stylesheet";
        fontLink.href = `https://fonts.googleapis.com/css?family=${fontToLoad.replace(
          / /g,
          "+"
        )}`;
      }
      if (brandProfile) {
        const primaryColor = brandProfile.primaryColor;
        const secondaryColor = brandProfile.secondaryColor;
        const tertiaryColor = brandProfile.tertiaryColor;
        console.log(primaryColor, "COLOR");
        setPrimaryColor(primaryColor);
        setSecondaryColor(secondaryColor);
        setTertiaryColor(tertiaryColor);
      }

      document.head.appendChild(fontLink);

      fontLink.addEventListener("load", () => {
        const content = document.getElementById("root");
        content.style.fontFamily = `${fontToLoad},sans-serif`;
      });
    }

    mount();
    // eslint-disable-next-line
  }, [dispatch, primaryColor, secondaryColor, tertiaryColor]);

  connectToWS();

  const theme = extendTheme({
    colors: {
      primary: {
        500: `${primaryColor}`,
      },
      secondary: {
        500: `${secondaryColor}`,
      },
      tertiary: {
        500: `${tertiaryColor}`,
      },
    },
  });

  //   dispatch(getBrandProfile());
  // }, []);
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--chakra-colors-tertiary",
        brandProfile.tertiaryColor
      );
  }
  applyThemeToDocument();

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Routes>
          {getRoutesNames &&
            !!getRoutesNames.length &&
            getRoutesNames.map((dynRoute, index) => {
              return (
                <Fragment key={dynRoute}>
                  <Route
                    key={`${dynRoute}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}`}
                    element={<Master routeName={dynRoute} />}
                  />
                  <Route
                    key={`${dynRoute}${index}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}/cms/:token`}
                    element={<Master routeName={dynRoute} />}
                  />
                  <Route
                    key={`${dynRoute}${index}${index}`}
                    path={`/${dynRoute.toLocaleLowerCase()}/:blogId`}
                    element={<Master routeName={dynRoute} />}
                  />
                </Fragment>
              );
            })}

          <Route path="appointment" element={<BookAppointment />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="/" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
