import comment from "./comment";
import emoji from "./emoji";
import like from "./like";
import post from "./post";
import reply from "./reply";
import report from "./report";

export default {
  comment,
  emoji,
  like,
  post,
  reply,
  report,
};
