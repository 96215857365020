import React from 'react';

const HeroSection10 = () => {
  return (
    <section className="bg-a2a2a2 relative">
      <img
        src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
        alt="hero"
        className="w-full h-580px object-cover bg-a2a2a2 "
      />

      <div className="absolute top-0 w-full h-580px lg:flex md:flex  flex justify-center   h-580px">
        <div className=" w-full  flex flex-col justify-center items-center">
          <h1 className="hero-header mb-8 clr-fff">Lorem ipsum generated</h1>
          <p className="fsize20 clr-fff">Lorem ipsum generated</p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection10;
