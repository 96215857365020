import { Button, Image } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useFormik } from "formik";
import * as Yup from "yup";
import imageVectorBottom from "assets/images/custom/Vector5.png";
import { addNewsletter } from "../../../redux/newsletter/newsletter-actions";
import {
  Input,
  InputGroup,
  FormErrorMessage,
  FormControl,
  InputRightElement,
} from "@chakra-ui/react";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import { toast } from "react-toastify";

const FeedbackSection = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    try {
      let data = await dispatch(addNewsletter(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Newsletter",
        //   text: `Subscribed to newsletter`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Subscribed to newsletter`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <section className="relative">
      <div className="container mx-auto relative zindex9  lg:py-16 md:py-12 py-8 lg:px-12 md:px-8 px-5">
        <form
          onSubmit={formik.handleSubmit}
          className="bggradient-mid   lg:rounded-lg md:rounded-lg rounded-md"
        >
          <div className="w-full lg:py-24 md:py-16 py-8 lg:px-0 md:px-0 px-6 text-center">
            <h2
              id="4813626074"
              className="dynamicStyle font-semibold text-white fsize32 md-fsize24 sm-fsize20 lheight28px uppercase"
            >
              {data
                ? data["4813626074"]
                : "SUBSCRIBE for fitness insights and tips"}
            </h2>
            <p
              id="4813626074"
              dangerouslySetInnerHTML={{
                __html: data ? data["4813626074"] : "no-data",
              }}
              className="dynamicStyle text-white fsize14 lg:mt-0 md:mt-0 mt-2 "
            ></p>
            <div className="lg:w-1/2 md:w-1/2 mx-auto flex justify-center lg:mt-6 md:mt-6 mt-4">
              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
              >
                <InputGroup>
                  <Input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    className="w-4/5 backdrop-filer text-black h45px fsize14 sm-fsize12  px-4 py-3 rounded-left-lg "
                    placeholder="Enter your email ID"
                  />

                  <Button
                    px="8"
                    className="f-normal uppercase h45px sm-fsize12  bg-white text-primary bg-white rounded-right-lg"
                    type="submit"
                  >
                    subscribe
                  </Button>
                </InputGroup>
                {formik.touched.email && formik.errors.email && (
                  <FormErrorMessage className="textwhite">
                    {formik.errors.email}
                  </FormErrorMessage>
                )}
              </FormControl>
            </div>
          </div>
        </form>
      </div>

      <Image src={imageVectorBottom} alt="image" className="homevectorbottom" />
    </section>
  );
};

export default FeedbackSection;
