import React from "react";
import cardBG from "../../../assets/images/CardBg.svg";
import { Image, CardBody, Card } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import Fade from "react-reveal/Fade";

import imageVectorBottom from "assets/images/custom/Vector5.png";
const FeatureCard = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  return (
    <section className="bg-secondary relative">
      <div className=" container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="text-center lg:mb-12 md:mb-12 mb-0 lg:mt-0 md:mt-0 mt-8">
          <h2
            id="4745715827"
            className="dynamicStyle lg:text-4xl md:text-2xl text-2xl text-white font-semibold"
          >
            {data ? data["4745715827"] : "HOW FIT2GOASIA HELP YOU"}
          </h2>
          <p
            id="2752292551"
            className="dynamicStyle lg:text-base md:text-base text-sm lg:mt-4 md:mt-4 mt-2 text-white"
            dangerouslySetInnerHTML={{
              __html: data ? data["2752292551"] : "no-data",
            }}
          ></p>
        </div>
        <Fade bottom>
          <div className="lg:grid md:grid lg:grid-cols-3 lg:mt-20 md:mt-20 lg:mt-16 md:mt-16 mt-10 md:grid-cols-2 lg:gap-20 md:gap-12">
            <div className="relative">
              <Image
                src={cardBG}
                alt="image"
                className="cardbgset sm-none w-full"
              />

              <Card className="bg-white h-230px abstop">
                <CardBody>
                  <div className="lg:px-4 md:px-4 lg:py-4 md:py-4">
                    <h2
                      id="0410418952"
                      className="dynamicStyle font-semibold text-center lg:text-2xl md:text-xl text-xl text-black"
                    >
                      {data ? data["0410418952"] : "PERSONAL TRAINING"}
                    </h2>
                    <p
                      id="3462663024"
                      className="dynamicStyle text8787  lg:line-clamp-5  md:line-clamp-5 lg:text-base md:text-base text-sm mt-4 lg:leading-7 md:leading-6 leading-6"
                      dangerouslySetInnerHTML={{
                        __html: data ? data["3462663024"] : "no-data",
                      }}
                    ></p>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="relative lg:mt-0 md:mt-0 mt-4">
              <Image
                src={cardBG}
                alt="image"
                className="cardbgset sm-none w-full"
              />
              <Card className="bg-white h-230px abstop">
                <CardBody>
                  <div className="lg:px-4 md:px-4 lg:py-4 md:py-4">
                    <h2
                      id="9572759646"
                      className="dynamicStyle font-semibold text-center lg:text-2xl md:text-xl text-xl text-black"
                    >
                      {data ? data["9572759646"] : "GROUP CLASSES"}
                    </h2>
                    <p
                      id="6392396673"
                      className="leading-6 dynamicStyle text8787  lg:line-clamp-5  md:line-clamp-5 lg:text-base md:text-base text-sm  mt-4 lg:leading-7 md:leading-6 leading-6"
                      dangerouslySetInnerHTML={{
                        __html: data ? data["6392396673"] : "no-data",
                      }}
                    ></p>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="relative lg:mt-0 md:mt-0 mt-4">
              <Image
                src={cardBG}
                alt="image"
                className="cardbgset sm-none w-full"
              />
              <Card className="bg-white h-230px abstop">
                <CardBody>
                  <div className="lg:px-4 md:px-4 lg:py-4 md:py-4">
                    <h2
                      id="7320836968"
                      className="dynamicStyle font-semibold text-center lg:text-2xl md:text-xl text-xl text-black"
                    >
                      {data ? data["7320836968"] : "ONLINE COACHING"}
                    </h2>
                    <p
                      id="9711851814"
                      dangerouslySetInnerHTML={{
                        __html: data ? data["9711851814"] : "no-data",
                      }}
                      className="leading-6 dynamicStyle text8787 lg:line-clamp-5  md:line-clamp-5  lg:text-base md:text-base text-sm mt-4 lg:leading-7 md:leading-6 leading-6"
                    ></p>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Fade>
      </div>
      <Image src={imageVectorBottom} alt="image" className="featurerbottom" />
    </section>
  );
};

export default FeatureCard;
