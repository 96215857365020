import React from "react";

import {
  Text,
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Divider,
  AbsoluteCenter,
  Box,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { EffectCoverflow,Navigation } from "swiper/modules";
import "swiper/css";
const Testimonial = () => {
  return (
    <section className="bg-f4f5f6">
      {" "}
      <div className=" container mx-auto  lg:py-16 md:py-16 py-8 lg:px-12 md:px-12">
        <div className="text-center mb-12">
          <h2 className="lg:text-4xl md:text-3xl text-black font-semibold">
            CLIENT SUCCESS STORIES
          </h2>
          <p className="mt-4 text030303">Real People, Real Results</p>
        </div>

        <Swiper
          spaceBetween={25}
          slidesPerView={3.2}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper"
          autoplay={{
            delay: "1000",
          }}
          loop
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 3.2,
              spaceBetween: 25,
            },
            1280: {
              slidesPerView: 3.2,
              spaceBetween: 25,
            },
            1024: {
              slidesPerView: 3.1,
              spaceBetween: 25,
            },
            991: {
              slidesPerView: 2.8,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
          }}
        >
          {/* <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div> */}
          {/* <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div> */}
          <SwiperSlide>
            {" "}
            <Image
              height="400px"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="heroImage"
              className="w-full object-cover rounded-xl"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image
              height="400px"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="heroImage"
              className="w-full object-cover rounded-xl"
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image
              height="400px"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="heroImage"
              className="w-full object-cover rounded-xl"
            />
          </SwiperSlide>{" "}
          <SwiperSlide>
            {" "}
            <Image
              height="400px"
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="heroImage"
              className="w-full object-cover rounded-xl"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonial;
