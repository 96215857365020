import React from 'react';

const Home4Section3 = () => {
  return (
    <section>
      <div className="text-center container mx-auto flex flex-col justify-center lg:mb-16">
        <h3 className="letter-spacing4px fsize24 font-semibold clr-ddd9d8 ">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold mb-2">Lorem Ipsum</h1>
        <div className="lg:w-2/3 md:w-2/3  mx-auto">
          <p className=" fsize18 text-center lg:px-8 md:px-6">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
      <div className="container mx-auto grid lg:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 mt-5 w-full lg:gap-4 md:gap-4 gap-3">
        <div className="custShadow1 h-300px flex gap-8 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3">
          <div className="w-30 h-full flex justify-center items-center">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt=""
              className="h-32 w-32  object-cover"
            />
          </div>
          <div className="w-70">
            <h2 className="text-start fsize24 lg:mb-4 md:mb-4 mb-2 font-semibold clr-04080C">
              Lorem Ipsum
            </h2>
            <p className=" text-start fsize18 clr-04080C">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              augue ipsum, pellentesque sit amet viverra nec, tempus at odio.
              Curabitur tempor molestie enim eget tristique.
            </p>
          </div>
        </div>
        <div className="custShadow1 h-300px flex gap-8 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3">
          <div className="w-30 h-full flex justify-center items-center">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt=""
              className="h-32 w-32  object-cover"
            />
          </div>
          <div className="w-70">
            <h2 className="text-start fsize24 lg:mb-4 md:mb-4 mb-2 font-semibold clr-04080C">
              Lorem Ipsum
            </h2>
            <p className=" text-start fsize18 clr-04080C">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              augue ipsum, pellentesque sit amet viverra nec, tempus at odio.
              Curabitur tempor molestie enim eget tristique.
            </p>
          </div>
        </div>
        <div className="custShadow1 h-300px flex gap-8 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3">
          <div className="w-30 h-full flex justify-center items-center">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt=""
              className="h-32 w-32  object-cover"
            />
          </div>
          <div className="w-70">
            <h2 className="text-start fsize24 lg:mb-4 md:mb-4 mb-2 font-semibold clr-04080C">
              Lorem Ipsum
            </h2>
            <p className=" text-start fsize18 clr-04080C">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              augue ipsum, pellentesque sit amet viverra nec, tempus at odio.
              Curabitur tempor molestie enim eget tristique.
            </p>
          </div>
        </div>
        <div className="custShadow1 h-300px flex gap-8 justify-center items-center lg:py-8 md:py-8 py-4 lg:px-6 md:px-6 px-3">
          <div className="w-30 h-full flex justify-center items-center">
            <img
              src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
              alt=""
              className="h-32 w-32  object-cover"
            />
          </div>
          <div className="w-70">
            <h2 className="text-start fsize24 lg:mb-4 md:mb-4 mb-2 font-semibold clr-04080C">
              Lorem Ipsum
            </h2>
            <p className=" text-start fsize18 clr-04080C">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              augue ipsum, pellentesque sit amet viverra nec, tempus at odio.
              Curabitur tempor molestie enim eget tristique.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home4Section3;
