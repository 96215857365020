import React from 'react';

const HeroSection7 = () => {
  return (
    <section className="bg-ddd9d8">
      <div className="container mx-auto lg:pt-16 pb-180px">
        <div className="text-center lg:my-8">
          <h1 className="hero-header lg:py-4 clr-fff">Lorem ipsum generated</h1>
          <p className="fsize20 pb-8 clr-fff">Lorem ipsum generated</p>
        </div>
        <div className="w-80 mx-auto">
          <img
            clr-fff
            src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            alt="hero"
            className="br-40px w-full h-450px object-cover bg-ddd9d8 border-6px"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection7;
