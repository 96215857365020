import { CalendarIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Logout,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getLog } from "redux/custom/subscription/log/log-actions";
import { getAllTransaction } from "redux/transaction/transaction-actions";
import { Link, useNavigate } from "react-router-dom";

const Transaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemOffset, setItemOffset] = useState(0);
  let userData = useSelector((state) => state.AuthDetails.user);
  // const [log, setLog] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  //   const endOffset = itemOffset + itemsPerPage;

  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  //   const currentItems = items.slice(itemOffset, endOffset);

  //   const pageCount = Math.ceil(items.length / itemsPerPage);
  //   const handlePageClick = (event) => {
  //     const newOffset = (event.selected * itemsPerPage) % items.length;

  //     setItemOffset(newOffset);
  //   };
  useEffect(() => {
    // getLogData();
    getTransactionData();
  }, [currentPage]);

  // const getLogData = async () => {
  //   try {
  //     if (userData && userData._id) {
  //       let payload = {
  //         userId: userData._id,
  //         offset: currentPage,
  //         limit: perPage,
  //       };
  //       let data = await dispatch(getLog(payload));

  //       if (
  //         data &&
  //         data.code == 200 &&
  //         data.data.length > 0 &&
  //         data.data[0].result &&
  //         data.data[0].result.length > 0
  //       ) {
  //         setLog(data.data[0]);
  //         setTotalCount(data.data[0].totalCount);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getTransactionData = async () => {
    try {
      if (userData && userData._id) {
        let payload = {
          userId: userData._id,
          offset: currentPage,
          limit: perPage,
        };
        let data = await dispatch(getAllTransaction(payload));

        console.log(data.data);
        if (
          data &&
          data.code == 200 &&
          data.data.result &&
          data.data.result.length > 0
        ) {
          setTransaction(data.data);
          setTotalCount(data.data.totalCount);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const indexMethod = (index) => {
    return perPage * currentPage + index + 1;
  };

  return (
    <div className="lg:p-6 md:p-6 lg:border md:border rounded-3px ">
      <h2 className=" fsize20 font-semibold pb-4 clr-606060">My Transaction</h2>
      <div className="mt-2">
        {transaction && transaction.result && transaction.result.length > 0 ? (
          <>
            <TableContainer>
              <Table variant="simple" className="border ">
                <Thead>
                  <Tr className="bg-f4f5f6">
                    <Th>Sr no</Th>
                    <Th>Date</Th>
                    <Th>Transaction Type</Th>
                    <Th>Plan</Th>
                    <Th>Amount</Th>
                    <Th>Txn Id</Th>
                    <Th>Status</Th>
                    <Th>Invoice</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <>
                    {transaction.result.map((transactionData, index) => (
                      <Tr key={transactionData._id}>
                        <Td className="fsize12">{indexMethod(index)}</Td>
                        <Td className="fsize12">
                          {transactionData.createdAt
                            ? new Date(
                                transactionData.createdAt
                              ).toLocaleDateString("en-IN")
                            : "-"}
                        </Td>
                        <Td className="fsize12">
                          {transactionData.subscriptionId &&
                          transactionData.subscriptionId.length > 0 &&
                          transactionData.subscriptionId[0].logType
                            ? transactionData.subscriptionId[0].logType
                            : "-"}
                        </Td>
                        <Td className="fsize12">
                          {transactionData &&
                          transactionData.planId &&
                          transactionData.planId.length > 0 &&
                          transactionData.planId[0].name
                            ? transactionData.planId[0].name
                            : "-"}
                        </Td>
                        <Td className="fsize12">
                          {transactionData &&
                          transactionData.planId &&
                          transactionData.planId.length > 0 &&
                          transactionData.planId[0].price >= 0
                            ? transactionData.planId[0].price > 0
                              ? `S$ ${transactionData.planId[0].price}`
                              : "Free"
                            : "-"}
                        </Td>
                        {transactionData && transactionData.transactionId && (
                          <Tooltip label={transactionData.transactionId}>
                            <Td className="fsize12 cursor-pointer">
                              {transactionData.transactionId
                                ? transactionData.transactionId.slice(-10)
                                : "-"}
                            </Td>
                          </Tooltip>
                        )}

                        <Td className="fsize12">
                          <div className="flex w-full gap-2">
                            {transactionData &&
                            transactionData.transactionStatus ? (
                              <Button
                                onClick={() => {
                                  if (
                                    transactionData.transactionStatus ===
                                    "Failed"
                                  ) {
                                    navigate("/subscription");
                                  }
                                }}
                                colorScheme={
                                  transactionData.transactionStatus ===
                                  "Success"
                                    ? "green"
                                    : transactionData.transactionStatus ===
                                      "Failed"
                                    ? "red"
                                    : transactionData.transactionStatus ===
                                      "Pending"
                                    ? "yellow"
                                    : "dark"
                                }
                                py="1"
                                height="1.5rem"
                                className="fsize12 "
                              >
                                {transactionData.transactionStatus}
                              </Button>
                            ) : (
                              "-"
                            )}
                          </div>
                        </Td>
                        <Td className="fsize12">
                          {transactionData &&
                          transactionData.subscriptionId &&
                          transactionData.subscriptionId.length > 0 &&
                          transactionData.subscriptionId[0].invoiceId ? (
                            <Link
                              to={`/invoice?si=${transactionData.subscriptionId[0]._id}`}
                              target="_blank"
                            >
                              {transactionData.subscriptionId[0].invoiceId}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </>
                </Tbody>
              </Table>
            </TableContainer>
            <div className="flex justify-end">
              <ReactPaginate
                className="flex items-center gap-2 pagination"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={perPage}
                pageCount={Math.ceil(totalCount / perPage)}
                previousLabel="<"
              />
            </div>
          </>
        ) : (
          <div className="flex p-4 justify-center fsize18 align-center">
            No Transaction
          </div>
        )}
      </div>
    </div>
  );
};

export default Transaction;
