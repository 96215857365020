import React from "react";
import { Image } from "@chakra-ui/react";
const HeroSection5 = () => {
  return (
    <section>
      <div className="bg-secondary">
        <div className="lg:flex md:flex sm-reverse lg:px-0 md:px-4 px-5 items-center lg:py-0 md:py-10 py-6">
          <div className="lg:w-3/5 md:w-3/5 lg:px-20 md:px-12 lg:pt-0 md:pt-0 pt-4">
            <p className="text-white fsize12 pb-2">Dec 8,2023 .KC Chua</p>
            <h2 className="lg:text-3xl md:text-2xl uppercase text-end  text-white text-xl font-semibold">
              TRANSFORM YOUR BODY WITH STRENGTH AND CONDITIONING
            </h2>
            <p className="text-white lg:text-base md:text-base text-sm  opacity-70 lg:mt-4 md:mt-4 mt-2">
              No we are not another workout site! We are a Fitness and Health
              Platform. Our mission is to Educate, Connect and Motivate you into
              living a fitter and healthier life.
            </p>
          </div>

          <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
            <Image
              src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="heroImage"
              className=" object-cover w-full sm-h250px minh450px object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection5;
