import Trainer_reducer from "./trainer/trainer-reducer";
import Testimonial_reducer from "./testimonial/testimonial-reducer";
import FitnessCategory_reducer from "./fitnessCategory/fitnessCategory-reducer";

//social
import post_reducer from "./social/post/post-reducer";
import comment_reducer from "./social/comment/comment-reducer";
import emoji_reducer from "./social/emoji/emoji-reducer";
import like_reducer from "./social/like/like-reducer";
import reply_reducer from "./social/reply/reply-reducer";
import report_reducer from "./social/report/report-reducer";

//subscription
import plan_reducer from "./subscription/plan/plan-reducer";
import log_reducer from "./subscription/log/log-reducer";

export default {
  trainer: Trainer_reducer,
  testimonial: Testimonial_reducer,
  fitnessCategory: FitnessCategory_reducer,

  //social
  post: post_reducer,
  comment: comment_reducer,
  emoji: emoji_reducer,
  like: like_reducer,
  reply: reply_reducer,
  report: report_reducer,

  //subscription
  plan: plan_reducer,
  log: log_reducer,
};
