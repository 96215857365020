import API from "../../repositoryFactory";

const createEmoji = "create";
const getEmoji = "get";
const updateEmoji = "update";
const deleteEmoji = "delete";

const emojiRoute = (route) => {
  return `fit2go/social/emoji/${route}`;
};

export default {
  createEmoji(payload) {
    return API.post(emojiRoute(createEmoji), payload);
  },
  getEmoji(payload) {
    return API.post(emojiRoute(getEmoji), payload);
  },
  updateEmoji(payload) {
    return API.post(emojiRoute(updateEmoji), payload);
  },
  deleteEmoji(payload) {
    return API.post(emojiRoute(deleteEmoji), payload);
  },
};
