import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux/es/exports";

import React from "react";

function ScrollToTop() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const data = useSelector((state) => state.AuthDetails);
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return <></>;
}

export default ScrollToTop;
