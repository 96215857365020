import React, { useEffect, useState } from "react";
import "swiper/css";
import { Button, Image } from "@chakra-ui/react";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import FeatherIcon from "feather-icons-react";
import { Navigation } from "swiper/modules";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { getAllPlan } from "redux/custom/subscription/plan/plan-actions";
import PaymentCheckout from "../../../components/Payment/Payment";
import { getTransaction } from "redux/transaction/transaction-actions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createLog,
  createPlanSubscription,
} from "../../../redux/custom/subscription/log/log-actions";
import { toCanvas } from "html-to-image";
import { trainerPaymentFailedEmail } from "../../../redux/custom/trainer/trainer-actions";

const Subscription = ({
  Becomeacoach = false,
  pageName,
  setFieldHandler,
  isPayment = true,
  onClosePlan,
  selectedPlan,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let allPlan = useSelector((state) => state.plan.AllPlan);
  let User = useSelector((state) => state.AuthDetails.user);

  const [transactionData, setTransactionData] = useState("");

  useEffect(() => {
    readQuery();
    getPlans();
  }, []);

  const readQuery = async () => {
    try {
      // if (User && User.trainerId) {
      //   if (User.role === "Trainer") {
      //     toast.warning("Already a trainer");
      //   } else {
      //     toast.warning("Already submitted trainer form");
      //   }
      //   navigate("/home");
      //   return;
      // }

      const queryParams = new URLSearchParams(window.location.search);
      const paramValue = queryParams.get("id");
      console.log(paramValue, "paramValue");
      if (paramValue) {
        const data = await dispatch(
          getTransaction({ transactionId: paramValue })
        );
        if (data && data.code === 200 && data.data && data.data.length > 0) {
          setTransactionData(data.data[0]);
          let selectedPlanData = allPlan.filter(
            (item) => item._id === data.data[0].planId
          );
          const urlWithoutParmas = window.location.href.split("?")[0];
          window.history.replaceState({}, document.title, urlWithoutParmas);
          if (
            data.data &&
            data.data.length > 0 &&
            data.data[0].transactionStatus !== "Success"
          ) {
            let email = await dispatch(trainerPaymentFailedEmail());
            toast.error("Transaction Failed: Please retry the payment");
            return;
          }
          if (
            data.data &&
            data.data.length > 0 &&
            data.data[0].transactionStatus === "Success" &&
            selectedPlanData &&
            selectedPlanData.length > 0
          ) {
            toast.success("Transaction Success: Payment successful");
            let planSubPayload = {
              planId: data.data[0].planId,
              userId: data.data[0].userId._id,
              transactionId: data.data[0]._id,
              // purchase_price: selectedPlanData[0] && selectedPlanData[0].price,
              // start_day: new Date(),
              // end_day:
              //   selectedPlanData[0] &&
              //   calculateDate(selectedPlanData[0].validity),
              // planObject: { ...selectedPlanData[0] },
              // transactionObject: { ...data.data[0] },
              // userObject: { ...data.data[0].userId },
            };
            const planSubscribeData = await dispatch(
              createPlanSubscription(planSubPayload)
            );
            if (planSubscribeData && planSubscribeData.code === 200) {
              toast.success("Subscription purchased successfully");
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateDate = (numDays) => {
    let currentDateObj = new Date();
    currentDateObj.setDate(currentDateObj.getDate() + numDays);
    return currentDateObj;
  };

  const getPlans = async () => {
    try {
      await dispatch(getAllPlan());
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectedPlan = (item) => {
    setFieldHandler("selectedPlan", item._id);
    onClosePlan();
  };

  const disableSubscribeButton = (item) => {
    if (!item.type) {
      return false;
    }
    if (User.role && item.type === User.role) {
      return false;
    }
    if (
      User.role &&
      item.type &&
      item.type === "Trainer" &&
      ["Trainer", "ExTrainer", "TrainerApplicant"].includes(User.role)
    ) {
      return false;
    }
    return true;
  };

  function getItemList(item) {
    return [
      {
        name: item.name,
        quantity: 1,
        price: parseInt(item.price),
      },
    ];
  }
  return (
    <section className="container planstop mx-auto lg:pt-16 md:pt-16 pt-8 lg:pb-8 md:pb-8 pb-6 lg:px-12 md:px-8 px-5">
      {isLoading ? (
        <div className="flex justify-center align-center">
          <ThreeDots visible={true} color="#ed1b24" radius={9} />
        </div>
      ) : (
        <>
          {allPlan && allPlan.length > 0 ? (
            <Swiper
              spaceBetween={20}
              modules={[Navigation]}
              autoplay={{
                delay: "1000",
              }}
              navigation={{
                nextEl: ".test-swiper-button-next",
                prevEl: ".test-swiper-button-prev",
              }}
              className="mySwiper"
              breakpoints={{
                1536: {
                  slidesPerView: 4.2,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 4.2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2.8,
                  spaceBetween: 10,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
                325: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
                300: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
              }}
            >
              {allPlan.map((item) => {
                return (
                  <SwiperSlide className="height-30rem bg-plancardwhite cardhover lg:px-6 md:px-6 px-4 py-4 mt-12">
                    <div>
                      <div className="flex justify-between">
                        <p className="borderblack text-black fsize12 p-1 fitcontent">
                          {item.name}
                        </p>
                        <p className="text-black fsize12 p-1 fitcontent">
                          {item.type ? `For ${item.type}` : ""}
                        </p>
                      </div>
                      <h2 className="text-primary lg:text-3xl md:text-3xl text-2xl font-semibold mt-4 lg:mb-3 md:mb-3 mb-2">
                        {item.price <= 0 ? "Free" : `S$ ${item.price}/-`}
                        {/* <span className="fsize16">Month </span> */}
                      </h2>
                      <p className="fsize12 lg:mb-4 md:mb-4 mb-4">
                        Validity:{" "}
                        {item.validity <= 0
                          ? "Forever"
                          : `${item.validity} days`}
                      </p>
                      <p className=" fsize12 lg:mb-4 md:mb-4 mb-4">
                        {item.description}{" "}
                      </p>
                      {Becomeacoach ? (
                        <>
                          {isPayment ? (
                            <PaymentCheckout
                              isDisabled={disableSubscribeButton(item)}
                              button={"Subscribe"}
                              buttonClass={"w-full font-semibold py-2 fsize14"}
                              buttonColor={"primary"}
                              itemList={getItemList(item)}
                              successUrl={"home"}
                              cancelUrl={"home"}
                            ></PaymentCheckout>
                          ) : (
                            <Button
                              variant="outline"
                              colorScheme="primary"
                              className="w-full font-semibold fsize14"
                              onClick={() => {
                                handleSelectedPlan(item);
                              }}
                            >
                              {selectedPlan && selectedPlan._id === item._id
                                ? "Selected"
                                : "Select"}
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {User && User._id ? (
                            <PaymentCheckout
                              isDisabled={disableSubscribeButton(item)}
                              button={"Subscribe"}
                              buttonClass={"w-full font-semibold py-2 fsize14"}
                              buttonColor={"primary"}
                              itemList={getItemList(item)}
                              planId={item._id}
                              userId={User._id}
                              successUrl={"subscription"}
                              cancelUrl={"subscription"}
                            ></PaymentCheckout>
                          ) : (
                            <Button
                              // variant="outline"
                              colorScheme="primary"
                              className="w-full font-semibold fsize14"
                              onClick={() => {
                                toast.warning(`Please login`);
                                navigate("/login");
                              }}
                            >
                              {"Subscribe"}
                            </Button>
                          )}
                        </>
                      )}

                      <div>
                        <div className=" lg:mt-6 md:mt-6 mt-4 ">
                          <div className=" lg:mt-6 md:mt-6 mt-4 ">
                            {item.features &&
                              item.features.length > 0 &&
                              item.features.map((item) => {
                                return (
                                  <div className=" mb-4 flex items-center gap-2">
                                    <div className="w-1/12">
                                      <FeatherIcon
                                        size={18}
                                        icon="check"
                                        className="cursor-pointer "
                                      />
                                    </div>
                                    <div className="w-11/12">
                                      <p className=" fsize12">{item.title}</p>
                                    </div>
                                  </div>
                                );
                              })}

                            {item.featuresExcluded &&
                              item.featuresExcluded.length > 0 &&
                              item.featuresExcluded.map((item) => {
                                return (
                                  <div className=" mb-4 flex items-center gap-2">
                                    <div className="w-1/12">
                                      <FeatherIcon
                                        size={18}
                                        icon="x"
                                        className="cursor-pointer "
                                      />
                                    </div>
                                    <div className="w-11/12">
                                      <p className=" fsize12">{item.title}</p>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      {/* <Image
                   
                   fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                   src={e.image}
                 
                 className="rounded-full roundetest cursor-pointer activeuser"
                   alt="heroImage"
                 /> */}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div className="flex justify-center align-center">
              No Plan Found
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Subscription;
