import React from "react";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContainerWrapper = () => {
  return (
    <ToastContainer
      draggable
      autoClose={3000}
      position="bottom-right"
      closeOnClick
      pauseOnHover
      transition={Bounce}
    />
  );
};

export default ToastContainerWrapper;
