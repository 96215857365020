import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

const ShareProfileSection2 = ({ blogId }) => {
  const [blogLink, setBlogLink] = useState(
    `${process.env.REACT_APP_FRONTEND_URL}/blog%20detail/${blogId}`
  );
  return (
    <>
      <div className="py-2 px-2">
        <div className="flex  gap-4">
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <FacebookShareButton url={blogLink}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <TwitterShareButton url={blogLink} title="Blog">
              <XIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <TelegramShareButton url={blogLink} title="Blog">
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <WhatsappShareButton url={blogLink} title="Blog" separator=":">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          {/* <div className="">
            <LinkedinShareButton
              url={`Trainer ${firstName} ${lastName} Profile`}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={size} round />
            </LinkedinShareButton>
          </div>
          <div className="">
            <EmailShareButton
              url={trainerLink}
              subject={`Trainer ${firstName} ${lastName} Profile`}
              body="body"
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={size} round />
            </EmailShareButton>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ShareProfileSection2;
