import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  MenuDivider,
  Divider,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { logout } from "../../redux/auth/auth-action";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import { toast } from "react-toastify";

const Header2 = () => {
  const [navbars, setnavbars] = useState(false);

  const changebackground = () => {
    if (window.scrollY < 1) {
      setnavbars(true);
    } else {
      setnavbars(false);
    }
  };

  useEffect(() => {
    changebackground();
    window.addEventListener("scroll", changebackground);
  });
  let { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let userData = useSelector((state) => state.AuthDetails.user);
  let menuData = useSelector((state) => state.menuState.menu);
  // console.log(menuData, "checkm");

  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  const [navbar, setnavbar] = useState(0);
  const setclick = () => setnavbar(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const handleLogout = async () => {
    if (userData && userData.email) {
      let payload = {
        email: userData.email,
      };
      try {
        const data = await dispatch(logout(payload));
        if (data && data.code == 200) {
          // Swal.fire({
          //   title: "Success",
          //   text: `Logged out successfully`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success(`Logged out successfully`);
          navigate("/");
        } else {
          if (data.message) {
            // Swal.fire({
            //   title: "Error",
            //   text: data.message,
            //   icon: "error",
            // });
            toast.error(data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const getImage = (image) => {
    if (image.includes("data:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };

  // if (window.location.pathname === "/login") return null;
  // if (window.location.pathname === "/register") return null;
  // if (window.location.pathname === "/forgot password") return null;
  return (
    <section className="w-full bg-white top-0 lg:fixed z-100">
      <div className="py-5 lg:px-16 md:px-8 px-5">
        <div className=" flex justify-between">
          <Image
            onClick={() => navigate("/")}
            src={getPrimaryImage(brandProfile)}
            alt="logo"
            className="w-24  h-8 object-contain cursor-pointer"
          />

          <div className="lg:flex md:flex mob-hidden items-center justify-center gap-8">
            {menuData
              ? menuData.map((e, i) => {
                  return (
                    <div key={i}>
                      {e.menutype === "page" && e.subMenu.length < 1 ? (
                        <NavLink
                          to={`/${e.pageData[0].page}`}
                          onClick={() => setclick(e)}
                          className={navbars ? "text-black" : "text-black"}
                          activeclassname="active"
                        >
                          {e.menuName}
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {e.menutype === "page" && e.subMenu.length > 1 ? (
                        <Menu>
                          <MenuButton
                            px={0}
                            py={2}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="0px"
                            // _hover={{ bg: 'gray.400' }}
                            // _expanded={{ bg: 'blue.400' }}
                            // _focus={{ boxShadow: 'outline' }}
                          >
                            File <ChevronDownIcon />
                          </MenuButton>
                          <MenuList>
                            {e.subMenu.map((sub) => {
                              return <MenuItem>{sub.menuName}</MenuItem>;
                            })}
                          </MenuList>
                        </Menu>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </div>

          {/* usericon */}

          <div className="lg:hidden md:hidden block drawer-w">
            <Button ref={btnRef} onClick={onOpenDrawer}>
              <FaBars />
            </Button>

            <Drawer
              size="xs"
              isOpen={isOpenDrawer}
              placement="right"
              onClose={onCloseDrawer}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                {/* <DrawerHeader>Options</DrawerHeader> */}
                <DrawerBody
                  className={
                    userData && userData._id
                      ? "sm-drawer-padding mt-6"
                      : "sm-drawer-padding"
                  }
                >
                  <div className="p-6 relative h-full">
                    <div className="pt-4 ">
                      {userData && userData._id && (
                        <Link
                          to="/user-profile"
                          onClick={() => onCloseDrawer()}
                        >
                          <div className="flex gap-2 items-center">
                            {/* <Avatar
                                  src="https://bit.ly/broken-link"
                                  size="sm"
                                /> */}
                            <h2 className=" font-semibold fsize16">
                              <span className="pl-5px">
                                {" "}
                                {`${userData.firstName} ${userData.lastName}`}
                              </span>
                            </h2>
                          </div>
                        </Link>
                      )}
                      <div className="lg:hidden md:hidden pt-4">
                        {menuData
                          ? menuData.map((e, i) => {
                              return (
                                <div key={i}>
                                  {e.menutype === "page" &&
                                  e.subMenu.length < 1 ? (
                                    <NavLink
                                      to={`/${e.pageData[0].page}`}
                                      onClick={() => {
                                        setclick(e);
                                        onClose();
                                        onCloseDrawer();
                                      }}
                                      ref={btnRef}
                                    >
                                      <p
                                        className={
                                          navbar === e
                                            ? "menuHover font-semibold  cursor-pointer"
                                            : " font-semibold cursor-pointer mb-4"
                                        }
                                      >
                                        {e.menuName}
                                      </p>
                                    </NavLink>
                                  ) : (
                                    ""
                                  )}

                                  {e.menutype === "page" &&
                                  e.subMenu.length > 1 ? (
                                    <div>
                                      <div
                                        px={0}
                                        py={2}
                                        transition="all 0.2s"
                                        borderRadius="md"
                                        borderWidth="0px"
                                        // _hover={{ bg: 'gray.400' }}
                                        // _expanded={{ bg: 'blue.400' }}
                                        // _focus={{ boxShadow: 'outline' }}
                                      >
                                        File <ChevronDownIcon />
                                      </div>
                                      <div>
                                        {e.subMenu.map((sub) => {
                                          return (
                                            <MenuItem>{sub.menuName}</MenuItem>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            })
                          : ""}
                      </div>
                      {userData && userData._id ? (
                        ""
                      ) : (
                        <div
                          className="text-primary cursor-pointer mt-2 p-4 w-full bordertop absolute bottom-0 left-0  flex items-center"
                          onClick={() => {
                            navigate("/login");
                            onClose();
                            onCloseDrawer();
                          }}
                        >
                          <Button
                            px="8"
                            colorScheme="primary"
                            onClick={() => {
                              navigate("/login");
                              onClose();
                            }}
                            className=" w-full py-2   font-semibold px-8"
                          >
                            Register / Sign In
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </div>

          <div className="lg:block md:block hidden">
            {userData && userData._id ? (
              <div className="lg:block md:block hidden">
                <Link to="/user-profile">
                  <div className="flex gap-2 items-center">
                    <Avatar
                      src={
                        userData.userImage
                          ? getImage(userData.userImage)
                          : "https://bit.ly/broken-link"
                      }
                      size="sm"
                    />
                    <h2 className="font-semibold">
                      <span className="pl-5px">
                        {" "}
                        {`${userData.firstName} ${userData.lastName}`}
                      </span>
                    </h2>
                  </div>
                </Link>
              </div>
            ) : (
              <Button
                px="8"
                colorScheme="primary"
                onClick={() => {
                  navigate("/login");
                  onClose();
                }}
                className=" w-full py-2   font-semibold px-8"
              >
                Register / Sign In
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header2;
