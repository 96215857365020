import API from "./repositoryFactory";

const getTransaction = "get";
const getAllTransaction = "getTransactions";

const transactionRoute = (route) => {
  return `transaction/${route}`;
};

export default {
  getTransaction(payload) {
    return API.post(transactionRoute(getTransaction), payload);
  },
  getAllTransaction(payload) {
    return API.post(transactionRoute(getAllTransaction), payload);
  },
};
