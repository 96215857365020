import React from 'react';

const HeroSection8 = () => {
  return (
    <section className="bg-ddd9d8 ">
      <div className=" container mx-auto  h-450px">
        <div className=" w-full h-450px flex flex-col justify-center items-center">
          <h1 className="hero-header mb-4 clr-fff">Lorem ipsum generated</h1>
          <p className="fsize20 clr-fff">Lorem ipsum generated</p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection8;
