const initialState = {
  user: {},
  userDetails: {},
  userProfile: {},
  otherLogin: "",
  IsEditUserProfile: false,
};

const AuthDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SetLogin":
      console.log(action.payload);
      return { ...state, user: action.payload };

    case "LOGOUT":
      return { ...state, user: {} };

    case "SET_DATA_SIGNUP":
      return { ...state, user: action.payload };

    case "SET_OTHER_LOGIN":
      return { ...state, otherLogin: action.payload };

    case "SET_ISEDITUSERPROFILE":
      return { ...state, IsEditUserProfile: action.payload };
    default:
      return state;
  }
};

export default AuthDetails;
