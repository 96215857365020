import {
  Button,
  Select,
  Input,
  Image,
  Textarea,
  Divider,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Checkbox,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import {
  setIsEditUserProfile,
  SaveBasicProfileDetails,
  getUserByToken,
  generateOTP,
  UpdateEmailMobile,
} from "../../../../redux/auth/auth-action";
import { getFitnessCategory } from "../../../../redux/custom/fitnessCategory/fitnessCategory-actions";
import OTPInput, { ResendOTP } from "otp-input-react";
import {
  setIsEditProfile,
  getTrainerById,
  setSelectedTrainerData,
  updateTrainer,
  uploadMedia,
  createQualification,
  editQualification,
  deleteQualification,
  createCertification,
  editCertification,
  deleteCertification,
} from "../../../../redux/custom/trainer/trainer-actions";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import React, { useState, useEffect, useRef } from "react";
import Multiselect from "multiselect-react-dropdown";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux/es/exports";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploaderBox from "components/UploaderBox";
import { ThreeDots } from "react-loader-spinner";

import { toast } from "react-toastify";
import Helper from "../../../../helper";

const EditProfile = () => {
  const dispatch = useDispatch();
  let { user } = useSelector((state) => state.AuthDetails);
  let { IsEditProfile, SelectedTrainerData: trainerData } = useSelector(
    (state) => state.trainer
  );
  const setIsEditProfileHandler = () => {
    dispatch(setIsEditProfile(!IsEditProfile));
  };

  const fitnessCategoriesOptions = useRef();

  const [userMobileCountry, setUserMobileCountry] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [userImagePreview, setUserImagePreview] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);

  const [userLoading, setUserLoading] = useState(false);

  const [submitType, setSubmitType] = useState("");

  const [qualificationImage, setQualificationImage] = useState("");
  const [certifiacteImage, setCertifiacteImage] = useState("");

  const [addEditQual, setAddEditQual] = useState("Add");
  const [addEditCert, setAddEditCert] = useState("Add");

  const [isLoading, setIsLoading] = useState(false);
  const [isShowEmailOTP, SetShowEmailOTP] = useState(false);

  const [DOBDates, setDOBDates] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 10))
      .toISOString()
      .split("T")[0]
  );

  const [options] = useState([
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ]);

  const [fitnessGoalOptions, setFitnessGoalOptions] = useState([
    { name: "Lose weight", id: 1 },
    { name: "Build muscle", id: 2 },
    { name: "Improve cardiovascular endurance", id: 3 },
    { name: "Increase flexibility", id: 4 },
    { name: "Enhance agility and balance", id: 5 },
    { name: "Reduce body fat percentage", id: 6 },
    { name: "Improve posture", id: 7 },
    { name: "Enhance athletic performance", id: 8 },
    { name: "Boost overall fitness level", id: 9 },
    { name: "Other", id: 10 },
  ]);

  const [specializationOptions, setSpecializationOptions] = useState([
    { name: "Active Aging", id: 1 },
    { name: "Corrective Exercise", id: 2 },
    { name: "Fitness", id: 3 },
    { name: "Group Fitness", id: 4 },
    { name: "Nutrition", id: 5 },
    { name: "Strength Training", id: 6 },
    { name: "Weight Management", id: 7 },
    { name: "Women's Health", id: 8 },
    { name: "Youth", id: 9 },
  ]);

  const [languageOptions, setLanguageOptions] = useState([
    { name: "English", id: 1 },
    { name: "Malay", id: 2 },
    { name: "Tamil", id: 3 },
    { name: "Mandarin", id: 4 },
  ]);

  const [trainingLocationOptions, setTrainingLocationOptions] = useState([
    { name: "My gym", id: 1 },
    { name: "Your gym", id: 2 },
    { name: "Park/public space", id: 3 },
  ]);

  const [trainingTypeOptions, setTrainingTypeOptions] = useState([
    { name: "Online", id: 1 },
    { name: "Offline", id: 2 },
  ]);

  useEffect(() => {
    fetchData();
    return () => {
      setIsEditProfileHandler();
    };
  }, [user]);

  useEffect(() => {
    populateData();
  }, [trainerData]);

  const fetchData = async () => {
    await getFitnessCategoryOption();
    await getTrainerData();
    await populateData();
  };

  const getTrainerData = async () => {
    try {
      const data = await dispatch(
        getTrainerById({
          trainerId: user.trainerId,
        })
      );

      if (
        data &&
        data.code === 200 &&
        data.data.result &&
        data.data.result.length > 0
      ) {
        console.log(data.data.result[0], "trainerData");
        await dispatch(setSelectedTrainerData(data.data.result[0]));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFitnessCategoryOption = async () => {
    try {
      const data = await dispatch(getFitnessCategory());

      if (data && data.code === 200) {
        let options = data.data.result.map((opt) => {
          return { name: opt.categoryName, id: opt._id };
        });

        // setFitnessCategoriesOptions(options);
        fitnessCategoriesOptions.current = options;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const populateData = async () => {
    if (user.userImage) {
      await setFieldHandler("userImagePreview", user.userImage);
    }
    if (user.coverImage) {
      await setFieldHandler("coverImagePreview", user.coverImage);
    }
    if (user.isDOBHide === true || user.isDOBHide === false) {
      await setFieldHandler("isDOBHide", user.isDOBHide);
    }
    await setFieldHandler("firstName", user.firstName ? user.firstName : "");
    await setFieldHandler("lastName", user.lastName ? user.lastName : "");
    await setFieldHandler("gender", user.gender ? user.gender : "");
    await setFieldHandler("dob", user.dob ? formatDate(user.dob) : "");
    await setFieldHandler("email", user.email ? user.email : "");
    await setFieldHandler("bio", user.bio ? user.bio : "");
    await setFieldHandler("city", user.city ? user.city : "");
    await setFieldHandler(
      "countryCode",
      user.countryCode ? user.countryCode : ""
    );
    await setFieldHandler("mobile", user.mobile ? user.mobile : "");
    await setFieldHandler("fitnessGoal", filterFitnessGoalData(user));

    console.log(trainerData, "trainerData");
    await setFieldHandler(
      "location",
      trainerData.location ? trainerData.location : ""
    );
    await setFieldHandler("about", trainerData.about ? trainerData.about : "");
    await setFieldHandler(
      "experience",
      trainerData.experience ? trainerData.experience : ""
    );
    await setFieldHandler("language", filterLanguageData(trainerData));
    await setFieldHandler(
      "trainingLocation",
      filterTrainingLocationData(trainerData)
    );
    await setFieldHandler("trainingType", filterTrainingTypeData(trainerData));
    await setFieldHandler(
      "specialization",
      filterSpecializationData(trainerData)
    );
    await setFieldHandler(
      "fitnessCategories",
      filterFitnessCategoriesData(trainerData)
    );

    await setFieldHandler(
      "facebook",
      trainerData.facebook ? trainerData.facebook : ""
    );
    await setFieldHandler(
      "instagram",
      trainerData.instagram ? trainerData.instagram : ""
    );
    await setFieldHandler(
      "youtube",
      trainerData.youtube ? trainerData.youtube : ""
    );
    await setFieldHandler(
      "twitter",
      trainerData.twitter ? trainerData.twitter : ""
    );

    setUserMobileCountry(`${user.countryCode}${user.mobile}`);
  };

  const filterFitnessGoalData = (user) => {
    if (user.fitnessGoal && user.fitnessGoal.length > 0) {
      let filterData = fitnessGoalOptions.filter((fgo) =>
        user.fitnessGoal.includes(fgo.name)
      );
      return filterData;
    }
  };
  const filterTrainingLocationData = (trainer) => {
    if (trainer.trainingLocation && trainer.trainingLocation.length > 0) {
      let filterData = trainingLocationOptions.filter((fd) =>
        trainer.trainingLocation.includes(fd.name)
      );
      return filterData;
    }
  };
  const filterLanguageData = (trainer) => {
    if (trainer.language && trainer.language.length > 0) {
      let filterData = languageOptions.filter((fd) =>
        trainer.language.includes(fd.name)
      );
      return filterData;
    }
  };
  const filterTrainingTypeData = (Trainer) => {
    if (Trainer.trainingType && Trainer.trainingType.length > 0) {
      let filterData = trainingTypeOptions.filter((fd) =>
        Trainer.trainingType.includes(fd.name)
      );
      return filterData;
    }
  };
  const filterSpecializationData = (Trainer) => {
    if (Trainer.specialization && Trainer.specialization.length > 0) {
      let filterData = specializationOptions.filter((fd) =>
        Trainer.specialization.includes(fd.name)
      );
      return filterData;
    }
  };
  const filterFitnessCategoriesData = (Trainer) => {
    if (
      Trainer.fitnessCategories &&
      Trainer.fitnessCategories.length > 0 &&
      fitnessCategoriesOptions.current &&
      fitnessCategoriesOptions.current.length > 0
    ) {
      let filterData = fitnessCategoriesOptions.current.filter((fd) =>
        Trainer.fitnessCategories.some((fc) => fc._id === fd.id)
      );
      return filterData;
    }
  };

  const formatDate = (date) => {
    const [day, month, year] = new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
      .format(new Date(date))
      .split("/");

    return `${year}-${month}-${day}`;
  };

  const GetEmailOTP = async () => {
    let emailValid = validateField("email", formik.values.email);
    let mobileValid = validateField("mobile", formik.values.mobile);
    if (!(emailValid && mobileValid)) {
      return;
    }
    let payload = {
      mobile: user.mobile,
      email: user.email.toLowerCase(),
      type: "UpdateAccount",
    };

    try {
      let data = await dispatch(generateOTP(payload));

      if (data && data.code === 200) {
        SetShowEmailOTP(true);
        toast.success("OTP successfully sent to your email ID");
      } else {
        if (data.message) {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleUpdateEmailMobile = async () => {
    let payload = {};
    if (user.mobile) {
      payload = {
        otp: formik.values.otp,
        mobile: user.mobile,
        newMobile: formik.values.mobile,
        newCountryCode: formik.values.countryCode,
      };
    }

    try {
      let data = await dispatch(UpdateEmailMobile(payload));

      if (data && data.code === 200) {
        await dispatch(getUserByToken({ token: user.token }));
        toast.success("Mobile Number Updated");
      } else {
        if (data.message) {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const {
    isOpen: isOpenCover,
    onOpen: onOpenCover,
    onClose: onCloseCover,
  } = useDisclosure();
  const {
    isOpen: isOpenImage,
    onOpen: onOpenImage,
    onClose: onCloseImage,
  } = useDisclosure();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    countryCode: "",
    gender: "",
    bio: "",
    dob: null,
    isDOBHide: false,
    city: "",

    otp: "",

    userImage: null,
    coverImage: null,
    preference: [],
    fitnessGoal: [],

    coverImagePreview: null,
    userImagePreview: null,

    experience: "",
    language: [],
    trainingLocation: [],
    trainingType: [],
    location: "",
    about: "",
    specialization: [],
    fitnessCategories: [],

    facebook: "",
    instagram: "",
    youtube: "",
    twitter: "",
  };

  const validationRules = Yup.object({
    firstName: Yup.string()
      .trim()
      // .min(3, "First name must be at least 3 characters")
      .max(25, "First name must be at most 25 characters")
      .required("First name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    lastName: Yup.string()
      .trim()
      // .min(3, "Last name must be at least 3 characters")
      .max(25, "Last name must be at most 25 characters")
      .required("Last name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    mobile: Yup.string().required("Mobile number is required"),
    countryCode: Yup.string().required("Country code is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date().required("DOB is required"),

    specialization: Yup.array().min(1, "Specialization is required"),
    about: Yup.string()
      .trim()
      // .min(3, "About must be at least 3 characters")
      .max(500, "About must be at most 500 characters")
      .required("About is required"),
    location: Yup.string()
      .trim()
      .min(3, "Location must be at least 3 characters")
      .max(25, "Location must be at most 25 characters")
      .required("Location is required")
      .matches(/^[a-zA-Z-, ]*$/, "Only alphabets is allowed"),
    fitnessCategories: Yup.array().min(1, "Fitness categories is required"),
    experience: Yup.string().required("Experience is required"),
    language: Yup.array().min(1, "Language  is required"),
    trainingType: Yup.array().min(1, "Training Type  is required"),
    trainingLocation: Yup.array().min(1, "Training Location is required"),

    userImage: Yup.mixed()
      // .required("Image is required")
      .test("fileType", "User image invalid file type", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "User image size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .nullable(),
    coverImage: Yup.mixed()
      // .required("Image is required")
      .test("fileType", "Cover image invalid file type", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "Cover image size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .nullable(),
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
    setUserMobileCountry(`${dialCountryCode}${phoneNumber}`);
    validateField("mobile", formik.values.mobile);
  };

  const validateField = (fieldName, value) => {
    try {
      validationRules.fields[fieldName].validateSync(value, {
        abortEarly: false,
      });
      formik.setFieldError(fieldName, undefined);
      formik.setFieldTouched(fieldName, true);
      return true;
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        formik.setFieldError(fieldName, error.errors[0]);
      }
      formik.setFieldTouched(fieldName, true);
      return false;
    }
  };

  const handleOnSubmit = async (values) => {
    console.log(values);
    let userPayload = new FormData();

    if (formik.values.firstName) {
      userPayload.append("firstName", formik.values.firstName);
    }
    if (formik.values.lastName) {
      userPayload.append("lastName", formik.values.lastName);
    }
    // if (formik.values.email) {
    //   userPayload.append("email", formik.values.email);
    // }
    // if (formik.values.mobile) {
    //   userPayload.append("mobile", formik.values.mobile);
    // }
    // if (formik.values.countryCode) {
    //   userPayload.append("countryCode", formik.values.countryCode);
    // }
    if (formik.values.gender) {
      userPayload.append("gender", formik.values.gender);
    }
    if (formik.values.dob) {
      userPayload.append("dob", formik.values.dob);
    }
    if (formik.values.city) {
      userPayload.append("city", formik.values.city);
    }
    if (formik.values.userImage) {
      userPayload.append("userImage", formik.values.userImage);
    }
    if (formik.values.coverImage) {
      userPayload.append("coverImage", formik.values.coverImage);
    }
    if (formik.values.isDOBHide === true || formik.values.isDOBHide === false) {
      userPayload.append("isDOBHide", formik.values.isDOBHide);
    }
    if (user._id) {
      userPayload.append("userId", user._id);
    }

    let payload = {};

    if (
      formik.values.fitnessCategories &&
      formik.values.fitnessCategories.length > 0
    ) {
      let value = formik.values.fitnessCategories.map((val) => val.id);
      Object.assign(payload, { fitnessCategories: value });
    }
    if (
      formik.values.specialization &&
      formik.values.specialization.length > 0
    ) {
      let value = formik.values.specialization.map((val) => val.name);
      Object.assign(payload, { specialization: value });
    }
    if (formik.values.language && formik.values.language.length > 0) {
      let value = formik.values.language.map((val) => val.name);
      Object.assign(payload, { language: value });
    }
    if (formik.values.location) {
      Object.assign(payload, { location: formik.values.location });
    }
    if (
      formik.values.trainingLocation &&
      formik.values.trainingLocation.length > 0
    ) {
      let value = formik.values.trainingLocation.map((val) => val.name);
      Object.assign(payload, {
        trainingLocation: value,
      });
    }
    if (formik.values.trainingType && formik.values.trainingType.length > 0) {
      let value = formik.values.trainingType.map((val) => val.name);
      Object.assign(payload, { trainingType: value });
    }
    if (formik.values.about) {
      Object.assign(payload, { about: formik.values.about });
    }
    if (formik.values.experience) {
      Object.assign(payload, { experience: formik.values.experience });
    }
    if (formik.values.facebook) {
      Object.assign(payload, { facebook: formik.values.facebook });
    }
    if (formik.values.instagram) {
      Object.assign(payload, { instagram: formik.values.instagram });
    }
    if (formik.values.youtube) {
      Object.assign(payload, { youtube: formik.values.youtube });
    }
    if (formik.values.twitter) {
      Object.assign(payload, { twitter: formik.values.twitter });
    }
    if (trainerData && trainerData._id) {
      Object.assign(payload, { trainerId: trainerData._id });
    }

    console.log(userPayload);
    console.log(payload);
    try {
      setUserLoading(true);
      let data = await dispatch(SaveBasicProfileDetails(userPayload));
      let trainerData = await dispatch(updateTrainer(payload));

      setUserLoading(false);
      if (data && data.code == 200 && trainerData && trainerData.code == 200) {
        await dispatch(getUserByToken({ token: user.token }));
        await getTrainerData();
        // Swal.fire({
        //   title: "Success",
        //   text: `Trainer updated successfully`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`Trainer updated successfully`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    } finally {
      setUserLoading(false);
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const setFieldHandler = async (field, value) => {
    await formik.setFieldValue(field, value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const getImage = (image) => {
    if (image.includes("data:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };

  //QUALIFICATION CERTIFICATION

  const qcinitialValues = {
    //Qualification
    qid: "",
    qtype: "",
    qtypeName: "",
    qfieldOfStudy: "",
    qstartDate: null,
    qendDate: null,
    // qgrade: "",
    // qactivity: "",
    qmedia: null,
    qmediaName: null,
    qmediaType: null,

    //Certification
    cid: "",
    ccourseName: "",
    corgName: "",
    // cstartDate: null,
    // cendDate: null,
    // cgrade: "",
    // cactivity: "",
    // ccertificateUrl: "",
    cmedia: null,
    cmediaName: null,
    cmediaType: null,
  };

  const qvalidationRules = Yup.object({
    qtype: Yup.string()
      .trim()
      // .min(3, "Type must be at least 3 characters")
      // .max(100, "Type must be at most 100 characters")
      .required("Type is required"),
    qtypeName: Yup.string()
      .trim()
      .min(3, "Field must be at least 3 characters")
      .max(100, "Field must be at most 100 characters")
      .required("Field is required"),
    qfieldOfStudy: Yup.string()
      .trim()
      // .min(3, "Field of study must be at least 3 characters")
      .max(100, "Field of study must be at most 100 characters"),
    // .required("Field of study is required"),
    qstartDate: Yup.date().required("Start date is required"),
    qendDate: Yup.date().required("End date is required"),
    // qgrade: Yup.string()
    //   .trim()
    //   // .min(2, "Grade must be at least 2 characters")
    //   .max(25, "Grade must be at most 25 characters")
    //   .required("Grade is required"),
    // qactivity: Yup.string()
    //   .trim()
    //   .min(3, "Activity must be at least 3 characters")
    //   .max(50, "Activity must be at most 50 characters")
    //   .required("Activity is required"),
    qmedia: Yup.mixed()
      .test("fileType", "Only image is allowed", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "File size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .nullable(),
  });

  const cvalidationRules = Yup.object({
    ccourseName: Yup.string()
      .trim()
      .min(3, "Course name must be at least 3 characters")
      .max(50, "Course name must be at most 50 characters")
      .required("Course name is required"),
    corgName: Yup.string()
      .trim()
      .min(3, "Org name must be at least 3 characters")
      .max(50, "Org name must be at most 50 characters")
      .required("Org name is required"),
    // cstartDate: Yup.date().required("Start date is required"),
    // cendDate: Yup.date().required("End date is required"),
    // cgrade: Yup.string()
    //   .trim()
    //   // .min(2, "Grade must be at least 2 characters")
    //   .max(25, "Grade must be at most 25 characters")
    //   .required("Grade is required"),
    // cactivity: Yup.string()
    //   .trim()
    //   .min(3, "Activity must be at least 3 characters")
    //   .max(50, "Activity must be at most 50 characters")
    //   .required("Activity is required"),
    // ccertificateUrl: Yup.string()
    //   .trim()
    //   .url("Please enter a valid URL")
    //   .min(3, "Certificate url must be at least 3 characters")
    //   .max(50, "Certificate url must be at most 50 characters")
    //   .required("Certificate url is required"),
    cmedia: Yup.mixed()
      .required("Media is required")
      .test("fileType", "Only image is allowed", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "File size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      }),
  });

  const qchandleTrim = (event) => {
    qcformik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const resetQC = () => {
    qcformik.setFieldValue("qtype", "");

    qcformik.setFieldValue("qid", "");
    qcformik.setFieldValue("qtypeName", "");
    qcformik.setFieldValue("qfieldOfStudy", "");
    qcformik.setFieldValue("qstartDate", "");
    qcformik.setFieldValue("qendDate", "");
    // qcformik.setFieldValue("qgrade", "");
    // qcformik.setFieldValue("qactivity", "");
    qcformik.setFieldValue("qmedia", "");
    qcformik.setFieldValue("qmediaName", "");
    qcformik.setFieldValue("qmediaType", "");
    setQualificationImage(null);

    qcformik.setFieldValue("cid", "");
    qcformik.setFieldValue("ccourseName", "");
    qcformik.setFieldValue("corgName", "");
    // qcformik.setFieldValue("cstartDate", "");
    // qcformik.setFieldValue("cendDate", "");
    // qcformik.setFieldValue("cgrade", "");
    // qcformik.setFieldValue("cactivity", "");
    // qcformik.setFieldValue("ccertificateUrl", "");
    qcformik.setFieldValue("cmedia", "");
    qcformik.setFieldValue("cmediaName", "");
    qcformik.setFieldValue("cmediaType", "");
    setCertifiacteImage(null);
  };

  const {
    isOpen: isOpenQuali,
    onOpen: onOpenQuali,
    onClose: onCloseQuali,
  } = useDisclosure();

  const {
    isOpen: isOpenCert,
    onOpen: onOpenCert,
    onClose: onCloseCert,
  } = useDisclosure();

  const handleQualificationImage = async (e) => {
    // setQualificationImage(e.target.files[0]);
    await qcformik.setFieldValue("qmedia", e.target.files[0]);
    await qcformik.setFieldValue("qmediaName", e.target.files[0].name);
    await qcformik.setFieldValue("qmediaType", e.target.files[0].type);
    await qcformik.setFieldTouched("qmedia", true);
  };

  const handleCertificationImage = async (e) => {
    // setCertifiacteImage(e.target.files[0]);
    await qcformik.setFieldValue("cmedia", e.target.files[0]);
    await qcformik.setFieldValue("cmediaName", e.target.files[0].name);
    await qcformik.setFieldValue("cmediaType", e.target.files[0].type);
    await qcformik.setFieldTouched("cmedia", true);
  };

  const handleQualPopulate = async (selectedQual) => {
    setAddEditQual("Edit");

    await qcformik.setFieldValue(
      "qid",
      selectedQual._id ? selectedQual._id : ""
    );
    await qcformik.setFieldValue(
      "qtype",
      selectedQual.type ? selectedQual.type : ""
    );
    await qcformik.setFieldValue(
      "qtypeName",
      selectedQual.typeName ? selectedQual.typeName : ""
    );
    await qcformik.setFieldValue(
      "qfieldOfStudy",
      selectedQual.fieldOfStudy ? selectedQual.fieldOfStudy : ""
    );
    await qcformik.setFieldValue(
      "qstartDate",
      selectedQual.startDate ? formatDate(selectedQual.startDate) : ""
    );
    await qcformik.setFieldValue(
      "qendDate",
      selectedQual.endDate ? formatDate(selectedQual.endDate) : ""
    );
    // await qcformik.setFieldValue(
    //   "qgrade",
    //   selectedQual.grade ? selectedQual.grade : ""
    // );
    // await qcformik.setFieldValue(
    //   "qactivity",
    //   selectedQual.activity ? selectedQual.activity : ""
    // );
    await qcformik.setFieldValue(
      "qmedia",
      selectedQual.media ? selectedQual.media : null
    );
    await qcformik.setFieldValue(
      "qmediaName",
      selectedQual.mediaName ? selectedQual.mediaName : ""
    );
    await qcformik.setFieldValue(
      "qmediaType",
      selectedQual.mediaType ? selectedQual.mediaType : ""
    );

    onOpenQuali();
  };

  const handleCertPopulate = async (selectedCert) => {
    setAddEditCert("Edit");

    await qcformik.setFieldValue(
      "cid",
      selectedCert._id ? selectedCert._id : ""
    );
    await qcformik.setFieldValue(
      "ccourseName",
      selectedCert.courseName ? selectedCert.courseName : ""
    );
    await qcformik.setFieldValue(
      "corgName",
      selectedCert.orgName ? selectedCert.orgName : ""
    );
    // await qcformik.setFieldValue(
    //   "cstartDate",
    //   selectedCert.startDate ? formatDate(selectedCert.startDate) : ""
    // );
    // await qcformik.setFieldValue(
    //   "cendDate",
    //   selectedCert.endDate ? formatDate(selectedCert.endDate) : ""
    // );
    // await qcformik.setFieldValue(
    //   "cgrade",
    //   selectedCert.grade ? selectedCert.grade : ""
    // );
    // await qcformik.setFieldValue(
    //   "cactivity",
    //   selectedCert.activity ? selectedCert.activity : ""
    // );
    // await qcformik.setFieldValue(
    //   "ccertificateUrl",
    //   selectedCert.certificateUrl ? selectedCert.certificateUrl : ""
    // );
    await qcformik.setFieldValue(
      "cmedia",
      selectedCert.media ? selectedCert.media : null
    );
    await qcformik.setFieldValue(
      "cmediaName",
      selectedCert.mediaName ? selectedCert.mediaName : ""
    );
    await qcformik.setFieldValue(
      "cmediaType",
      selectedCert.mediaType ? selectedCert.mediaType : ""
    );

    onOpenCert();
  };

  const handleQCSubmit = () => {
    if (submitType == "Qual") {
      handleQualSubmit();
    } else {
      handleCertSubmit();
    }
    setSubmitType("");
  };

  const handleQualSubmit = async (e) => {
    let QData = {};

    if (qcformik.values.qtype) {
      Object.assign(QData, { type: qcformik.values.qtype });
    }
    if (qcformik.values.qtypeName) {
      Object.assign(QData, { typeName: qcformik.values.qtypeName });
    }
    if (qcformik.values.qfieldOfStudy) {
      Object.assign(QData, { fieldOfStudy: qcformik.values.qfieldOfStudy });
    }
    if (qcformik.values.qstartDate) {
      Object.assign(QData, { startDate: qcformik.values.qstartDate });
    }
    if (qcformik.values.qendDate) {
      Object.assign(QData, { endDate: qcformik.values.qendDate });
    }
    // if (qcformik.values.qgrade) {
    //   Object.assign(QData, { grade: qcformik.values.qgrade });
    // }
    // if (qcformik.values.qactivity) {
    //   Object.assign(QData, { activity: qcformik.values.qactivity });
    // }
    if (qcformik.values.qmediaName) {
      Object.assign(QData, { mediaName: qcformik.values.qmediaName });
    }
    if (qcformik.values.qmediaType) {
      Object.assign(QData, { mediaType: qcformik.values.qmediaType });
    }

    if (addEditQual === "Add" && trainerData && trainerData._id) {
      Object.assign(QData, { trainerId: trainerData._id });
    }

    if (addEditQual === "Edit" && qcformik.values.qid) {
      Object.assign(QData, { qualificationId: qcformik.values.qid });
    }

    try {
      setIsLoading(true);
      if (qcformik.values.qmedia) {
        let file = qcformik.values.qmedia;
        const payload = new FormData();

        if (file) {
          payload.append("media", file);
        }

        const data = await dispatch(uploadMedia(payload));

        if (data && data.code === 200) {
          Object.assign(QData, { media: data.data });
        }
      }

      if (addEditQual === "Add") {
        const Qdata = await dispatch(createQualification(QData));
        if (Qdata && Qdata.code == 200) {
          await getTrainerData();
          // Swal.fire({
          //   title: "Success",
          //   text: `Qualification added successfully`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success(`Qualification added successfully`);
          qcformik.handleReset();
        } else {
          if (Qdata.message) {
            // Swal.fire({
            //   title: "Error",
            //   text: Qdata.message,
            //   icon: "error",
            // });
            toast.error(Qdata.message);
          }
        }
      } else {
        const Qdata = await dispatch(editQualification(QData));
        if (Qdata && Qdata.code == 200) {
          await getTrainerData();
          // Swal.fire({
          //   title: "Success",
          //   text: `Qualification edited successfully`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success(`Qualification edited successfully`);
          qcformik.handleReset();
        } else {
          if (Qdata.message) {
            // Swal.fire({
            //   title: "Error",
            //   text: Qdata.message,
            //   icon: "error",
            // });
            toast.error(Qdata.message);
          }
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }

    onCloseQuali();
    resetQC();
  };

  const handleCertSubmit = async (e) => {
    let CData = {};

    if (qcformik.values.ccourseName) {
      Object.assign(CData, { courseName: qcformik.values.ccourseName });
    }
    if (qcformik.values.corgName) {
      Object.assign(CData, { orgName: qcformik.values.corgName });
    }
    // if (qcformik.values.cstartDate) {
    //   Object.assign(CData, { startDate: qcformik.values.cstartDate });
    // }
    // if (qcformik.values.cendDate) {
    //   Object.assign(CData, { endDate: qcformik.values.cendDate });
    // }
    // if (qcformik.values.cgrade) {
    //   Object.assign(CData, { grade: qcformik.values.cgrade });
    // }
    // if (qcformik.values.cactivity) {
    //   Object.assign(CData, { activity: qcformik.values.cactivity });
    // }
    // if (qcformik.values.ccertificateUrl) {
    //   Object.assign(CData, { certificateUrl: qcformik.values.ccertificateUrl });
    // }
    if (qcformik.values.cmedia) {
      Object.assign(CData, { media: qcformik.values.cmedia });
    }
    if (qcformik.values.cmediaName) {
      Object.assign(CData, { mediaName: qcformik.values.cmediaName });
    }
    if (qcformik.values.cmediaType) {
      Object.assign(CData, { mediaType: qcformik.values.cmediaType });
    }

    if (addEditCert === "Add" && trainerData && trainerData._id) {
      Object.assign(CData, { trainerId: trainerData._id });
    }

    if (addEditCert === "Edit" && qcformik.values.cid) {
      Object.assign(CData, { certificationId: qcformik.values.cid });
    }

    try {
      setIsLoading(true);
      if (qcformik.values.cmedia) {
        let file = qcformik.values.cmedia;
        const payload = new FormData();

        if (file) {
          payload.append("media", file);
        }

        const data = await dispatch(uploadMedia(payload));

        if (data && data.code === 200) {
          Object.assign(CData, { media: data.data });
        }
      }

      if (addEditCert === "Add") {
        const Cdata = await dispatch(createCertification(CData));
        if (Cdata && Cdata.code == 200) {
          await getTrainerData();
          // Swal.fire({
          //   title: "Success",
          //   text: `Certification added successfully`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success(`Certification added successfully`);
          qcformik.handleReset();
        } else {
          if (Cdata.message) {
            // Swal.fire({
            //   title: "Error",
            //   text: Cdata.message,
            //   icon: "error",
            // });
            toast.error(Cdata.message);
          }
        }
      } else {
        const Cdata = await dispatch(editCertification(CData));
        if (Cdata && Cdata.code == 200) {
          await getTrainerData();
          // Swal.fire({
          //   title: "Success",
          //   text: `Certification edited successfully`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success(`Certification edited successfully`);
          qcformik.handleReset();
        } else {
          if (Cdata.message) {
            // Swal.fire({
            //   title: "Error",
            //   text: Cdata.message,
            //   icon: "error",
            // });
            toast.error(Cdata.message);
          }
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }

    onCloseCert();
    resetQC();
  };

  const handleDeleteQual = async (id) => {
    try {
      Swal.fire({
        title: "warning",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2bc370",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const Qdata = await dispatch(
            deleteQualification({ qualificationId: id })
          );
          if (Qdata && Qdata.code == 200) {
            await getTrainerData();
            // Swal.fire({
            //   title: "Success",
            //   text: `Qualification deleted successfully`,
            //   icon: "success",
            //   timer: "2500",
            // });
            toast.success(`Qualification deleted successfully`);
            qcformik.handleReset();
          } else {
            if (Qdata.message) {
              // Swal.fire({
              //   title: "Error",
              //   text: Qdata.message,
              //   icon: "error",
              // });
              toast.error(Qdata.message);
            }
          }
        }
      });
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const handleDeleteCert = async (id) => {
    try {
      Swal.fire({
        title: "warning",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2bc370",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const Cdata = await dispatch(
            deleteCertification({ certificationId: id })
          );
          if (Cdata && Cdata.code == 200) {
            await getTrainerData();
            // Swal.fire({
            //   title: "Success",
            //   text: `Certification deleted successfully`,
            //   icon: "success",
            //   timer: "2500",
            // });
            toast.success(`Certification deleted successfully`);
            qcformik.handleReset();
          } else {
            if (Cdata.message) {
              // Swal.fire({
              //   title: "Error",
              //   text: Cdata.message,
              //   icon: "error",
              // });
              toast.error(Cdata.message);
            }
          }
        }
      });
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const qcformik = useFormik({
    initialValues: qcinitialValues,
    onSubmit: handleQCSubmit,
    validationSchema:
      isOpenQuali === true
        ? qvalidationRules
        : isOpenCert === true
        ? cvalidationRules
        : "",
    validateOnChange: true,
  });

  console.log(qcformik.errors, "qcformik.errors");

  return (
    <div>
      {/* edit profile  */}

      <Modal isOpen={isOpenQuali} onClose={onCloseQuali}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={qcformik.handleSubmit}>
            <ModalHeader>{addEditQual} Qualification</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <FormControl
                  isInvalid={!!qcformik.errors.qtype && qcformik.touched.qtype}
                  className="mb-4"
                >
                  <FormLabel className="fsize14">
                    Qualification type<span className="text-danger">*</span>
                  </FormLabel>

                  <Select
                    name="qtype"
                    value={qcformik.values.qtype}
                    onChange={qcformik.handleChange}
                    onBlur={handleTrim}
                    placeholder="Select Option"
                    className="fsize16"
                  >
                    {/* <option>Certificate</option> */}
                    <option>Diploma</option>
                    <option>Degree</option>
                  </Select>
                  {/* <Input
                    name="qtype"
                    value={qcformik.values.qtype}
                    onChange={qcformik.handleChange}
                    onBlur={qchandleTrim}
                    placeholder="Qualification type"
                    className="fsize13"
                  /> */}
                  {qcformik.touched.qtype && qcformik.errors.qtype && (
                    <FormErrorMessage>{qcformik.errors.qtype}</FormErrorMessage>
                  )}
                </FormControl>

                {qcformik.values.qtype && (
                  <FormControl
                    isInvalid={
                      !!qcformik.errors.qtypeName && qcformik.touched.qtypeName
                    }
                    className="mb-4"
                  >
                    <FormLabel className="fsize14">
                      {qcformik.values.qtype} Name
                      <span className="text-danger">*</span>
                    </FormLabel>
                    <Input
                      name="qtypeName"
                      value={qcformik.values.qtypeName}
                      onChange={qcformik.handleChange}
                      onBlur={qchandleTrim}
                      placeholder="Enter Name"
                      className="fsize13"
                    />{" "}
                    {qcformik.touched.qtypeName &&
                      qcformik.errors.qtypeName && (
                        <FormErrorMessage>
                          {qcformik.errors.qtypeName}
                        </FormErrorMessage>
                      )}
                  </FormControl>
                )}

                <FormControl
                  isInvalid={
                    !!qcformik.errors.qfieldOfStudy &&
                    qcformik.touched.qfieldOfStudy
                  }
                  className="mb-4"
                >
                  <FormLabel className="fsize14">Field of Study</FormLabel>
                  <Input
                    name="qfieldOfStudy"
                    value={qcformik.values.qfieldOfStudy}
                    onChange={qcformik.handleChange}
                    onBlur={qchandleTrim}
                    placeholder="Field of Study"
                    className="fsize13"
                  />{" "}
                  {qcformik.touched.qfieldOfStudy &&
                    qcformik.errors.qfieldOfStudy && (
                      <FormErrorMessage>
                        {qcformik.errors.qfieldOfStudy}
                      </FormErrorMessage>
                    )}
                </FormControl>

                <div className="lg:flex w-full gap-4 md:flex mb-4">
                  <div className="lg:w-1/2 md:w-1/2">
                    <FormControl
                      isInvalid={
                        !!qcformik.errors.qstartDate &&
                        qcformik.touched.qstartDate
                      }
                    >
                      <FormLabel className="fsize14">
                        Start Date<span className="text-danger">*</span>
                      </FormLabel>
                      <Input
                        name="qstartDate"
                        value={qcformik.values.qstartDate}
                        onChange={qcformik.handleChange}
                        onBlur={qchandleTrim}
                        type="date"
                        placeholder="DD/MM/YYYY"
                        className="fsize13"
                        max={
                          new Date(
                            qcformik.values.qendDate
                              ? qcformik.values.qendDate
                              : new Date()
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                      />
                      {qcformik.touched.qstartDate &&
                        qcformik.errors.qstartDate && (
                          <FormErrorMessage>
                            {qcformik.errors.qstartDate}
                          </FormErrorMessage>
                        )}
                    </FormControl>
                  </div>
                  <div className="lg:w-1/2 md:w-1/2">
                    <FormControl
                      isInvalid={
                        !!qcformik.errors.qendDate && qcformik.touched.qendDate
                      }
                    >
                      <FormLabel className="fsize14">
                        End Date<span className="text-danger">*</span>
                      </FormLabel>
                      <Input
                        name="qendDate"
                        value={qcformik.values.qendDate}
                        onChange={qcformik.handleChange}
                        onBlur={qchandleTrim}
                        type="date"
                        placeholder="DD/MM/YYYY"
                        className="fsize13"
                        min={
                          new Date(
                            qcformik.values.qstartDate
                              ? qcformik.values.qstartDate
                              : null
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                      />
                      {qcformik.touched.qendDate &&
                        qcformik.errors.qendDate && (
                          <FormErrorMessage>
                            {qcformik.errors.qendDate}
                          </FormErrorMessage>
                        )}
                    </FormControl>
                  </div>
                </div>

                {/* <FormControl
                  isInvalid={
                    !!qcformik.errors.qgrade && qcformik.touched.qgrade
                  }
                  className="mb-4"
                >
                  <FormLabel className="fsize14">Grade</FormLabel>
                  <Input
                    name="qgrade"
                    value={qcformik.values.qgrade}
                    onChange={qcformik.handleChange}
                    onBlur={qchandleTrim}
                    placeholder="grade"
                    className="fsize13"
                  />
                  {qcformik.touched.qgrade && qcformik.errors.qgrade && (
                    <FormErrorMessage>
                      {qcformik.errors.qgrade}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  isInvalid={
                    !!qcformik.errors.qactivity && qcformik.touched.qactivity
                  }
                  className="mb-4"
                >
                  <FormLabel className="fsize14">Activity</FormLabel>
                  <Input
                    name="qactivity"
                    value={qcformik.values.qactivity}
                    onChange={qcformik.handleChange}
                    onBlur={qchandleTrim}
                    placeholder="Activity"
                    className="fsize13"
                  />
                  {qcformik.touched.qactivity && qcformik.errors.qactivity && (
                    <FormErrorMessage>
                      {qcformik.errors.qactivity}
                    </FormErrorMessage>
                  )}
                </FormControl> */}

                <FormControl
                  isInvalid={
                    !!qcformik.errors.qmedia && qcformik.touched.qmedia
                  }
                >
                  <FormLabel className="fsize14">
                    Media attachment(optional)
                  </FormLabel>
                  <div className="relative">
                    <InputGroup size="md">
                      <Input
                        readOnly
                        disabled
                        name="qmedia"
                        value={qcformik.values.qmediaName}
                        placeholder="media"
                        className="fsize13 cursor-pointer"
                      />
                      <InputRightElement
                        width="6.5rem"
                        className="cursor-pointer"
                      >
                        {qcformik.values.qmediaName ? (
                          <h2
                            onClick={async () => {
                              setQualificationImage(null);
                              await qcformik.setFieldValue("qmediaName", "");
                              await qcformik.setFieldValue("qmediaType", "");
                              await qcformik.setFieldValue("qmedia", "");
                              await qcformik.setFieldTouched("qmedia", false);
                            }}
                            className="cursor-pointer fsize14 text-primary font-semibold"
                          >
                            X
                          </h2>
                        ) : (
                          <div className="relative cursor-pointer">
                            <h2 className="cursor-pointer  fsize14 text-primary font-semibold">
                              Upload
                              <input
                                type="file"
                                className="opacity-0 uploader-input cursor-pointer left-0 w-full "
                                onChange={handleQualificationImage}
                              />
                            </h2>{" "}
                          </div>
                        )}
                      </InputRightElement>
                    </InputGroup>
                  </div>
                  {qcformik.touched.qmedia && qcformik.errors.qmedia && (
                    <FormErrorMessage>
                      {qcformik.errors.qmedia}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </ModalBody>

            <ModalFooter className="mt-6">
              <Button
                variant="outline"
                colorScheme="secondary"
                mr={3}
                onClick={onCloseQuali}
              >
                Close
              </Button>

              <Button
                isLoading={isLoading}
                spinner={<ThreeDots visible={true} color="white" radius={3} />}
                type="submit"
                onClick={() => setSubmitType("Qual")}
                px="8"
                colorScheme="primary"
              >
                {addEditQual === "Edit" ? "Update" : "Add"}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCert} onClose={onCloseCert}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={qcformik.handleSubmit}>
            <ModalHeader> {addEditCert} Certification</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <FormControl
                  isInvalid={
                    !!qcformik.errors.ccourseName &&
                    qcformik.touched.ccourseName
                  }
                  className="mb-4"
                >
                  <FormLabel className="fsize14">
                    Certificate Name<span className="text-danger">*</span>
                  </FormLabel>
                  <Input
                    name="ccourseName"
                    value={qcformik.values.ccourseName}
                    onChange={qcformik.handleChange}
                    onBlur={handleTrim}
                    placeholder="Enter..."
                  />
                  {qcformik.touched.ccourseName &&
                    qcformik.errors.ccourseName && (
                      <FormErrorMessage>
                        {qcformik.errors.ccourseName}
                      </FormErrorMessage>
                    )}
                </FormControl>

                <FormControl
                  isInvalid={
                    !!qcformik.errors.corgName && qcformik.touched.corgName
                  }
                  className="mb-4"
                >
                  <FormLabel className="fsize14">
                    Name of Organisation<span className="text-danger">*</span>
                  </FormLabel>
                  <Input
                    name="corgName"
                    value={qcformik.values.corgName}
                    onChange={qcformik.handleChange}
                    onBlur={handleTrim}
                    placeholder="Enter..."
                  />
                  {qcformik.touched.corgName && qcformik.errors.corgName && (
                    <FormErrorMessage>
                      {qcformik.errors.corgName}
                    </FormErrorMessage>
                  )}
                </FormControl>

                {/* <div className="lg:flex w-full gap-4 md:flex mb-4">
                  <div className="lg:w-1/2 md:w-1/2">
                    <FormControl
                      isInvalid={
                        !!qcformik.errors.cstartDate &&
                        qcformik.touched.cstartDate
                      }
                    >
                      <FormLabel className="fsize14">
                        Start Date<span className="text-danger">*</span>
                      </FormLabel>
                      <Input
                        name="cstartDate"
                        value={qcformik.values.cstartDate}
                        onChange={qcformik.handleChange}
                        onBlur={handleTrim}
                        type="date"
                        placeholder="DD/MM/YYYY"
                        max={
                          new Date(
                            qcformik.values.cendDate
                              ? qcformik.values.cendDate
                              : new Date()
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                      />
                      {qcformik.touched.cstartDate &&
                        qcformik.errors.cstartDate && (
                          <FormErrorMessage>
                            {qcformik.errors.cstartDate}
                          </FormErrorMessage>
                        )}
                    </FormControl>
                  </div>

                  <div className="lg:w-1/2 md:w-1/2">
                    <FormControl
                      isInvalid={
                        !!qcformik.errors.cendDate && qcformik.touched.cendDate
                      }
                    >
                      <FormLabel className="fsize14">
                        End Date<span className="text-danger">*</span>
                      </FormLabel>
                      <Input
                        name="cendDate"
                        value={qcformik.values.cendDate}
                        onChange={qcformik.handleChange}
                        onBlur={handleTrim}
                        type="date"
                        placeholder="DD/MM/YYYY"
                        min={
                          new Date(
                            qcformik.values.cstartDate
                              ? qcformik.values.cstartDate
                              : null
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                      />
                      {qcformik.touched.cendDate &&
                        qcformik.errors.cendDate && (
                          <FormErrorMessage>
                            {qcformik.errors.cendDate}
                          </FormErrorMessage>
                        )}
                    </FormControl>
                  </div>
                </div> */}

                {/* <FormControl
                  isInvalid={
                    !!qcformik.errors.cgrade && qcformik.touched.cgrade
                  }
                  className="mb-4"
                >
                  <FormLabel className="fsize14">Grade</FormLabel>
                  <Input
                    name="cgrade"
                    value={qcformik.values.cgrade}
                    onChange={qcformik.handleChange}
                    onBlur={handleTrim}
                    placeholder="Grade"
                  />
                  {qcformik.touched.cgrade && qcformik.errors.cgrade && (
                    <FormErrorMessage>
                      {qcformik.errors.cgrade}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  isInvalid={
                    !!qcformik.errors.cactivity && qcformik.touched.cactivity
                  }
                  className="mb-4"
                >
                  <FormLabel className="fsize14">Activity</FormLabel>
                  <Input
                    name="cactivity"
                    value={qcformik.values.cactivity}
                    onChange={qcformik.handleChange}
                    onBlur={handleTrim}
                    placeholder="Enter..."
                  />
                  {qcformik.touched.cactivity && qcformik.errors.cactivity && (
                    <FormErrorMessage>
                      {qcformik.errors.cactivity}
                    </FormErrorMessage>
                  )}
                </FormControl> */}

                {/* <FormControl
                  isInvalid={
                    !!qcformik.errors.ccertificateUrl &&
                    qcformik.touched.ccertificateUrl
                  }
                  className="mb-4"
                >
                  <FormLabel>
                    Certfication Link<span className="text-danger">*</span>
                  </FormLabel>
                  <Input
                    name="ccertificateUrl"
                    value={qcformik.values.ccertificateUrl}
                    onChange={qcformik.handleChange}
                    onBlur={handleTrim}
                    placeholder="Enter..."
                  />
                  {qcformik.touched.ccertificateUrl &&
                    qcformik.errors.ccertificateUrl && (
                      <FormErrorMessage>
                        {qcformik.errors.ccertificateUrl}
                      </FormErrorMessage>
                    )}
                </FormControl> */}

                <FormControl
                  isInvalid={
                    !!qcformik.errors.cmedia && qcformik.touched.cmedia
                  }
                >
                  <FormLabel className="fsize14">
                    Media attachment<span className="text-danger">*</span>
                  </FormLabel>
                  <div className="relative">
                    <InputGroup size="md">
                      <Input
                        readOnly
                        disabled
                        name="cmedia"
                        value={qcformik.values.cmediaName}
                        placeholder="Enter..."
                        className="cursor-pointer"
                      />
                      <InputRightElement
                        width="6.5rem"
                        className="cursor-pointer"
                      >
                        {qcformik.values.cmediaName ? (
                          <h2
                            onClick={async () => {
                              setCertifiacteImage(null);
                              await qcformik.setFieldValue("cmediaName", "");
                              await qcformik.setFieldValue("cmedia", "");
                              await qcformik.setFieldValue("cmediaType", "");
                              await qcformik.setFieldTouched("cmedia", false);
                            }}
                            className="cursor-pointer fsize14 text-primary font-semibold"
                          >
                            X
                          </h2>
                        ) : (
                          <div className="relative cursor-pointer">
                            <h2 className="cursor-pointer fsize14 text-primary font-semibold">
                              Upload
                              <input
                                type="file"
                                className="opacity-0 uploader-input cursor-pointer left-0 w-full "
                                onChange={handleCertificationImage}
                              />
                            </h2>{" "}
                          </div>
                        )}
                      </InputRightElement>
                    </InputGroup>
                  </div>
                  {qcformik.touched.cmedia && qcformik.errors.cmedia && (
                    <FormErrorMessage>
                      {qcformik.errors.cmedia}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </ModalBody>

            <ModalFooter className="mt-6">
              <Button
                variant="outline"
                colorScheme="secondary"
                mr={3}
                onClick={onCloseCert}
              >
                Close
              </Button>

              <Button
                isLoading={isLoading}
                spinner={<ThreeDots visible={true} color="white" radius={3} />}
                type="submit"
                onClick={() => setSubmitType("Cert")}
                px="8"
                colorScheme="primary"
              >
                {addEditCert === "Edit" ? "Update" : "Add"}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <form className="w-full" onSubmit={formik.handleSubmit}>
        <Modal isOpen={isOpenCover} onClose={onCloseCover}>
          <ModalOverlay />

          <ModalContent>
            <ModalHeader> Upload Cover Image </ModalHeader>

            <ModalBody>
              <div>
                <FormControl
                  mt={4}
                  isInvalid={
                    !!formik.errors.coverImage && formik.touched.coverImage
                  }
                >
                  {!formik.values.coverImage && (
                    <UploaderBox
                      accept="image/*"
                      onFileSelect={async (file) => {
                        setFieldHandler("coverImage", file);
                        validateField("coverImage", file);
                      }}
                      onPreview={async (prev) =>
                        setFieldHandler("coverImagePreview", prev)
                      }
                    />
                  )}
                  {formik.values.coverImage &&
                    formik.values.coverImagePreview && (
                      <div className="relative">
                        <Image
                          src={
                            formik.values.coverImagePreview
                              ? getImage(formik.values.coverImagePreview)
                              : `${process.env.REACT_APP_STORAGE_URL}fit2go/undefined/primaryLogo-DpYo0uWB2xJu3JgeKKl92-1701935536`
                          }
                          // fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}fit2go/undefined/primaryLogo-DpYo0uWB2xJu3JgeKKl92-1701935536`}
                          className="object-contain w-full h-200px"
                        />
                        <FeatherIcon
                          onClick={() => {
                            setFieldHandler("coverImage", null);
                            setFieldHandler("coverImagePreview", null);
                            formik.setFieldError("coverImage", undefined);
                            formik.setFieldTouched("coverImage", false);
                          }}
                          icon="x"
                          className="border bg-fff rounded-full absolute top-2 right-4 cursor-pointer"
                          size={16}
                        />
                      </div>
                    )}
                  <div className="flex justify-center align-center">
                    {formik.touched.coverImage && formik.errors.coverImage && (
                      <FormErrorMessage>
                        {formik.errors.coverImage}
                      </FormErrorMessage>
                    )}
                  </div>
                  <p className="fsize13 text-gray mt-2">
                    Image Resolution: 1095px * 300px
                  </p>
                </FormControl>
              </div>
            </ModalBody>

            <ModalFooter className="mt-4">
              <Button
                variant="outline"
                colorScheme="secondary"
                mr={3}
                onClick={() => {
                  onCloseCover();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpenImage} onClose={onCloseImage}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader> Upload User Image </ModalHeader>

            <ModalBody>
              <div>
                <FormControl
                  mt={4}
                  isInvalid={
                    !!formik.errors.userImage && formik.touched.userImage
                  }
                >
                  {!formik.values.userImage && (
                    <UploaderBox
                      accept="image/*"
                      onFileSelect={async (file) => {
                        setFieldHandler("userImage", file);
                        validateField("userImage", file);
                      }}
                      onPreview={async (prev) =>
                        setFieldHandler("userImagePreview", prev)
                      }
                    />
                  )}
                  {formik.values.userImage &&
                    formik.values.userImagePreview && (
                      <div className="relative">
                        {" "}
                        <Image
                          src={
                            formik.values.userImagePreview
                              ? getImage(formik.values.userImagePreview)
                              : null
                          }
                          className="object-contain w-full h-200px"
                        />
                        <FeatherIcon
                          onClick={() => {
                            setFieldHandler("userImage", null);
                            setFieldHandler("userImagePreview", null);
                            formik.setFieldError("userImage", undefined);
                            formik.setFieldTouched("userImage", false);
                          }}
                          icon="x"
                          className="border bg-fff rounded-full absolute top-2 right-4 cursor-pointer"
                          size={16}
                        />
                      </div>
                    )}
                  <div className="flex justify-center align-center">
                    {formik.touched.userImage && formik.errors.userImage && (
                      <FormErrorMessage>
                        {formik.errors.userImage}
                      </FormErrorMessage>
                    )}
                  </div>
                </FormControl>
              </div>
            </ModalBody>

            <ModalFooter className="mt-4">
              <Button
                variant="outline"
                colorScheme="secondary"
                mr={3}
                onClick={() => {
                  onCloseImage();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <div className="border  py-4 px-6 rounded-3px">
          <h2 className="fsize18 font-semibold pb-4  ">Edit Trainer Profile</h2>
          <div className="w-full relative">
            <Image
              src={
                formik.values.coverImagePreview
                  ? getImage(formik.values.coverImagePreview)
                  : null
              }
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}fit2go/undefined/primaryLogo-DpYo0uWB2xJu3JgeKKl92-1701935536`}
              alt="user-background"
              className="lg:rounded-lg md:rounded-lg w-full object-cover h-300px "
            />
            <FeatherIcon
              onClick={onOpenCover}
              icon="edit"
              className=" absolute right-2 top-2 w-6 h-6 edit_icon  p-1 rounded-full"
            />
          </div>
          <div className="lg:flex md:flex justify-between items-end pb-6 minus3rem-mtop">
            <div className="py-2 relative w-max lg:mx-0 md:mx-0 mx-auto lg:ml-8">
              <Image
                src={
                  formik.values.userImagePreview
                    ? getImage(formik.values.userImagePreview)
                    : null
                }
                fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile-pic"
                className="w-32 h-32 rounded-full bg-6f6f6f text-center border3px-fff"
              />
              <FeatherIcon
                onClick={onOpenImage}
                icon="edit"
                className=" absolute right-1 bottom-5 w-6 h-6 edit_icon  p-1 rounded-full"
              />
            </div>
            <div className="flex gap-4 justify-center ">
              <Button
                isLoading={userLoading}
                spinner={<ThreeDots visible={true} color="white" radius={3} />}
                type="submit"
                colorScheme="primary"
                className="fsize14 clr-fff"
                onClick={() =>
                  formik.errors && !Helper.Object.isEmpty(formik.errors)
                    ? toast.warning(
                        formik.errors[Object.keys(formik.errors)[0]]
                      )
                    : ""
                }
              >
                Save changes
              </Button>
              <Button
                onClick={() => setIsEditProfileHandler()}
                colorScheme="secondary"
                className="fsize14 clr-fff"
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="py-4">
            <div>
              <h2 className="fsize18 font-semibold mb-4 clr-606060">
                Basic Details
              </h2>
              <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
                <div className="w-full">
                  <FormControl
                    isInvalid={
                      !!formik.errors.firstName && formik.touched.firstName
                    }
                  >
                    <label className="clr-7b7b7b fsize14">First Name</label>
                    <Input
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      type="text"
                      className="w-full border-6d6d6d rounded-lg px-4 py-3 mt-2 fsize14"
                      placeholder="Enter your first name"
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <FormErrorMessage>
                        {formik.errors.firstName}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
                <div className="w-full">
                  <FormControl
                    isInvalid={
                      !!formik.errors.lastName && formik.touched.lastName
                    }
                    className="mb-4"
                  >
                    <label className="clr-7b7b7b fsize14">Last Name</label>
                    <Input
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      type="text"
                      className="w-full border-6d6d6d rounded-lg px-4 mt-2 py-3 fsize14"
                      placeholder="Enter your last name"
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <FormErrorMessage>
                        {formik.errors.lastName}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
              </div>

              <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
                <div className="w-full">
                  <FormControl
                    isInvalid={!!formik.errors.mobile && formik.touched.mobile}
                  >
                    <label className="clr-7b7b7b fsize14">Mobile Number</label>
                    <div className="relative">
                      <PhoneInput
                        disabled={isShowEmailOTP}
                        name="mobile"
                        country={"sg"}
                        enableSearch={true}
                        className="mt-2 phoneinput w-full mt-2 themefamily"
                        value={userMobileCountry}
                        onChange={handlePhoneChange}
                      />
                      <div className="absolute top-0 right-0">
                        {userMobileCountry &&
                          user.countryCode &&
                          user.mobile &&
                          userMobileCountry !==
                            `${user.countryCode}${user.mobile}` &&
                          !isShowEmailOTP && (
                            <Button
                              onClick={GetEmailOTP}
                              colorScheme="primary"
                              className="w-full py-1 fsize13"
                            >
                              Send OTP
                            </Button>
                          )}
                      </div>
                    </div>
                    {isShowEmailOTP && (
                      <div>
                        <div className="mt-4 resendotp-t">
                          <p className=" fsize14 sm-fsize12 text333 font-semibold mb-1">
                            {"Enter OTP"}
                          </p>
                          <FormControl
                            isInvalid={
                              !!formik.errors.otp && formik.touched.otp
                            }
                          >
                            <OTPInput
                              name="otp"
                              value={formik.values.otp}
                              onChange={(e) => {
                                formik.setFieldValue("otp", e);
                              }}
                              OTPLength={6}
                              otpType="number"
                              className="numb-otp"
                              inputclassName="themefamily"
                            />
                            {formik.touched.otp && formik.errors.otp && (
                              <FormErrorMessage>
                                {formik.errors.otp}
                              </FormErrorMessage>
                            )}
                          </FormControl>

                          <div className="mt-2">
                            <ResendOTP onResendClick={() => GetEmailOTP()} />
                          </div>
                          {formik.values.otp &&
                            formik.values.otp.toString().length === 6 && (
                              <Button
                                onClick={handleUpdateEmailMobile}
                                colorScheme="primary"
                                className="w-full py-1 mt-2 fsize13"
                              >
                                Update Mobile Number
                              </Button>
                            )}
                        </div>
                      </div>
                    )}
                    {formik.touched.mobile && formik.errors.mobile && (
                      <FormErrorMessage>
                        {formik.errors.mobile}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
                <div className="w-full">
                  <FormControl
                    isInvalid={!!formik.errors.email && formik.touched.email}
                    className="mb-4"
                  >
                    <label className="clr-7b7b7b fsize14 cursor-pointer flex items-center gap-1">
                      Email Address{" "}
                      <Popover trigger="hover">
                        <PopoverTrigger trigger="hover">
                          <span className="bg-fa w-6 h-6 flex items-center justify-center rounded-full">?</span>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody>
                            Want to update your email? Please write us at
                            <a
                              href="mailto:info@fit2go.com"
                              className="text-primary underline ml-2"
                            >
                              info@fit2go.com
                            </a>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </label>
                    <Input
                      readOnly
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14 cursor-not-allowed
                      "
                      placeholder="Enter your email address"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    )}
                  </FormControl>
                </div>
              </div>
              <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
                <div className="w-full">
                  <FormControl
                    isInvalid={!!formik.errors.dob && formik.touched.dob}
                  >
                    <label className="clr-7b7b7b fsize14">Date of Birth</label>
                    <Input
                      name="dob"
                      value={formik.values.dob}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      type="date"
                      className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                      placeholder="DD/MM/YYYY"
                      max={DOBDates}
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <FormErrorMessage>{formik.errors.dob}</FormErrorMessage>
                    )}
                  </FormControl>
                  <Checkbox
                    size="sm"
                    className="mt-2"
                    colorScheme={"primary"}
                    isChecked={formik.values.isDOBHide}
                    onChange={async (value) => {
                      await setFieldHandler("isDOBHide", value.target.checked);
                    }}
                  >
                    <p className="fsize12">Hide date of birth in profile</p>
                  </Checkbox>
                </div>
                <div className="w-full">
                  <FormControl
                    isInvalid={!!formik.errors.gender && formik.touched.gender}
                  >
                    <label className="clr-7b7b7b fsize14">Gender</label>
                    <Select
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      className="text-gray mt-2"
                      placeholder="Select option"
                    >
                      <option> Male</option>
                      <option> Female</option>
                      <option>Other </option>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && (
                      <FormErrorMessage>
                        {formik.errors.gender}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>

            <Divider />

            <div className="mt-8">
              <h2 className="fsize18 font-semibold mb-4 clr-606060">
                Other Details
              </h2>

              <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
                <div className="w-full">
                  <FormControl
                    isInvalid={
                      !!formik.errors.experience && formik.touched.experience
                    }
                    className="mb-4"
                  >
                    <label className="clr-7b7b7b fsize14">Experience</label>
                    <Select
                      name="experience"
                      value={formik.values.experience}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      placeholder="select option"
                      className="w-full rounded-lg  mt-2 fsize14"
                    >
                      <option value="0 to 1 Year">0 to 1 Year</option>
                      <option value="2 to 5 Years">2 to 5 Years</option>
                      <option value="6 to 10 Years">6 to 10 Years</option>
                      <option value="11 to 15 Years">11 to 15 Years</option>
                      <option value="16 to 20 Years">16 to 20 Years</option>
                      <option value="More than 20 Year">
                        More than 20 Year
                      </option>
                    </Select>
                    {formik.touched.experience && formik.errors.experience && (
                      <FormErrorMessage>
                        {formik.errors.experience}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
                <div className="w-full">
                  <label className="clr-7b7b7b fsize14">Language</label>
                  <FormControl
                    isInvalid={
                      !!formik.errors.language && formik.touched.language
                    }
                  >
                    <Multiselect
                      name="language"
                      className={`w-full  rounded-lg  fsize14 mt-2 cust-multiselect ${
                        formik.errors.language && "cust-error-border"
                      }`}
                      options={languageOptions}
                      selectedValues={formik.values.language}
                      onSelect={async (value) => {
                        await setFieldHandler("language", value);
                      }}
                      onRemove={async (value) => {
                        await setFieldHandler("language", value);
                      }}
                      displayValue="name"
                    />{" "}
                    {formik.touched.language && formik.errors.language && (
                      <FormErrorMessage>
                        {formik.errors.language}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
              </div>

              <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
                <div className="w-full">
                  <label className="clr-7b7b7b fsize14">
                    Where Do you Train
                  </label>
                  <FormControl
                    isInvalid={
                      !!formik.errors.trainingLocation &&
                      formik.touched.trainingLocation
                    }
                  >
                    <Multiselect
                      name="trainingLocation"
                      className={`w-full  rounded-lg  fsize14 mt-2 cust-multiselect ${
                        formik.errors.trainingLocation && "cust-error-border"
                      }`}
                      options={trainingLocationOptions}
                      selectedValues={formik.values.trainingLocation}
                      onSelect={async (value) => {
                        await setFieldHandler("trainingLocation", value);
                      }}
                      onRemove={async (value) => {
                        await setFieldHandler("trainingLocation", value);
                      }}
                      displayValue="name"
                    />{" "}
                    {formik.touched.trainingLocation &&
                      formik.errors.trainingLocation && (
                        <FormErrorMessage>
                          {formik.errors.trainingLocation}
                        </FormErrorMessage>
                      )}
                  </FormControl>
                </div>

                <div className="w-full">
                  <label className="clr-7b7b7b fsize14">Training Type</label>
                  <FormControl
                    isInvalid={
                      !!formik.errors.trainingType &&
                      formik.touched.trainingType
                    }
                  >
                    <Multiselect
                      name="trainingType"
                      className={`w-full  rounded-lg  fsize14 mt-2 cust-multiselect ${
                        formik.errors.trainingType && "cust-error-border"
                      }`}
                      options={trainingTypeOptions}
                      selectedValues={formik.values.trainingType}
                      onSelect={async (value) => {
                        await setFieldHandler("trainingType", value);
                      }}
                      onRemove={async (value) => {
                        await setFieldHandler("trainingType", value);
                      }}
                      displayValue="name"
                    />{" "}
                    {formik.touched.trainingType &&
                      formik.errors.trainingType && (
                        <FormErrorMessage>
                          {formik.errors.trainingType}
                        </FormErrorMessage>
                      )}
                  </FormControl>
                </div>

                <div className="w-full">
                  <label className="clr-7b7b7b fsize14 ">Location</label>
                  <FormControl
                    isInvalid={
                      !!formik.errors.location && formik.touched.location
                    }
                  >
                    <Input
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      onBlur={handleTrim}
                      name="location"
                      placeholder="Enter..."
                      className="mt-2"
                    />
                    {formik.touched.location && formik.errors.location && (
                      <FormErrorMessage>
                        {formik.errors.location}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </div>
              </div>

              <div>
                <FormControl
                  isInvalid={!!formik.errors.about && formik.touched.about}
                >
                  <label className="clr-7b7b7b fsize14">Why hire me</label>
                  <Textarea
                    value={formik.values.about}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    name="about"
                    className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                    placeholder="Type here"
                    rows="4"
                  ></Textarea>
                  {formik.touched.about && formik.errors.about && (
                    <FormErrorMessage>{formik.errors.about}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>

            <Divider className="mt-8" />

            <div className="mt-8">
              <div>
                {/* <h2 className="fsize18 font-semibold clr-606060 mb-4">
                  Specialisation{" "}
                </h2> */}
                <label className="clr-7b7b7b fsize14 ">Specialisation</label>
                <FormControl
                  isInvalid={
                    !!formik.errors.specialization &&
                    formik.touched.specialization
                  }
                >
                  <Multiselect
                    name="specialization"
                    className={`w-full  rounded-lg  fsize14 mt-2 cust-multiselect ${
                      formik.errors.specialization && "cust-error-border"
                    }`}
                    options={specializationOptions}
                    selectedValues={formik.values.specialization}
                    onSelect={async (value) => {
                      await setFieldHandler("specialization", value);
                    }}
                    onRemove={async (value) => {
                      await setFieldHandler("specialization", value);
                    }}
                    displayValue="name"
                  />{" "}
                  {formik.touched.specialization &&
                    formik.errors.specialization && (
                      <FormErrorMessage>
                        {formik.errors.specialization}
                      </FormErrorMessage>
                    )}
                </FormControl>
              </div>
            </div>
            <div className="mt-8">
              <div>
                {/* <h2 className="fsize18 font-semibold clr-606060 mb-4">
                  Fitness Category{" "}
                </h2> */}
                <label className="clr-7b7b7b fsize14 ">Fitness Category</label>
                <FormControl
                  isInvalid={
                    !!formik.errors.fitnessCategories &&
                    formik.touched.fitnessCategories
                  }
                >
                  <Multiselect
                    name="fitnessCategories"
                    className={`w-full  rounded-lg  fsize14 mt-2 cust-multiselect ${
                      formik.errors.fitnessCategories && "cust-error-border"
                    }`}
                    options={fitnessCategoriesOptions.current}
                    selectedValues={formik.values.fitnessCategories}
                    onSelect={async (value) => {
                      await setFieldHandler("fitnessCategories", value);
                    }}
                    onRemove={async (value) => {
                      await setFieldHandler("fitnessCategories", value);
                    }}
                    displayValue="name"
                  />{" "}
                  {formik.touched.fitnessCategories &&
                    formik.errors.fitnessCategories && (
                      <FormErrorMessage>
                        {formik.errors.fitnessCategories}
                      </FormErrorMessage>
                    )}
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* education */}

      <div className="rounded-lg  px-6 cust-shadow mt-6">
        <div className="  py-4 ">
          <h2 className="fsize18 font-semibold mb-4 clr-606060 flex justify-between">
            Qualification Details
            <FeatherIcon
              icon="plus"
              fontSize="12px"
              className="cursor-pointer"
              onClick={() => {
                resetQC();
                setAddEditQual("Add");
                onOpenQuali();
              }}
            />
          </h2>

          <Divider />
          <div
            className="grid lg:grid-cols-2 md:grid-cols-1
                       grid-cols-1 gap-4 p-2 mt-4"
          >
            {trainerData &&
            trainerData.qualification &&
            trainerData.qualification.length > 0
              ? trainerData.qualification.map((qualification) => (
                  <div
                    className="flex items-start gap-2"
                    key={qualification._id}
                  >
                    <div className="w-full flex gap-4">
                      <div>
                        <Image
                          src={
                            qualification.media
                              ? getImage(qualification.media)
                              : null
                          }
                          fallbackSrc="https://nimbusclustersg.blob.core.windows.net/server01/fit2go/asset/graduate.png"
                          alt="logo"
                          className="w-16 h-16 object-cover rounded-sm"
                        />
                      </div>
                      <div>
                        <h2 className="font-bold text-black uppercase">
                          {qualification.typeName}
                        </h2>
                        <p className="fsize12 font-semibold text-black">
                          {qualification.type}
                        </p>
                        <span className="fsize12 pr-2 text-gray">
                          {new Date(qualification.startDate).toLocaleDateString(
                            "en-IN"
                          )}
                          -
                          {new Date(qualification.endDate).toLocaleDateString(
                            "en-IN"
                          )}
                        </span>
                      </div>
                    </div>

                    <Menu width="50">
                      <MenuButton
                        px={0}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="0px"
                      >
                        <FeatherIcon icon="more-vertical" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            resetQC();
                            handleQualPopulate(qualification);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleDeleteQual(qualification._id);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                ))
              : "No Qualification Found"}
          </div>
        </div>

        <div className="  py-4  ">
          <h2 className="fsize18 font-semibold mb-4 clr-606060 flex justify-between">
            Certification Details
            <FeatherIcon
              icon="plus"
              fontSize="12px"
              className="cursor-pointer"
              onClick={() => {
                resetQC();
                setAddEditCert("Add");
                onOpenCert();
              }}
            />
          </h2>

          <Divider />
          <div
            className="grid lg:grid-cols-2 md:grid-cols-1
                       grid-cols-1 gap-4 p-2 mt-4"
          >
            {trainerData &&
            trainerData.certification &&
            trainerData.certification.length > 0
              ? trainerData.certification.map((certification) => (
                  <div
                    className="flex w-full items-start gap-2"
                    key={certification._id}
                  >
                    <div className="w-full">
                      <h2 className="font-bold text-black uppercase">
                        <a
                          target="_blank"
                          href={
                            certification.media
                              ? getImage(certification.media)
                              : ""
                          }
                          rel="noreferrer"
                          className="fsize14 flex items-center gap-2"
                        >
                          {certification.courseName}
                          <ExternalLinkIcon w={4} h={4} color="black" />
                        </a>
                      </h2>
                      <p className="fsize12 font-semibold mt-2 text-gray">
                        {certification.orgName}
                      </p>
                      {/* <span className="fsize12 pr-2 text-gray">
                        {new Date(certification.startDate).toLocaleDateString(
                          "en-IN"
                        )}
                        -
                        {new Date(certification.endDate).toLocaleDateString(
                          "en-IN"
                        )}
                      </span> */}
                    </div>

                    <Menu width="50">
                      <MenuButton
                        px={0}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="0px"
                      >
                        <FeatherIcon icon="more-vertical" />
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            resetQC();
                            handleCertPopulate(certification);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleDeleteCert(certification._id);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                ))
              : "No Certification Found"}
          </div>
        </div>
      </div>

      {/* social Details */}
      {/* <div className="mt-6 border rounded-lg cust-shadow py-4 px-6">
        <form>
          <h2 className="fsize18 font-semibold mb-4 clr-606060">
            Social Details
          </h2>
          <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
            <div className="w-full">
              <FormControl
                isInvalid={!!formik.errors.facebook && formik.touched.facebook}
                className="mb-4"
              >
                <label className="clr-7b7b7b fsize14">Facebook</label>
                <Input
                  name="facebook"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                  placeholder="https://"
                />
                {formik.touched.facebook && formik.errors.facebook && (
                  <FormErrorMessage>{formik.errors.facebook}</FormErrorMessage>
                )}
              </FormControl>
            </div>
            <div className="w-full">
              <FormControl
                isInvalid={
                  !!formik.errors.instagram && formik.touched.instagram
                }
                className="mb-4"
              >
                <label className="clr-7b7b7b fsize14">Instagram</label>
                <Input
                  name="instagram"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                  placeholder="https://"
                />
                {formik.touched.instagram && formik.errors.instagram && (
                  <FormErrorMessage>{formik.errors.instagram}</FormErrorMessage>
                )}
              </FormControl>
            </div>
          </div>
          <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
            <div className="w-full">
              <FormControl
                isInvalid={!!formik.errors.youtube && formik.touched.youtube}
                className="mb-4"
              >
                <label className="clr-7b7b7b fsize14">Youtube</label>
                <Input
                  name="youtube"
                  value={formik.values.youtube}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                  placeholder="https://"
                />
                {formik.touched.youtube && formik.errors.youtube && (
                  <FormErrorMessage>{formik.errors.youtube}</FormErrorMessage>
                )}
              </FormControl>
            </div>
            <div className="w-full">
              <FormControl
                isInvalid={!!formik.errors.twitter && formik.touched.twitter}
                className="mb-4"
              >
                <label className="clr-7b7b7b fsize14">Twitter</label>
                <Input
                  name="twitter"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                  placeholder="https://"
                />
                {formik.touched.twitter && formik.errors.twitter && (
                  <FormErrorMessage>{formik.errors.twitter}</FormErrorMessage>
                )}
              </FormControl>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default EditProfile;
