import React from 'react';

const HeroSection9 = () => {
  return (
    <section className="bg-ddd9d8 lg:px-12 md:px-12">
      <div className="lg:flex md:flex gap-4 block container mx-auto lg:pt-32 lg:pb-16">
        <div className="lg:w-1/3 md:w-1/3 w-full  lg:flex flex-col justify-center lg:px-4">
          <h1 className=" hero-header mb-4">Lorem ipsum generated</h1>
          <p className="fsize20">Lorem ipsum generated</p>
        </div>
        <div className="lg:w-1/3 md:w-1/3 w-full h-680px">
          <img
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="hero"
            className="br-40px w-full h-full object-cover bg-ddd9d8 border-6px"
          />
        </div>
        <div className="lg:w-1/3 md:w-1/3 w-full  lg:flex flex-col justify-center lg:px-4">
          <p className="fsize20">Lorem ipsum generated</p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection9;
