import apis from "../../../apis/client";

export const createTestimonial = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.testimonial.createTestimonial(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const updateTestimonial = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.testimonial.updateTestimonial(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getTestimonial = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.testimonial.getTestimonial(payload);

      if (data) {
        dispatch(setTestimonial(data.data.data));
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const deleteTestimonial = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.testimonial.deleteTestimonial(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

export const setTestimonial = (data) => {
  return {
    type: "SET_TESTIMONIAL",
    payload: data,
  };
};
