import API from "../../repositoryFactory";

const createReply = "create";
const getReply = "get";
const updateReply = "update";
const deleteReply = "delete";

const replyRoute = (route) => {
  return `fit2go/social/reply/${route}`;
};

export default {
  createReply(payload) {
    return API.post(replyRoute(createReply), payload);
  },
  getReply(payload) {
    return API.post(replyRoute(getReply), payload);
  },
  updateReply(payload) {
    return API.post(replyRoute(updateReply), payload);
  },
  deleteReply(payload) {
    return API.post(replyRoute(deleteReply), payload);
  },
};
