import { combineReducers } from "redux";

//CUSTOM
import CustomRootReducer from "./custom/customRootReducer";

import Cms_reducer from "./cms/cms-reducer";
import BD_reducer from "./brandDetails/bd-reducer";
import Menu_reducer from "./menu/menu-reducer";
import Marcom_reducer from "./marcom/marcom-reducer";
import AuthDetails from "./auth/auth-reducer";
import BlogDetails from "./blogs/blogs-reducer";
import userDetails from "./userDetails/user-reducer";
import newsletter from "./newsletter/newsletter-reducer";
import gateways from "./payment/gateway-reducer";
import stripe from "./payment/stripe-reducer";
import chat from "./chat/chat-reducer";

const rootReducer = combineReducers({
  //CUSTOM
  ...CustomRootReducer,

  cms: Cms_reducer,
  brandDetails: BD_reducer,
  menuState: Menu_reducer,
  contact: Marcom_reducer,
  AuthDetails: AuthDetails,
  BlogDetails: BlogDetails,
  userDetails: userDetails,
  newsletter: newsletter,
  gateways: gateways,
  stripe: stripe,
  chat: chat,
});

export default rootReducer;
