// import axios from "axios";
import { setWS } from "redux/chat/chat-actions";
import { ws, connectToWS, disconnectToWS } from "redux/chat/chat-websocket";
import apis from "../../apis/client/auth";
import Helper from "../../helper";

export const signupWithPassword = (payload) => {
  return async (dispatch) => {
    try {
      let register = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.signupWithPassword(register);

      console.log(data, "registerData");
      if (data) {
        dispatch(setDataLogin(data.data));
        await connectToWS();
        dispatch(setWS(ws));
      }
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};

export const CreateNewPassword = (payload) => {
  return async (dispatch) => {
    try {
      let NewpassWordPayload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.createPassword(NewpassWordPayload);

      console.log(data, "registerData");
      // if (data) dispatch(setDataSignup(data.data));
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};

export const ConfirmPassword = (payload) => {
  return async (dispatch) => {
    try {
      let NewpassWordPayload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.CheckPassword(NewpassWordPayload);

      console.log(data, "registerData");
      // if (data) dispatch(setDataSignup(data.data));
      return data;
    } catch (error) {}
  };
};

//ToggleAccount
export const ToggleAccount = (payload) => {
  return async (dispatch) => {
    try {
      let NewpassWordPayload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.ToggleAccount(NewpassWordPayload);

      if (data && data.code === 200) {
        await dispatch(logout({ email: payload.email }));
      }
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};

export const loginwithPassword = (payload) => {
  return async (dispatch) => {
    try {
      let login = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      // console.log(payload, login);
      let { data } = await apis.loginwithPassword(login);

      console.log(data, "project");

      if (data.data && Helper.Object.isEmpty(data.data.errmsg)) {
        console.log(ws, "ws");
        dispatch(setDataLogin(data.data.userAuth));
        connectToWS();
        dispatch(setWS(ws));
      }

      return data;
    } catch (error) {
      // console.log();
      return error.response.data;
      // console.log(error);
    }
  };
};

export const generateOTP = (payload) => {
  return async (dispatch) => {
    try {
      let generateotp = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };

      let { data } = await apis.generateOTP(generateotp);

      console.log(data, "OTP");
      if (data) dispatch(setGenerateOTP(data.data));
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};

export const verifyOTP = (payload) => {
  return async (dispatch) => {
    try {
      let verifyotp = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      console.log(payload, verifyotp);
      let { data } = await apis.verifyOTP(verifyotp);

      console.log(data.data.auth, "project");
      if (data) dispatch(setVerifyOTP(data.data.auth));
    } catch (error) {
      return error.response.data;
    }
  };
};

export const logout = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      console.log(payload, "payload logout");

      let { data } = await apis.logout(payload);

      // console.log(data.data, "logout");
      // window.location.href = "/";
      if (data) {
        dispatch(userLogout());
        disconnectToWS();
        record = data;
      }
    } catch (error) {
      return error.response;
    }
    return record;
  };
};

export const forgetPassword = (payload) => {
  return async (dispatch) => {
    try {
      let forgetpass = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
        ...payload,
      };
      console.log(payload, forgetpass);
      let { data } = await apis.forgetPassword(forgetpass);

      console.log(data.data, "forgetp");
      if (data) dispatch(userForgetPassword(data.data));
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};
export const SaveBasicProfileDetails = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      // let basicProfilePayload = {
      //   projectName: process.env.REACT_APP_COMMUNITY_NAME,
      //   ...payload,
      // };

      let { data } = await apis.SaveBasicProfile(payload);
      // if (data && data.code == 200) {
      //   dispatch(setProfileUserDetails(data.data));
      // }
      console.log(data, "OTP");
      // if (data) dispatch(setGenerateOTP(data.data));
      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};
export const UpdateEmailMobile = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.UpdateEmailMobile(payload);
      record = data;
    } catch (error) {
      return error.response.data;
      console.log(error);
    }
    return record;
  };
};

export const GetUsersProfileInfo = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.GetUserInfo();

      // console.log(data, "OTP");
      if (data && data.code === 200) {
        dispatch(setDataLogin(data.data));
      }
      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const getUserByToken = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.getUserByToken(payload);

      if (data) {
        dispatch(setDataLogin(data.data));
      }

      record = data;
    } catch (error) {
      console.log(error);
    }
    return record;
  };
};

export const setDataSignup = (data) => {
  console.log(data, "hello");
  return {
    type: "SET_BASIC_PROFILE",
    payload: data,
  };
};

export const setProfileUserDetails = (data) => {
  return {
    type: "SET_DATA_SIGNUP",
    payload: data,
  };
};

export const setGenerateOTP = (data) => {
  console.log(data, "generateotp");
  return {
    type: "SET_GENERATE_OTP",
    payload: data,
  };
};

export const setVerifyOTP = (data) => {
  console.log(data, "verifyotp");
  return {
    type: "SET_VERIFY_OTP",
    payload: data,
  };
};

export const setDataLogin = (data) => {
  console.log(data, "login");
  return {
    type: "SetLogin",
    payload: data,
  };
};
export const userLogout = (data) => {
  console.log(data, "logout");
  return {
    type: "LOGOUT",
    // payload: data,
  };
};

export const userForgetPassword = (data) => {
  console.log(data, "forgetpassword");
  return {
    type: "UserForgetPassword",
    payload: data,
  };
};

export const otherLogin = (data) => {
  return {
    type: "SET_OTHER_LOGIN",
    payload: data,
  };
};
export const setIsEditUserProfile = (data) => {
  return {
    type: "SET_ISEDITUSERPROFILE",
    payload: data,
  };
};
