import React from "react";

import HomeSection from "./components/HeroSection1";
import LeftImgRightText from "./components/LeftImgRightText";
import LeftTextRightImg from "./components/LeftTextRightImg";
import CardImage from "./components/CardImage";

import FeatureCard from "./components/FeatureCard";

import Header3 from "../header/Header3";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux/es/exports";
const Home = () => {
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);

  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }

  return (
    <div>
      <Helmet>
        <title>Sections</title>
        <meta name="description" content="sections" />
        <meta name="keyword" content="sections" />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Header3 />
      <HomeSection />
      <LeftImgRightText />
      <FeatureCard />
      <LeftTextRightImg />
      {/* <Testimonial /> */}
      <CardImage />
      {/* <Footer6 /> */}
    </div>
  );
};

export default Home;
