import React from 'react';

const HeroSection11 = () => {
  return (
    <section className="bg-ddd9d8 ">
      <div className=" container mx-auto  h-450px">
        <div className="h-450px w-60 mx-auto flex flex-col justify-center items-center">
          <p className="fsize18 letter-spacing4px font-semibold clr-fff mb-2">
            About Us
          </p>
          <h1 className="hero-header mb-4 clr-fff">Lorem ipsum</h1>

          <p className="fsize18 clr-fff text-center ">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection11;
