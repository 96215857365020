const initialState = {
  Post: {},
};

const Post = (state = initialState, action) => {
  switch (action.type) {
    case "SET_POST":
      return { ...state, Post: action.payload };
    case "SET_SINGLE_POST":
      if (action.payload.result && action.payload.result.length > 0) {
        let post = action.payload.result[0];
        let index = state.Post.result.findIndex((po) => po._id === post._id);
        if (index !== -1) {
          const newPost = [...state.Post.result];
          newPost[index] = post;
          return { ...state, Post: { ...state.Post, result: newPost } };
        } else {
          return state;
        }
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default Post;
