import React from "react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import Fade from "react-reveal/Fade";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
function TermsFormat({ pageName }) {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  return (
    <section>
      <div className="bggradient-mid minmaxh280px">
        <div className="  h-full container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-5 ">
          <Fade bottom>
            <h2
              id="6698440258"
              className="dynamicStyle lg:text-3xl md:text-2xl text-xl text-white h-full font-semibold justify-center flex items-center"
            >
              {data ? data["6698440258"] : "User Agreement"}
            </h2>
          </Fade>
        </div>
      </div>

      <div className="container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-5 ">
        <ReactQuill
          id="2694505665"
          theme="bubble"
          readOnly
          className="dynamicStyle"
          value={data ? data["2694505665"] : "User Agreement"}
        />

        {/* <p
          dangerouslySetInnerHTML={{
            __html: data ? data["2694505665"] : "User Agreement",
          }}
          id="2694505665"
          className="dynamicStyle"
        ></p> */}
      </div>
    </section>
  );
}

export default TermsFormat;
