import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiChevronDown } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const Header3 = () => {
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let menuData = useSelector((state) => state.menuState.menu);
  // console.log(menuData, "checkm");

  //primarylogo
  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  const [navbar, setnavbar] = useState(0);
  const setclick = () => setnavbar(false);
  return (
    <section>
      <div className="lg:py-6 md:py-6 py-4 lg:px-12 md:px-8 px-5">
        <div className="bg-white flex justify-between">
          <img
            src={getPrimaryImage(brandProfile)}
            alt="logo"
            className="w-24  h-8 object-contain"
          />

          <div className="flex items-center justify-center gap-8">
            {menuData
              ? menuData.map((e, i) => {
                  return (
                    <div key={i}>
                      {e.menutype === "page" && e.subMenu.length < 1 ? (
                        <NavLink
                          to={`/${e.pageData[0].page}`}
                          onClick={() => setclick(e)}
                        >
                          <p
                            className={
                              navbar === e
                                ? "menuHover font-semibold cursor-pointer"
                                : " font-medium cursor-pointer"
                            }
                          >
                            {e.menuName}
                          </p>
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {e.menutype === "page" && e.subMenu.length > 1 ? (
                        <Menu>
                          <MenuButton
                            px={0}
                            py={2}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="0px"
                            // _hover={{ bg: 'gray.400' }}
                            // _expanded={{ bg: 'blue.400' }}
                            // _focus={{ boxShadow: 'outline' }}
                          >
                            File <ChevronDownIcon />
                          </MenuButton>
                          <MenuList>
                            {e.subMenu.map((sub) => {
                              return <MenuItem>{sub.menuName}</MenuItem>;
                            })}
                          </MenuList>
                        </Menu>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </div>

          <Menu>
            <MenuButton>
              <span className="flex items-center">
                EN <FiChevronDown />
              </span>
            </MenuButton>
            <MenuList>
              <MenuItem>EN</MenuItem>
              <MenuItem>EN1</MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default Header3;
