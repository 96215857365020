import React from 'react';

const HeroSection6 = () => {
  return (
    <section className="bg-ddd9d8 lg:px-12">
      <div className="lg:flex md:flex  block container mx-auto lg:py-16">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-20">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            alt="hero"
            className="br-40px w-full h-450px object-cover bg-ddd9d8 border-6px"
          />
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full  lg:flex flex-col justify-center">
          <h1 className="lg:w-3/5 hero-header clr-fff mb-4">
            Lorem ipsum generated
          </h1>
          <p className="fsize20 clr-fff">Lorem ipsum generated</p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection6;
