import React from 'react';

import 'swiper/css';

const FeatureCard2 = () => {
  return (
    <section>
      <div className="text-center w-1/2 mx-auto lg:mb-16">
        <h3 className="letter-spacing4px fsize24 font-semibold clr-ddd9d8 lg:mb-2">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold lg:mb-8">Lorem Ipsum</h1>
        <p className="fsize18">
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful content.
        </p>
      </div>
      <div className="lg:flex lg:flex-col container mx-auto">
        <div className="lg:flex items-center lg:gap-6 hover-6f6f6f w-full lg:mb-6 lg:px-6 lg:py-6 h-120px rounded-lg box-shadow-inset bg-d2d2d2 br-ddd9d8">
          <div className="lg:flex items-center gap-2 w-2/5">
            <img
              src=""
              alt="arrow"
              className="object-cover lg:w-16 lg:h-16 rounded-full bg-EDF2F6"
            />
            <p className="fsize24 font-semibold ">Lorem Ipsum</p>
          </div>
          <div className="w-3/5">
            <p className="fsize18">
              Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem lorem ipsum
              ipsum Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem Ipsum
              lorem ipsum
            </p>
          </div>
        </div>
        <div className="lg:flex items-center lg:gap-6 hover-ddd9d8 w-full lg:mb-6 lg:px-6 lg:py-6 h-120px rounded-lg box-shadow-outer  br-ddd9d8">
          <div className="lg:flex items-center gap-2 w-2/5">
            <img
              src=""
              alt="mouse"
              className="object-cover lg:w-16 lg:h-16 rounded-full bg-EDF2F6"
            />
            <p className="fsize24 font-semibold ">Lorem Ipsum</p>
          </div>
          <div className="w-3/5">
            <p className="fsize18">
              Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem lorem ipsum
              ipsum Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem Ipsum
              lorem ipsum
            </p>
          </div>
        </div>
        <div className="lg:flex items-center lg:gap-6 hover-6f6f6f w-full lg:mb-6 lg:px-6 lg:py-6 h-120px rounded-lg box-shadow-inset bg-d2d2d2 br-ddd9d8">
          <div className="lg:flex items-center gap-2 w-2/5">
            <img
              src=""
              alt="arrow"
              className="object-cover lg:w-16 lg:h-16 rounded-full bg-EDF2F6"
            />
            <p className="fsize24 font-semibold ">Lorem Ipsum</p>
          </div>
          <div className="w-3/5">
            <p className="fsize18">
              Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem lorem ipsum
              ipsum Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem Ipsum
              lorem ipsum
            </p>
          </div>
        </div>
        <div className="lg:flex items-center lg:gap-6 hover-ddd9d8 w-full lg:mb-6 lg:px-6 lg:py-6 h-120px rounded-lg box-shadow-outer  br-ddd9d8">
          <div className="lg:flex items-center gap-2 w-2/5">
            <img
              src=""
              alt="mouse"
              className="object-cover lg:w-16 lg:h-16 rounded-full bg-EDF2F6"
            />
            <p className="fsize24 font-semibold ">Lorem Ipsum</p>
          </div>
          <div className="w-3/5">
            <p className="fsize18">
              Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem lorem ipsum
              ipsum Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem Ipsum
              lorem ipsum
            </p>
          </div>
        </div>
        <div className="lg:flex items-center lg:gap-6 hover-6f6f6f w-full lg:mb-6 lg:px-6 lg:py-6 h-120px rounded-lg box-shadow-inset bg-d2d2d2 br-ddd9d8">
          <div className="lg:flex items-center gap-2 w-2/5">
            <img
              src=""
              alt="arrow"
              className="object-cover lg:w-16 lg:h-16 rounded-full bg-EDF2F6"
            />
            <p className="fsize24 font-semibold ">Lorem Ipsum</p>
          </div>
          <div className="w-3/5">
            <p className="fsize18">
              Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem lorem ipsum
              ipsum Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem Ipsum
              lorem ipsum
            </p>
          </div>
        </div>
        <div className="lg:flex items-center lg:gap-6 hover-ddd9d8 w-full lg:mb-6 lg:px-6 lg:py-6 h-120px rounded-lg box-shadow-outer  br-ddd9d8">
          <div className="lg:flex items-center gap-2 w-2/5">
            <img
              src=""
              alt="mouse"
              className="object-cover lg:w-16 lg:h-16 rounded-full bg-EDF2F6"
            />
            <p className="fsize24 font-semibold ">Lorem Ipsum</p>
          </div>
          <div className="w-3/5">
            <p className="fsize18">
              Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem lorem ipsum
              ipsum Lorem Ipsum lorem ipsum Lorem Ipsum lorem ipsum Lorem Ipsum
              lorem ipsum
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureCard2;
