import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

const ShareProfileSection = ({ selectedTrainer }) => {
  const {
    isOpen: isOpenScan,
    onOpen: onOpenScan,
    onClose: onCloseScan,
  } = useDisclosure();

  const qrCodeRef = useRef(null);
  const [size, setSize] = useState(24);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [copied, setCopied] = useState(false);
  const [trainerLink, setTrainerLink] = useState(
    `${process.env.REACT_APP_FRONTEND_URL}/trainer-profile?tn=${firstName}${lastName}&ti=${selectedTrainer._id}`
  );

  const downloadQRCode = () => {
    const node = document.getElementById("trainerQRData");
    console.log(node);

    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = `${firstName}${lastName}QRCode.png`;
        link.href = dataUrl;
        link.click();
      })

      .catch(function (error) {
        console.error("Error converting HTML to image:", error);
      });
  };

  useEffect(() => {
    setFirstName(
      selectedTrainer.userId &&
        selectedTrainer.userId.length &&
        selectedTrainer.userId[0] &&
        selectedTrainer.userId[0].firstName
        ? selectedTrainer.userId[0].firstName
        : ""
    );
    setLastName(
      selectedTrainer.userId &&
        selectedTrainer.userId.length &&
        selectedTrainer.userId[0] &&
        selectedTrainer.userId[0].lastName
        ? selectedTrainer.userId[0].lastName
        : ""
    );
    setTrainerLink(
      `${process.env.REACT_APP_FRONTEND_URL}/trainer-profile?tn=${firstName}${lastName}&ti=${selectedTrainer._id}`
    );
  }, [selectedTrainer, firstName, lastName]);

  return (
    <>
      <div className="py-2 px-2">
        <div className="flex  gap-2">
          <div className="cursor-pointer" onClick={onOpenScan}>
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <QRCode
              size={size}
              // style={{ height: "auto", maxWidth: "30", width: "30" }}
              value={trainerLink}
            />
          </div>
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <FacebookShareButton url={trainerLink}>
              <FacebookIcon size={size} round />
            </FacebookShareButton>
          </div>
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <TwitterShareButton
              url={trainerLink}
              title={`Trainer ${firstName} ${lastName} Profile`}
            >
              <XIcon size={size} round />
            </TwitterShareButton>
          </div>
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <TelegramShareButton
              url={trainerLink}
              title={`Trainer ${firstName} ${lastName} Profile`}
            >
              <TelegramIcon size={size} round />
            </TelegramShareButton>
          </div>
          <div className="">
            {/* <img
              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
              alt="share-img"
              className="w-8 h-8"
            /> */}
            <WhatsappShareButton
              url={trainerLink}
              title={`Trainer ${firstName} ${lastName} Profile`}
              separator=":: "
            >
              <WhatsappIcon size={size} round />
            </WhatsappShareButton>
          </div>
          {/* <div className="">
            <LinkedinShareButton
              url={`Trainer ${firstName} ${lastName} Profile`}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={size} round />
            </LinkedinShareButton>
          </div>
          <div className="">
            <EmailShareButton
              url={trainerLink}
              subject={`Trainer ${firstName} ${lastName} Profile`}
              body="body"
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={size} round />
            </EmailShareButton>
          </div> */}
        </div>
      </div>
      <Modal isOpen={isOpenScan} onClose={onCloseScan} size="xs">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <ModalCloseButton color="black" />
            <div ref={qrCodeRef}>
              <div id="trainerQRData">
                <QRCode
                  className="mt-8"
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={trainerLink}
                  viewBox={`0 0 256 256`}
                />
                {/* <img
                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="share-img"
                className="w-36 h-36 mx-auto mt-2"
              /> */}
                <h2 className="font-semibold text-center fsize18 uppercase py-4">
                  @{`${firstName} ${lastName}`}
                </h2>
              </div>
              <div className="flex justify-center gap-4 w-full mb-2">
                <Button
                  onClick={() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 500);

                    navigator.clipboard.writeText(trainerLink);
                  }}
                  colorScheme="primary"
                  variant="outline"
                >
                  {copied ? "Copied!" : "Copy Link"}
                </Button>
                <Button onClick={downloadQRCode} colorScheme="primary">
                  Download
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareProfileSection;
