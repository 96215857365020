import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FaRegThumbsUp } from "react-icons/fa";
import FeatherIcon from "feather-icons-react";

const UserFeeds = () => {
  const buttonLabels = [
    "Gym Trainer",
    "2 years experience",
    "Cardiovascular",
    "Functional",
    "Yoga",
    "Gym Trainer",
    "Functional",
    "Yoga",
    "2 years experience",
    "Cardiovascular",
    "Gym Trainer",
    "2 years experience",
    "Yoga",
    "Cardiovascular",
    "Functional",
  ];

  const timeSlots = [
    "10.00am-12.30pm",
    "3.00am-5.30pm",
    "3.00am-5.30pm",
    "11.00am-12.30pm",
    "3.00am-5.30pm",
    // Add more time slots as needed
  ];

  const langaugeSlots = [
    "Hindi",
    "English",
    "Spanish",
    "Singaporean",
    // Add more time slots as needed
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [displayedComments, setDisplayedComments] = useState(3);

  const loadMoreContents = () => {
    setDisplayedComments(displayedComments + 3);
  };

  const totalCommentsData = [
    {
      name: "John Tender",
      rating: "*****",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "John Tender 2",
      rating: "**",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "John Tender 3",
      rating: "***",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "John Tender 3",
      rating: "***",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "John Tender 3",
      rating: "***",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "John Tender 3",
      rating: "***",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "John Tender 3",
      rating: "***",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    {
      name: "John Tender 3",
      rating: "***",
      content:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum sequi libero consectetur adipisicing elit. Rerum sequi libero...",
      helpfulCount: 0,
      replyCount: 0,
      imageUrl:
        "https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg",
    },
    // Add more comment objects as needed
  ];

  return (
    <section>
      <div className="w-full lg:flex md:flex ">
        <div className="w-65 sm-w-full lg:pr-8 md:pr-8">
          <div className="lg:hidden md:hidden block text-right mb-2">
            <p
              className="text-gray flex justify-end items-center fsize18 gap-1"
              ref={btnRef}
              colorScheme="teal"
              onClick={onOpen}
            >
              Filter <FeatherIcon icon="filter" />
            </p>
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Categories</DrawerHeader>
                <DrawerBody className="sm-drawer-padding">
                  <div className="w-full ">
                    <div className="flex flex-col gap-4">
                      <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                        <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                          specialisation
                        </h2>
                        <div className="py-2 px-2">
                          <div className="flex flex-wrap gap-4">
                            {buttonLabels.map((label, index) => (
                              <Button key={index} className="fsize14 bg-fff">
                                {label}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                        <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                          prefferred time
                        </h2>
                        <div className="py-2 px-2">
                          <div className="flex flex-wrap gap-4">
                            {timeSlots.map((timeSlot, index) => (
                              <Button
                                key={index}
                                variant="outline"
                                colorScheme="primary"
                                className="fsize12px bg-fff"
                              >
                                {timeSlot}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                        <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                          language
                        </h2>
                        <div className="py-2 px-2">
                          <div className="flex flex-wrap gap-4">
                            {langaugeSlots.map((langaugeSlots, index) => (
                              <Button
                                key={index}
                                variant="outline"
                                colorScheme="primary"
                                className="fsize12px bg-fff"
                              >
                                {langaugeSlots}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </div>
          <Tabs variant="soft-rounded" colorScheme="green">
            <div className="flex justify-between bottom-border-ebebeb">
              <TabList className=" pb-2">
                <Tab className="lg:px-4  rounded-sm">About me</Tab>
                <Tab className="lg:px-4   rounded-sm">Activity</Tab>
                <Tab className="lg:px-4   rounded-sm">Reviews</Tab>
              </TabList>
            </div>

            <TabPanels>
              <TabPanel className="px-0px">
                <div className="flex flex-col gap-4">
                  <div className="rounded-lg bg-f4f4f4 p-1">
                    <p className="text-black px-2 py-1.5 uppercase font-semibold">
                      About me
                    </p>
                    <div className="bg-white  rounded-lg">
                      <p className="clr-515151 px-2 py-1 fsize14 lh-26px sm-lh-22px">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Eos soluta iure at non. A explicabo, in velit id
                        enim eos maiores voluptatibus debitis numquam, nesciunt
                        cum consequuntur aut deleniti sapiente labore vel earum
                        repellat autem doloremque quaerat officiis voluptas
                        sequi nulla consequatur? Quisquam nobis numquam rerum
                        necessitatibus, veniam, ullam vitae facere fugiat omnis
                        ex enim perferendis repudiandae voluptas nam ducimus
                        vero neque impedit. Dolore, cupiditate facilis. Tempora
                        eos recusandae, voluptatibus ex repellat blanditiis
                        pariatur commodi neque dolor beatae inventore totam,
                        obcaecati voluptatem quisquam vero dicta autem similique
                        ducimus laborum eveniet? Sunt facere ipsa eum tempora,
                        doloribus quis reiciendis voluptates atque!
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg bg-f4f4f4 p-1">
                    <p className="text-black px-2 py-1.5 uppercase font-semibold">
                      Qualification
                    </p>
                    <div className="rounded-lg bg-white">
                      <div
                        className="grid lg:grid-cols-2 md:grid-cols-1
                       grid-cols-1 gap-4 p-2"
                      >
                        <div className="flex gap-2">
                          <div>
                            <img
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt="logo"
                              className="w-16 h-16 object-cover rounded-sm"
                            />
                          </div>
                          <div>
                            <h2 className="font-bold text-black uppercase">
                              london university
                            </h2>
                            <p className="fsize12 font-semibold text-black">
                              Bachelor Degree - Fitness Trainer
                            </p>
                            <span className="fsize12 text-gray">
                              feb-2020 - Jan 2022
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <div>
                            <img
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt="logo"
                              className="w-16 h-16 object-cover rounded-sm"
                            />
                          </div>
                          <div>
                            <h2 className="font-bold text-black uppercase">
                              london university
                            </h2>
                            <p className="fsize12 font-semibold text-black">
                              Bachelor Degree - Fitness Trainer
                            </p>
                            <span className="fsize12 text-gray">
                              feb-2020 - Jan 2022
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <div>
                            <img
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt="logo"
                              className="w-16 h-16 object-cover rounded-sm"
                            />
                          </div>
                          <div>
                            <h2 className="font-bold text-black uppercase">
                              london university
                            </h2>
                            <p className="fsize12 font-semibold text-black">
                              Bachelor Degree - Fitness Trainer
                            </p>
                            <span className="fsize12 text-gray">
                              feb-2020 - Jan 2022
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <div>
                            <img
                              src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                              alt="logo"
                              className="w-16 h-16 object-cover rounded-sm"
                            />
                          </div>
                          <div>
                            <h2 className="font-bold text-black uppercase">
                              london university
                            </h2>
                            <p className="fsize12 font-semibold text-black">
                              Bachelor Degree - Fitness Trainer
                            </p>
                            <span className="fsize12 text-gray">
                              feb-2020 - Jan 2022
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-lg bg-f4f4f4 p-1">
                    <p className="text-black px-2 py-1.5 uppercase font-semibold">
                      certifcation
                    </p>
                    <div className="bg-white rounded-lg p-2">
                      <Swiper
                        spaceBetween={25}
                        slidesPerView={3.2}
                        observer={true}
                        observeParents={true}
                        modules={[Navigation]}
                        className="mySwiper"
                        autoplay={{
                          delay: "1000",
                        }}
                        loop
                        navigation={{
                          nextEl: ".image-swiper-button-next",
                          prevEl: ".image-swiper-button-prev",
                        }}
                        breakpoints={{
                          1536: {
                            slidesPerView: 3.3,
                            spaceBetween: 25,
                          },
                          1280: {
                            slidesPerView: 3.3,
                            spaceBetween: 25,
                          },
                          1024: {
                            slidesPerView: 3.3,
                            spaceBetween: 25,
                          },
                          991: {
                            slidesPerView: 2.8,
                            spaceBetween: 25,
                          },
                          767: {
                            slidesPerView: 2,
                            spaceBetween: 25,
                          },
                          640: {
                            slidesPerView: 1.2,
                            spaceBetween: 25,
                          },
                          425: {
                            slidesPerView: 1.2,
                            spaceBetween: 25,
                          },
                          320: {
                            slidesPerView: 1.2,
                            spaceBetween: 25,
                          },
                        }}
                      >
                        <SwiperSlide>
                          <div className="flex flex-col">
                            <p className="fsize14 uppercase font-semibold">
                              london university
                            </p>
                            <p className="flex gap-1 flex-end fsize12 clr-6f6f6f underline">
                              Bachelor Degree - Fitness Trainer{" "}
                              <ExternalLinkIcon w={4} h={4} color="black" />
                            </p>
                            <div className="w-full mt-2">
                              <img
                                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                alt="card"
                                className="w-full rounded-md h-24"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="flex flex-col">
                            <p className="fsize14 uppercase font-semibold">
                              london university
                            </p>
                            <p className="flex gap-1 flex-end fsize12 clr-6f6f6f underline">
                              Bachelor Degree - Fitness Trainer{" "}
                              <ExternalLinkIcon w={4} h={4} color="black" />
                            </p>
                            <div className="w-full mt-2">
                              <img
                                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                alt="card"
                                className="w-full rounded-md h-24"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="flex flex-col">
                            <p className="fsize14 uppercase font-semibold">
                              london university
                            </p>
                            <p className="flex gap-1 flex-end fsize12 clr-6f6f6f underline">
                              Bachelor Degree - Fitness Trainer{" "}
                              <ExternalLinkIcon w={4} h={4} color="black" />
                            </p>
                            <div className="w-full mt-2">
                              <img
                                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                alt="card"
                                className="w-full rounded-md h-24"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="flex flex-col">
                            <p className="fsize14 uppercase font-semibold">
                              london university
                            </p>
                            <p className="flex gap-1 flex-end fsize12 clr-6f6f6f underline">
                              Bachelor Degree - Fitness Trainer{" "}
                              <ExternalLinkIcon w={4} h={4} color="black" />
                            </p>
                            <div className="w-full mt-2">
                              <img
                                src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                alt="card"
                                className="w-full rounded-md h-24"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="px-0px w-full">
                <div className="flex flex-col gap-4">
                  <div className="rounded-lg bg-f4f4f4 p-1">
                    <div className="lg:flex md:flex items-center gap-2 ">
                      <div className="lg:pt-0 md:pt-4 pt-0">
                        <img
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="profile"
                          className="mx-auto object-cover relative z-10 rounded-full border3px-fff h-16 w-16"
                        />
                      </div>
                      <div className="lg:text-left md:text-left text-center flex flex-col gap-1 justify-between">
                        <h2 className="fsize18 font-semibold">MANISH SHARMA</h2>

                        <p className="clr-9c9c9c fsize14 md-fsize18px   font-medium">
                          WOODLANDS
                        </p>
                      </div>
                    </div>
                    <p className="fsize16 p-1 text-black">
                      No we are not another workout site! We are Health and
                      Fiteness Platform
                    </p>
                    <div className="bg-white  m-1">
                      <p className="px-2 py-1 fsize14 w-full maxheight350 overflow-auto">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Placeat quidem nostrum ducimus! Temporibus, voluptas?
                        Quia eveniet officiis nihil? Nemo similique nostrum
                        quia, aut voluptate accusantium itaque repellat! Neque
                        dignissimos nulla aut ab voluptatem odio recusandae
                        asperiores. Dignissimos repellendus veniam quos delectus
                        aut. Aliquid voluptatum iste error, iure repellendus
                        impedit assumenda reiciendis id maxime vel accusantium
                        quasi a ut explicabo at iusto nihil laborum sint? Earum
                        quae delectus veritatis repellat maxime consectetur,
                        cumque enim quibusdam dicta animi aliquam corrupti
                        excepturi fuga? Ad nostrum, dicta ipsam expedita
                        architecto autem aliquam qui, at facilis voluptates
                        eaque commodi suscipit dolorem quaerat natus dolorum
                        enim voluptatem quisquam? Consequatur consectetur natus
                        nihil, unde iure adipisci vitae fugit fugiat accusamus,
                        quidem ducimus est quam ipsa suscipit in. Qui quasi
                        officia, autem odio voluptatem, dolor ducimus ab, unde
                        laborum sapiente sunt adipisci quaerat possimus nemo
                        facere ex voluptas. Ad recusandae, quas magnam atque
                        quidem repellendus, iure nesciunt consequuntur eius
                        architecto, harum placeat molestias possimus reiciendis.
                        Hic qui quo deserunt, magni tempora corrupti eius
                        possimus odit numquam saepe praesentium inventore
                        asperiores ipsam mollitia maxime autem blanditiis
                        dolorum minima repudiandae distinctio eum explicabo
                        consequuntur? Porro eos repellendus quidem aspernatur
                        quisquam et aliquam vero expedita nostrum, similique
                        praesentium cumque commodi voluptatum ut deleniti
                        tenetur, quia corrupti modi natus, recusandae
                        dignissimos ducimus! Qui obcaecati ducimus saepe natus!
                        Consectetur maiores architecto cupiditate ipsa id maxime
                        ab officiis nostrum quos quaerat nihil veniam nobis
                        praesentium, ad, deleniti ipsam? Aliquam veritatis
                        minima repellendus minus adipisci explicabo nam, iure
                        expedita? Nulla, enim suscipit, animi corporis pariatur
                        facilis incidunt odit nostrum ab praesentium soluta
                        recusandae voluptates. Iure perferendis earum error
                        facere quos architecto blanditiis. Dolor, adipisci odit
                        tempora iusto atque fugiat minus, illum accusamus
                        aspernatur vero nisi laboriosam unde velit consectetur
                        nesciunt, maxime at modi quasi nam possimus eveniet
                        optio aliquam maiores rem? Iste fuga recusandae
                        accusantium porro nostrum quas amet magni veritatis
                        quidem distinctio perspiciatis saepe error voluptas
                        voluptatum, similique odio illo, fugiat cumque non,
                        maxime consequuntur enim doloribus! Accusamus, quos
                        dolorum nobis, aperiam maiores, eligendi animi
                        aspernatur id nesciunt sint tempora incidunt quo sed? In
                        non magni maxime reiciendis neque! Eaque debitis dolores
                        rem unde ex. Sequi, iure iusto excepturi temporibus
                        explicabo maxime unde odio quis repellendus ratione
                        accusantium. Officia, exercitationem distinctio cum
                        possimus veniam nesciunt reprehenderit eaque, molestiae
                        similique ab veritatis a repellendus quam suscipit
                        repudiandae quisquam vitae architecto assumenda
                        doloremque. Qui officia perferendis facere in eligendi!
                        Eligendi dolorem repudiandae rem odio exercitationem
                        consequuntur impedit suscipit, sed quidem dignissimos
                        iure, debitis non corporis animi? Necessitatibus non rem
                        aut ad doloribus. Minus expedita animi sequi sit
                        quisquam voluptates beatae perferendis eveniet accusamus
                        maxime dolorem optio pariatur, magni dolorum officia
                        illum, voluptate labore. Deserunt at error ullam
                        explicabo aut eum tempora repudiandae quasi doloremque
                        accusamus vitae quam aliquam qui iure adipisci eligendi
                        quia fuga maxime optio unde enim ducimus alias, amet
                        officiis. Aliquid a, ut officiis labore fugiat ipsum
                        tempora, vero illum illo id ullam. Unde, mollitia totam.
                        Magni aliquid quas itaque doloribus placeat tempore
                        vitae ea consequatur quam provident maxime corporis
                        voluptatum officia repudiandae adipisci rerum, maiores
                        earum doloremque, numquam incidunt, repellat sint ad
                        inventore. Sequi quibusdam quidem aut ut quis vel non!
                        Praesentium ut magnam aut delectus in harum temporibus
                        impedit dicta enim explicabo perferendis mollitia
                        quibusdam vitae adipisci minima dolores libero,
                        excepturi maxime est illo eligendi vel repellendus
                        possimus aperiam? At laudantium sapiente eum sit nam.
                        Explicabo placeat dignissimos sunt aut sint aliquam
                        fugiat, maxime quae beatae ipsam, id, aperiam nemo.
                        Deleniti autem quidem nisi possimus eveniet facilis
                        natus error, id neque dolorum quisquam minus excepturi
                        quas adipisci officiis omnis, eius iure sed tempore in.
                        Aspernatur nihil quibusdam esse beatae iste alias non
                        iusto doloribus minima, necessitatibus, asperiores
                        facilis? Mollitia laboriosam labore consectetur
                        exercitationem magnam ea, accusamus ipsum laborum,
                        itaque at sunt. Voluptatum vero non neque, cumque
                        ratione accusantium sint nulla odit, porro velit
                        molestiae consequuntur eligendi quis commodi expedita?
                        Officiis necessitatibus libero ratione veniam ex modi
                        placeat. Iure, minima neque unde non illum culpa dolores
                        accusamus deserunt omnis delectus eligendi, blanditiis
                        veritatis repellendus quaerat quo mollitia ratione
                        sapiente voluptate placeat dolorem excepturi rerum nulla
                        eveniet! Molestias, quis! Nostrum odit facilis
                        doloremque alias. Suscipit, atque aliquam molestias
                        cupiditate officia repudiandae consequuntur ex maiores
                        incidunt provident quisquam soluta. Reiciendis
                        architecto odit saepe ipsam, quibusdam, ut non sed sint
                        officiis pariatur numquam quasi obcaecati beatae? Iste
                        tenetur aliquam voluptatum itaque excepturi mollitia
                        dolore, deleniti beatae nulla provident adipisci
                        reprehenderit fuga. Amet laboriosam necessitatibus
                        facilis dolorum voluptatem aspernatur, iure eveniet
                        officia ipsa dignissimos deserunt porro, iusto impedit
                        minus! Molestiae sequi assumenda praesentium aspernatur
                        et corrupti eos dolorum rem maxime! Adipisci provident
                        odit nobis eveniet ipsam voluptatum eius commodi porro
                        illum dolorum repellat eum, quidem temporibus facilis
                        voluptatem suscipit veritatis autem recusandae minus
                        quas aliquam illo qui! Harum, quae possimus maiores
                        corrupti, adipisci quo nobis provident excepturi dolor
                        rerum architecto. Adipisci at excepturi aliquam
                        obcaecati reprehenderit unde, inventore quam. Expedita,
                        nulla ab excepturi fugit facilis ipsam voluptatibus
                        animi! Voluptate libero itaque, vitae quidem laudantium
                        corrupti, repellat ducimus harum facere error enim
                        maiores totam ratione fuga natus fugit quasi aspernatur
                        soluta eligendi sequi, officia inventore labore. Vel at
                        quos incidunt. Ut quos magni odio dolorem provident.
                        Ducimus, quaerat laboriosam libero possimus nesciunt
                        quod asperiores, atque unde repellat quis accusantium
                        ad, consectetur corporis nobis rerum delectus.
                        Cupiditate similique at in quam saepe ipsa quis animi
                        laudantium quos vitae numquam, aspernatur architecto
                        odit dolorem adipisci dolor ducimus eligendi doloremque
                        sed, commodi ipsam quae totam? Suscipit omnis minima
                        blanditiis commodi vitae, provident harum delectus
                        corrupti accusamus accusantium fugiat amet ab sed totam
                        beatae possimus cumque velit, illo ex esse maiores
                        impedit aperiam dolorem. Magni eveniet, facilis modi
                        eius illum accusantium pariatur quae distinctio ut,
                        sequi iste provident laboriosam ad consectetur aliquam
                        blanditiis quis molestiae. Suscipit esse impedit vel
                        numquam pariatur vitae ipsam eum aperiam, repellat
                        voluptatum delectus hic minima aspernatur. Assumenda
                        labore ex perspiciatis totam maiores, repudiandae, sit,
                        veniam nihil quae facilis quasi! Illum eos natus fugiat
                        facere adipisci dolorum corporis in, possimus, quo
                        repellat illo fuga blanditiis consectetur hic sint saepe
                        minima voluptas accusamus, repudiandae atque
                        consequuntur nemo libero unde!
                      </p>
                    </div>
                  </div>
                  <div className="rounded-lg bg-f4f4f4 p-1">
                    <div className="lg:flex md:flex items-center gap-2 ">
                      <div className="lg:pt-0 md:pt-4 pt-0">
                        <img
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="profile"
                          className="mx-auto object-cover relative z-10 rounded-full border3px-fff h-16 w-16"
                        />
                      </div>
                      <div className="lg:text-left md:text-left text-center flex flex-col gap-1 justify-between">
                        <h2 className="fsize18 font-semibold">MANISH SHARMA</h2>

                        <p className="clr-9c9c9c fsize14 md-fsize18px   font-medium">
                          WOODLANDS
                        </p>
                      </div>
                    </div>
                    <p className="fsize16 p-1 text-black">
                      No we are not another workout site! We are Health and
                      Fiteness Platform
                    </p>
                    <div className="bg-white  m-1">
                      <img
                        src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                        alt="card-images"
                        className="w-full maxheight350"
                      />
                    </div>
                  </div>
                  <div className="rounded-lg bg-f4f4f4 p-1">
                    <div className="lg:flex md:flex items-center gap-2 ">
                      <div className="lg:pt-0 md:pt-4 pt-0">
                        <img
                          src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                          alt="profile"
                          className="mx-auto object-cover relative z-10 rounded-full border3px-fff h-16 w-16"
                        />
                      </div>
                      <div className="lg:text-left md:text-left text-center flex flex-col gap-1 justify-between">
                        <h2 className="fsize18 font-semibold">MANISH SHARMA</h2>

                        <p className="clr-9c9c9c fsize14 md-fsize18px   font-medium">
                          WOODLANDS
                        </p>
                      </div>
                    </div>
                    <p className="fsize16 p-1 text-black">
                      No we are not another workout site! We are Health and
                      Fiteness Platform
                    </p>
                    <div className="bg-white  m-1 flex justify-center">
                      <video width="640" height="360" controls>
                        <source src="your-video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="px-0px w-full">
                <div className="pt-4">
                  <div className="flex justify-between items-center">
                    <h1 className="font-semibold uppercase fsize20 sm-fsize16">
                      [4,129 ratings] 5000 students
                    </h1>
                    <p className="font-semibold uppercase fsize20 sm-fsize16">
                      [4.5]*
                    </p>
                  </div>
                  <div className="pt-8">
                    {totalCommentsData
                      .slice(0, displayedComments)
                      .map((comment, index) => (
                        <div
                          key={index}
                          className="mb-6 lg:flex md:flex flex-start w-full gap-3"
                        >
                          <div>
                            <div className="bg-f4f4f4 p-2 rounded-lg">
                              <div className="flex justify-between">
                                <div className="flex items-center gap-2">
                                  <img
                                    src={comment.imageUrl}
                                    alt="logo"
                                    className="w-6 h-6 rounded-full "
                                  />
                                  <h2 className="font-semibold fsize16 uppercase">
                                    {comment.name}
                                  </h2>
                                </div>
                                <p>{comment.rating}</p>
                              </div>
                              <p className="fsize14 mt-1">{comment.content}</p>
                            </div>
                            <div className="flex items-center gap-8 mt-2">
                              <div className="flex gap-2">
                                <FaRegThumbsUp />
                                <p className="fsize12">
                                  Helpful ({comment.helpfulCount})
                                </p>
                              </div>
                              <p className="fsize12">
                                Reply ({comment.replyCount})
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {displayedComments < totalCommentsData.length && (
                    <h2
                      onClick={loadMoreContents}
                      className="cursor-pointer text-primary uppercase fsize16 md:fsize14 sm-fsize14 underline"
                    >
                      LOAD MORE COMMENTS
                    </h2>
                  )}
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
        <div className="w-35 lg:mt-0 md:mt-0 mt-6 lg:block md:block hidden">
          <div className="flex flex-col gap-4">
            <div className="rounded-lg bg-f4f4f4 p-1">
              <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                specialisation
              </h2>
              <div className="py-2 px-2">
                <div className="flex flex-wrap gap-4">
                  {buttonLabels.map((label, index) => (
                    <Button key={index} className="fsize14 bg-fff">
                      {label}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded-lg bg-f4f4f4 p-1">
              <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                prefferred time
              </h2>
              <div className="py-2 px-2">
                <div className="flex flex-wrap gap-4">
                  {timeSlots.map((timeSlot, index) => (
                    <Button
                      key={index}
                      variant="outline"
                      colorScheme="primary"
                      className="fsize12px bg-fff"
                    >
                      {timeSlot}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <div className="rounded-lg bg-f4f4f4 p-1">
              <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                language
              </h2>
              <div className="py-2 px-2">
                <div className="flex flex-wrap gap-4">
                  {langaugeSlots.map((langaugeSlots, index) => (
                    <Button
                      key={index}
                      variant="outline"
                      colorScheme="primary"
                      className="fsize12px bg-fff"
                    >
                      {langaugeSlots}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserFeeds;
