import React, { useState } from "react";
import { useSelector } from "react-redux/es/exports";
import FeatherIcon from "feather-icons-react";
import {
  Image,
  Button,
  Input,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import Fade from "react-reveal/Fade";
import { Link, NavLink, useNavigate } from "react-router-dom";
const HeroSection1 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);

  const [search, setSearch] = useState("");

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const redirectToCoach = () => {
    navigate(`/coach?s=${encodeURIComponent(search)}`);
  };

  const navigate = useNavigate();
  return (
    <section className="bg-secondary ">
      <div className="lg:flex md:flex sm-reverse items-center">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:pl-20 md:pl-10 pl-6 lg:pr-0 md:pr-0 pr-6 lg:py-0 md:py-0 py-10">
          <Fade left>
            <div className="flex items-center gap-4">
              <p
                className="lg:text-base md:text-sm text-sm text-white dynamicStyle"
                id="4532162874"
              >
                {data ? data["4532162874"] : "EDUCATE"}
              </p>
              <span className="block h-2 w-2 bg-primary rounded-full"> </span>
              <p
                className="lg:text-base md:text-sm text-sm text-white dynamicStyle"
                id="2494898441"
              >
                {data ? data["2494898441"] : "CONNECT"}
              </p>
              <span className="block h-2 w-2 bg-primary rounded-full"> </span>
              <p
                className="lg:text-base md:text-sm text-sm text-white dynamicStyle"
                id="8347387826"
              >
                {data ? data["8347387826"] : "MOTIVATE"}
              </p>
            </div>

            <div className=" mt-2">
              <h2
                className="lg:text-6xl text-end md:text-3xl text-xl text-white  font-semibold dynamicStyle"
                id="3287321607"
              >
                {data ? data["3287321607"] : `TRANSFORM < br /> YOUR`}
              </h2>

              {/* <h2
              className="lg:text-8xl md:text-4xl text-3xl lheight text-primary   font-semibold pl-2 dynamicStyle"
              id="2067155663"
            >
              {data ? data["2067155663"] : "BODY"}
            </h2> */}
            </div>

            <div className="text-end lg:mt-8 md:mt-4 md:pl-0">
              <p
                className="lg:text-base md:text-base text-sm text-white opacity-70 dynamicStyle"
                id="8645751504"
                dangerouslySetInnerHTML={{
                  __html: data ? data["8645751504"] : "no-data",
                }}
              ></p>
              <div className="lg:pr-8 md:pr-8 relative md:pl-0 lg:mt-6 md:mt-6 mt-6 lg:w-3/4">
                <InputGroup size="md">
                  {" "}
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        redirectToCoach();
                      }
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                    className=" text-white"
                    placeholder="Search for trainers....."
                  />
                  <InputRightElement width="3.5rem" className="cursor-pointer">
                    <div className=" top11">
                      <FeatherIcon
                        icon="search"
                        size={20}
                        onClick={() => redirectToCoach()}
                        className="text-white  cursor-pointer"
                      />
                    </div>
                  </InputRightElement>
                </InputGroup>
                {/* <Button
                onClick={() => navigate("/coach")}
                className="trans-btn w-full rounded-full  text-center dynamicStyle"
                id="4443636199"
                py="6"
              >
                {data ? data["4443636199"] : "FIND TRAINER"}
                <FeatherIcon
                  icon="search"
                  size={20}
                  className="text-white ml-2"
                />
              </Button> */}
              </div>
            </div>
          </Fade>
        </div>
        <div className="lg:w-1/2 md:w-1/2 w-full lg:flex  md:flex justify-end">
          <div id="8392435683" className="dynamicStyle2">
            {" "}
            <Image
              src={data ? getImage(data["8392435683"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className="w-full minh500px lg:object-contain md:object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection1;
