import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import TrainerEditProfile from "./components/TrainerEditProfile";
import TrainerProfilePage from "./components/TrainerProfilePage";
import Appointment from "./components/Appointment";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  setIsEditProfile,
  setSaveTrainerData,
} from "../../../redux/custom/trainer/trainer-actions";
import UserProfilePage from "./components/UserProfilePage";
import UserEditProfile from "./components/UserEditProfile";
import {
  setIsEditUserProfile,
  GetUsersProfileInfo,
} from "../../../redux/auth/auth-action";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { logout } from "../../../redux/auth/auth-action";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ManageAccount from "./components/ManageAccount";
import HelpSupport from "./components/HelpSupport";
import FindCoach from "./components/FindCoach";
import { toast } from "react-toastify";
import Transaction from "./components/Transaction";
import MainChat from "./chat/MainChat";

const Profile = () => {
  const dispatch = useDispatch();
  let userDetail = useSelector((state) => state.AuthDetails.user);
  console.log(userDetail, "checkpr");
  const navigate = useNavigate();
  let userData = useSelector((state) => state.AuthDetails.user);
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const btnRef = React.useRef();
  const menuItems = [
    {
      id: 1,
      name: "Profile",
      icon: "user",
    },
    // {
    //   id: 2,
    //   name: "My Appointment",
    //   icon: "calendar",
    // },
    // {
    //   id: 3,
    //   name: "Find Coach",
    //   icon: "file-text",
    // },
    {
      id: 4,
      name: "My Transaction",
      icon: "credit-card",
    },
    {
      id: 5,
      name: "Manage Accounts",
      icon: "settings",
    },
    // {
    //   id: 6,
    //   name: "Help & Support",
    //   icon: "help-circle",
    // },
    // {
    //   id: 7,
    //   name: "Chat",
    //   icon: "message-square",
    // },
  ];
  const [selectedItem, setSelectedItem] = useState(menuItems[0].name);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getQuery();
    fetchUser();
  }, []);

  const getQuery = () => {
    let getSelectedMenu = searchParams.get("sm");
    if (
      menuItems.some(
        (item) =>
          item.name?.toLowerCase() === getSelectedMenu?.toLocaleLowerCase()
      )
    ) {
      setSelectedItem(getSelectedMenu);
    }
  };

  const fetchUser = async () => {
    try {
      console.log("GetUsersProfileInfo");
      await dispatch(GetUsersProfileInfo());
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (item) => {
    if (item && userDetail.role === "Trainer" && item === "Profile") {
      dispatch(setIsEditProfile(false));
    } else if (item && userDetail.role === "User" && item === "Profile") {
      dispatch(setIsEditUserProfile(false));
    }
    setSelectedItem(item);
    console.log(item);
  };
  const handleLogout = async () => {
    if (userData && userData.email) {
      let payload = {
        email: userData.email,
      };
      Swal.fire({
        title: "warning",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2bc370",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const data = await dispatch(logout(payload));
            if (data && data.code == 200) {
              dispatch(setSaveTrainerData(null));
              toast.success(`Logged out successfully`);
              navigate("/");
            } else {
              if (data.message) {
                toast.error(data.message);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  };
  return (
    <div>
      {userData &&
        userData.role === "TrainerApplicant" &&
        userData.planId &&
        userData.subscriptionId &&
        userData.trainerId && (
          <div className="cust-marquee  py-3 lg:px-12 md:px-12 px-6  flex w-full justify-between">
            <p className="flex fsize14 font-medium text-primary items-center gap-3">
              <FeatherIcon
                icon="info"
                className="text-primary  w-5 h-5 flex relative"
              />
              <span className="cursor-pointer font-bold text-primary">
                Important Notice:
              </span>
              Your Trainer Application is under review! You will shortly receive
              an update on your registered email id.
            </p>
            {/* <div class="flex gap-4 justify-end items-center pr-6 cursor-pointer">
              <FeatherIcon
                icon="x"
                className="text-primary  w-5 h-5 flex relative"
              />
            </div> */}
          </div>
        )}

      {userData &&
        userData.role === "TrainerApplicant" &&
        // !userData.planId &&
        !userData.subscriptionId &&
        userData.trainerId && (
          <>
            <div className="cust-marquee  py-3 lg:px-12 md:px-12 px-6  flex w-full justify-between">
              <p className="flex fsize14 font-medium text-primary items-center gap-3">
                <FeatherIcon
                  icon="info"
                  className="text-primary  w-5 h-5 flex relative"
                />
                <span className="cursor-pointer font-bold text-primary">
                  Important Notice:
                </span>
                Your Payment was unsuccessful, please retry your payment and
                your application will be sent for review.
              </p>
              {/* <div class="flex gap-4 justify-end items-center pr-6 cursor-pointer">
                <FeatherIcon
                  icon="x"
                  className="text-primary  w-5 h-5 flex relative"
                />
              </div> */}
            </div>
          </>
        )}
      <section className="container mx-auto lg:px-12 md:px-4 lg:py-6  md:py-6 py-6 px-5">
        <div className="lg:hidden zindex_menu md:hidden block text-right mb-2">
          {/* <p
          className="text-gray flex justify-end items-center fsize18 gap-1"
          ref={btnRef}
          colorScheme="teal"
          onClick={onOpenDrawer}
        >
          <div className="flex gap-2">
            <FeatherIcon
              icon="align-justify"
              className="cursor-pointer text-303030"
              size={25}
            />
          </div>
        </p> */}

          <Menu>
            <MenuButton
              px={0}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="0px"
            >
              Profile <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              <MenuItem>
                <div className="lg:flex md:flex justify-between  w-full">
                  <div className=" w-full ">
                    <div className=" ">
                      <ul className="py-0">
                        {menuItems.map((item, index) => (
                          <li
                            key={index}
                            className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                              selectedItem === item.name
                                ? "selected text-primary font-semibold"
                                : ""
                            }`}
                            onClick={() => handleClick(item.name)}
                          >
                            <FeatherIcon icon={item.icon} className="w-4 h-4" />
                            {item.name}
                          </li>
                        ))}
                        <li
                          onClick={handleLogout}
                          className="py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 "
                        >
                          <FeatherIcon icon="log-out" className="w-4 h-4" />
                          Logout
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </MenuItem>
            </MenuList>
          </Menu>
          <Drawer
            isOpen={isOpenDrawer}
            placement="right"
            onClose={onCloseDrawer}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              {/* <DrawerHeader>Options</DrawerHeader> */}
              <DrawerBody className="sm-drawer-padding">
                <div className="lg:flex md:flex justify-between  w-full">
                  <div className=" w-full ">
                    <div className=" ">
                      <ul className="py-6">
                        {menuItems.map((item, index) => (
                          <li
                            key={index}
                            className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                              selectedItem === item.name
                                ? "selected text-primary font-semibold"
                                : ""
                            }`}
                            onClick={() => handleClick(item.name)}
                          >
                            <FeatherIcon icon={item.icon} className="w-4 h-4" />
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </div>

        <div className="lg:flex md:flex justify-between  w-full">
          <div className="lg:w-1/5 mob-hidden md:w-2/6 w-full pr-6">
            <div className="border  ">
              <ul className="py-3">
                {menuItems.map((item, index) => (
                  <li
                    key={index}
                    className={`py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 ${
                      selectedItem === item.name
                        ? "selected text-primary font-semibold"
                        : ""
                    }`}
                    onClick={() => handleClick(item.name)}
                  >
                    <FeatherIcon icon={item.icon} className="w-4 h-4" />
                    {item.name}
                  </li>
                ))}
                <li
                  onClick={handleLogout}
                  className="py-2 px-4 mb-2 cursor-pointer flex items-center fsize14 font-medium text-gray gap-3 "
                >
                  <FeatherIcon icon="log-out" className="w-4 h-4" />
                  Logout
                </li>
              </ul>
            </div>
          </div>

          <div className="lg:w-4/5 md:w-4/6 w-full ">
            {(userDetail.role === "User" ||
              userDetail.role === "TrainerApplicant" ||
              userDetail.role === "ExTrainer") &&
            selectedItem === "EditProfile" ? (
              <UserEditProfile />
            ) : userDetail.role === "Trainer" &&
              selectedItem === "EditProfile" ? (
              <TrainerEditProfile />
            ) : (userDetail.role === "User" ||
                userDetail.role === "TrainerApplicant" ||
                userDetail.role === "ExTrainer") &&
              selectedItem === "Profile" ? (
              <UserProfilePage />
            ) : userDetail.role === "Trainer" && selectedItem === "Profile" ? (
              <TrainerProfilePage />
            ) : (
              ""
            )}

            {selectedItem === "My Appointment" && <Appointment />}
            {selectedItem === "My Transaction" && <Transaction />}
            {selectedItem === "Manage Accounts" && <ManageAccount />}
            {selectedItem === "Help & Support" && <HelpSupport />}
            {selectedItem === "Find Coach" && <FindCoach />}
            {/* {selectedItem === "Chat" && <MainChat />} */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
