import React, { Fragment, useCallback, useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import Multiselect from "multiselect-react-dropdown";
import imageVector from "assets/images/custom/Vector5.png";
import imageVectorBottom from "assets/images/custom/Vector5.png";
import {
  Button,
  Image,
  Card,
  FormControl,
  FormLabel,
  Switch,
  Divider,
  ButtonGroup,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Checkbox,
  Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  getTrainer,
  setSelectedTrainer,
  getTrainerLocations,
  setTrainersLoadMore,
  getTrainersLoadMore,
} from "../../../redux/custom/trainer/trainer-actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { ThreeDots } from "react-loader-spinner";
import Fade from "react-reveal/Fade";
import { debounce } from "lodash";
import { FaAlignRight } from "react-icons/fa";
import { getFitnessCategory } from "../../../redux/custom/fitnessCategory/fitnessCategory-actions";
// import AddNewCoachPopUp from "../mainProfile/components/AddNewCoachPopUp";

const CONSTANT_PER_PAGE = 12;
const CONSTANT_PAGE = 0;

const UserCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(CONSTANT_PAGE);
  const [perPage, setPerPage] = useState(CONSTANT_PER_PAGE);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const handleShowFilter = () => {
    setShowFilter((state) => !state);
  };

  const [isOpenBooking, setIsOpenBooking] = useState(false);
  const onCloseBooking = () => setIsOpenBooking(false);
  const onOpenNewBooking = () => setIsOpenBooking(true);

  let Trainers = useSelector((state) => state.trainer.Trainers);
  let TrainersLoadMore = useSelector((state) => state.trainer.TrainersLoadMore);

  const [gender, setGender] = useState(["Male", "Female", "Other"]);
  const [genderData, setGenderData] = useState([]);

  const [location, setLocation] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const [language, setLanguage] = useState([
    "English",
    "Malay",
    "Tamil",
    "Mandarin",
  ]);
  const [languageData, setLanguageData] = useState([]);

  const [specialization, setSpecialization] = useState([
    "Active Aging",
    "Corrective Exercise",
    "Fitness",
    "Group Fitness",
    "Nutrition",
    "Strength Training",
    "Weight Management",
    "Women's Health",
    "Youth",
  ]);
  const [specializationData, setSpecializationData] = useState([]);

  const [fitnessCategories, setFitnessCategories] = useState([]);
  const [fitnessCategoriesData, setFitnessCategoriesData] = useState([]);

  useEffect(() => {
    getQueryData();
  }, [
    // perPage,
    page,
  ]);

  useEffect(() => {
    getFitnessCategoryOption();
    getTrainerLocationOption();
    if (window.location.pathname === "/Coach") {
      scrollPage();
    }
  }, []);

  const getFitnessCategoryOption = async () => {
    try {
      const data = await dispatch(getFitnessCategory());

      if (data && data.code === 200) {
        // let options = data.data.result.map((opt) => {
        //   return opt.categoryName;
        // });
        setFitnessCategories(data.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTrainerLocationOption = async () => {
    try {
      let payload = { status: "Approved" };
      const data = await dispatch(getTrainerLocations(payload));

      if (data && data.code === 200) {
        let options = data.data.map((opt) => {
          return { name: opt.location, id: opt._id };
        });
        setLocation(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMultiSelectChange = (state, setState, value) => {
    if (state.includes(value)) {
      setState(state.filter((item) => item !== value));
    } else {
      setState([...state, value]);
    }
  };

  const scrollPage = () => {
    const UserCardSection = document.getElementById("user-card");
    if (UserCardSection) {
      UserCardSection.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, 0);
    }
  };

  const getQueryData = () => {
    let coachSearch = searchParams.get("s");
    if (coachSearch) {
      coachSearch = decodeURIComponent(coachSearch);
      setSearch(coachSearch);
      handleGetTrainer(coachSearch);
      scrollPage();
    } else {
      handleGetTrainer();
    }
  };

  const handleGetTrainer = async (searchText = "", filterReset = false) => {
    let payload = {
      offset: page,
      limit: perPage,
      trainerStatus: "Approved",
      testimonialStatus: ["Approved"],
    };

    if (searchText !== "")
      Object.assign(payload, { search: searchText.trim() });

    if (!filterReset && genderData && genderData.length > 0) {
      Object.assign(payload, { gender: genderData });
    }

    if (
      !filterReset &&
      fitnessCategoriesData &&
      fitnessCategoriesData.length > 0
    ) {
      Object.assign(payload, { fitnessCategories: fitnessCategoriesData });
    }

    if (!filterReset && languageData && languageData.length > 0) {
      Object.assign(payload, { language: languageData });
    }

    if (!filterReset && specializationData && specializationData.length > 0) {
      Object.assign(payload, { specialization: specializationData });
    }

    if (!filterReset && locationData && locationData.length > 0) {
      let locationDataMap = locationData.map((data) => data.name);
      Object.assign(payload, { location: locationDataMap });
    }

    setIsLoading(true);
    const data = await dispatch(getTrainersLoadMore(payload));

    if (data && data.code === 200 && data.data && data.data.totalCount > 0) {
      if (page === 0) {
        dispatch(setTrainersLoadMore(data.data));
      } else {
        dispatch(
          setTrainersLoadMore({
            ...data.data,
            result: [...TrainersLoadMore.result, ...data.data.result],
          })
        );
      }
    } else {
      dispatch(setTrainersLoadMore({}));
    }

    setIsLoading(false);
  };

  const handleDebounceSearch = useCallback(debounce(handleGetTrainer, 500), []);

  const handleSearch = (e) => {
    setPage(0);
    const value = e.target.value;
    setSearch(value);
    handleDebounceSearch(value);
  };

  const handleLoadMore = () => {
    // setPerPage((state) => state + 2);
    setPage((state) => state + 1);
  };

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="relative">
      <Image src={imageVector} alt="image" className="homebanner-c" />
      <div
        id="user-card"
        className="relative zindex9 container mx-auto lg:py-20 md:py-12 py-8 lg:px-12 md:px-8 px-5"
      >
        <div className="w-full mb-20 flex justify-between items-center">
          <div className="lg:w-1/2 md:w-1/2 w-full pr-6">
            <div className="relative w-full">
              <input
                value={search}
                onChange={handleSearch}
                type="text"
                placeholder="search"
                className="w-full border h-12 rounded-sm lg:px-6 md:px-4 px-2"
              />
              <FeatherIcon
                icon="search"
                className="absolute right-4 top-2 mt-1"
              />
            </div>
          </div>

          <div className="flex gap-4 items-center">
            {/* <Button
              colorScheme="tertiary"
              className=" fsize12"
              onClick={onOpenNewBooking}
            >
              Find a Coach
            </Button>
            <AddNewCoachPopUp isOpen={isOpenBooking} onClose={onCloseBooking} /> */}

            {/* for web */}
            <FaAlignRight
              className="cursor-pointer lg:block md:hidden hidden fsize22"
              onClick={() => handleShowFilter()}
            />

            {/* for mobile */}
            <FaAlignRight
              className="cursor-pointer lg:hidden md:block block fsize22"
              onClick={onOpen}
              ref={btnRef}
            />
          </div>
        </div>

        {/* for mobile */}
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody>
              <div>
                <h2 className="text-gray font-semibold text-xl">Filter</h2>

                {/* <FormControl
                  display="flex"
                  className="my-4"
                  alignItems="center"
                >
                  <FormLabel
                    htmlFor="Show Only Available Slot"
                    className="text-gray fsize14"
                    mb="0"
                  >
                    Show Only Available Slot
                  </FormLabel>
                  <Switch id="email-alerts" />
                </FormControl> */}

                <Divider className="text-gray" orientation="horizontal" />

                <div className="mt-4">
                  <h2 className="font-semibold text-gray">Gender</h2>

                  <ButtonGroup className="mt-4" spacing="4">
                    {gender.map((option) => {
                      return (
                        <Button
                          key={option}
                          className="fsize12"
                          {...(genderData.includes(option)
                            ? { colorScheme: "secondary" }
                            : {})}
                          onClick={() =>
                            handleMultiSelectChange(
                              genderData,
                              setGenderData,
                              option
                            )
                          }
                        >
                          {option}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </div>

                <Divider
                  className=" my-4 text-gray "
                  orientation="horizontal"
                />

                <div>
                  <h2 className="mb-4 font-semibold text-gray">Location</h2>

                  {/* <Multiselect
                    className="fsize14"
                    options={cityOption.options} // Options to display in the dropdown
                    // selectedValues={selectedValue} // Preselected value to persist in dropdown
                    // onSelect={this.onSelect} // Function will trigger on select event
                    // onRemove={this.onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                  /> */}
                  <Multiselect
                    className={`fsize14`}
                    options={location}
                    selectedValues={locationData}
                    onSelect={(value) => {
                      setLocationData(value);
                    }}
                    onRemove={(value) => {
                      setLocationData(value);
                    }}
                    displayValue="name"
                  />
                </div>

                <Divider
                  className=" my-4 text-gray "
                  orientation="horizontal"
                />

                <div className="mb-4">
                  <h2 className="font-semibold text-gray">Specialization</h2>

                  <div className="mt-4 flex flex-wrap gap-2">
                    <ButtonGroup className="mt-4 flex flex-wrap gap-2">
                      {specialization.map((option) => {
                        return (
                          <Button
                            key={option}
                            className="fsize12 marg-inline-start"
                            {...(specializationData.includes(option)
                              ? { colorScheme: "secondary" }
                              : {})}
                            onClick={() =>
                              handleMultiSelectChange(
                                specializationData,
                                setSpecializationData,
                                option
                              )
                            }
                          >
                            {option}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </div>
                </div>

                <Divider className="text-gray" orientation="horizontal" />

                <div className="my-8">
                  <h2 className="font-semibold text-gray">
                    Fitness Categories
                  </h2>

                  <div className="mt-4 flex flex-wrap gap-2">
                    <ButtonGroup className="mt-4 flex flex-wrap gap-2">
                      {fitnessCategories &&
                        fitnessCategories.length > 0 &&
                        fitnessCategories.map((option) => {
                          return (
                            <Button
                              key={option._id}
                              className="fsize12 marg-inline-start"
                              {...(fitnessCategoriesData.includes(option._id)
                                ? { colorScheme: "secondary" }
                                : {})}
                              onClick={() =>
                                handleMultiSelectChange(
                                  fitnessCategoriesData,
                                  setFitnessCategoriesData,
                                  option._id
                                )
                              }
                            >
                              {option.categoryName}
                            </Button>
                          );
                        })}
                    </ButtonGroup>
                  </div>
                </div>

                <Divider className="text-gray" orientation="horizontal" />

                <div className="mt-4">
                  <h2 className="font-semibold text-gray">Languages</h2>

                  <div className="mt-4 flex flex-wrap gap-2">
                    <ButtonGroup className="mt-4 flex flex-wrap gap-2">
                      {language.map((option) => {
                        return (
                          <Button
                            key={option}
                            className="fsize12 marg-inline-start"
                            {...(languageData.includes(option)
                              ? { colorScheme: "secondary" }
                              : {})}
                            onClick={() =>
                              handleMultiSelectChange(
                                languageData,
                                setLanguageData,
                                option
                              )
                            }
                          >
                            {option}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </div>
                </div>

                <ButtonGroup className="md:mt-12 mt-20" spacing="6">
                  <Button
                    onClick={async () => {
                      onClose();
                      await handleGetTrainer(search);
                      scrollPage();
                    }}
                    className="w-full"
                    py="6"
                    colorScheme="primary"
                  >
                    Apply Filter
                  </Button>
                  <Button
                    onClick={async () => {
                      onClose();
                      setGenderData([]);
                      setFitnessCategoriesData([]);
                      setSpecializationData([]);
                      setLanguageData([]);
                      setLocationData([]);
                      await handleGetTrainer(search, true);
                      scrollPage();
                    }}
                    className="w-full"
                    py="6"
                    colorScheme="gray"
                  >
                    Clear Filter
                  </Button>
                </ButtonGroup>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <div className="lg:flex md:flex">
            <>
              {TrainersLoadMore?.result &&
              TrainersLoadMore.result.length > 0 ? (
                <div
                  className={
                    showFilter
                      ? "lg:w-3/4 md:w-3/4 w-full lg:pr-6 md:pr-6"
                      : " w-full"
                  }
                >
                  <Fade bottom>
                    <div
                      className={
                        showFilter
                          ? "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-6 md:gap-y-8"
                          : "grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-x-6 md:gap-y-8"
                      }
                    >
                      {TrainersLoadMore?.result.map((trainer) => (
                        <div className="relative h-360px lg:mt-8 md:mt-8 mt-12 lg:mb-8 md:mb-8 mb-8 bg-f4f5f6 rounded-lg p-4 text-center">
                          <div className="relative absolute m-auto w-28 h-28 flex justify-center left-0 topminus35">
                            <Image
                              src={
                                trainer.userId &&
                                trainer.userId.length &&
                                trainer.userId[0].userImage
                                  ? getImage(trainer.userId[0].userImage)
                                  : `${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`
                              }
                              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                              alt="profile-img"
                              className="w-28 h-28 rounded-full  border3px-fff object-cover"
                            />
                            {trainer.userId &&
                            trainer.userId.length > 0 &&
                            trainer.userId[0].status &&
                            trainer.userId[0].status === "Offline" ? (
                              <FeatherIcon
                                icon="x-circle"
                                className="bg-white text-gray  rounded-full border-2  absolute bottom-4 zindex99 right-0  w-6 h-6 "
                              ></FeatherIcon>
                            ) : (
                              <p className="border-2 block absolute bottom-4 zindex99 right-0 bg-success w-5 h-5 rounded-full"></p>
                            )}
                          </div>
                          <div className="mt-4">
                            <h2 className="fsize20 font-semibold line-clamp-1">
                              {trainer.userId &&
                              trainer.userId.length &&
                              trainer.userId[0] &&
                              trainer.userId[0].firstName
                                ? trainer.userId[0].firstName
                                : ""}{" "}
                              {trainer.userId &&
                              trainer.userId.length &&
                              trainer.userId[0] &&
                              trainer.userId[0].lastName
                                ? trainer.userId[0].lastName
                                : ""}
                            </h2>
                            <div className="flex justify-center items-center">
                              <p className="clr-9c9c9c fsize14 font-medium">
                                {trainer.location
                                  ? trainer.location
                                  : "Singapore"}
                                {/* , SG{" "} */}
                                {/* {trainer.price ? trainer.price : "0.0"} */}
                              </p>
                              <div className="hr1"></div>
                              <p className="clr-9c9c9c fsize14 font-medium flex items-center">
                                {trainer.rating}{" "}
                                <ReactStars
                                  count={1}
                                  value={1}
                                  size={15}
                                  edit={false}
                                  activeColor="#ffd700"
                                  classNames="mt-2px"
                                />{" "}
                                <div className="ml-2">
                                  {trainer.testimonials &&
                                  trainer.testimonials.length > 0
                                    ? trainer.testimonials.length
                                    : "0"}{" "}
                                  REVIEW
                                </div>
                              </p>
                            </div>

                            <div className="flex flex-wrap justify-center gap-2 pt-2">
                              {trainer.experience ? (
                                <div className="bg-white rounded-sm p-2">
                                  <p className="fsize10 font-semibold">
                                    {trainer.experience}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                              {trainer.fitnessCategories &&
                              trainer.fitnessCategories.length > 0
                                ? trainer.fitnessCategories
                                    .slice(0, 3)
                                    .map((fc, index) => (
                                      <Fragment key={index}>
                                        <div className="bg-white rounded-sm p-2">
                                          <p className="fsize10 font-semibold">
                                            {fc.categoryName}
                                          </p>
                                        </div>
                                      </Fragment>
                                    ))
                                : ""}
                            </div>
                            <div className="flex flex-wrap justify-center items-center mt-4">
                              {trainer.specialization &&
                              trainer.specialization.length > 0
                                ? trainer.specialization
                                    .slice(0, 3)
                                    .map((spec, index, specModifArray) => (
                                      <Fragment key={index}>
                                        <p className="clr-9c9c9c fsize10 md-fsize12 font-semibold">
                                          {spec + " "}
                                        </p>
                                        {index < specModifArray.length - 1 ? (
                                          <div className="hr1 "> </div>
                                        ) : (
                                          ""
                                        )}
                                      </Fragment>
                                    ))
                                : "No Specialization"}
                            </div>
                            <div className="lg:absolute md:absolute bottom-4 left-0 flex justify-center items-center gap-4 mt-6 w-full">
                              {/* <Button
                                variant="outline"
                                colorScheme="primary"
                                className="w-1/3 rounded-lg  py-2 fsize14 font-semibold"
                              >
                                CHAT
                              </Button> */}
                              <Button
                                onClick={() => {
                                  dispatch(setSelectedTrainer(trainer._id));
                                  navigate(
                                    `/trainer-profile?tn=${
                                      trainer.userId &&
                                      trainer.userId.length &&
                                      trainer.userId[0] &&
                                      trainer.userId[0].firstName
                                        ? trainer.userId[0].firstName
                                        : ""
                                    }${
                                      trainer.userId &&
                                      trainer.userId.length &&
                                      trainer.userId[0] &&
                                      trainer.userId[0].lastName
                                        ? trainer.userId[0].lastName
                                        : ""
                                    }&ti=${trainer._id}`
                                  );
                                }}
                                colorScheme="tertiary"
                                className="w-2/3 rounded-lg border py-2 fsize14   font-semibold"
                              >
                                VIEW PROFILE
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Fade>
                  {TrainersLoadMore &&
                    TrainersLoadMore.totalPages &&
                    TrainersLoadMore.totalPages - 1 > page && (
                      <div className="lg:mt-5 md:mt-10 mt-2  text-center">
                        <Button
                          className="bg-primary textwhite"
                          onClick={handleLoadMore}
                          width="200px"
                          height="50px"
                        >
                          LOAD MORE
                        </Button>
                      </div>
                    )}
                </div>
              ) : (
                <div className="flex lg:w-3/4 md:w-3/4 justify-center align-center">
                  No Trainer Found
                </div>
              )}
            </>
            <div
              className={showFilter ? "lg:w-1/4 md:w-1/4 filter35 w-full" : ""}
            >
              {showFilter ? (
                <Card className="cerashadow " py="6" px="4">
                  <h2 className="text-gray font-semibold text-xl">Filter</h2>

                  {/* <FormControl
                        display="flex"
                        className="my-8"
                        alignItems="center"
                      >
                        <FormLabel
                          htmlFor="Show Only Available Slot"
                          className="text-gray fsize14"
                          mb="0"
                        >
                          Show Only Available Slot
                        </FormLabel>
                        <Switch id="email-alerts" />
                      </FormControl> */}

                  {/* <Divider className="text-gray" orientation="horizontal" /> */}

                  <div className="mt-8">
                    <h2 className="font-semibold text-gray">Gender</h2>

                    {/* {gender.map((option) => {
                          <Checkbox
                            key={option}
                            isChecked={gender.includes(option)}
                            onChange={() =>
                              handleMultiSelectChange(
                                genderData,
                                setGenderData,
                                option
                              )
                            }
                          >
                            {option}
                          </Checkbox>;
                        })} */}

                    <ButtonGroup className="mt-4" spacing="4">
                      {gender.map((option) => {
                        return (
                          <Button
                            key={option}
                            className="fsize12"
                            {...(genderData.includes(option)
                              ? { colorScheme: "secondary" }
                              : {})}
                            onClick={() =>
                              handleMultiSelectChange(
                                genderData,
                                setGenderData,
                                option
                              )
                            }
                          >
                            {option}
                          </Button>
                        );
                      })}
                    </ButtonGroup>

                    {/* <ButtonGroup className="mt-4" spacing="4">
                          <Button className="fsize12" colorScheme="secondary">
                            Male
                          </Button>
                          <Button className="fsize12">Female</Button>
                          <Button className="fsize12">Others</Button>
                        </ButtonGroup> */}
                  </div>

                  <Divider
                    className=" my-8 text-gray "
                    orientation="horizontal"
                  />

                  <div>
                    <h2 className="mb-4 font-semibold text-gray">Location</h2>

                    {/* <Multiselect
                          className="fsize14"
                          options={cityOption.options} // Options to display in the dropdown
                          // selectedValues={selectedValue} // Preselected value to persist in dropdown
                          // onSelect={this.onSelect} // Function will trigger on select event
                          // onRemove={this.onRemove} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        /> */}

                    <Multiselect
                      className={`fsize14`}
                      options={location}
                      selectedValues={locationData}
                      onSelect={(value) => {
                        setLocationData(value);
                      }}
                      onRemove={(value) => {
                        setLocationData(value);
                      }}
                      displayValue="name"
                    />
                  </div>

                  <Divider
                    className=" my-8 text-gray "
                    orientation="horizontal"
                  />

                  <div className="mb-8">
                    <h2 className="font-semibold text-gray">Specialization</h2>

                    <div className="mt-4 flex flex-wrap gap-2">
                      <ButtonGroup className="mt-4 flex flex-wrap gap-2">
                        {specialization.map((option) => {
                          return (
                            <Button
                              key={option}
                              className="fsize12 marg-inline-start"
                              {...(specializationData.includes(option)
                                ? { colorScheme: "secondary" }
                                : {})}
                              onClick={() =>
                                handleMultiSelectChange(
                                  specializationData,
                                  setSpecializationData,
                                  option
                                )
                              }
                            >
                              {option}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                  </div>

                  <Divider className="text-gray" orientation="horizontal" />

                  <div className="my-8">
                    <h2 className="font-semibold text-gray">
                      Fitness Categories
                    </h2>

                    <div className="mt-4 flex flex-wrap gap-2">
                      <ButtonGroup className="mt-4 flex flex-wrap gap-2">
                        {fitnessCategories &&
                          fitnessCategories.length > 0 &&
                          fitnessCategories.map((option) => {
                            return (
                              <Button
                                key={option._id}
                                className="fsize12 marg-inline-start"
                                {...(fitnessCategoriesData.includes(option._id)
                                  ? { colorScheme: "secondary" }
                                  : {})}
                                onClick={() =>
                                  handleMultiSelectChange(
                                    fitnessCategoriesData,
                                    setFitnessCategoriesData,
                                    option._id
                                  )
                                }
                              >
                                {option.categoryName}
                              </Button>
                            );
                          })}
                      </ButtonGroup>
                    </div>
                  </div>

                  <Divider className="text-gray" orientation="horizontal" />

                  <div className="mt-8">
                    <h2 className="font-semibold text-gray">Languages</h2>

                    <div className="mt-4 flex flex-wrap gap-2">
                      <ButtonGroup className="mt-4 flex flex-wrap gap-2">
                        {language.map((option) => {
                          return (
                            <Button
                              key={option}
                              className="fsize12 marg-inline-start"
                              {...(languageData.includes(option)
                                ? { colorScheme: "secondary" }
                                : {})}
                              onClick={() =>
                                handleMultiSelectChange(
                                  languageData,
                                  setLanguageData,
                                  option
                                )
                              }
                            >
                              {option}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                  </div>

                  <ButtonGroup className="mt-20" spacing="6">
                    <Button
                      onClick={async () => {
                        setPage(0);
                        handleShowFilter();
                        await handleGetTrainer(search);
                        scrollPage();
                      }}
                      className="w-full"
                      py="6"
                      colorScheme="primary"
                    >
                      Apply Filter
                    </Button>
                    <Button
                      onClick={async () => {
                        setPage(0);
                        handleShowFilter();
                        setGenderData([]);
                        setFitnessCategoriesData([]);
                        setSpecializationData([]);
                        setLanguageData([]);
                        setLocationData([]);
                        await handleGetTrainer(search, true);
                        scrollPage();
                      }}
                      className="w-full"
                      py="6"
                      colorScheme="gray"
                    >
                      Clear Filter
                    </Button>
                  </ButtonGroup>
                </Card>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
      <Image src={imageVectorBottom} alt="image" className="homevectorbottom" />
    </section>
  );
};

export default UserCard;
