import React from 'react';
import { EffectCoverflow,Navigation } from "swiper/modules";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

const CardImage2 = () => {
  return (
    <section>
      <div className="text-center container mx-auto flex flex-col justify-center lg:mb-16">
        <h3 className="letter-spacing4px fsize24 font-semibold clr-ddd9d8 lg:mb-2">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold lg:mb-8">Lorem Ipsum</h1>
        <div className=" mx-auto lg:w-3/5 flex">
          <p className=" fsize18 text-center">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
      <div className="container mx-auto ">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="w-full p-2">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full p-2">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-cover card-img2"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default CardImage2;
