import API from "../../repositoryFactory";

const togglePostLike = "togglePostLike";
const toggleCommentLike = "toggleCommentLike";
const toggleReplyLike = "toggleReplyLike";

const likeRoute = (route) => {
  return `fit2go/social/like/${route}`;
};

export default {
  togglePostLike(payload) {
    return API.post(likeRoute(togglePostLike), payload);
  },
  toggleCommentLike(payload) {
    return API.post(likeRoute(toggleCommentLike), payload);
  },
  toggleReplyLike(payload) {
    return API.post(likeRoute(toggleReplyLike), payload);
  },
};
