import { store } from "redux/store";
import { setWS } from "./chat-actions";

let ws = null;
let tryConnect = true;

const connectToWS = async () => {
  if (ws) {
    ws.close();
    tryConnect = false;
  }

  if (
    process.env.REACT_APP_WS_BASE_URL &&
    store.getState().AuthDetails.user.token
  ) {
    ws = new WebSocket(
      process.env.REACT_APP_WS_BASE_URL,
      store.getState().AuthDetails.user.token
    );

    console.log("WS", ws);
    // dispatch(setWS(ws));
    // setWS(ws)
    ws.onopen = function (e) {
      console.log("WS CONNECTED");
      setTimeout(() => {
        sendCustomEvent("fetchOnline", "online");
      }, 2000);
    };

    // ws.onmessage = function (e) {
    //   try {
    //     console.log("on message");
    //     const eventData = JSON.parse(e.data);
    //     console.log("eventData", eventData);
    //     // if (eventData.type === "sendMessage") {
    //     //   handleMessage(eventData.data);
    //     // }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    ws.onclose = function (e) {
      console.log(tryConnect, "ws Disconnected");
      if (tryConnect && store.getState().AuthDetails.user.token) {
        setTimeout(() => {
          console.log("WS Disconnected. Trying to reconnect.");
          connectToWS();
          tryConnect = false;
        }, 1000);
      }
      // else {
      //   if (ws) {
      //     ws.close();
      //   }
      // }
    };
  }
};

const disconnectToWS = async () => {
  try {
    //   let ws = store.getState().chat.WS;
    if (ws) {
      console.log(ws, "ws logout");
      setTimeout(() => {
        sendCustomEvent("fetchOnline", "offline");
        tryConnect = false;
        ws.close();
        ws = null;
        store.dispatch(setWS(null));
      }, 1000);
    }
  } catch (error) {
    console.log(error);
  }
};

const sendCustomEvent = (eventName, eventData) => {
  if (ws.readyState === WebSocket.OPEN) {
    const eventObj = {
      type: eventName,
      data: eventData,
    };
    ws.send(JSON.stringify(eventObj));
  }
};

export { ws, connectToWS, disconnectToWS, sendCustomEvent };
