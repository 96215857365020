const initialState = {
  FitnessCategory: {},
};

const FitnessCategory = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FITNESS_CATEGORY":
      return { ...state, FitnessCategory: action.payload };
    default:
      return state;
  }
};

export default FitnessCategory;
