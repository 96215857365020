import React from "react";
import { useSelector } from "react-redux/es/exports";
import { Image } from "@chakra-ui/react";
import Fade from "react-reveal/Fade";

const Founders = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <div>
      <div className="bggradient-right30 ">
        <div className="lg:flex md:flex items-center container mx-auto lg:py-24 md:py-16 py-12 lg:px-12 md:px-8 px-5">
          <div className="lg:w-6/12 md:w-full lg:pr-10">
            <div className="dynamicStyle2" id="2053287608">
              <Image
                src={data ? getImage(data["2053287608"]) : ""}
                className="chua-img tb-h280px object-contain w-full"
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                alt="chua"
              />
            </div>
          </div>

          <div className="lg:w-6/12 md:w-full lg:pl-10 lg:mt-0 mt-6">
            <Fade right>
              <h2
                id="8159973227"
                className="dynamicStyle lg:text-4xl md:text-2xl text-xl  font-semibold text-white"
              >
                {data ? data["8159973227"] : "no-data"}
              </h2>
              <p
                id="0210835708"
                dangerouslySetInnerHTML={{
                  __html: data ? data["0210835708"] : "no-data",
                }}
                className="dynamicStyle fsize18 md-fsize16 sm-fsize14 mt-3 sm-fsize14 text-white lg:mt-3 md:mt-0 font-normal opacity-70 lg:leading-7 md:leading-7 leading-6"
              ></p>
              <p
                id="4556735013"
                dangerouslySetInnerHTML={{
                  __html: data ? data["4556735013"] : "no-data",
                }}
                className="dynamicStyle fsize18 md-fsize16 sm-fsize14 text-white  opacity-70 lg:leading-7 md:leading-7 leading-6 mt-3"
              ></p>
            </Fade>
          </div>
        </div>
      </div>
      <div className="bggradient-left30">
        <div className="lg:flex md:flex  sm-reverse items-center container mx-auto lg:py-24 md:py-18 py-12 lg:px-12 md:px-8 px-5">
          <div className="lg:w-6/12 md:w-full lg:pr-10 lg:mt-0 mt-6">
            <Fade left>
              <h2
                id="9366882168"
                className="dynamicStyle lg:text-4xl md:text-2xl text-xl  font-semibold text-white"
              >
                {data ? data["9366882168"] : "no-data"}
              </h2>
              <p
                id="5238377999"
                dangerouslySetInnerHTML={{
                  __html: data ? data["5238377999"] : "no-data",
                }}
                className="dynamicStyle fsize16 text-white sm-fsize14 opacity-70 lg:leading-7 md:leading-7 leading-6 mt-3"
              ></p>
            </Fade>
          </div>
          <div className="lg:w-6/12 md:w-full  w-full lg:pl-10">
            <div id="2142586895" className="dynamicStyle2">
              <Image
                src={data ? getImage(data["2142586895"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                className="chua-img tb-h280px object-contain w-full"
                alt="chua"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founders;
