import React, { useEffect, useState } from "react";
import imageVector from "assets/images/custom/Vector5.png";
import imageVectorBottom from "assets/images/custom/Vector5.png";
import {
  Text,
  Image,
  Divider,
  Box,
  Card,
  Button,
  AbsoluteCenter,
  FormControl,
  Input,
  FormLabel,
  Textarea,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import Multiselect from "multiselect-react-dropdown";
import FeatherIcon from "feather-icons-react";
import { Line } from "rc-progress";
import { AddIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  addTrainer,
  setSaveTrainerData,
  trainerPaymentSuccessEmail,
  trainerPaymentFailedEmail,
  uploadMedia,
} from "../../../redux/custom/trainer/trainer-actions";
import { createLog } from "../../../redux/custom/subscription/log/log-actions";
import { getFitnessCategory } from "../../../redux/custom/fitnessCategory/fitnessCategory-actions";
import {
  SaveBasicProfileDetails,
  GetUsersProfileInfo,
} from "../../../redux/auth/auth-action";
import { useFormik } from "formik";
import * as Yup from "yup";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { uniqueId } from "lodash";
import { ThreeDots } from "react-loader-spinner";
import { getAllPlan } from "redux/custom/subscription/plan/plan-actions";
import PaymentCheckout from "../../../components/Payment/Payment";
import { getTransaction } from "redux/transaction/transaction-actions";
import Subscription from "./SubscriptionPlan";
import SubscriptionGradient from "./SubscriptionGradient";
import { toast } from "react-toastify";
import Helper from "../../../helper";

const BecameCoachForm = ({ pageName }) => {
  const {
    isOpen: isOpenPlan,
    onOpen: onOpenPlan,
    onClose: onClosePlan,
  } = useDisclosure();

  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let User = useSelector((state) => state.AuthDetails.user);
  let SaveTrainerData = useSelector((state) => state.trainer.SaveTrainerData);
  const [check, setCheck] = useState(1);
  const [transactionData, setTransactionData] = useState("");

  const [isQualCert, setIsQualCert] = useState(false);

  const [userMobileCountry, setUserMobileCountry] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [userImagePreview, setUserImagePreview] = useState(null);
  const [certifiacteImage, setCertifiacteImage] = useState("");
  const [qualificationImage, setQualificationImage] = useState("");

  const [specializationData, setSpecializationData] = useState([]);
  const [fitnessCategoriesData, setFitnessCategoriesData] = useState([]);
  const [languageData, setLanguageData] = useState([]);

  const [addEditQual, setAddEditQual] = useState("Add");
  const [addEditCert, setAddEditCert] = useState("Add");
  const [selectedObject, setSelectedObject] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [DOBDates, setDOBDates] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 10))
      .toISOString()
      .split("T")[0]
  );

  const [specializationOptions, setSpecializationOptions] = useState([
    { name: "Active Aging", id: 1 },
    { name: "Corrective Exercise", id: 2 },
    { name: "Fitness", id: 3 },
    { name: "Group Fitness", id: 4 },
    { name: "Nutrition", id: 5 },
    { name: "Strength Training", id: 6 },
    { name: "Weight Management", id: 7 },
    { name: "Women's Health", id: 8 },
    { name: "Youth", id: 9 },
  ]);

  const [languageOptions, setLanguageOptions] = useState([
    { name: "English", id: 1 },
    { name: "Malay", id: 2 },
    { name: "Tamil", id: 3 },
    { name: "Mandarin", id: 4 },
  ]);

  const [trainingLocationOptions, setTrainingLocationOptions] = useState([
    { name: "My gym", id: 1 },
    { name: "Your gym", id: 2 },
    { name: "Park/public space", id: 3 },
  ]);

  const [trainingTypeOptions, setTrainingTypeOptions] = useState([
    { name: "Online", id: 1 },
    { name: "Offline", id: 2 },
  ]);

  const [fitnessCategoriesOptions, setFitnessCategoriesOptions] = useState([]);

  const [qualificationData, setQualificationData] = useState([]);
  const [certificationData, setCertificationData] = useState([]);

  let allPlan = useSelector((state) => state.plan.AllPlan);

  const {
    isOpen: isOpenQuali,
    onOpen: onOpenQuali,
    onClose: onCloseQuali,
  } = useDisclosure();

  const {
    isOpen: isOpenCert,
    onOpen: onOpenCert,
    onClose: onCloseCert,
  } = useDisclosure();

  const handleNext = async () => {
    console.log(check);
    // formik.errors = {};
    // await formik.setErrors({});
    console.log(formik.errors, "formik.errors1");
    const isValid = await formik.validateForm();
    if (check == 1) {
      // const isValid = await formik.validateForm();
      // console.log(isValid);
      await validateTrainerForm1();
    }
    if (check == 2) {
      console.log("check2");
      // const isValid = await formik.validateForm();
      // console.log(isValid);
      // formik.errors = {};
      await validateTrainerForm2();
    }

    // console.log(formik.errors);

    console.log(isValid, "formik.errorsisValid");
    if (Helper.Object.isEmpty(isValid)) {
      setCheck(check + 1);
    }
  };
  const handlePrevious = () => {
    setCheck(check - 1);
  };

  useEffect(() => {
    readQuery();
    fetchPlan();
    populateData();
    getFitnessCategoryOption();
  }, []);

  const readQuery = async () => {
    try {
      if (User && User.trainerId) {
        if (User.role === "Trainer") {
          toast.warning("Already a trainer");
        } else {
          toast.warning("Already submitted trainer form");
        }
        navigate("/home");
        return;
      }

      const queryParams = new URLSearchParams(window.location.search);
      const paramValue = queryParams.get("id");
      console.log(paramValue, "paramValue");
      if (paramValue) {
        const data = await dispatch(
          getTransaction({ transactionId: paramValue })
        );
        if (data && data.code === 200 && data.data && data.data.length > 0) {
          setTransactionData(data.data[0]);
          let selectedPlanData = allPlan.filter(
            (item) => item._id === data.data[0].planId
          );
          const urlWithoutParmas = window.location.href.split("?")[0];
          window.history.replaceState({}, document.title, urlWithoutParmas);
          if (
            data.data &&
            data.data.length > 0 &&
            data.data[0].transactionStatus !== "Success"
          ) {
            // Swal.fire({
            //   title: "Transaction Failed",
            //   text: `Please retry the payment`,
            //   icon: "error",
            // });
            let email = await dispatch(trainerPaymentFailedEmail());
            toast.error("Transaction Failed: Please retry the payment");
          }
          if (
            data.data &&
            data.data.length > 0 &&
            data.data[0].transactionStatus === "Success" &&
            selectedPlanData &&
            selectedPlanData.length > 0
          ) {
            let logPayload = {
              planId: data.data[0].planId,
              userId: data.data[0].userId._id,
              transactionId: data.data[0]._id,
              purchase_price: selectedPlanData[0] && selectedPlanData[0].price,
              start_day: new Date(),
              end_day:
                selectedPlanData[0] &&
                calculateDate(selectedPlanData[0].validity),
              planObject: { ...selectedPlanData[0] },
              transactionObject: { ...data.data[0] },
              userObject: { ...data.data[0].userId },
            };
            const logData = await dispatch(createLog(logPayload));
            if (logData && logData.data) {
              setFieldHandler("subscriptionLog", logData.data);
              let userPayload = new FormData();
              if (data.data[0] && data.data[0].planId) {
                userPayload.append("planId", data.data[0].planId);
              }
              if (logData.data && logData.data._id) {
                userPayload.append("subscriptionId", logData.data._id);
              }
              let userSave = await dispatch(
                SaveBasicProfileDetails(userPayload)
              );
              let userData = await dispatch(GetUsersProfileInfo());
            }
            // Swal.fire({
            //   title: "Transaction Success",
            //   text: `Payment successful`,
            //   icon: "success",
            // });
            let email = await dispatch(trainerPaymentSuccessEmail());
            toast.success("Transaction Success: Payment successful");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateDate = (numDays) => {
    let currentDateObj = new Date();
    currentDateObj.setDate(currentDateObj.getDate() + numDays);
    return currentDateObj;
  };

  const fetchPlan = async () => {
    try {
      await dispatch(getAllPlan());
      await dispatch(GetUsersProfileInfo());
    } catch (error) {
      console.log(error);
    }
  };

  function getItemList(item) {
    return [
      {
        id: item._id,
        name: item.name,
        quantity: 1,
        price: parseInt(item.price),
      },
    ];
  }

  const getFitnessCategoryOption = async () => {
    try {
      const data = await dispatch(getFitnessCategory());

      if (data && data.code === 200) {
        let options = data.data.result.map((opt) => {
          return { name: opt.categoryName, id: opt._id };
        });

        setFitnessCategoriesOptions(options);
        console.log(options, "fitnessCategoriesOptions");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const populateData = async () => {
    console.log(User);

    if (User.userImage) {
      await formik.setFieldValue(
        "userImage",
        User.userImage ? User.userImage : null
      );
      setUserImagePreview(User.userImage);
    }
    await formik.setFieldTouched("userImage", true);
    await formik.setFieldValue(
      "firstName",
      User.firstName ? User.firstName : ""
    );
    await formik.setFieldTouched("firstName", true);
    await formik.setFieldValue("lastName", User.lastName ? User.lastName : "");
    await formik.setFieldTouched("lastName", true);
    await formik.setFieldValue("gender", User.gender ? User.gender : "");
    await formik.setFieldTouched("gender", true);
    await formik.setFieldValue("dob", User.dob ? formatDate(User.dob) : null);
    await formik.setFieldTouched("dob", true);
    await formik.setFieldValue("email", User.email ? User.email : "");
    await formik.setFieldTouched("email", true);

    // if (User.userImage) {
    //   await setFieldHandler(
    //     "userImage",
    //     User.userImage ? User.userImage : null
    //   );
    //   setUserImagePreview(User.userImage);
    // }
    // await setFieldHandler("firstName", User.firstName ? User.firstName : "");
    // await setFieldHandler("lastName", User.lastName ? User.lastName : "");
    // await setFieldHandler("gender", User.gender ? User.gender : "");
    // await setFieldHandler("dob", User.dob ? formatDate(User.dob) : null);
    // await setFieldHandler("email", User.email ? User.email : "");

    setUserMobileCountry(`${User.countryCode}${User.mobile}`);
    // console.log("formik.values.mobile", formik.values.mobile);
    await formik.setErrors({});
    // await formik.validateForm();
    if (SaveTrainerData) {
      const parsedData = JSON.parse(SaveTrainerData);
      console.log(parsedData, "parsedData");
      formik.setValues(parsedData);
      // if (parsedData.sUserImage) setUserImage(parsedData.sUserImage);
      if (parsedData.suserImagePreview)
        setUserImagePreview(parsedData.suserImagePreview);
      if (parsedData.slanguageData) setLanguageData(parsedData.slanguageData);
      if (parsedData.sspecializationData)
        setSpecializationData(parsedData.sspecializationData);
      if (parsedData.sfitnessCategoriesData)
        setFitnessCategoriesData(parsedData.sfitnessCategoriesData);
      if (parsedData.squalificationData)
        setQualificationData(parsedData.squalificationData);
      if (parsedData.scertificationData)
        setCertificationData(parsedData.scertificationData);
      if (parsedData.scheck) setCheck(parsedData.scheck);
    }
  };

  const formatDate = (date) => {
    const [day, month, year] = new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
      .format(new Date(date))
      .split("/");

    return `${year}-${month}-${day}`;
  };

  const initialValues = {
    //trainer
    NRICNumber: "",
    specialization: [],
    qualification: [],
    certification: [],
    about: "",
    language: [],
    location: "",
    trainingLocation: [],
    trainingType: [],
    fitnessCategories: [],
    experience: "",
    preferredTime: [],
    price: "",
    rating: "",

    selectedPlan: "",
    selectedPlanData: {},
    subscriptionLog: {},

    //User
    userImage: null,
    firstName: "",
    lastName: "",
    countryCode: "",
    mobile: "",
    gender: "",
    email: "",
    dob: null,

    //Qualification
    qtype: "",
    qtypeName: "",
    qfieldOfStudy: "",
    qstartDate: null,
    qendDate: null,
    // qgrade: "",
    // qactivity: "",
    qmedia: null,
    qmediaName: null,
    qmediaType: null,

    //Certification
    ccourseName: "",
    corgName: "",
    // cstartDate: null,
    // cendDate: null,
    // cgrade: "",
    // cactivity: "",
    // ccertificateUrl: "",
    cmedia: null,
    cmediaName: null,
    cmediaType: null,
  };

  const validationRules1 = Yup.object({
    // NRICNumber: Yup.string()
    //   .trim()
    //   .min(9, "NRIC number must be 9 characters")
    //   .max(9, "NRIC number must be 9 characters")
    //   .required("NRIC number is required")
    //   .matches(/^[a-zA-Z]*$/, "Only alphabets is allowed"),
    userImage: Yup.mixed()
      .required("Image is required")
      .test(
        "fileType",
        "Invalid file type. Only JPEG, PNG allowed.",
        (value) => {
          return (
            value &&
            ((value.type && ["image/jpeg", "image/png"].includes(value.type)) ||
              (typeof value == "string" && value.includes("fit2go/fit2go/")))
          );
        }
      )
      .test("fileSize", "User image size is too large (Max: 5MB)", (value) => {
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      }),
    firstName: Yup.string()
      .trim()
      // .min(3, "First name must be at least 3 characters")
      .max(25, "First name must be at most 25 characters")
      .required("First name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    lastName: Yup.string()
      .trim()
      // .min(3, "Last name must be at least 3 characters")
      .max(25, "Last name must be at most 25 characters")
      .required("Last name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
  });

  const validationRules2 = Yup.object({
    specialization: Yup.array().min(1, "Specialization is required"),
    gender: Yup.string().required("Gender is required"),
    about: Yup.string()
      .trim()
      // .min(3, "About must be at least 3 characters")
      .max(500, "About must be at most 500 characters")
      .required("About is required"),
    location: Yup.string()
      .trim()
      .min(3, "Location must be at least 3 characters")
      .max(25, "Location must be at most 25 characters")
      .required("Location is required")
      .matches(/^[a-zA-Z-, ]*$/, "Only alphabets is allowed"),
    fitnessCategories: Yup.array().min(1, "Fitness category is required"),
    experience: Yup.string().required("Experience is required"),
    dob: Yup.date().required("DOB is required"),
    language: Yup.array().min(1, "Language  is required"),
    trainingType: Yup.array().min(1, "Training Type  is required"),
    trainingLocation: Yup.array().min(1, "Training Location is required"),
  });

  const validationRulesQual = Yup.object({
    qtype: Yup.string()
      .trim()
      // .min(3, "Type must be at least 3 characters")
      // .max(100, "Type must be at most 100 characters")
      .required("Type is required"),
    qtypeName: Yup.string()
      .trim()
      .min(3, "Field must be at least 3 characters")
      .max(100, "Field must be at most 100 characters")
      .required("Field is required"),
    qfieldOfStudy: Yup.string()
      .trim()
      // .min(3, "Field of study must be at least 3 characters")
      .max(100, "Field of study must be at most 100 characters"),
    // .required("Field of study is required"),
    qstartDate: Yup.date().required("Start date is required"),
    qendDate: Yup.date().required("End date is required"),
    // qgrade: Yup.string()
    //   .trim()
    //   // .min(2, "Grade must be at least 2 characters")
    //   .max(25, "Grade must be at most 25 characters")
    //   .required("Grade is required"),
    // qactivity: Yup.string()
    //   .trim()
    //   .min(3, "Activity must be at least 3 characters")
    //   .max(50, "Activity must be at most 50 characters")
    //   .required("Activity is required"),
    qmedia: Yup.mixed()
      .test("fileType", "Only image is allowed", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "File size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .nullable(),
  });

  const validationRulesCert = Yup.object({
    ccourseName: Yup.string()
      .trim()
      .min(3, "Course name must be at least 3 characters")
      .max(50, "Course name must be at most 50 characters")
      .required("Course name is required"),
    corgName: Yup.string()
      .trim()
      .min(3, "Org name must be at least 3 characters")
      .max(50, "Org name must be at most 50 characters")
      .required("Org name is required"),
    cstartDate: Yup.date().required("Start date is required"),
    cendDate: Yup.date().required("End date is required"),
    // cgrade: Yup.string()
    //   .trim()
    //   // .min(2, "Grade must be at least 2 characters")
    //   .max(25, "Grade must be at most 25 characters")
    //   .required("Grade is required"),
    // cactivity: Yup.string()
    //   .trim()
    //   .min(3, "Activity must be at least 3 characters")
    //   .max(50, "Activity must be at most 50 characters")
    //   .required("Activity is required"),
    // ccertificateUrl: Yup.string()
    //   .trim()
    //   .url("Please enter a valid URL")
    //   .min(3, "Certificate url must be at least 3 characters")
    //   .max(50, "Certificate url must be at most 50 characters")
    //   .required("Certificate url is required"),
    cmedia: Yup.mixed()
      .required("Media is required")
      .test("fileType", "Only image is allowed", (value) => {
        if (!value) return true;
        if (typeof value === "string") return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "File size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        if (typeof value === "string") return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      }),
  });

  const validateTrainerForm1 = async () => {
    validateField("firstName", formik.values.firstName);
    validateField("lastName", formik.values.lastName);
    validateField("userImage", formik.values.userImage);
  };

  const validateTrainerForm2 = () => {
    validateField("gender", formik.values.gender);
    validateField("dob", formik.values.dob);
    validateField("language", formik.values.language);
    validateField("location", formik.values.location);
    validateField("trainingType", formik.values.trainingType);
    validateField("trainingLocation", formik.values.trainingLocation);
    validateField("about", formik.values.about);
    validateField("experience", formik.values.experience);
    validateField("specialization", formik.values.specialization);
    validateField("fitnessCategories", formik.values.fitnessCategories);
  };

  const validateTrainerFormQual = () => {
    let qc = validateField("qtype", formik.values.qtype);
    let qd = validateField("qtypeName", formik.values.qtypeName);
    let qf = validateField("qfieldOfStudy", formik.values.qfieldOfStudy);
    let qs = validateField("qstartDate", formik.values.qstartDate);
    let qe = validateField("qendDate", formik.values.qendDate);
    // let qg = validateField("qgrade", formik.values.qgrade);
    // let qa = validateField("qactivity", formik.values.qactivity);
    let qm = validateField("qmedia", formik.values.qmedia);
    return qc && qd && qf && qs && qe && qm;
  };

  const validateTrainerFormCert = () => {
    let cc = validateField("ccourseName", formik.values.ccourseName);
    let co = validateField("corgName", formik.values.corgName);
    // let cs = validateField("cstartDate", formik.values.cstartDate);
    // let ce = validateField("cendDate", formik.values.cendDate);
    // let cg = validateField("cgrade", formik.values.cgrade);
    // let ca = validateField("cactivity", formik.values.cactivity);
    // let cu = validateField("ccertificateUrl", formik.values.ccertificateUrl);
    let cm = validateField("cmedia", formik.values.cmedia);
    return cc && co && cm;
  };

  const handleOnSubmit = async (values) => {
    let payload = {};
    let userPayload = new FormData();

    if (formik.values.NRICNumber) {
      Object.assign(payload, { NRICNumber: formik.values.NRICNumber });
    }
    if (formik.values.about) {
      Object.assign(payload, { about: formik.values.about });
    }
    if (formik.values.location) {
      Object.assign(payload, { location: formik.values.location });
    }
    if (formik.values.experience) {
      Object.assign(payload, { experience: formik.values.experience });
    }
    if (formik.values.price) {
      Object.assign(payload, { price: formik.values.price });
    }
    // if (
    //   formik.values.specialization &&
    //   formik.values.specialization.length > 0
    // ) {
    //   Object.assign(payload, { specialization: formik.values.specialization });
    // }
    // if (
    //   formik.values.fitnessCategories &&
    //   formik.values.fitnessCategories.length > 0
    // ) {
    //   Object.assign(payload, {
    //     fitnessCategories: formik.values.fitnessCategories,
    //   });
    // }
    if (fitnessCategoriesData && fitnessCategoriesData.length > 0) {
      let value = fitnessCategoriesData.map((val) => val.id);
      Object.assign(payload, { fitnessCategories: value });
    }
    if (specializationData && specializationData.length > 0) {
      let value = specializationData.map((val) => val.name);
      Object.assign(payload, { specialization: value });
    }
    if (languageData && languageData.length > 0) {
      let value = languageData.map((val) => val.name);
      Object.assign(payload, { language: value });
    }
    if (formik.values.location) {
      Object.assign(payload, { location: formik.values.location });
    }
    if (
      formik.values.trainingLocation &&
      formik.values.trainingLocation.length > 0
    ) {
      let value = formik.values.trainingLocation.map((val) => val.name);
      Object.assign(payload, {
        trainingLocation: value,
      });
    }
    if (formik.values.trainingType && formik.values.trainingType.length > 0) {
      let value = formik.values.trainingType.map((val) => val.name);
      Object.assign(payload, { trainingType: value });
    }

    if (formik.values.preferredTime && formik.values.preferredTime.length > 0) {
      Object.assign(payload, { preferredTime: formik.values.preferredTime });
    }
    // if (formik.values.language && formik.values.language.length > 0) {
    //   Object.assign(payload, { language: formik.values.language });
    // }
    if (qualificationData && qualificationData.length > 0) {
      Object.assign(payload, { qualification: qualificationData });
    }
    if (certificationData && certificationData.length > 0) {
      Object.assign(payload, { certification: certificationData });
    }

    // if (userImage) {
    //   console.log("userImage", userImage);
    //   userPayload.append("userImage", userImage);
    // }
    if (formik.values.firstName) {
      userPayload.append("firstName", formik.values.firstName);
    }
    if (formik.values.lastName) {
      userPayload.append("lastName", formik.values.lastName);
    }
    if (formik.values.gender) {
      userPayload.append("gender", formik.values.gender);
    }
    if (formik.values.dob) {
      userPayload.append("dob", formik.values.dob);
    }
    if (transactionData && transactionData.planId) {
      userPayload.append("planId", transactionData.planId);
    }
    if (formik.values.subscriptionLog && formik.values.subscriptionLog._id) {
      userPayload.append("subscriptionId", formik.values.subscriptionLog._id);
    }

    console.log(payload, "payloadsubmit1");
    console.log(
      formik.values.fitnessCategories,
      formik.values.specialization,
      "payloadsubmit"
    );
    console.log(
      // userImage,
      formik.values.firstName,
      formik.values.lastName,
      formik.values.gender,
      formik.values.dob,
      "userPayloadsubmit"
    );

    try {
      let userSave = await dispatch(SaveBasicProfileDetails(userPayload));
      if (userSave && userSave.code === 200) {
        let data = await dispatch(addTrainer(payload));
        if (data && data.code == 200) {
          let userData = await dispatch(GetUsersProfileInfo());
          if (userData && userData.code === 200) {
            Swal.fire({
              title: "Your application has been submitted successfully",
              text: `Our team will promptly review it and provide an update shortly.`,
              icon: "success",
            });
            dispatch(setSaveTrainerData(null));
            handleReset();
            navigate("/home");
          }
        } else {
          if (data.message) {
            // Swal.fire({
            //   title: "Error",
            //   text: data.message,
            //   icon: "error",
            // });
            toast.error(data.message);
          }
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const setFieldHandler = async (field, value) => {
    await formik.setFieldValue(field, value);
    await formik.setFieldTouched(field, true);
  };

  const handleReset = () => {
    formik.handleReset();
    setSpecializationData([]);
    setFitnessCategoriesData([]);
    setQualificationImage("");
    setCertifiacteImage("");
    setUserImagePreview(null);
    // setUserImage(null);
    setUserMobileCountry(null);
    setQualificationData([]);
    setCertificationData([]);
    formik.setFieldValue("gender", "");
    resetQualification();
    resetCertification();
    setLanguageData([]);
    setSelectedObject({});
    setIsLoading(false);

    populateData();
    setCheck(1);
  };

  const resetQualification = () => {
    formik.setFieldValue("qtype", "");

    formik.setFieldValue("qtypeName", "");
    formik.setFieldValue("qfieldOfStudy", "");
    formik.setFieldValue("qstartDate", "");
    formik.setFieldValue("qendDate", "");
    // formik.setFieldValue("qgrade", "");
    // formik.setFieldValue("qactivity", "");
    formik.setFieldValue("qmedia", "");
    formik.setFieldValue("qmediaName", "");
    formik.setFieldValue("qmediaType", "");
    formik.setTouched({});
    setQualificationImage(null);
    setSelectedObject({});
  };

  const resetCertification = () => {
    formik.setFieldValue("ccourseName", "");
    formik.setFieldValue("corgName", "");
    // formik.setFieldValue("cstartDate", "");
    // formik.setFieldValue("cendDate", "");
    // formik.setFieldValue("cgrade", "");
    // formik.setFieldValue("cactivity", "");
    // formik.setFieldValue("ccertificateUrl", "");
    formik.setFieldValue("cmedia", "");
    formik.setFieldValue("cmediaName", "");
    formik.setFieldValue("cmediaType", "");
    formik.setTouched({});
    setCertifiacteImage(null);
    setSelectedObject({});
  };

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
    validateField("mobile", formik.values.mobile);
  };

  const validateField = (fieldName, value) => {
    try {
      (check === 1
        ? validationRules1
        : check === 2
        ? validationRules2
        : check === 3 && isQualCert === true
        ? validationRulesQual
        : check === 3 && isQualCert === false
        ? validationRulesCert
        : ""
      ).fields[fieldName].validateSync(value, {
        abortEarly: false,
      });
      formik.setFieldError(fieldName, undefined);
      formik.setFieldTouched(fieldName, true);
      return true;
    } catch (error) {
      formik.setFieldError(fieldName, error.errors[0]);
      formik.setFieldTouched(fieldName, true);
      return false;
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema:
      check === 1
        ? validationRules1
        : check === 2
        ? validationRules2
        : check === 3 && isQualCert === true
        ? validationRulesQual
        : check === 3 && isQualCert === false
        ? validationRulesCert
        : "",
    validateOnChange: true,
  });

  console.log(formik.errors, "formik.errors");

  const handleSaveFormData = () => {
    console.log("userImage", userImage);
    Object.assign(formik.values, {
      // sUserImage: userImage,
      suserImagePreview: userImagePreview,
      slanguageData: languageData,
      sspecializationData: specializationData,
      sfitnessCategoriesData: fitnessCategoriesData,
      squalificationData: qualificationData,
      scertificationData: certificationData,
      scheck: check,
    });
    // console.log(formik.values.sUserImage);
    dispatch(setSaveTrainerData(JSON.stringify(formik.values)));
  };

  useEffect(() => {
    if (allPlan && allPlan.length > 0) {
      let selectedPlanData = allPlan.filter(
        (item) => item._id === formik.values.selectedPlan
      );
      if (selectedPlanData && selectedPlanData.length > 0) {
        console.log(selectedPlanData[0]);
        formik.setFieldValue("selectedPlanData", selectedPlanData[0]);
      } else {
        formik.setFieldValue("selectedPlanData", {});
      }
    }
  }, [formik.values.selectedPlan]);

  const handleUserImage = async (e) => {
    try {
      let imageValid = false;
      // setUserImage(e.target.files[0]);
      let userPayload = new FormData();
      if (e.target.files[0]) {
        userPayload.append("userImage", e.target.files[0]);
        imageValid = validateField("userImage", e.target.files[0]);
        console.log(imageValid, formik.values.userImage);
      }
      if (imageValid) {
        let data = await dispatch(SaveBasicProfileDetails(userPayload));
        if (data && data.code === 200) {
          renderImage(e.target.files[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getImageProfile(image) {
    if (image && image.includes("data:image")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const renderImage = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setUserImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleQualification = async () => {
    if (validateTrainerFormQual()) {
      let QData = {};
      Object.assign(QData, { id: uniqueId("q") });

      if (formik.values.qtype) {
        Object.assign(QData, { type: formik.values.qtype });
      }
      if (formik.values.qtypeName) {
        Object.assign(QData, { typeName: formik.values.qtypeName });
      }
      if (formik.values.qfieldOfStudy) {
        Object.assign(QData, { fieldOfStudy: formik.values.qfieldOfStudy });
      }
      if (formik.values.qstartDate) {
        Object.assign(QData, { startDate: formik.values.qstartDate });
      }
      if (formik.values.qendDate) {
        Object.assign(QData, { endDate: formik.values.qendDate });
      }
      // if (formik.values.qgrade) {
      //   Object.assign(QData, { grade: formik.values.qgrade });
      // }
      // if (formik.values.qactivity) {
      //   Object.assign(QData, { activity: formik.values.qactivity });
      // }
      if (formik.values.qmediaName) {
        Object.assign(QData, { mediaName: formik.values.qmediaName });
      }
      if (formik.values.qmediaType) {
        Object.assign(QData, { mediaType: formik.values.qmediaType });
      }

      if (formik.values.qmedia) {
        let file = formik.values.qmedia;
        const payload = new FormData();

        if (file) {
          payload.append("media", file);
        }

        try {
          setIsLoading(true);
          const data = await dispatch(uploadMedia(payload));
          if (data && data.code === 200) {
            Object.assign(QData, { media: data.data });
            console.log(QData);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }

      setQualificationData((state) => [...state, QData]);
      console.log(qualificationData);
      onCloseQuali();
      resetQualification();
    }
  };

  const handleCertification = async () => {
    if (validateTrainerFormCert()) {
      if (!formik.values.cmedia) {
        return;
      }
      let CData = {};
      Object.assign(CData, { id: uniqueId("c") });
      if (formik.values.ccourseName) {
        Object.assign(CData, { courseName: formik.values.ccourseName });
      }
      if (formik.values.corgName) {
        Object.assign(CData, { orgName: formik.values.corgName });
      }
      // if (formik.values.cstartDate) {
      //   Object.assign(CData, { startDate: formik.values.cstartDate });
      // }
      // if (formik.values.cendDate) {
      //   Object.assign(CData, { endDate: formik.values.cendDate });
      // }
      // if (formik.values.cgrade) {
      //   Object.assign(CData, { grade: formik.values.cgrade });
      // }
      // if (formik.values.cactivity) {
      //   Object.assign(CData, { activity: formik.values.cactivity });
      // }
      // if (formik.values.ccertificateUrl) {
      //   Object.assign(CData, { certificateUrl: formik.values.ccertificateUrl });
      // }
      if (formik.values.cmediaName) {
        Object.assign(CData, { mediaName: formik.values.cmediaName });
      }
      if (formik.values.cmediaType) {
        Object.assign(CData, { mediaType: formik.values.cmediaType });
      }

      if (formik.values.cmedia && typeof formik.values.cmedia !== "string") {
        let file = formik.values.cmedia;
        const payload = new FormData();

        if (file) {
          payload.append("media", file);
        }

        try {
          setIsLoading(true);
          const data = await dispatch(uploadMedia(payload));
          if (data && data.code === 200) {
            Object.assign(CData, { media: data.data });
            console.log(CData);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }

      setCertificationData((state) => [...state, CData]);
      console.log(certificationData);
      onCloseCert();
      resetCertification();
    }
  };

  const handleQualificationImage = async (e) => {
    // setQualificationImage(e.target.files[0]);
    await formik.setFieldValue("qmedia", e.target.files[0]);
    await formik.setFieldValue("qmediaName", e.target.files[0].name);
    await formik.setFieldValue("qmediaType", e.target.files[0].type);
    await formik.setFieldTouched("qmedia", true);
  };

  const handleCertificationImage = async (e) => {
    // setCertifiacteImage(e.target.files[0]);
    await formik.setFieldValue("cmedia", e.target.files[0]);
    await formik.setFieldValue("cmediaName", e.target.files[0].name);
    await formik.setFieldValue("cmediaType", e.target.files[0].type);
    await formik.setFieldTouched("cmedia", true);
  };

  const handleEditQual = async (id) => {
    let selectedQual = qualificationData.find((qd) => qd.id === id);

    setSelectedObject(selectedQual);
    setAddEditQual("Edit");

    await formik.setFieldValue("qtype", selectedQual.type);
    await formik.setFieldValue("qtypeName", selectedQual.typeName);
    await formik.setFieldValue("qfieldOfStudy", selectedQual.fieldOfStudy);
    await formik.setFieldValue("qstartDate", selectedQual.startDate);
    await formik.setFieldValue("qendDate", selectedQual.endDate);
    // await formik.setFieldValue("qgrade", selectedQual.grade);
    // await formik.setFieldValue("qactivity", selectedQual.activity);
    await formik.setFieldValue("qmedia", selectedQual.media);
    await formik.setFieldValue("qmediaName", selectedQual.mediaName);
    await formik.setFieldValue("qmediaType", selectedQual.mediaType);

    setIsQualCert(true);
    onOpenQuali();
  };

  const handleEditCert = async (id) => {
    let selectedCert = certificationData.find((cd) => cd.id === id);

    setSelectedObject(selectedCert);
    setAddEditCert("Edit");

    await formik.setFieldValue("ccourseName", selectedCert.courseName);
    await formik.setFieldValue("corgName", selectedCert.orgName);
    // await formik.setFieldValue("cstartDate", selectedCert.startDate);
    // await formik.setFieldValue("cendDate", selectedCert.endDate);
    // await formik.setFieldValue("cgrade", selectedCert.grade);
    // await formik.setFieldValue("cactivity", selectedCert.activity);
    // await formik.setFieldValue("ccertificateUrl", selectedCert.certificateUrl);
    // await formik.setFieldValue("cmedia", selectedCert.media);
    await formik.setFieldValue("cmedia", selectedCert.mediaName);
    await formik.setFieldValue("cmediaName", selectedCert.mediaName);
    await formik.setFieldValue("cmediaType", selectedCert.mediaType);

    setIsQualCert(false);
    onOpenCert();
  };

  const handleEditQualSubmit = async (e) => {
    if (validateTrainerFormQual()) {
      let QData = {};

      if (formik.values.qtype) {
        Object.assign(QData, { type: formik.values.qtype });
      }
      if (formik.values.qtypeName) {
        Object.assign(QData, { typeName: formik.values.qtypeName });
      }
      if (formik.values.qfieldOfStudy) {
        Object.assign(QData, { fieldOfStudy: formik.values.qfieldOfStudy });
      }
      if (formik.values.qstartDate) {
        Object.assign(QData, { startDate: formik.values.qstartDate });
      }
      if (formik.values.qendDate) {
        Object.assign(QData, { endDate: formik.values.qendDate });
      }
      // if (formik.values.qgrade) {
      //   Object.assign(QData, { grade: formik.values.qgrade });
      // }
      // if (formik.values.qactivity) {
      //   Object.assign(QData, { activity: formik.values.qactivity });
      // }
      if (formik.values.qmediaName) {
        Object.assign(QData, { mediaName: formik.values.qmediaName });
      }
      if (formik.values.qmediaType) {
        Object.assign(QData, { mediaType: formik.values.qmediaType });
      }
      if (selectedObject && selectedObject.id) {
        Object.assign(QData, { id: selectedObject.id });
      }

      if (formik.values.qmedia) {
        let file = formik.values.qmedia;
        const payload = new FormData();

        if (file) {
          payload.append("media", file);
        }

        try {
          setIsLoading(true);
          const data = await dispatch(uploadMedia(payload));
          if (data && data.code === 200) {
            Object.assign(QData, { media: data.data });
            console.log(QData);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }

      setQualificationData((prevData) =>
        prevData.map((obj) => (obj.id === selectedObject.id ? QData : obj))
      );
      console.log(qualificationData);
      onCloseQuali();
      resetQualification();
    }
  };

  const handleEditCertSubmit = async (e) => {
    if (validateTrainerFormCert()) {
      let CData = {};
      if (!formik.values.cmedia) {
        return;
      }
      if (formik.values.ccourseName) {
        Object.assign(CData, { courseName: formik.values.ccourseName });
      }
      if (formik.values.corgName) {
        Object.assign(CData, { orgName: formik.values.corgName });
      }
      // if (formik.values.cstartDate) {
      //   Object.assign(CData, { startDate: formik.values.cstartDate });
      // }
      // if (formik.values.cendDate) {
      //   Object.assign(CData, { endDate: formik.values.cendDate });
      // }
      // if (formik.values.cgrade) {
      //   Object.assign(CData, { grade: formik.values.cgrade });
      // }
      // if (formik.values.cactivity) {
      //   Object.assign(CData, { activity: formik.values.cactivity });
      // }
      // if (formik.values.ccertificateUrl) {
      //   Object.assign(CData, { certificateUrl: formik.values.ccertificateUrl });
      // }
      if (formik.values.cmediaName) {
        Object.assign(CData, { mediaName: formik.values.cmediaName });
      }
      if (formik.values.cmediaType) {
        Object.assign(CData, { mediaType: formik.values.cmediaType });
      }
      if (selectedObject && selectedObject.id) {
        Object.assign(CData, { id: selectedObject.id });
      }

      if (formik.values.cmedia && typeof formik.values.cmedia !== "string") {
        let file = formik.values.cmedia;
        const payload = new FormData();

        if (file) {
          payload.append("media", file);
        }

        try {
          setIsLoading(true);
          const data = await dispatch(uploadMedia(payload));
          if (data && data.code === 200) {
            Object.assign(CData, { media: data.data });
            console.log(CData, "formik.values.cmedia");
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        Object.assign(CData, { media: selectedObject.media });
      }
      setCertificationData((prevData) =>
        prevData.map((obj) => (obj.id === selectedObject.id ? CData : obj))
      );
      onCloseCert();
      resetCertification();
    }
  };

  const handleDeleteQual = async (id) => {
    Swal.fire({
      title: "warning",
      text: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2bc370",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setQualificationData((prev) => prev.filter((qd) => qd.id !== id));
      }
    });
  };

  const handleDeleteCert = async (id) => {
    Swal.fire({
      title: "warning",
      text: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2bc370",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCertificationData((prev) => prev.filter((cd) => cd.id !== id));
      }
    });
  };

  return (
    <div className="relative">
      <Image src={imageVector} alt="image" className="homebanner-c" />
      <section className="relative zindex9 container mx-auto lg:px-12 md:px-8 px-5 lg:py-16 md:py-16 py-6">
        <div className="lg:flex md:flex h-full ">
          <div className="lg:pr-20 md:pr-12 pr-0 lg:w-1/2 md:w-1/2 flex items-center ">
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <div className="w-full">
                <Modal isOpen={isOpenQuali} onClose={onCloseQuali}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>{addEditQual} Qualification</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <div>
                        <FormControl
                          isInvalid={
                            !!formik.errors.qtype && formik.touched.qtype
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">
                            Qualification type
                            <span className="text-danger">*</span>
                          </FormLabel>
                          <Select
                            name="qtype"
                            value={formik.values.qtype}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Select Option"
                            className="fsize16"
                          >
                            {/* <option>Certificate</option> */}
                            <option>Diploma</option>
                            <option>Degree</option>
                          </Select>
                          {/* <Input
                            name="qtype"
                            value={formik.values.qtype}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Here..."
                          /> */}
                          {formik.touched.qtype && formik.errors.qtype && (
                            <FormErrorMessage>
                              {formik.errors.qtype}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        {formik.values.qtype && (
                          <FormControl
                            isInvalid={
                              !!formik.errors.qtypeName &&
                              formik.touched.qtypeName
                            }
                            className="mb-4"
                          >
                            <FormLabel className="fsize14">
                              {formik.values.qtype} Name
                              <span className="text-danger">*</span>
                            </FormLabel>
                            <Input
                              name="qtypeName"
                              value={formik.values.qtypeName}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                              placeholder="Enter..."
                            />
                            {formik.touched.qtypeName &&
                              formik.errors.qtypeName && (
                                <FormErrorMessage>
                                  {formik.errors.qtypeName}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        )}
                        <FormControl
                          isInvalid={
                            !!formik.errors.qfieldOfStudy &&
                            formik.touched.qfieldOfStudy
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">
                            Field of Study
                          </FormLabel>
                          <Input
                            name="qfieldOfStudy"
                            value={formik.values.qfieldOfStudy}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Enter..."
                          />{" "}
                          {formik.touched.qfieldOfStudy &&
                            formik.errors.qfieldOfStudy && (
                              <FormErrorMessage>
                                {formik.errors.qfieldOfStudy}
                              </FormErrorMessage>
                            )}
                        </FormControl>

                        <div className="lg:flex w-full gap-4 md:flex mb-4">
                          <div className="lg:w-1/2 md:w-1/2">
                            <FormControl
                              isInvalid={
                                !!formik.errors.qstartDate &&
                                formik.touched.qstartDate
                              }
                            >
                              <FormLabel className="fsize14">
                                Start Date<span className="text-danger">*</span>
                              </FormLabel>
                              <Input
                                name="qstartDate"
                                value={formik.values.qstartDate}
                                onChange={formik.handleChange}
                                onBlur={handleTrim}
                                type="date"
                                placeholder="DD/MM/YYYY"
                                max={
                                  new Date(
                                    formik.values.qendDate
                                      ? formik.values.qendDate
                                      : new Date()
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
                              />
                              {formik.touched.qstartDate &&
                                formik.errors.qstartDate && (
                                  <FormErrorMessage>
                                    {formik.errors.qstartDate}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </div>
                          <div className="lg:w-1/2 md:w-1/2">
                            <FormControl
                              isInvalid={
                                !!formik.errors.qendDate &&
                                formik.touched.qendDate
                              }
                            >
                              <FormLabel className="fsize14">
                                End Date<span className="text-danger">*</span>
                              </FormLabel>
                              <Input
                                name="qendDate"
                                value={formik.values.qendDate}
                                onChange={formik.handleChange}
                                onBlur={handleTrim}
                                type="date"
                                placeholder="DD/MM/YYYY"
                                min={
                                  new Date(
                                    formik.values.qstartDate
                                      ? formik.values.qstartDate
                                      : null
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
                              />
                              {formik.touched.qendDate &&
                                formik.errors.qendDate && (
                                  <FormErrorMessage>
                                    {formik.errors.qendDate}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </div>
                        </div>

                        {/* <FormControl
                          isInvalid={
                            !!formik.errors.qgrade && formik.touched.qgrade
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">Grade</FormLabel>
                          <Input
                            name="qgrade"
                            value={formik.values.qgrade}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="grade"
                          />
                          {formik.touched.qgrade && formik.errors.qgrade && (
                            <FormErrorMessage>
                              {formik.errors.qgrade}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isInvalid={
                            !!formik.errors.qactivity &&
                            formik.touched.qactivity
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">Activity</FormLabel>
                          <Input
                            name="qactivity"
                            value={formik.values.qactivity}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Enter..."
                          />
                          {formik.touched.qactivity &&
                            formik.errors.qactivity && (
                              <FormErrorMessage>
                                {formik.errors.qactivity}
                              </FormErrorMessage>
                            )}
                        </FormControl> */}

                        <FormControl
                          isInvalid={
                            !!formik.errors.qmedia && formik.touched.qmedia
                          }
                        >
                          <FormLabel className="fsize14">
                            Media attachment(optional)
                          </FormLabel>
                          <div className="relative">
                            <InputGroup size="md">
                              <Input
                                readOnly
                                disabled
                                name="qmedia"
                                value={formik.values.qmediaName}
                                placeholder="Enter..."
                                className="cursor-pointer"
                              />
                              <InputRightElement
                                width="6.5rem"
                                className="cursor-pointer"
                              >
                                {formik.values.qmediaName ? (
                                  <h2
                                    onClick={async () => {
                                      setQualificationImage(null);
                                      await formik.setFieldValue(
                                        "qmediaName",
                                        ""
                                      );
                                      await formik.setFieldValue(
                                        "qmediaType",
                                        ""
                                      );
                                      await formik.setFieldValue("qmedia", "");
                                      await formik.setFieldTouched(
                                        "qmedia",
                                        false
                                      );
                                    }}
                                    className="cursor-pointer fsize14 text-primary font-semibold"
                                  >
                                    X
                                  </h2>
                                ) : (
                                  <div className="relative cursor-pointer">
                                    <h2 className="cursor-pointer fsize14 text-primary font-semibold">
                                      Upload
                                      <input
                                        type="file"
                                        className="uploader-input cursor-pointer opacity-0  left-0 w-full "
                                        onChange={handleQualificationImage}
                                      />
                                    </h2>
                                  </div>
                                )}
                              </InputRightElement>
                            </InputGroup>
                          </div>
                          {formik.touched.qmedia && formik.errors.qmedia && (
                            <FormErrorMessage>
                              {formik.errors.qmedia}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </div>
                    </ModalBody>

                    <ModalFooter className="mt-6">
                      <Button
                        variant="outline"
                        colorScheme="secondary"
                        mr={3}
                        onClick={onCloseQuali}
                      >
                        Close
                      </Button>
                      {addEditQual === "Add" ? (
                        <Button
                          isLoading={isLoading}
                          spinner={
                            <ThreeDots
                              visible={true}
                              color="white"
                              radius={3}
                            />
                          }
                          onClick={(e) => {
                            handleQualification(e);
                          }}
                          px="8"
                          colorScheme="primary"
                        >
                          Add
                        </Button>
                      ) : (
                        <FeatherIcon
                          isLoading={isLoading}
                          spinner={
                            <ThreeDots
                              visible={true}
                              color="white"
                              radius={3}
                            />
                          }
                          onClick={(e) => {
                            handleEditQualSubmit(e);
                          }}
                          icon="edit"
                          className="cursor-pointer"
                        />
                      )}
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                <Modal isOpen={isOpenCert} onClose={onCloseCert}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader> {addEditCert} Certification</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <div>
                        <FormControl
                          isInvalid={
                            !!formik.errors.ccourseName &&
                            formik.touched.ccourseName
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">
                            Certificate Name
                            <span className="text-danger">*</span>
                          </FormLabel>
                          <Input
                            name="ccourseName"
                            value={formik.values.ccourseName}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Enter..."
                          />
                          {formik.touched.ccourseName &&
                            formik.errors.ccourseName && (
                              <FormErrorMessage>
                                {formik.errors.ccourseName}
                              </FormErrorMessage>
                            )}
                        </FormControl>

                        <FormControl
                          isInvalid={
                            !!formik.errors.corgName && formik.touched.corgName
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">
                            Name of Organisation
                            <span className="text-danger">*</span>
                          </FormLabel>
                          <Input
                            name="corgName"
                            value={formik.values.corgName}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Enter..."
                          />
                          {formik.touched.corgName &&
                            formik.errors.corgName && (
                              <FormErrorMessage>
                                {formik.errors.corgName}
                              </FormErrorMessage>
                            )}
                        </FormControl>

                        {/* <div className="lg:flex w-full gap-4 md:flex mb-4">
                          <div className="lg:w-1/2 md:w-1/2">
                            <FormControl
                              isInvalid={
                                !!formik.errors.cstartDate &&
                                formik.touched.cstartDate
                              }
                            >
                              <FormLabel className="fsize14">
                                Start Date<span className="text-danger">*</span>
                              </FormLabel>
                              <Input
                                name="cstartDate"
                                value={formik.values.cstartDate}
                                onChange={formik.handleChange}
                                onBlur={handleTrim}
                                type="date"
                                placeholder="DD/MM/YYYY"
                                max={
                                  new Date(
                                    formik.values.cendDate
                                      ? formik.values.cendDate
                                      : new Date()
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
                              />
                              {formik.touched.cstartDate &&
                                formik.errors.cstartDate && (
                                  <FormErrorMessage>
                                    {formik.errors.cstartDate}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </div>

                          <div className="lg:w-1/2 md:w-1/2">
                            <FormControl
                              isInvalid={
                                !!formik.errors.cendDate &&
                                formik.touched.cendDate
                              }
                            >
                              <FormLabel className="fsize14">
                                End Date<span className="text-danger">*</span>
                              </FormLabel>
                              <Input
                                name="cendDate"
                                value={formik.values.cendDate}
                                onChange={formik.handleChange}
                                onBlur={handleTrim}
                                type="date"
                                placeholder="DD/MM/YYYY"
                                min={
                                  new Date(
                                    formik.values.cstartDate
                                      ? formik.values.cstartDate
                                      : null
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
                              />
                              {formik.touched.cendDate &&
                                formik.errors.cendDate && (
                                  <FormErrorMessage>
                                    {formik.errors.cendDate}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </div>
                        </div> */}

                        {/* <FormControl
                          isInvalid={
                            !!formik.errors.cgrade && formik.touched.cgrade
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">Grade</FormLabel>
                          <Input
                            name="cgrade"
                            value={formik.values.cgrade}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Grade"
                          />
                          {formik.touched.cgrade && formik.errors.cgrade && (
                            <FormErrorMessage>
                              {formik.errors.cgrade}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isInvalid={
                            !!formik.errors.cactivity &&
                            formik.touched.cactivity
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">Activity</FormLabel>
                          <Input
                            name="cactivity"
                            value={formik.values.cactivity}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Enter..."
                          />
                          {formik.touched.cactivity &&
                            formik.errors.cactivity && (
                              <FormErrorMessage>
                                {formik.errors.cactivity}
                              </FormErrorMessage>
                            )}
                        </FormControl> */}

                        {/* <FormControl
                          isInvalid={
                            !!formik.errors.ccertificateUrl &&
                            formik.touched.ccertificateUrl
                          }
                          className="mb-4"
                        >
                          <FormLabel>
                            Certfication Link
                            <span className="text-danger">*</span>
                          </FormLabel>
                          <Input
                            name="ccertificateUrl"
                            value={formik.values.ccertificateUrl}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            placeholder="Enter..."
                          />
                          {formik.touched.ccertificateUrl &&
                            formik.errors.ccertificateUrl && (
                              <FormErrorMessage>
                                {formik.errors.ccertificateUrl}
                              </FormErrorMessage>
                            )}
                        </FormControl> */}

                        <FormControl
                          isInvalid={
                            !!formik.errors.cmedia && formik.touched.cmedia
                          }
                        >
                          <FormLabel className="fsize14">
                            Media attachment
                            <span className="text-danger">*</span>
                          </FormLabel>
                          <div className="relative">
                            <InputGroup size="md">
                              <Input
                                readOnly
                                disabled
                                name="cmedia"
                                value={formik.values.cmediaName}
                                placeholder="Enter..."
                                className="cursor-pointer"
                              />
                              <InputRightElement
                                width="6.5rem"
                                className="cursor-pointer"
                              >
                                {formik.values.cmediaName ? (
                                  <h2
                                    onClick={async () => {
                                      setCertifiacteImage(null);
                                      await formik.setFieldValue(
                                        "cmediaName",
                                        ""
                                      );
                                      await formik.setFieldValue(
                                        "cmediaType",
                                        ""
                                      );
                                      await formik.setFieldValue("cmedia", "");
                                      await formik.setFieldTouched(
                                        "cmedia",
                                        false
                                      );
                                    }}
                                    className="cursor-pointer fsize14 text-primary font-semibold"
                                  >
                                    X
                                  </h2>
                                ) : (
                                  <div className="relative cursor-pointer">
                                    <h2 className="cursor-pointer fsize14 text-primary font-semibold">
                                      Upload
                                      <input
                                        type="file"
                                        className="uploader-input cursor-pointer opacity-0 left-0 w-full "
                                        onChange={handleCertificationImage}
                                      />
                                    </h2>
                                  </div>
                                )}
                              </InputRightElement>
                            </InputGroup>
                          </div>
                          {formik.touched.cmedia && formik.errors.cmedia && (
                            <FormErrorMessage>
                              {formik.errors.cmedia}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </div>
                    </ModalBody>

                    <ModalFooter className="mt-6">
                      <Button
                        variant="outline"
                        colorScheme="secondary"
                        mr={3}
                        onClick={onCloseCert}
                      >
                        Close
                      </Button>
                      {addEditCert === "Add" ? (
                        <Button
                          isLoading={isLoading}
                          spinner={
                            <ThreeDots
                              visible={true}
                              color="white"
                              radius={3}
                            />
                          }
                          onClick={(e) => {
                            handleCertification(e);
                          }}
                          px="8"
                          colorScheme="primary"
                        >
                          Add
                        </Button>
                      ) : (
                        <FeatherIcon
                          className="cursor-pointer"
                          isLoading={isLoading}
                          spinner={
                            <ThreeDots
                              visible={true}
                              color="white"
                              radius={3}
                            />
                          }
                          onClick={(e) => {
                            handleEditCertSubmit(e);
                          }}
                          icon="edit"
                        ></FeatherIcon>
                      )}
                    </ModalFooter>
                  </ModalContent>
                </Modal>

                <Line
                  percent={100 / (4 - check)}
                  strokeWidth={3}
                  trailWidth={3}
                  strokeLinecap="round"
                  strokeColor="#0fba2a"
                />

                <div className="cust-shadow p-6 text-center w-full">
                  {check === 1 && (
                    <div>
                      <div>
                        <FormControl
                          isInvalid={
                            !!formik.errors.userImage &&
                            formik.touched.userImage
                          }
                          className="mb-4"
                        >
                          <div className="bg-f4f5f6 mx-auto wh140px rounded-full flex items-center justify-center">
                            {userImagePreview ? (
                              <Image
                                src={
                                  userImagePreview
                                    ? getImageProfile(userImagePreview)
                                    : `${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`
                                }
                                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                                alt="image"
                                className="wh140px rounded-full"
                                name="userImage"
                              />
                            ) : (
                              <FeatherIcon
                                icon="camera"
                                size="40"
                                className="text-gray cursor-pointer"
                              />
                            )}
                          </div>
                          <div className="flex items-center justify-center">
                            {formik.touched.userImage &&
                              formik.errors.userImage && (
                                <FormErrorMessage>
                                  {formik.errors.userImage}
                                </FormErrorMessage>
                              )}
                          </div>
                        </FormControl>

                        <Button
                          variant="outline"
                          px="8"
                          py="5"
                          className="font-normal mt-4 relative"
                        >
                          Select from folder
                          <input
                            type="file"
                            id="userImage"
                            name="userImage"
                            accept="image/*"
                            className="opacity-0 absolute left-0 w-full "
                            onChange={async (e) => {
                              handleUserImage(e);
                              await formik.setFieldValue(
                                "userImage",
                                e.target.files[0]
                              );
                            }}
                          />
                        </Button>
                      </div>

                      {/* <Box position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  or
                </AbsoluteCenter>
              </Box> */}

                      {/* <div className="text-center">
                <FeatherIcon
                  icon="upload-cloud"
                  size="40"
                  className="text-gray cursor-pointer mx-auto"
                />
                <h2 className="mt-2">Upload from drive</h2>
              </div> */}

                      <div className="mt-8">
                        <FormControl
                          isInvalid={
                            !!formik.errors.firstName &&
                            formik.touched.firstName
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">First Name</FormLabel>
                          <Input
                            name="firstName"
                            placeholder="Enter..."
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                          />
                          {formik.touched.firstName &&
                            formik.errors.firstName && (
                              <FormErrorMessage>
                                {formik.errors.firstName}
                              </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl
                          isInvalid={
                            !!formik.errors.lastName && formik.touched.lastName
                          }
                          className="mb-4"
                        >
                          <FormLabel className="fsize14">Last Name</FormLabel>
                          <Input
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={handleTrim}
                            name="lastName"
                            placeholder="Enter..."
                          />
                          {formik.touched.lastName &&
                            formik.errors.lastName && (
                              <FormErrorMessage>
                                {formik.errors.lastName}
                              </FormErrorMessage>
                            )}
                        </FormControl>
                      </div>
                    </div>
                  )}
                  {check === 2 && (
                    <div>
                      <div className="lg:flex w-full gap-4 mb-4 md:flex">
                        {/* <div className="lg:w-1/4 md:w-1/4">
                        {" "}
                        <FormControl>
                          <FormLabel className="fsize14">
                            Country Code
                          </FormLabel>
                          <Input placeholder="+65" />
                        </FormControl>
                      </div>
                      <div className="lg:w-3/4 md:w-3/4">
                        {" "}
                        <FormControl>
                          <FormLabel className="fsize14">
                            Mobile Number{" "}
                          </FormLabel>
                          <Input placeholder="98765 43210" />
                        </FormControl>
                      </div> */}
                        <FormControl
                          isInvalid={
                            !!formik.errors.mobile && formik.touched.mobile
                          }
                        >
                          <FormLabel className="fsize14">
                            Mobile Number
                          </FormLabel>
                          <PhoneInput
                            disabled
                            name="mobile"
                            country={"sg"}
                            enableSearch={true}
                            className="mt-2 phoneinput w-full mt-2 themefamily"
                            value={userMobileCountry}
                            onChange={handlePhoneChange}
                          />
                          {formik.touched.mobile && formik.errors.mobile && (
                            <FormErrorMessage>
                              {formik.errors.mobile}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </div>

                      {/* <FormControl
                        isInvalid={
                          !!formik.errors.NRICNumber &&
                          formik.touched.NRICNumber
                        }
                        className="mb-4"
                      >
                        <FormLabel className="fsize14">NRIC Number</FormLabel>
                        <Input
                          name="NRICNumber"
                          value={formik.values.NRICNumber}
                          onChange={formik.handleChange}
                          onBlur={handleTrim}
                          placeholder="Here..."
                        />
                        {formik.touched.NRICNumber &&
                          formik.errors.NRICNumber && (
                            <FormErrorMessage>
                              {formik.errors.NRICNumber}
                            </FormErrorMessage>
                          )}
                      </FormControl> */}

                      <FormControl
                        isInvalid={
                          !!formik.errors.email && formik.touched.email
                        }
                        className="mb-4"
                      >
                        <FormLabel className="fsize14">Email</FormLabel>
                        <Input
                          name="email"
                          readOnly
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={handleTrim}
                          placeholder="Enter..."
                        />
                        {formik.touched.email && formik.errors.email && (
                          <FormErrorMessage>
                            {formik.errors.email}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <div className="lg:flex w-full gap-4 md:flex mb-4">
                        <div className="lg:w-1/2 md:w-1/2">
                          <FormControl
                            isInvalid={
                              !!formik.errors.gender && formik.touched.gender
                            }
                          >
                            <FormLabel className="fsize14">Gender</FormLabel>
                            <Select
                              name="gender"
                              value={formik.values.gender}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                              className="text-gray"
                              placeholder="Select option"
                            >
                              <option>Male</option>
                              <option>Female</option>
                              <option>Other </option>
                            </Select>
                            {formik.touched.gender && formik.errors.gender && (
                              <FormErrorMessage>
                                {formik.errors.gender}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </div>
                        <div className="lg:w-1/2 md:w-1/2">
                          <FormControl
                            isInvalid={
                              !!formik.errors.dob && formik.touched.dob
                            }
                          >
                            <FormLabel className="fsize14">
                              Date Of Birth
                            </FormLabel>
                            <Input
                              name="dob"
                              value={formik.values.dob}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                              type="date"
                              placeholder="DD/MM/YYYY"
                              max={DOBDates}
                            />
                            {formik.touched.dob && formik.errors.dob && (
                              <FormErrorMessage>
                                {formik.errors.dob}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </div>
                      </div>

                      <div className="lg:flex w-full gap-4 md:flex mb-4">
                        <div className="lg:w-1/2 md:w-1/2">
                          <FormControl
                            isInvalid={
                              !!formik.errors.language &&
                              formik.touched.language
                            }
                          >
                            <FormLabel className="fsize14">
                              Language's spoken{" "}
                            </FormLabel>
                            {/* <Select
                              name="language"
                              value={formik.values.language}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                              className="text-gray"
                              placeholder="Select option"
                            >
                              <option>English</option>
                              <option>Malay</option>
                              <option>Tamil</option>
                              <option>Mandarin</option>
                            </Select> */}
                            <Multiselect
                              name="language"
                              className={`fsize14 ${
                                formik.errors.language &&
                                formik.touched.language &&
                                "cust-error-border"
                              }`}
                              options={languageOptions}
                              selectedValues={languageData}
                              onSelect={(value) => {
                                setFieldHandler("language", value);
                                setLanguageData(value);
                              }}
                              onRemove={(value) => {
                                setFieldHandler("language", value);
                                setLanguageData(value);
                              }}
                              displayValue="name"
                            />
                            {formik.touched.language &&
                              formik.errors.language && (
                                <FormErrorMessage>
                                  {formik.errors.language}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </div>
                        <div className="lg:w-1/2 md:w-1/2">
                          <FormControl
                            isInvalid={
                              !!formik.errors.location &&
                              formik.touched.location
                            }
                          >
                            <FormLabel className="fsize14">Location</FormLabel>
                            <Input
                              value={formik.values.location}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                              name="location"
                              placeholder="Enter..."
                            />
                            {formik.touched.location &&
                              formik.errors.location && (
                                <FormErrorMessage>
                                  {formik.errors.location}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </div>
                      </div>

                      <FormControl
                        isInvalid={
                          !!formik.errors.trainingLocation &&
                          formik.touched.trainingLocation
                        }
                        className="mb-4"
                      >
                        <FormLabel className="fsize14">
                          Where do you train your clients
                        </FormLabel>
                        <Multiselect
                          name="trainingLocation"
                          className={`w-full  rounded-lg  fsize14 mt-2 cust-multiselect ${
                            formik.errors.trainingLocation &&
                            formik.touched.trainingLocation &&
                            "cust-error-border"
                          }`}
                          options={trainingLocationOptions}
                          selectedValues={formik.values.trainingLocation}
                          onSelect={async (value) => {
                            await setFieldHandler("trainingLocation", value);
                          }}
                          onRemove={async (value) => {
                            await setFieldHandler("trainingLocation", value);
                          }}
                          displayValue="name"
                        />
                        {formik.touched.trainingLocation &&
                          formik.errors.trainingLocation && (
                            <FormErrorMessage>
                              {formik.errors.trainingLocation}
                            </FormErrorMessage>
                          )}
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!formik.errors.trainingType &&
                          formik.touched.trainingType
                        }
                        className="mb-4"
                      >
                        <FormLabel className="fsize14">Training Type</FormLabel>
                        <Multiselect
                          name="trainingType"
                          className={`w-full  rounded-lg  fsize14 mt-2 cust-multiselect ${
                            formik.errors.trainingType &&
                            formik.touched.trainingType &&
                            "cust-error-border"
                          }`}
                          options={trainingTypeOptions}
                          selectedValues={formik.values.trainingType}
                          onSelect={async (value) => {
                            await setFieldHandler("trainingType", value);
                          }}
                          onRemove={async (value) => {
                            await setFieldHandler("trainingType", value);
                          }}
                          displayValue="name"
                        />
                        {formik.touched.trainingType &&
                          formik.errors.trainingType && (
                            <FormErrorMessage>
                              {formik.errors.trainingType}
                            </FormErrorMessage>
                          )}
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!formik.errors.about && formik.touched.about
                        }
                        className="mb-4"
                      >
                        <FormLabel className="fsize14">Why hire me!</FormLabel>
                        <Textarea
                          name="about"
                          value={formik.values.about}
                          onChange={formik.handleChange}
                          onBlur={handleTrim}
                          placeholder="Why hire me..."
                        />
                        {formik.touched.about && formik.errors.about && (
                          <FormErrorMessage>
                            {formik.errors.about}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!formik.errors.experience &&
                          formik.touched.experience
                        }
                        className="mb-4"
                      >
                        <FormLabel className="fsize14">Experience</FormLabel>
                        <Select
                          name="experience"
                          value={formik.values.experience}
                          onChange={formik.handleChange}
                          onBlur={handleTrim}
                          className="text-gray"
                          placeholder="Select option"
                        >
                          <option value="0 to 1 Year">0 to 1 Year</option>
                          <option value="2 to 5 Years">2 to 5 Years</option>
                          <option value="6 to 10 Years">6 to 10 Years</option>
                          <option value="11 to 15 Years">11 to 15 Years</option>
                          <option value="16 to 20 Years">16 to 20 Years</option>
                          <option value="More than 20 Year">
                            More than 20 Year
                          </option>
                        </Select>
                        {formik.touched.experience &&
                          formik.errors.experience && (
                            <FormErrorMessage>
                              {formik.errors.experience}
                            </FormErrorMessage>
                          )}
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!formik.errors.specialization &&
                          formik.touched.specialization
                        }
                        className="mb-4"
                      >
                        <FormLabel className="fsize14">
                          Specialisation
                        </FormLabel>
                        {/* <Select
                        name="specialization"
                        value={formik.values.specialization}
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                        className="text-gray"
                        placeholder="Select option"
                        multiple
                      >
                        <option>Active Aging</option>
                        <option>Corrective Exercise</option>
                        <option>Fitness</option>
                        <option>Group Fitness</option>
                        <option>Nutrition</option>
                        <option>Strength Training</option>
                        <option>Weight Management</option>
                        <option>Women's Health</option>
                        <option>Youth</option>
                      </Select> */}
                        <Multiselect
                          name="specialization"
                          className={`fsize14 ${
                            formik.errors.specialization &&
                            formik.touched.specialization &&
                            "cust-error-border"
                          }`}
                          options={specializationOptions} // Options to display in the dropdown
                          selectedValues={specializationData} // Preselected value to persist in dropdown
                          onSelect={(value) => {
                            setFieldHandler("specialization", value);
                            // let selectedValue = value.map((val) => val.name);
                            // formik.setFieldValue("specialization", value);
                            setSpecializationData(value);
                          }} // Function will trigger on select event
                          onRemove={(value) => {
                            setFieldHandler("specialization", value);
                            setSpecializationData(value);
                            // formik.setFieldValue("specialization", value);
                          }} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                        {formik.touched.specialization &&
                          formik.errors.specialization && (
                            <FormErrorMessage>
                              {formik.errors.specialization}
                            </FormErrorMessage>
                          )}
                      </FormControl>

                      <FormControl
                        isInvalid={
                          !!formik.errors.fitnessCategories &&
                          formik.touched.fitnessCategories
                        }
                      >
                        <FormLabel className="fsize14">
                          Fitness Categories
                        </FormLabel>
                        {/* <Select
                        name="fitnessCategories"
                        value={formik.values.fitnessCategories}
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                        className="text-gray"
                        placeholder="Select option"
                        multiple
                      >
                        {fitnessCategoriesOptions &&
                          fitnessCategoriesOptions.length > 0 &&
                          fitnessCategoriesOptions.map((fitCategory) => (
                            <option key={fitCategory._id}>
                              {fitCategory.categoryName}
                            </option>
                          ))}
                      </Select> */}
                        <Multiselect
                          name="fitnessCategories"
                          // value={formik.values.fitnessCategories}
                          // onChange={formik.handleChange}
                          // onBlur={handleTrim}
                          className={`fsize14 ${
                            formik.errors.fitnessCategories &&
                            formik.touched.fitnessCategories &&
                            "cust-error-border"
                          }`}
                          options={fitnessCategoriesOptions} // Options to display in the dropdown
                          selectedValues={fitnessCategoriesData} // Preselected value to persist in dropdown
                          onSelect={(value) => {
                            // let selectedValue = value.map((val) => val.name);
                            // formik.setFieldValue("specialization", value);
                            setFieldHandler("fitnessCategories", value);
                            setFitnessCategoriesData(value);
                          }} // Function will trigger on select event
                          onRemove={(value) => {
                            setFieldHandler("fitnessCategories", value);
                            setFitnessCategoriesData(value);
                            // formik.setFieldValue("specialization", value);
                          }} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                        {formik.touched.fitnessCategories &&
                          formik.errors.fitnessCategories && (
                            <FormErrorMessage>
                              {formik.errors.fitnessCategories}
                            </FormErrorMessage>
                          )}
                      </FormControl>
                    </div>
                  )}

                  {check === 3 && (
                    <div>
                      <Box>
                        <h2 className="bg-f4f5f6 p-2 font-semibold flex justify-between">
                          Qualification{" "}
                          <FeatherIcon
                            icon="plus"
                            fontSize="12px"
                            className="cursor-pointer"
                            onClick={() => {
                              resetQualification();
                              setAddEditQual("Add");
                              setIsQualCert(true);
                              onOpenQuali();
                            }}
                          />
                        </h2>

                        <div className="text-left py-4 px-2">
                          {qualificationData && qualificationData.length > 0 ? (
                            qualificationData.map((qd) => (
                              <div key={qd.id} className="flex justify-between">
                                <div className="mb-6">
                                  <h2 className="font-semibold ">
                                    {qd.typeName}
                                  </h2>
                                  <p className="fsize14">{qd.type}</p>
                                  <p className="text-gray fsize14">
                                    {qd.startDate} - {qd.endDate}
                                  </p>
                                </div>
                                <div className="flex mb-6 items-center gap-2">
                                  <FeatherIcon
                                    onClick={() => handleEditQual(qd.id)}
                                    icon="edit"
                                    size={18}
                                    className="cursor-pointer"
                                  />

                                  <FeatherIcon
                                    icon="trash-2"
                                    size={18}
                                    className="cursor-pointer text-primary"
                                    onClick={() => handleDeleteQual(qd.id)}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <div>No Qualification Added</div>
                          )}
                        </div>
                      </Box>

                      <Box>
                        <h2 className="bg-f4f5f6 p-2 font-semibold flex justify-between">
                          Certification
                          <FeatherIcon
                            icon="plus"
                            fontSize="12px"
                            className="cursor-pointer"
                            onClick={() => {
                              resetCertification();
                              setAddEditCert("Add");
                              setIsQualCert(false);
                              onOpenCert();
                            }}
                          />
                        </h2>

                        <div className="text-left py-4 px-2">
                          {certificationData && certificationData.length > 0 ? (
                            certificationData.map((cd) => (
                              <div key={cd.id} className="flex justify-between">
                                <div className="mb-4">
                                  <h2 className="font-semibold">
                                    {cd.orgName}
                                  </h2>
                                  <a
                                    target="_blank"
                                    href={cd.media ? getImage(cd.media) : ""}
                                    className="text-gray fsize14 underline flex gap-2"
                                  >
                                    {cd.courseName}
                                    <ExternalLinkIcon
                                      w={4}
                                      h={4}
                                      color="black"
                                    />
                                  </a>
                                </div>
                                <div className="flex mb-4 gap-2">
                                  <FeatherIcon
                                    icon="edit"
                                    size={18}
                                    className="cursor-pointer"
                                    onClick={() => handleEditCert(cd.id)}
                                  />

                                  <FeatherIcon
                                    icon="trash-2"
                                    size={18}
                                    className="cursor-pointer text-primary"
                                    onClick={() => handleDeleteCert(cd.id)}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <div>No Certification Added</div>
                          )}
                        </div>
                      </Box>

                      {/* <Box className="sdddd"> */}
                      {/* <h2 className="bg-f4f5f6 p-2 mb-2 items-center font-semibold flex justify-between">
                          Subscription
                          <a
                            className="cursor-pointer underline"
                            href="#planDetails"
                          >
                            <span className="flex items-center fsize14 sm-fsize12 gap-2">
                              Plan Details
                              <FeatherIcon
                                icon="info"
                                size={18}
                                className="cursor-pointer text-warning"
                              />
                            </span>
                          </a>
                        </h2> */}

                      {/* <div className="text-left pt-4 pb-2 px-2">
                          <FormControl
                            isInvalid={
                              !!formik.errors.selectedPlan &&
                              formik.touched.selectedPlan
                            }
                            className="mb-4"
                          >
                            <FormLabel className="fsize14">
                              Select plan
                            </FormLabel>
                            <Select
                              isDisabled={
                                transactionData &&
                                transactionData.transactionStatus === "Success"
                              }
                              name="selectedPlan"
                              value={formik.values.selectedPlan}
                              onChange={formik.handleChange}
                              className="text-gray"
                              placeholder="Select option"
                            >
                              {allPlan &&
                                allPlan.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                            </Select>
                            {formik.touched.selectedPlan &&
                              formik.errors.selectedPlan && (
                                <FormErrorMessage>
                                  {formik.errors.selectedPlan}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </div> */}

                      {/* {
                          // transactionData &&
                          //   transactionData.transactionStatus !==
                          //     "Success"
                          formik.values.selectedPlanData &&
                          formik.values.selectedPlanData._id ? (
                            <div className="bg-f4f5f6 px-2 py-3 font-semibold items-center flex justify-between">
                              <p className="fsize14 font-semibold">
                                {formik.values.selectedPlanData.name}{" "}
                                {(!transactionData ||
                                  (transactionData &&
                                    transactionData.transactionStatus !==
                                      "Success")) && (
                                  <span
                                    onClick={onOpenPlan}
                                    className="cursor-pointer text-primary font-semibold underline"
                                  >
                                    Change Plan
                                  </span>
                                )}
                              </p>
                              <a
                                className="cursor-pointer underline"
                                href="#planDetails"
                              >
                                <FeatherIcon
                                  icon="check-circle"
                                  size={18}
                                  className="cursor-pointer text-success"
                                />
                              </a>
                            </div>
                          ) : (
                            <div className="bg-f4f5f6 px-2 py-3 font-semibold items-center flex gap-4 justify-between">
                              <p className="fsize14 text-left font-semibold">
                                Please Select your Subscription Plan{" "}
                                <span
                                  onClick={onOpenPlan}
                                  className="cursor-pointer text-primary font-semibold underline"
                                >
                                  Click Here
                                </span>
                              </p>
                              <a
                                className="cursor-pointer underline"
                                href="#planDetails"
                              >
                                <FeatherIcon
                                  icon="info"
                                  size={18}
                                  className="cursor-pointer text-warning"
                                />
                              </a>
                            </div>
                          )
                        } */}
                      {/* <div className="is_modalwidth">
                          <Modal
                            isOpen={isOpenPlan}
                            onClose={onClosePlan}
                            size="xl"
                          >
                            <ModalOverlay />
                            <ModalContent maxW="1200px"> */}
                      {/* <ModalHeader></ModalHeader> */}
                      {/* <ModalCloseButton /> */}
                      {/* <ModalBody padding="0">
                                <div className="w-full">
                                  <ModalCloseButton color="white" />
                                  <div className="bggradient-mid w-full flex justify-center items-center h-48">
                                    <p className="text-white fsize32 md-fsize24 sm-fsize18 uppercase font-semibold">
                                      Get listed in coach community
                                    </p>
                                  </div>
                                  <Subscription
                                    Becomeacoach={true}
                                    setFieldHandler={setFieldHandler}
                                    isPayment={false}
                                    onClosePlan={onClosePlan}
                                    selectedPlan={
                                      formik.values.selectedPlanData
                                    }
                                  />
                                </div>
                              </ModalBody>
                            </ModalContent>
                          </Modal>
                        </div> */}
                      {/* </Box> */}

                      {/* <div className="pb-4 px-2">
                        {formik.values.selectedPlanData &&
                          formik.values.selectedPlanData._id && (
                            <> */}
                      {/* <div>Selected Plan</div> */}
                      {/* <div className="flex justify-between">
                                <div>
                                  <b>Plan:</b>{" "}
                                  {formik.values.selectedPlanData.name
                                    ? formik.values.selectedPlanData.name
                                    : "-"}
                                </div>
                                <div>
                                  <b>Price:</b>{" "}
                                  {formik.values.selectedPlanData.price
                                    ? `S$ ${formik.values.selectedPlanData.price}`
                                    : "-"}
                                </div>
                              </div> */}
                      {/* <div
                                onClick={() => {
                                  console.log("payment");
                                  handleSaveFormData();
                                }}
                                className="mt-2"
                              >
                                <PaymentCheckout
                                  button={"Subscribe"}
                                  buttonClass={"font-semibold fsize14"}
                                  buttonColor={"primary"}
                                  itemList={getItemList(
                                    formik.values.selectedPlanData
                                  )}
                                  planId={formik.values.selectedPlanData._id}
                                  userId={User._id}
                                  successUrl={"becameacoach"}
                                  cancelUrl={"becameacoach"}
                                  isDisabled={
                                    transactionData &&
                                    transactionData.transactionStatus ===
                                      "Success"
                                  }
                                ></PaymentCheckout>
                              </div> */}
                      {/* {transactionData && transactionData._id && (
                                <div className="mt-2">
                                  <div>
                                    Transaction Status:{" "}
                                    {transactionData.transactionStatus ===
                                    "Success"
                                      ? "Success"
                                      : "Failed"}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                      </div> */}
                    </div>
                  )}

                  <div
                    className={
                      check === 1
                        ? "flex justify-end mt-6"
                        : "flex justify-between mt-6"
                    }
                  >
                    {check !== 1 ? (
                      <Button
                        onClick={handlePrevious}
                        variant="outline"
                        colorScheme="secondary"
                        px="8"
                        py="6"
                      >
                        {" "}
                        Previous
                      </Button>
                    ) : (
                      ""
                    )}
                    {check !== 3 ? (
                      <Button
                        onClick={handleNext}
                        colorScheme="primary"
                        px="8"
                        py="6"
                      >
                        Next
                      </Button>
                    ) : (
                      <>
                        {/* {!transactionData ||
                        transactionData.transactionStatus !== "Success" ? (
                          <div
                            onClick={() => {
                              console.log("payment");
                              handleSaveFormData();
                            }}
                          >
                            <PaymentCheckout
                              button={"Subscribe"}
                              buttonClass={"font-semibold "}
                              buttonColor={"primary"}
                              itemList={getItemList(
                                formik.values.selectedPlanData
                              )}
                              planId={formik.values.selectedPlanData._id}
                              userId={User._id}
                              successUrl={"becameacoach"}
                              cancelUrl={"becameacoach"}
                              isDisabled={
                                !formik.values.selectedPlanData._id ||
                                transactionData.transactionStatus === "Success"
                              }
                            ></PaymentCheckout>
                          </div>
                        ) : ( */}
                        <Button
                          // isDisabled={
                          //   !transactionData ||
                          //   transactionData.transactionStatus !== "Success" ||
                          //   (User && User.trainerId)
                          // }
                          onClick={handleOnSubmit}
                          colorScheme="primary"
                          px="12"
                          py="6"
                        >
                          Submit
                        </Button>
                        {/* )} */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div
            id="1440723315"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 lg:flex md:flex hidden justify-end w-full"
          >
            <Image
              src={data ? getImage(data["1440723315"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className=" w-full sm-h250px minh450px object-contain"
            />
          </div>
        </div>
      </section>
      <Image src={imageVectorBottom} alt="image" className="homevectorbottom" />
    </div>
  );
};

export default BecameCoachForm;
