import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ToggleAccount,
  generateOTP,
  loginwithPassword,
} from "../../../redux/auth/auth-action";
import {
  Text,
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Divider,
  AbsoluteCenter,
  Box,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import FeatherIcon from "feather-icons-react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useDispatch, useSelector } from "react-redux/es/exports";
// import OtherAuth from "../../auth/OtherAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { getMetaByPageName } from "../../../redux/cms/cms-actions";
import SEO from "../../../cms/SEO";
import { toast } from "react-toastify";

const Login = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [metaData, setMetaData] = useState({});
  const [OTP, setOTP] = useState("");
  const [isActivating, setIsActivating] = useState(false);

  //===========SEO============
  // useEffect(() => {
  //   const getSEO = async () => {
  //     let data = await dispatch(getMetaByPageName({ pageName: "Login" }));
  //     if (data.code === 200) {
  //       setMetaData(data.data);
  //     }
  //   };
  //   getSEO();
  // }, []);
  //===========SEO============

  const initialValues = {
    email: "",
    password: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
      password: values.password,
    };
    try {
      let data = await dispatch(loginwithPassword(payload));

      if (data && data.code == 200) {
        formik.handleReset();
        if (
          data.data.userAuth &&
          data.data.userAuth.role === "TrainerApplicant" &&
          data.data.userAuth.planId &&
          data.data.userAuth.subscriptionId &&
          !data.data.userAuth.trainerId
        ) {
          // Swal.fire({
          //   title: "Success",
          //   text: `Please fill trainer form`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.warning(`Please fill trainer form`);
          navigate("/becameacoach");
        } else if (
          data.data.userAuth &&
          data.data.userAuth.role === "Trainer"
        ) {
          toast.success(`Welcome back, ${data.data.userAuth.firstName}`);
          navigate("/user-profile");
        } else {
          // Swal.fire({
          //   title: "Success",
          //   text: `Welcome back ${data.data.userAuth.firstName}`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success(`Welcome back, ${data.data.userAuth.firstName}`);
          navigate("/");
        }
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
          if (data.message === "Account is deactivated" && data.code === 401) {
            await GetEmailOTP();
          }
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const ActivateOtp = async () => {
    try {
      console.log("ActivateOtp");
      if (!formik.values.password) {
        return;
      }

      let payload = {
        email: formik.values.email.toLowerCase(),
        password: formik.values.password,
        otp: OTP,
      };

      let data = await dispatch(ToggleAccount(payload));
      if (data && data.code == 200) {
        setOTP("");
        // navigate("/login");
        toast.success(`Account activated successfully`);
        await handleOnSubmit(formik.values);
      } else {
        if (data.message) {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const GetEmailOTP = async () => {
    // let emailValid = validateField("email", formik.values.email);
    // let mobileValid = validateField("mobile", formik.values.mobile);
    if (!formik.values.email) {
      return;
    }
    let payload = {
      // mobile: formik.values.mobile,
      email: formik.values.email.toLowerCase(),
      type: "AccountActivation",
    };

    try {
      // setIsActivating(true);
      let data = await dispatch(generateOTP(payload));
      if (data && data.code === 200) {
        toast.success("OTP successfully sent to your email ID");
        setIsActivating(true);
      } else {
        if (data.message) {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <>
      <SEO metaData={metaData} />

      <section className="container mx-auto lg:h-screen md:h-screen ">
        <div className="lg:flex md:flex items-center h-full lg:p-0 md:p-0 p-6">
          <div
            id="4805490279"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 lg:block md:block hidden"
          >
            <Image
              maxH="600"
              src={data ? getImage(data["4805490279"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              alt="loginImage"
              className="m-auto"
            />
          </div>
          <div className="smcust-shadow lg:w-1/2 md:w-1/2 lg:py-4 md:py-4 py-8 lg:px-12 md:px-6 px-5 ">
            <div>
              <h1
                fontSize="4xl"
                id="4130656747"
                className="dynamicStyle text-center lg:text-4xl md:text-2xl text-2xl font-semibold"
              >
                {data ? data["4130656747"] : "LOGIN"}
              </h1>

              <p className=" lg:text-base md:text-base text-sm text-center lg:mt-4 md:mt-4 mt-2">
                <span
                  id="1084915749"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["1084915749"] : "no-data",
                  }}
                  className="dynamicStyle text333 opacity-70"
                ></span>{" "}
                <span className="text-black hov-underline cursor-pointer font-semibold">
                  <Link to="/user-agreement"> User Agreement</Link>
                </span>{" "}
                and{" "}
                <span className="text-black cursor-pointer hov-underline font-semibold">
                  <Link to="/privacy-policy">Privacy policy</Link>
                </span>{" "}
              </p>

              <form onSubmit={formik.handleSubmit}>
                <div className="lg:mt-16 md:mt-8 mt-8">
                  <div className="lg:mb-6 md:mb-4 mb-4">
                    <h3
                      id="7829710743"
                      className="dynamicStyle fsize18 sm-fsize14  text333 font-semibold"
                    >
                      {data ? data["7829710743"] : "Email Address"}
                    </h3>
                    <FormControl
                      isInvalid={!!formik.errors.email && formik.touched.email}
                    >
                      <Input
                        disabled={isActivating}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                        className="mt-2 fsize14"
                        placeholder="Enter your email address"
                      />
                      {formik.touched.email && formik.errors.email && (
                        <FormErrorMessage>
                          {formik.errors.email}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </div>

                  <div>
                    <h3
                      id="9378435345"
                      fontSize="xl"
                      className="dynamicStyle fsize18  sm-fsize14 text333 font-semibold"
                    >
                      {data ? data["9378435345"] : "Password"}
                    </h3>
                    <FormControl
                      isInvalid={
                        !!formik.errors.password && formik.touched.password
                      }
                    >
                      <InputGroup>
                        <Input
                          disabled={isActivating}
                          autoComplete={false}
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={handleTrim}
                          pr="4.5rem"
                          type={show ? "text" : "password"}
                          className="mt-2 fsize14"
                          placeholder="Enter your password"
                        />
                        <InputRightElement paddingTop="5">
                          <div
                            onClick={() => {
                              handleClick();
                            }}
                          >
                            {" "}
                            {show ? (
                              <FeatherIcon
                                icon="eye"
                                size={16}
                                onClick={() => {
                                  handleClick();
                                }}
                              />
                            ) : (
                              <FeatherIcon
                                size={16}
                                icon="eye-off"
                                onClick={() => {
                                  handleClick();
                                }}
                              />
                            )}
                          </div>
                        </InputRightElement>{" "}
                      </InputGroup>
                      {formik.touched.password && formik.errors.password && (
                        <FormErrorMessage>
                          {formik.errors.password}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </div>
                  {isActivating ? (
                    <>
                      <p className="mt-4 fsize14">
                        Please enter the OTP sent on your email to re-activate
                        your account.
                      </p>
                      <div className="pt-2">
                        <OTPInput
                          name="otp"
                          value={OTP}
                          onChange={(e) => {
                            setOTP(e);
                          }}
                          OTPLength={6}
                          otpType="number"
                          className="numb-otp"
                          inputclassName="themefamily"
                        />
                        <div className="mt-2">
                          <ResendOTP onResendClick={() => GetEmailOTP()} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="pt-4">
                      <span className="fsize15 custcolor underline cursor-pointer">
                        <Link to="/forgot password">Forgot Password?</Link>
                      </span>
                    </div>
                  )}
                  {isActivating ? (
                    <div>
                      <Button
                        isDisabled={OTP.toString().length === 6 ? false : true}
                        colorScheme="primary"
                        className="w-full mt-8 sm-fsize16px"
                        size="lg"
                        onClick={() => ActivateOtp()}
                      >
                        Activate
                      </Button>
                    </div>
                  ) : (
                    <div id="6896927244" className="dynamicStyle">
                      <Button
                        colorScheme="primary"
                        className="w-full mt-8 sm-fsize16px"
                        size="lg"
                        type="submit"
                      >
                        {/* onClick={handleLoginSubmit} */}{" "}
                        {data ? data["6896927244"] : "Login"}
                      </Button>
                    </div>
                  )}
                </div>
              </form>

              <p className=" lg:text-base md:text-base text-sm text-center mt-4">
                <span id="5028680478" className="dynamicStyle">
                  {" "}
                  {data ? data["5028680478"] : "Create a New Account?"}
                </span>
                <span className="text-black hov-underline cursor-pointer font-semibold pl-2">
                  <Link to="/register">Register</Link>
                </span>{" "}
              </p>
              {/* 
              <Box className="sm-px-30px" position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  <p id="8012950153" className="dynamicStyle">
                    {" "}
                    {data ? data["8012950153"] : "or"}
                  </p>
                </AbsoluteCenter>
              </Box>

              <OtherAuth /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
