import { Button } from '@chakra-ui/react';
import React from 'react';

const AboutSection = () => {
  return (
    <section>
      <div className="text-center w-1/2 mx-auto lg:mb-16">
        <h3 className="letter-spacing4px fsize18 font-semibold clr-ddd9d8 lg:mb-2">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold lg:mb-8">Lorem Ipsum</h1>
        <p className="fsize18">
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful content.
        </p>
      </div>
      <div className="container mx-auto lg:mt-4">
        <div className="w-full h-400px ">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            alt="section2"
            className="br-28px bg-ddd9d8 w-full h-full object-cover"
          />
        </div>
        <div className="lg:mt-12 flex justify-center">
          <Button colorScheme="primary" className="rounded-sm  fsize14">
            Enquire Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
