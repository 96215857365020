import axios from "axios";
import { store } from "../../redux/store";
import { userLogout, logout } from "../../redux/auth/auth-action";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
  baseURL,
  headers: {
    "community-name": process.env.REACT_APP_COMMUNITY_NAME,
    accept: "application/json",
  },
});

API.interceptors.request.use(
  (config) => {
    let token = "";
    if (
      store.getState() &&
      store.getState().AuthDetails &&
      store.getState().AuthDetails.user &&
      store.getState().AuthDetails.user.token
    ) {
      token = store.getState().AuthDetails.user.token;
    }
    const cmsToken = localStorage.getItem("cmsToken");

    if (cmsToken) {
      config.headers["Cms-Authorization"] = `Bearer ${cmsToken}`;
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error.response.data);
    if (
      error.response.data.code == 401 &&
      error.response.data.message == "No authorization token was found"
    ) {
      console.log("logout");
      // Swal.fire({
      //   // title: "Session Expired",
      //   text: "Please Login",
      //   icon: "warning",
      //   confirmButtonColor: "#ed1b24",
      //   showConfirmButton: true,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     window.location.href = "/login";
      //   }
      // });
      toast.warning("Please login");
      await store.dispatch(
        logout({ email: store.getState().AuthDetails.user.email })
      );
      // userLogout();
      window.location.href = "/login";
    }
    if (store.getState().AuthDetails.user.mobile) {
      const user = store.getState().AuthDetails.user;

      if (
        error.response.data.code == 401 &&
        (error.response.data.message == "Unauthorized" ||
          error.response.data.message == "jwt expired" ||
          error.response.data.name == "Unauthorized")
      ) {
        console.log("logout");
        // Swal.fire({
        //   title: "Session Expired",
        //   text: "Your session has ended and for security reasons, we request you to login again",
        //   icon: "warning",
        //   confirmButtonColor: "#ed1b24",
        //   showConfirmButton: true,
        // }).then(async (result) => {
        //   if (result.isConfirmed) {
        //     if (user && user.mobile) {
        //       await store.dispatch(userLogout({ mobile: user.mobile }));
        //     }
        //     window.location.href = "/login";
        //   }
        // });
        toast.warning("Session Expired: we request you to login again");
        await store.dispatch(
          logout({ email: store.getState().AuthDetails.user.email })
        );
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default API;
