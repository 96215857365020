import { generateOTP, forgetPassword } from "../../../redux/auth/auth-action";
import {
  Text,
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Divider,
  AbsoluteCenter,
  Box,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import OtherAuth from "../../auth/OtherAuth";

import { useFormik } from "formik";
import * as Yup from "yup";

import { getMetaByPageName } from "../../../redux/cms/cms-actions";
import SEO from "../../../cms/SEO";

import { toast } from "react-toastify";

const ForgotPassword = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [confirmShow, setConfirmShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleShowPass = () => setConfirmShow(!confirmShow);
  const [isShowEmailOTP, SetShowEmailOTP] = useState(false);
  const [metaData, setMetaData] = useState({});

  //===========SEO============
  // useEffect(() => {
  //   const getSEO = async () => {
  //     let data = await dispatch(
  //       getMetaByPageName({ pageName: "Forgot password" })
  //     );
  //     if (data.code === 200) {
  //       setMetaData(data.data);
  //     }
  //   };
  //   getSEO();
  // }, []);
  //===========SEO============

  const validateField = (fieldName, value) => {
    try {
      validationRules.fields[fieldName].validateSync(value, {
        abortEarly: false,
      });

      formik.setFieldError(fieldName, undefined);
      formik.setFieldTouched(fieldName, true);

      return true;
    } catch (error) {
      formik.setFieldError(fieldName, error.errors[0]);
      formik.setFieldTouched(fieldName, true);
      return false;
    }
  };

  const GetEmailOTP = async () => {
    let emailValid = validateField("email", formik.values.email);
    if (!emailValid) {
      return;
    }

    let payload = {
      email: formik.values.email.toLowerCase(),
      type: "ForgotPassword",
    };

    try {
      let data = await dispatch(generateOTP(payload));

      if (data && data.code === 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: "OTP successfully send to your email ID",
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success("OTP successfully sent to your email ID");
        SetShowEmailOTP(true);
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const initialValues = {
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  };

  const validationRules = Yup.object({
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password"), null], "Password must match"),
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP number must be 6 digits"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: values.email.toLowerCase(),
      otp: values.otp,
      password: values.password,
    };

    try {
      let data = await dispatch(forgetPassword(payload));
      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Congratulations",
        //   text: "Your password has been successfully updated.",
        //   icon: "success",
        // });
        toast.success("Your password has been successfully updated.");

        formik.handleReset();
        navigate("/login");
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = async (event) => {
    await formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <>
      <SEO metaData={metaData} />
      <section className="container mx-auto h-screen  ">
        <div className="flex items-center h-full lg:p-0 md:p-0 p-6">
          <div
            id="4500491604"
            className="lg:block md:block hidden dynamicStyle2 lg:w-1/2 md:w-1/2"
          >
            <Image
              maxH="600"
              src={data ? getImage(data["4500491604"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              alt="loginImage"
              className="m-auto"
            />
          </div>
          <div className="smcust-shadow lg:w-1/2 md:w-1/2 lg:py-8 md:py-8 py-8 lg:px-12 md:px-6 px-5 ">
            <div>
              <p
                id="8517390691"
                className="dynamicStyle lg:text-3xl md:text-2xl text-2xl text-center font-semibold"
              >
                {" "}
                {data ? data["8517390691"] : "Forgot Password"}
              </p>

              <p className="  lg:text-base md:text-base text-sm text-center lg:mt-4 md:mt-4 mt-2">
                <span
                  id="6824818838"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["6824818838"] : "no-data",
                  }}
                  className="dynamicStyle text333 opacity-70"
                ></span>{" "}
                <span className="text-black underline cursor-pointer font-semibold">
                  <Link to="/user-agreement"> User Agreement</Link>
                </span>{" "}
                and{" "}
                <span className="text-black cursor-pointer underline font-semibold">
                  <Link to="/privacy-policy"> Privacy policy</Link>
                </span>{" "}
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="lg:mt-16 md:mt-8 mt-8">
                  <div className="mb-6">
                    <h3
                      id="2273171631"
                      className="dynamicStyle fsize16 sm-fsize14 text333 font-semibold"
                    >
                      {data ? data["2273171631"] : "Email Address"}
                    </h3>
                    <FormControl
                      isInvalid={!!formik.errors.email && formik.touched.email}
                    >
                      <InputGroup>
                        <Input
                          name="email"
                          className="mt-2 fsize14"
                          placeholder="Enter your email address"
                          pr="5.5rem"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={handleTrim}
                        />
                        {!isShowEmailOTP && (
                          <InputRightElement
                            pr="0rem"
                            width="6.5rem"
                            paddingTop="4"
                          >
                            <Button h="1.75rem" size="sm" onClick={GetEmailOTP}>
                              Send OTP
                            </Button>
                          </InputRightElement>
                        )}
                      </InputGroup>

                      {formik.touched.email && formik.errors.email && (
                        <FormErrorMessage>
                          {formik.errors.email}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    {isShowEmailOTP && (
                      <div className="mt-4 resendotp-t">
                        <FormControl
                          isInvalid={!!formik.errors.otp && formik.touched.otp}
                        >
                          <OTPInput
                            name="otp"
                            value={formik.values.otp}
                            onChange={(e) => {
                              formik.setFieldValue("otp", e);
                            }}
                            OTPLength={6}
                            otpType="number"
                            className="numb-otp"
                            inputclassName="themefamily"
                          />
                          {formik.touched.otp && formik.errors.otp && (
                            <FormErrorMessage>
                              {formik.errors.otp}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <div className="mt-2">
                          <ResendOTP onResendClick={() => GetEmailOTP()} />
                        </div>
                      </div>
                    )}
                  </div>
                  {formik.values.otp &&
                    formik.values.otp.toString().length === 6 &&
                    isShowEmailOTP && (
                      <div className="lg:flex md:flex gap-4">
                        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-6">
                          <h3
                            id="9263675044"
                            className="dynamicStyle fsize16 sm-fsize14 text333 font-semibold"
                          >
                            {data ? data["9263675044"] : "Password"}
                          </h3>
                          <FormControl
                            isInvalid={
                              !!formik.errors.password &&
                              formik.touched.password
                            }
                          >
                            <InputGroup>
                              <Input
                                name="password"
                                autoComplete={false}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={handleTrim}
                                pr="4.5rem"
                                type={show ? "text" : "password"}
                                className="mt-2 fsize14"
                                placeholder="Enter your password"
                              />
                              <InputRightElement paddingTop="4">
                                <div
                                  onClick={() => {
                                    handleClick();
                                  }}
                                >
                                  {" "}
                                  {show ? (
                                    <FeatherIcon
                                      icon="eye"
                                      size={16}
                                      onClick={() => {
                                        handleClick();
                                      }}
                                    />
                                  ) : (
                                    <FeatherIcon
                                      size={16}
                                      icon="eye-off"
                                      onClick={() => {
                                        handleClick();
                                      }}
                                    />
                                  )}
                                </div>
                              </InputRightElement>{" "}
                            </InputGroup>
                            {formik.touched.password &&
                              formik.errors.password && (
                                <FormErrorMessage>
                                  {formik.errors.password}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </div>

                        <div className="lg:w-1/2 md:w-1/2 w-full">
                          <div>
                            <h3
                              id="1769083815"
                              className="dynamicStyle fsize16 sm-fsize14 text333 font-semibold"
                            >
                              {data
                                ? data["1769083815"]
                                : "    Confirm Password"}
                            </h3>
                            <FormControl
                              isInvalid={
                                !!formik.errors.confirmPassword &&
                                formik.touched.confirmPassword
                              }
                            >
                              <InputGroup>
                                <Input
                                  autoComplete={false}
                                  name="confirmPassword"
                                  value={formik.values.confirmPassword}
                                  onChange={formik.handleChange}
                                  onBlur={handleTrim}
                                  pr="4.5rem"
                                  type={confirmShow ? "text" : "password"}
                                  className="mt-2 fsize14"
                                  placeholder="Enter your password"
                                />
                                <InputRightElement paddingTop="4">
                                  <div
                                    onClick={() => {
                                      handleShowPass();
                                    }}
                                  >
                                    {" "}
                                    {confirmShow ? (
                                      <FeatherIcon
                                        icon="eye"
                                        size={16}
                                        onClick={() => {
                                          handleShowPass();
                                        }}
                                      />
                                    ) : (
                                      <FeatherIcon
                                        size={16}
                                        icon="eye-off"
                                        onClick={() => {
                                          handleShowPass();
                                        }}
                                      />
                                    )}
                                  </div>
                                </InputRightElement>{" "}
                              </InputGroup>
                              {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword && (
                                  <FormErrorMessage>
                                    {formik.errors.confirmPassword}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}
                  <div id="5710125758" className="dynamicStyle">
                    <Button
                      isDisabled={
                        !(
                          formik.values.otp &&
                          formik.values.otp.toString().length === 6 &&
                          formik.values.password &&
                          formik.values.confirmPassword
                        )
                      }
                      type="submit"
                      colorScheme="primary"
                      className="w-full mt-8 sm-fsize16px"
                      size="lg"
                    >
                      {data ? data["5710125758"] : "Submit"}
                    </Button>
                  </div>
                </div>
              </form>
              <p className=" lg:text-base md:text-base text-sm text-center mt-4">
                <span id="4960003500" className="dynamicStyle">
                  {" "}
                  {data ? data["4960003500"] : "    Already have an Account?"}
                </span>
                <span className="text-black hov-underline cursor-pointer font-semibold pl-2">
                  <Link to="/login">Login</Link>
                </span>{" "}
              </p>

              {/* <Box className="sm-px-30px" position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  <p id="1801691592" className="dynamicStyle">
                    {" "}
                    {data ? data["1801691592"] : "or"}
                  </p>
                </AbsoluteCenter>
              </Box> */}

              {/* <OtherAuth /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
