const initialState = {
  Emoji: {},
};

const Emoji = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EMOJI":
      return { ...state, Emoji: action.payload };
    default:
      return state;
  }
};

export default Emoji;
