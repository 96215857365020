import API from "../../repositoryFactory";

const createPost = "create";
const getPost = "get";
const updatePost = "update";
const deletePost = "delete";
const updatePollVote = "updatePollVote";

const postRoute = (route) => {
  return `fit2go/social/post/${route}`;
};

export default {
  createPost(payload) {
    return API.post(postRoute(createPost), payload);
  },
  getPost(payload) {
    return API.post(postRoute(getPost), payload);
  },
  updatePost(payload) {
    return API.post(postRoute(updatePost), payload);
  },
  deletePost(payload) {
    return API.post(postRoute(deletePost), payload);
  },
  updatePollVote(payload) {
    return API.post(postRoute(updatePollVote), payload);
  },
};
