import React, { useEffect, useState } from "react";
import { Image } from "@chakra-ui/react";
import { getTestimonial } from "redux/custom/testimonial/testimonial-actions";
import imageVector from "assets/images/custom/Vector5.png";
import FeatherIcon from "feather-icons-react";
import "swiper/css";
import Fade from "react-reveal/Fade";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";

import { EffectCoverflow, Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Rating from "react-rating";
const Testimonial5 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const [selectedTestimonial, SetSelectedTestimonial] = useState({});
  let testimonal = useSelector((state) => state.testimonial.Testimonials);
  console.log(testimonal, "text");
  // const ratingChanged = (newRating) => {
  //   console.log(newRating);
  // };
  const handleSelectedTestimonal = (e) => {
    SetSelectedTestimonial(e);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestimonial());
    if (testimonal && testimonal.result && testimonal.result.length > 0) {
      SetSelectedTestimonial(testimonal.result[0]);
    }
  }, []);

  return (
    <section className="bggradient-mid relative testimonial lg:pb-8 md:pb-8 pb-8">
      <Image src={imageVector} alt="image" className="testimonaltop" />
      <div className=" container mx-auto px-5 lg:py-16 md:py-16 py-8 lg:px-12 md:px-8">
        <div className="text-center lg:mb-12 md:mb-6 mb-6 lg:mt-0 md:mt-0 mt-8">
          <h2
            id="9466016868"
            className="dynamicStyle text-white lg:text-4xl text-2xl md:text-3xl font-semibold"
          >
            {data ? data["9466016868"] : "  CLIENT SUCCESS STORIES"}
          </h2>
          <p
            id="4102193340"
            dangerouslySetInnerHTML={{
              __html: data ? data["4102193340"] : "no-data",
            }}
            className="dynamicStyle mt-4 text-white"
          ></p>
        </div>

        <div>
          <Swiper
            spaceBetween={20}
            effect={"coverflow"}
            modules={[EffectCoverflow, Navigation]}
            autoplay={{
              delay: "1000",
            }}
            navigation={{
              nextEl: ".test-swiper-button-next",
              prevEl: ".test-swiper-button-prev",
            }}
            className="mySwiper"
            grabCursor={true}
            centeredSlides={false}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              820: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              325: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              300: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
            }}
          >
            <div className="swiper-button  test-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button test-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            {testimonal &&
              testimonal.result &&
              testimonal.result.length > 0 &&
              testimonal.result.map((e) => (
                <SwiperSlide>
                  <div className=" mx-auto bg-white lg:p-8 md:p-6 p-4 rounded-xl lg:mt-6 md:mt-6  mt-6">
                    <div className="text-center my-2">
                      {e.userId && e.userId.length > 0 ? (
                        <Image
                          // onClick={() => handleSelectedTestimonal(e)}
                          fallbackSrc={`https://nexusclustersg.blob.core.windows.net/server01/fit2go/fit2go/profileImage/userImage-MjK1UVwi1th0b8ZPuIIjs-1707747761`}
                          src={`${process.env.REACT_APP_STORAGE_URL}${e.userId[0].userImage}`}
                          className="rounded-full roundetest cursor-pointer activeuser mx-auto object-cover"
                          alt="heroImage"
                        />
                      ) : (
                        <Image
                          // onClick={() => handleSelectedTestimonal(e)}
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                          className="rounded-full roundetest cursor-pointer activeuser mx-auto"
                          alt="heroImage"
                        />
                      )}
                    </div>
                    <div className="flex justify-center mt-4">
                      <Rating
                        initialRating={e.rating}
                        readonly={true}
                        emptySymbol={
                          <FeatherIcon icon="star" className="mr-1" size={22} />
                        }
                        fullSymbol={
                          <FeatherIcon
                            icon="star"
                            className="mr-1 fillstroke"
                            size={22}
                          />
                        }
                        fractions={2}
                      />
                    </div>

                    <h2 className="sm-fsize20 fsize24 font-semibold text-primary text-center mt-3">
                      {e.name}
                    </h2>

                    <p className="lineClamp5 sm-fsize14 md-fsize14 fsize16 text-dark text-center">
                      {e.testimonial}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonial5;
