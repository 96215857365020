const initialState = {
  project: {},
  user: {},
};
const userDetails = (state = initialState, action) => {
  switch (action.type) {
    case "setProject":
      return { ...state, project: action.payload };
    case "setUser":
      return { ...state, user: action.payload };

    default:
      return state;
  }
};

export default userDetails;
