import apis from "../../apis/client/transaction";
export const getTransaction = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.getTransaction(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      return error.response.data;
    }
    return record;
  };
};

export const getAllTransaction = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.getAllTransaction(payload);
      if (data) {
        dispatch(setAllTransaction(data.data));
        record = data;
      }
    } catch (error) {
      
      return error.response.data;
    }
    return record;
  };
};

export const setAllTransaction = (data) => {
  return {
    type: "setAllTransaction",
    payload: data,
  };
};
