import React from 'react';
import { Button, Checkbox } from '@chakra-ui/react';

const ContactForm1 = () => {
  return (
    <section className="lg:py-6">
      <div className="container mx-auto lg:my-12">
        <div className="lg:flex lg:justify-between gap-4 w-full">
          <div className="lg:w-1/2">
            <p className="lg:w-2/3 fsize86 font-semibold text-white">
              Lorem Ipsum generate
            </p>
          </div>
          <div className="lg:w-1/2 contactcss">
            <p className="lg:mb-4 letter-spacing4px fsize24 font-semibold clr-ddd9d8">
              Lorem Ipsum
            </p>
            <form>
              <div className="lg:mb-10">
                <input
                  type="text"
                  placeholder="Full Name*"
                  className="inputFeild"
                />
              </div>
              <div className="lg:mb-10 w-full lg:flex gap-8">
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Phone number*"
                    className="inputFeild"
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Email Id*"
                    className="inputFeild"
                  />
                </div>
              </div>
              <div className="lg:mb-10">
                <input
                  type="text"
                  placeholder="Your Message*"
                  className="inputFeild"
                />
              </div>
              <div className="pb-3">
                <Checkbox>
                  <span className="fsize14">
                    Check to subscribe to our Newsletter
                  </span>
                </Checkbox>
              </div>
              <Button
                colorScheme="primary"
                className="fsize16 font-semibold w-full lg:p-6  lg:rounded-md"
              >
                Enquire Now
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm1;
