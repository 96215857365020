import React from "react";
import Fade from "react-reveal/Fade";
import { Image, AspectRatio } from "@chakra-ui/react";
import { useSelector } from "react-redux/es/exports";

const HeroSection = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const handleErorrImage = (e) => {
    e.target.src =
      "https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg";
  };
  return (
    <section className=" bg-secondary">
      <div className="lg:flex md:flex sm-reverse h-full items-center">
        <div className="lg:w-3/5 md:w-3/5 lg:px-20 md:px-8 px-5 lg:py-0 md:py-8 py-6">
          <Fade left>
            <div className="flex items-center gap-4">
              <p id="8588175253" className="text-white sm-fsize14 dynamicStyle">
                {data ? data["8588175253"] : "EDUCATE"}
              </p>
              <span className="block h-2 w-2 bg-primary rounded-full"> </span>
              <p id="1659103740" className="text-white sm-fsize14 dynamicStyle">
                {data ? data["1659103740"] : "CONNECT"}
              </p>
              <span className="block h-2 w-2 bg-primary rounded-full"> </span>
              <p id="2552274841" className="text-white sm-fsize14 dynamicStyle">
                {data ? data["2552274841"] : "MOTIVATE"}
              </p>
            </div>

            <div className=" lg:mt-8 md:mt-8 mt-4">
              <h2
                id="5298456299"
                className="lg:text-4xl text-end md:text-2xl text-xl text-white  font-semibold dynamicStyle"
              >
                {" "}
                {data
                  ? data["5298456299"]
                  : "STAY INFORMED WITH OUR LATEST ARTICLES"}
              </h2>
              <p
                id="3430366058"
                dangerouslySetInnerHTML={{
                  __html: data ? data["3430366058"] : "no-data",
                }}
                className="text-white opacity-70 lg:mt-8 md:mt-4 mt-2 sm-fsize14 dynamicStyle lg:leading-7 md:leading-7 leading-6"
              ></p>
            </div>
          </Fade>
        </div>
        <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
          <div id="7473290310" className="dynamicStyle2">
            {" "}
            <Image
              src={data ? getImage(data["7473290310"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              onError={handleErorrImage}
              alt="heroImage"
              className="object-cover w-full sm-h250px minh450px object-contain"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
