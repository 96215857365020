const initialState = {
  sections: {},
  css: {},
  class: {},
  sectionNames: {},
  routeNames: [],
  allSections: {},
  allSectionsCss: {},
  allSectionsClass: {},
  files: {},
};
const Cms_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "getcms":
      return { ...state, sections: action.payload };
    case "csscms":
      return { ...state, css: action.payload };
    case "setSelectedData":
      return {
        ...state,
        selectedData: action.payload,
        orignalSelectedData: action.payload,
      };
    case "updateStore":
      console.log(action.payload.section, action.payload.content, "reducer");
      const updatepages = {
        ...state.sections,

        [action.payload.section]: {
          ...state.sections[action.payload.section],
          [action.payload.elementId]: action.payload.content,
        },
      };

      console.log(updatepages);

      return {
        ...state,

        sections: updatepages,
      };
    case "updateCssStore":
      console.log(action.payload);
      const updatecss = {
        ...state.css,

        [action.payload.section]: {
          ...state.css[action.payload.section],
          [action.payload.elementId]: action.payload.css,
        },
      };

      console.log(updatecss);

      return {
        ...state,

        css: updatecss,
      };
    case "updateSections":
      console.log(action.payload, "sections1");
      const updateSections = {
        ...state.allSections,

        [action.payload.pageName]: {
          ...state.allSections[action.payload.pageName],
          [action.payload.elementId]: action.payload.content,
        },
      };

      return {
        ...state,

        allSections: updateSections,
      };
    case "updateSectionsCss":
      console.log(action.payload);
      const updateSectionsCss = {
        ...state.allSectionsCss,
        [action.payload.pageName]: {
          ...state.allSectionsCss[action.payload.pageName],
          [action.payload.elementId]: action.payload.css,
        },
      };

      return {
        ...state,

        allSectionsCss: updateSectionsCss,
      };
    case "updateSectionsClass":
      console.log(action.payload);
      const updateSectionsClass = {
        ...state.allSectionsClass,
        [action.payload.pageName]: {
          ...state.allSectionsClass[action.payload.pageName],
          [action.payload.elementId]: action.payload.class,
        },
      };

      return {
        ...state,

        allSectionsClass: updateSectionsClass,
      };
    case "updateImage":
      console.log(action.payload);
      if (action.payload.delete) {
        const updateImage = {
          ...state.files,
        };
        delete updateImage[action.payload.elementId];
        return {
          ...state,

          files: updateImage,
        };
      } else {
        const updateImage = {
          ...state.files,

          [action.payload.elementId]: action.payload.file,
        };

        return {
          ...state,

          files: updateImage,
        };
      }
    case "getSections":
      console.log(action.payload, "section names");
      return { ...state, sectionNames: action.payload };
    case "setRouteNames":
      let routeNames = [...new Set(Object.keys(action.payload))];
      console.log(routeNames);
      return { ...state, routeNames: routeNames };
    case "setUserData":
      return { ...state, userData: action.payload };
    case "setCmsActive":
      return { ...state, cmsActive: action.payload };
    case "setAllSections":
      return { ...state, allSections: action.payload };
    case "setAllSectionsCss":
      return { ...state, allSectionsCss: action.payload };
    case "setAllSectionsClass":
      return { ...state, allSectionsClass: action.payload };
    default:
      return state;
  }
};

export default Cms_reducer;
