import React from "react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const FeatureCard1 = () => {
  return (
    <section>
      <div className="text-center w-1/2 mx-auto lg:mb-16">
        <h3 className="fsize24 font-semibold clr-ddd9d8 lg:mb-2">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold lg:mb-8">Lorem Ipsum</h1>
        <p className="fsize18">
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to demonstrate the visual form of a document or a
          typeface without relying on meaningful content.
        </p>
      </div>
      <div className="container mx-auto ">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 2.5,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 2.5,
              spaceBetween: 30,
            },
            505: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 1.2,
              spaceBetween: 15,
            },
          }}
        >
          <SwiperSlide>
            <div className="w-full flex flex-col items-center card lg:px-8 lg:py-12">
              <div>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  alt=""
                  className="object-cover card-img mb-6"
                />
              </div>
              <div className="pb-4">
                <h1 className="fsize24 font-semibold">Lorem Ipsum</h1>
              </div>
              <p className="fsize15 text-center">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-col items-center card lg:px-8 lg:py-12">
              <div>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  alt=""
                  className="object-cover card-img mb-6"
                />
              </div>
              <div className="pb-4">
                <h1 className="fsize24 font-semibold">Lorem Ipsum</h1>
              </div>
              <p className="fsize15 text-center">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-col items-center card lg:px-8 lg:py-12">
              <div>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  alt=""
                  className="object-cover card-img mb-6"
                />
              </div>
              <div className="pb-4">
                <h1 className="fsize24 font-semibold">Lorem Ipsum</h1>
              </div>
              <p className="fsize15 text-center">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex flex-col items-center card lg:px-8 lg:py-12">
              <div>
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                  alt=""
                  className="object-cover card-img mb-6"
                />
              </div>
              <div className="pb-4">
                <h1 className="fsize24 font-semibold">Lorem Ipsum</h1>
              </div>
              <p className="fsize15 text-center">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying on meaningful content.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default FeatureCard1;
