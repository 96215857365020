import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Image,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import FeatherIcon from "feather-icons-react";
import bg from "../../../assets/images/CardBg.svg";

const BookAppointment = () => {
  const [value, onChange] = useState(new Date());
  const [locationValue, setLocationValue] = useState("1");
  const [sessionValue, setSessionValue] = useState("1");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  return (
    <div className="container mx-auto lg:px-12 md:px-0 px-5 lg:py-16 md:py-16 py-8">
      <AlertDialog
        isCentered
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
            ></AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <div className=" text-center">
                <Image
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="appointment"
                  className="rounded-lg w-24 h-24 mx-auto"
                />
                <div className="my-4">
                  <h2 className="fsize20 font-bold mb-1">
                    Your Appointment has been Confirmed
                  </h2>
                  <div className="flex justify-center gap-4">
                    <div className="flex gap-2">
                      <h2 className="fsize16 font-medium">Name:</h2>
                      <p className="fsize16">Manish Sharma</p>
                    </div>
                    <div className="flex gap-2">
                      <h2 className="fsize16 font-medium">Name:</h2>
                      <p className="fsize16">Manish Sharma</p>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <h2 className="fsize16 font-medium">Time: </h2>
                    <p className="fsize16">7:15pm - 8:15pm</p>
                  </div>
                </div>
              </div>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <div className="w-full lg:flex md:flex gap-4">
        <div className="w-full">
          {/* <div className="bg-f4f5f6 w-full p-2">
            <div className="bg-black w-12 h-12 flex flex-col items-center justify-center rounded-md">
              <p className="fsize16 clr-fff">8</p>
              <p className="uppercase fsize12 clr-fff">fri</p>
            </div>
          </div> */}
          <Calendar onChange={onChange} value={value} className="w-100" />
          <div className="flex flex-wrap gap-3 mt-8">
            <Button
              display="inline-block"
              py={2}
              px={3}
              height={55}
              textAlign="left"
              variant="outline"
              colorScheme="white"
            >
              <p className="fsize14">12.00 PM to 12.00 PM</p>
              <span className="clr-d9d9d9 fsize12 flex font-normal items-center gap-1">
                <FeatherIcon icon="clock" className="w-4 clr-d9d9d9" /> 60 min
              </span>
            </Button>
            <Button
              display="inline-block"
              py={2}
              px={3}
              height={55}
              textAlign="left"
              className=" bg-black"
              variant="outline"
              background="black"
            >
              <p className="fsize14 clr-fff">12.00 PM to 12.00 PM</p>
              <span className="clr-fff fsize12 flex font-normal items-center gap-1">
                <FeatherIcon icon="clock" className="w-4 clr-fff" /> 60 min
              </span>
            </Button>
          </div>

          <div className="flex gap-8 border-b-2 mt-8 py-4 ">
            <div className="flex items-center gap-2">
              <div className="border px-1 rounded-md">
                <FeatherIcon icon="clock" className="w-4 " />
              </div>
              <p className="fsize14 font-medium ">Booked Slots</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="border px-1 rounded-md">
                <FeatherIcon icon="clock" className="w-4 " />
              </div>
              <p className=" fsize14 font-medium ">Available Slots</p>
            </div>
          </div>

          <div className="mt-12 flex justify-between">
            <div>
              <h2 className="font-medium fsize20">Types Session</h2>
              <div className="mt-4">
                <RadioGroup onChange={setSessionValue} value={sessionValue}>
                  <Stack direction="row" spacing={6}>
                    <div className="cust-shadow  py-2 px-3 rounded-full ">
                      <Radio value="1">
                        <span className="ml-1">Online</span>
                      </Radio>
                    </div>
                    <div className="cust-shadow  py-2 px-3 rounded-full ">
                      <Radio value="2">
                        <span className="ml-1">Offline</span>
                      </Radio>
                    </div>
                  </Stack>
                </RadioGroup>
              </div>
            </div>
            <div>
              <h2 className="font-medium fsize20">Select Location</h2>
              <div className="mt-4">
                <RadioGroup onChange={setLocationValue} value={locationValue}>
                  <Stack direction="row" spacing={6}>
                    <div className="cust-shadow  py-2 px-3 rounded-full ">
                      <Radio value="1">
                        <span className="ml-1">Gym</span>
                      </Radio>
                    </div>
                    <div className="cust-shadow  py-2 px-3 rounded-full ">
                      <Radio value="2">
                        <span className="ml-1">Outside</span>
                      </Radio>
                    </div>
                  </Stack>
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full relative   h-90">
          <img alt="fit2" src={bg} className="img-fuit m-auto rounded-lg" />
          <div className=" absolute top-0 left-0 flex justify-center items-center h-90 sm-h-80  w-full ">
            <div className="border bg-white shadow-lg lg:w-9/12 md:w-9/12 w-11/12 rounded-lg px-4 py-4">
              <div className="py-2 w-full flex justify-center ">
                <img
                  src="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                  alt="profile-img"
                  className="w-28 h-28 rounded-full"
                />
              </div>
              <div>
                <h2 className="fsize24 font-semibold uppercase text-center">
                  MANISH SHARMA
                </h2>
                <div className="flex justify-center items-center">
                  <p className="clr-9c9c9c fsize18 font-medium uppercase">
                    woodland
                  </p>
                  <div className="hr1"></div>
                  <p className="clr-9c9c9c fsize18 font-medium uppercase">
                    5*, 120 REVIEW
                  </p>
                </div>

                <div className="bg-white rounded-md w-full lg:mt-12 md:mt-12 mt-6">
                  <label htmlFor="" className="fsize14">
                    Booking Time
                  </label>
                  <input
                    type="time"
                    className="border-6d6d6d fsize14 font-semibold w-full px-3 py-3 rounded-md"
                  />
                </div>

                <div className="bg-white rounded-md w-full mt-4">
                  <label htmlFor="" className="fsize14">
                    Booking Date
                  </label>
                  <input
                    type="date"
                    className="border-6d6d6d fsize14 font-semibold w-full px-3 py-3 rounded-md"
                  />
                </div>

                <div className="mt-4 w-full">
                  <label htmlFor="" className="fsize14">
                    Session Type
                  </label>
                  <div className="border-6d6d6d  flex justify-between items-center px-3 py-3 bg-white rounded-md">
                    <p className="fsize14 font-semibold">Online</p>
                    <div className="online-dot"></div>
                  </div>
                </div>

                <div className="mt-12 w-full">
                  <Button
                    onClick={onOpen}
                    className="w-full fsize16 appointment-btn "
                    colorScheme="primary"
                  >
                    Book Appointment
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAppointment;
