import React from "react";
import imageVector from "assets/images/custom/Vector5.png";
import imageVectorBottom from "assets/images/custom/Vector5.png";
import fit2GoAsia from "assets/images/custom/fitasia.png";
import {
  Text,
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Divider,
  AbsoluteCenter,
  Box,
} from "@chakra-ui/react";
import { logout } from "../../../redux/auth/auth-action";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Fade from "react-reveal/Fade";

import Helper from "../../../helper";

import { toast } from "react-toastify";

const LeftImgRightText = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.cms.allSections[pageName]);
  let User = useSelector((state) => state.AuthDetails.user);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(logout({ email: User.email }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="relative">
      <Image src={imageVector} alt="image" className="homebanner-c" />
      <div className="lg:flex md:flex container mx-auto items-center lg:py-16 md:py-10 py-8 lg:px-12 md:px-8 px-5 relative">
        <div className="lg:w-1/2 md:w-1/2 w-full lg:pr-8 md:pr-8 relative zindex9">
          <div id="3787124660" className="dynamicStyle2">
            {" "}
            <Image
              src={data ? getImage(data["3787124660"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              alt="heroImage"
              className="w-full sm-h250px minh450px object-contain"
            />
          </div>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-4 zindex9">
          <Fade right>
            <h2
              id="8001616167"
              className="dynamicStyle lg:text-4xl md:text-2xl  text-xl text-black font-bold"
            >
              {data ? data["8001616167"] : "no-data"}
            </h2>

            <p
              id="3526496342"
              className=" dynamicStyle lg:mt-4 md:mt-4 mt-2 text030303 lg:text-base md:text-base text-sm lg:leading-7 md:leading-7 leading-6"
              dangerouslySetInnerHTML={{
                __html: data ? data["3526496342"] : "no-data",
              }}
            ></p>

            <div className="zindex9 relative">
              <div className="dynamicStyle " id="8700048552">
                <Button
                  onClick={() => {
                    if (User && User._id && User.trainerId) {
                      if (User.role === "Trainer") {
                        toast.warning("Already a trainer");
                      } else {
                        toast.warning("Already submitted trainer form");
                      }
                      return;
                    } else if (
                      User &&
                      User._id &&
                      User.role === "TrainerApplicant"
                    ) {
                      navigate("/becameacoach");
                    } else if (
                      User &&
                      User._id &&
                      User.role !== "TrainerApplicant"
                    ) {
                      handleLogout();
                      navigate("/register");
                      // toast.warning("Only trainer applicant are allowed");
                    } else if (Helper.Object.isEmpty(User)) {
                      console.log("No User");
                      toast.warning("Please login");
                      navigate("/login");
                    }
                  }}
                  width="180px"
                  height="50px"
                  colorScheme="primary"
                  className="lg:mt-8 md:mt-8 mt-6 "
                >
                  {data ? data["8700048552"] : "Find Coach"}
                </Button>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <Image src={fit2GoAsia} alt="image" className="fit2goAsia-text" />
      <Image src={imageVectorBottom} alt="image" className="homevectorbottom" />
    </section>
  );
};

export default LeftImgRightText;
