import Rfactory from '../../apis/client/index';
const Menu = Rfactory.getmenu;

// export default {
export const getAllMenu = () => {
  return async dispatch => {
    try {
      let { data } = await Menu.getAllMenu();
      console.log(data, 'menuu');
      dispatch(menu_reducer(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setLoading = payload => {
  return async dispatch => {
    try {
      dispatch(menu_loader(payload));
    } catch (error) {
      console.log(error);
    }
  };
};

export const menu_reducer = data => {
  return {
    type: 'setMenuData',
    payload: data,
  };
};

export const menu_loader = data => {
  return {
    type: 'setLoader',
    payload: data,
  };
};
