import Avatar from "./Avatar.jsx";

export default function Contact({
  id,
  channel,
  onClick,
  user,
  selected,
  loggedInUser,
  isTyping,
  selectedChannel,
}) {
  const getName = (user) => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    } else if (user?.firstName && !user?.lastName) {
      return user?.firstName;
    } else if (user?.name) {
      return user.name;
    }
    return "Unknown";
  };
  return (
    <div
      key={id}
      onClick={() => onClick(id)}
      className={
        "border-b bg-white w-full border-gray-100 rounded-md mb-1 flex items-center gap-2 cursor-pointer " +
        (selected ? "bg-blue-50" : "")
      }
    >
      {selected && <div className="w-1 bg-primary h-12 rounded-r-md"></div>}
      <div className="flex gap-2 py-3 pl-4 items-center">
        <Avatar user={user} channel={channel} />
        <div className="flex flex-col">
          <span className="text-gray-800 fsize14">
            {!channel?.isGroupChat ? getName(user) : channel?.channelName}
          </span>
          {channel?.latestMessage && (
            <p className="text-gray-800 fsize10">
              {channel?.isGroupChat && channel?.latestMessage?.text ? (
                <b>
                  {channel?.latestMessage?.sender?._id == loggedInUser._id
                    ? "You"
                    : getName(channel?.latestMessage?.sender)}
                  {": "}
                </b>
              ) : (
                ""
              )}
              {channel?.latestMessage?.text?.length > 30
                ? channel?.latestMessage?.text.substring(0, 31) + "..."
                : channel?.latestMessage?.text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
