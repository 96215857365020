import React from "react";
import { useSelector } from "react-redux/es/exports";
import Fade from "react-reveal/Fade";
import { Text, Image, Box } from "@chakra-ui/react";
const HeroSection4 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  return (
    <section>
      <div className="bg-secondary">
        <div className="lg:flex md:flex sm-reverse lg:px-0 px-5 lg:py-0 md:py-8 py-6 items-center ">
          <div className="lg:w-3/5 md:w-3/5 lg:px-20 md:px-8 lg:mt-0 md:mt-0 mt-4">
            <Fade left>
              <h2
                id="6270534268"
                className="dynamicStyle lg:text-4xl uppercase text-end md:text-2xl text-white text-xl font-semibold"
              >
                {data ? data["6270534268"] : "What is fit2go.asia about?"}
              </h2>
              <p
                id="7855163857"
                className="dynamicStyle text-white sm-fsize14 opacity-70 lg:mt-4 md:mt-4 mt-2 lg:leading-7 md:leading-7 leading-6"
                dangerouslySetInnerHTML={{
                  __html: data ? data["7855163857"] : "no-data",
                }}
              ></p>
            </Fade>
          </div>

          <div className="lg:w-2/5 md:w-2/5 lg:flex md:flex justify-end w-full">
            <div id="2628293436" className="dynamicStyle2">
              <Image
                src={data ? getImage(data["2628293436"]) : ""}
                fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                alt="heroImage"
                className="object-cover w-full sm-h250px minh450px object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection4;
