import React from "react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonial4 = () => {
  return (
    <section className="container mx-auto">
      <div className="text-center  flex flex-col justify-center lg:mb-16">
        <h3 className="letter-spacing4px fsize18 font-semibold clr-ddd9d8 ">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold  ">Lorem Ipsum</h1>
        <div>
          <p className="w-4/5 mx-auto fsize18 text-center">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
      <div className="w-60 mx-auto">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 21,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 25,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="testimonial-card border rounded-lg flex">
              <div className="w-35 flex flex-col items-center justify-center">
                <div className="testimonial-div ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    alt=""
                    className="testimonial-img rounded-full object-cover"
                  />
                </div>
                <div className="w-full text-center">
                  <h2 className="fsize24 font-semibold ">John Deo</h2>
                  <p className="fsize18">CEO & Co Founder</p>
                </div>
              </div>
              <div className="w-70">
                <p className="fsize18 pt-4 pb-8">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent augue ipsum, pellentesque sit amet viverra nec,
                  tempus at odio. Curabitur tempor molestie enim eget tristique.
                  Sed mi libero, semper quis lorem a, ultrices molestie arcu.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-card border rounded-lg flex">
              <div className="w-35 flex flex-col items-center justify-center">
                <div className="testimonial-div ">
                  <img
                    src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                    alt=""
                    className="testimonial-img rounded-full object-cover"
                  />
                </div>
                <div className="w-full text-center">
                  <h2 className="fsize24 font-semibold ">John Deo</h2>
                  <p className="fsize18">CEO & Co Founder</p>
                </div>
              </div>
              <div className="w-70">
                <p className="fsize18 pt-4 pb-8">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent augue ipsum, pellentesque sit amet viverra nec,
                  tempus at odio. Curabitur tempor molestie enim eget tristique.
                  Sed mi libero, semper quis lorem a, ultrices molestie arcu.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonial4;
