import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  RadioGroup,
  Stack,
  Radio,
  Progress,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import {
  FaUserCheck,
  FaRegThumbsUp,
  FaEye,
  FaPhoneAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  ExternalLinkIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";
import { Navigation } from "swiper/modules";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  UpdateUserView,
  getTrainerById,
  setSelectedTrainer as setSelectedTrainerData,
} from "../../../redux/custom/trainer/trainer-actions";
import {
  createPost,
  getPost,
  getSinglePost,
  updatePollVote,
} from "../../../redux/custom/social/post/post-actions";
import {
  createComment,
  deleteComment,
  getComment,
  getSingleComment,
} from "../../../redux/custom/social/comment/comment-actions";
import {
  createReply,
  deleteReply,
  getReply,
  getSingleReply,
} from "../../../redux/custom/social/reply/reply-actions";
import { getEmoji } from "../../../redux/custom/social/emoji/emoji-actions";
import { createReport } from "../../../redux/custom/social/report/report-actions";
import {
  togglePostLike,
  toggleCommentLike,
  toggleReplyLike,
} from "../../../redux/custom/social/like/like-actions";
import { createTestimonial } from "../../../redux/custom/testimonial/testimonial-actions";
import UploaderBox from "components/UploaderBox";
import EmojiPicker from "emoji-picker-react";
import Facebook from "../../../assets/images/custom/fb.png";
import Youtube from "../../../assets/images/custom/youTube.png";
import X from "../../../assets/images/custom/x.png";
import Instagram from "../../../assets/images/custom/instagram.png";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import { ThreeDots } from "react-loader-spinner";
import { MdDelete, MdMail } from "react-icons/md";
import ShareSection from "./ShareProfileSection";
import { useSearchParams, useNavigate } from "react-router-dom";
import Rating from "react-rating";

import { useFormik } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { getPlan } from "../../../redux/custom/subscription/plan/plan-actions";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { isEmpty } from "lodash";
import { createChannel } from "../../../redux/chat/chat-actions";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isOpen: isOpenScan,
    onOpen: onOpenScan,
    onClose: onCloseScan,
  } = useDisclosure();

  const {
    isOpen: isOpenReview,
    onOpen: onOpenReview,
    onClose: onCloseReview,
  } = useDisclosure();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTrainer, setSelectedTrainer] = useState({});
  // const [trainerPlanData, setTrainerPlanData] = useState(null);
  const [isCommentOpen, setIsCommentOpen] = useState("");
  const [commentMessage, setCommentMessage] = useState("");
  const [isReplyOpen, setIsReplyOpen] = useState("");
  const [replyMessage, setReplyMessage] = useState("");

  const [reportReason, setReportReason] = useState("");
  const [postReportId, setPostReportId] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [reportLoading, setReportLoading] = useState(false);

  const [postLoading, setPostLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);

  const [fetchCommentLoading, setFetchCommentLoading] = useState(false);
  const [fetchReplyLoading, setFetchReplyLoading] = useState(false);

  let selectedTrainerId = useSelector((state) => state.trainer.SelectedTrainer);
  let userData = useSelector((state) => state.AuthDetails.user);

  let postData = useSelector((state) => state.post.Post);
  let emojiData = useSelector((state) => state.emoji.Emoji);
  let commentData = useSelector((state) => state.comment.Comment);
  let replyData = useSelector((state) => state.reply.Reply);

  const getImage = (image) => {
    if (image.includes("data:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };

  const hideContent = (data, num = 3, noOfStart) => {
    if (data) {
      if (
        // (userData.planId &&
        //   userData.planId.name &&
        //   userData.planId.name === "Fit2Fitter") ||
        selectedTrainer &&
        selectedTrainer.userId &&
        selectedTrainer.userId.length > 0 &&
        selectedTrainer.userId[0].subscriptionId &&
        selectedTrainer.userId[0].subscriptionId.length > 0 &&
        selectedTrainer.userId[0].subscriptionId[0].planObject &&
        selectedTrainer.userId[0].subscriptionId[0].planObject.name ===
          "Fit2Coach Pro"
      ) {
        return data;
      } else {
        return data.substring(0, num) + "*".repeat(noOfStart);
      }
    }
  };

  // const getSinglePlan = async (planId) => {
  //   try {
  //     let payload = {
  //       _id: planId,
  //     };
  //     if (planId) {
  //       const data = await dispatch(getPlan(payload));
  //       if (data && data.code === 200) {
  //         setTrainerPlanData(data.data);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleReport = async () => {
    let reason = reportReason === "Other" ? otherReason : reportReason;
    if (userData && userData._id && reason) {
      try {
        let payload = {
          postId: postReportId,
          reason: reason,
        };
        setReportLoading(true);
        const data = await dispatch(createReport(payload));
        setReportLoading(false);
        if (data && data.code === 200) {
          // Swal.fire({
          //   title: "Success",
          //   text: `Report submitted successfully`,
          //   icon: "success",
          //   timer: "2500",
          // });
          toast.success(`Report submitted successfully`);
          onCloseReport();
          setOtherReason("");
          setPostReportId("");
          setReportReason("");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setReportLoading(false);
      }
    } else {
      console.log("Please login");
    }
  };

  const handleComment = (post) => {
    if (isCommentOpen === post._id) {
      setIsCommentOpen("");
    } else {
      setIsCommentOpen(post._id);
      getPostComment(post);
    }
  };

  const handleReply = (comment, post = {}) => {
    if (isReplyOpen === comment._id) {
      setIsReplyOpen("");
    } else {
      setIsReplyOpen(comment._id);
      getCommentReply(comment);
    }
  };

  const getPostComment = async (post) => {
    try {
      let payload = {
        postId: post._id,
        userId: userData._id,
      };
      setFetchCommentLoading(true);
      const data = await dispatch(getComment(payload));
      setFetchCommentLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchCommentLoading(false);
    }
  };

  const getCommentReply = async (comment, post = {}) => {
    try {
      let payload = {
        commentId: comment._id,
        userId: userData._id,
      };
      setFetchReplyLoading(true);
      const data = await dispatch(getReply(payload));
      setFetchReplyLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchReplyLoading(false);
    }
  };

  const handleSubmitComment = async (post) => {
    try {
      if (!commentMessage.trim()) return;
      let payload = {
        postId: post._id,
        message: commentMessage,
      };
      setCommentLoading(true);
      const data = await dispatch(createComment(payload));
      setCommentLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        getPostComment(post);
        setCommentMessage("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCommentLoading(false);
    }
  };
  const handleSubmitReply = async (comment, post) => {
    try {
      if (!replyMessage.trim()) return;
      let payload = {
        postId: post._id,
        commentId: comment._id,
        message: replyMessage,
      };
      setReplyLoading(true);
      const data = await dispatch(createReply(payload));
      setReplyLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        handleSingleComment(comment, post);
        getCommentReply(comment);
        setReplyMessage("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReplyLoading(false);
    }
  };

  const [postPage, setPostPage] = useState(0);
  const [postPerPage, setPostPerPage] = useState(10000);

  function calculateAge(birthDate) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    // If the birthday hasn't occurred yet this year, subtract 1 from age
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    return age;
  }

  useEffect(() => {
    getQueryData();
    getSelectedTrainerData();
    fetchPost();
    fetchEmoji();
  }, [selectedTrainerId]);

  const getQueryData = async () => {
    let trainerId = searchParams.get("ti");
    let trainerName = searchParams.get("tn");
    await dispatch(setSelectedTrainerData(trainerId));
    console.log(selectedTrainerId);
  };

  const fetchEmoji = async () => {
    try {
      const data = await dispatch(getEmoji());
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPost = async () => {
    try {
      console.log(selectedTrainerId);
      let payload = {
        offset: postPage,
        limit: postPerPage,
        trainerId: selectedTrainerId,
      };
      if (userData && userData._id) {
        Object.assign(payload, { userId: userData._id });
      }

      if (selectedTrainerId) {
        const data = await dispatch(getPost(payload));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSelectedTrainerData = async () => {
    try {
      console.log(selectedTrainerId);
      if (selectedTrainerId && userData && userData._id) {
        await dispatch(
          UpdateUserView({
            trainerId: selectedTrainerId,
            userId: userData._id,
          })
        );
      }

      if (selectedTrainerId) {
        const data = await dispatch(
          getTrainerById({
            trainerId: selectedTrainerId,
            trainerStatus: "Approved",
            testimonialStatus: ["Approved"],
          })
        );
        if (
          data &&
          data.code === 200 &&
          data.data.result &&
          data.data.result.length > 0
        ) {
          setSelectedTrainer(data.data.result[0]);
          // let trainerData = data.data.result[0];
          // let planId =
          //   trainerData &&
          //   trainerData.userId &&
          //   trainerData.userId.length > 0 &&
          //   trainerData.userId[0] &&
          //   trainerData.userId[0].planId
          //     ? trainerData.userId[0].planId
          //     : null;
          // await getSinglePlan(planId);
        }
      }
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const getLikedEmoji = (element, emojiData) => {
    let emoji = emojiData.result.filter(
      (emoji) => element.likes[0].emojiId === emoji._id
    );

    return (
      <div className="flex justify-start">
        <Image
          src={`${process.env.REACT_APP_STORAGE_URL}${
            emoji && emoji.length > 0 && emoji[0].media ? emoji[0].media : null
          }`}
          alt={
            emoji && emoji.length > 0 && emoji[0].name
              ? emoji[0].name
              : "No Emoji"
          }
          className="w-5 h-5 rounded-full cursor-pointer"
          fallbackSrc="https://nexusclustersg.blob.core.windows.net/server01/fit2go/fit2go/emoji/media-nAG3vqDUIVt7qdCBfLB4w-1706883516"
        />
      </div>
    );
  };

  const buttonLabels = [
    "Gym Trainer",
    "2 years experience",
    "Cardiovascular",
    "Functional",
    "Yoga",
    "Gym Trainer",
    "Functional",
    "Yoga",
    "2 years experience",
    "Cardiovascular",
    "Gym Trainer",
    "2 years experience",
    "Yoga",
    "Cardiovascular",
    "Functional",
  ];

  const timeSlots = [
    "10.00am-12.30pm",
    "3.00am-5.30pm",
    "3.00am-5.30pm",
    "11.00am-12.30pm",
    "3.00am-5.30pm",
    // Add more time slots as needed
  ];

  const langaugeSlots = [
    "Hindi",
    "English",
    "Spanish",
    "Singaporean",
    // Add more time slots as needed
  ];

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const {
    isOpen: isOpenReport,
    onOpen: onOpenReport,
    onClose: onCloseReport,
  } = useDisclosure();
  const {
    isOpen: isOpenPost,
    onOpen: onOpenPost,
    onClose: onClosePost,
  } = useDisclosure();
  const btnRef = React.useRef();

  const [displayedComments, setDisplayedComments] = useState(3);

  const loadMoreContents = () => {
    setDisplayedComments(displayedComments + 3);
  };

  const handlePostLike = async (post, emoji) => {
    try {
      let payload = {
        postId: post._id,
        emojiId: emoji._id,
      };
      const data = await dispatch(togglePostLike(payload));
      if (data && data.code === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCommentLike = async (comment, emoji) => {
    try {
      let payload = {
        commentId: comment._id,
        emojiId: emoji._id,
      };
      const data = await dispatch(toggleCommentLike(payload));
      if (data && data.code === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleReplyLike = async (reply, emoji) => {
    try {
      let payload = {
        replyId: reply._id,
        emojiId: emoji._id,
      };
      const data = await dispatch(toggleReplyLike(payload));
      if (data && data.code === 200) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSinglePost = async (post) => {
    try {
      let payload = {
        userId: userData._id,
        // trainerId: userData.trainerId,
        trainerId: selectedTrainerId,
        postId: post._id,
      };
      const data = await dispatch(getSinglePost(payload));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSingleComment = async (comment, post) => {
    try {
      let payload = {
        userId: userData._id,
        postId: post._id,
        commentId: comment._id,
      };
      const data = await dispatch(getSingleComment(payload));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSingleReply = async (reply, comment, post) => {
    try {
      let payload = {
        userId: userData._id,
        postId: post._id,
        commentId: comment._id,
        replyId: reply._id,
      };
      const data = await dispatch(getSingleReply(payload));
    } catch (error) {
      console.log(error);
    }
  };

  const calculatePollPercentage = (voteCount, totalVotes) => {
    if (totalVotes > 0) {
      return Math.round((voteCount / totalVotes) * 100);
    }
    return 0;
  };

  const getPollSelected = (pollOptions) => {
    let pollData = pollOptions.filter(
      (pollOption) => pollOption.userSelected === true
    );

    return pollData && pollData.length > 0 ? pollData[0]._id : "";
  };

  const handlePollVote = async (pollOptionId, post) => {
    let payload = {
      pollOptionId: pollOptionId,
      postId: post._id,
    };

    try {
      let data = await dispatch(updatePollVote(payload));
      if (data && data.code == 200) {
        handleSinglePost(post);
      }
    } catch (error) {
      console.log("Failed to update poll");
    }
  };

  const ProgressComponent = (
    voteCount,
    totalVotes,
    selectedOption,
    pollOption
  ) => {
    let pollPercentage = calculatePollPercentage(voteCount, totalVotes);
    return (
      <div className="flex items-center justify-between mt-2 w-full gap-4">
        <Progress
          // className="w-full poll-bg-ff5c62"
          className={`w-full poll-bg-ff5c62 ${
            selectedOption === pollOption._id ? "border-secondary" : ""
          }`}
          height="32px"
          value={selectedOption ? pollPercentage : 0}
        ></Progress>
        {selectedOption && (
          <span className="w-16 font-semibold">
            {selectedOption ? pollPercentage : 0}%
          </span>
        )}
      </div>
    );
  };

  const initialValues = {
    firstName: userData && userData.firstName ? userData.firstName : "",
    lastName: userData && userData.lastName ? userData.lastName : "",
    testimonial: "",
    rating: 0,
  };

  const validationRules = Yup.object({
    firstName: Yup.string()
      .trim()
      // .min(3, "First name must be at least 3 characters")
      .max(25, "First name must be at most 25 characters")
      .required("First name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    lastName: Yup.string()
      .trim()
      // .min(3, "Last name must be at least 3 characters")
      .max(25, "Last name must be at most 25 characters")
      .required("Last name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    testimonial: Yup.string()
      .trim()
      // .min(3, "Testimonial must be at least 3 characters")
      .max(500, "Testimonial name must be at most 500 characters")
      .required("Testimonial is required"),
    rating: Yup.number()
      .min(1, "Rating is required")
      .max(5, "Rating cannot be greater than 5")
      .required("Rating is required"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      name: `${values.firstName} ${values.lastName}`,
      rating: values.rating,
      testimonial: values.testimonial,
      userId: userData._id,
      trainerId: selectedTrainerId,
    };
    try {
      let data = await dispatch(createTestimonial(payload));
      if (data && data.code == 200) {
        formik.handleReset();
        toast.success(`Your feedback is submitted for review`);
        onCloseReview();
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  const handleDeleteReply = async (reply, comment, post) => {
    try {
      let payload = {
        replyId: reply._id,
      };
      setReplyLoading(true);
      const data = await dispatch(deleteReply(payload));
      setReplyLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        handleSingleComment(comment, post);
        getCommentReply(comment);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReplyLoading(false);
    }
  };

  const handleDeleteComment = async (comment, post) => {
    try {
      let payload = {
        commentId: comment._id,
      };
      setCommentLoading(true);
      const data = await dispatch(deleteComment(payload));
      setCommentLoading(false);
      if (data && data.code === 200) {
        handleSinglePost(post);
        getPostComment(post);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCommentLoading(false);
    }
  };

  const handleChat = async () => {
    if (!selectedTrainer?.userId[0]?._id) {
      return;
    }
    if (isEmpty(userData)) {
      toast.warning(`Please login`);
      navigate("/login");
      return;
    }
    try {
      let payload = { userId: selectedTrainer?.userId[0]?._id };
      let data = await dispatch(createChannel(payload));
      if (data && data.code == 200) {
        toast.success(`Added user in chat`);
        navigate("/user-profile?sm=Chat");
      }
    } catch (error) {
      if (error?.response?.data?.code == 409) {
        if (error?.response?.data?.message === "Chat has been restored") {
          toast.warning(error?.response?.data?.message);
          navigate("/user-profile?sm=Chat");
          return;
        }
        toast.warning(error?.response?.data?.message);
        navigate("/user-profile?sm=Chat");
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <div className="container mx-auto lg:py-4 md:py-4 py-4 lg:px-12 md:px-8 px-5">
      {/* profile  */}
      <section>
        <div className="w-full ">
          <Image
            src={`${process.env.REACT_APP_STORAGE_URL}${
              selectedTrainer.userId &&
              selectedTrainer.userId.length > 0 &&
              selectedTrainer.userId[0].coverImage
            }`}
            fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}fit2go/undefined/primaryLogo-DpYo0uWB2xJu3JgeKKl92-1701935536`}
            alt="user-background"
            className="lg:rounded-lg md:rounded-lg w-full object-cover h-300px "
          />
        </div>
        <div className="lg:flex md:flex block gap-8 items-end justify-between minus2rem-mtop ">
          <div className="lg:flex md:flex items-end gap-4 lg:ml-8">
            <div className="relative h-40 w-10rem mx-auto lg:pt-0 md:pt-4 pt-0">
              <Image
                src={`${process.env.REACT_APP_STORAGE_URL}${
                  selectedTrainer.userId &&
                  selectedTrainer.userId.length > 0 &&
                  selectedTrainer.userId[0].userImage
                }`}
                fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile"
                className="mx-auto object-cover relative z-10 rounded-full border6px-fff h-40 w-10rem"
              />
              {selectedTrainer.userId &&
              selectedTrainer.userId.length > 0 &&
              selectedTrainer.userId[0].status &&
              selectedTrainer.userId[0].status === "Offline" ? (
                <FeatherIcon
                  icon="x-circle"
                  className="bg-white text-gray  rounded-full border-2  absolute bottom-4 zindex99 lg:right-4 md:right-2 right-4  w-6 h-6 "
                ></FeatherIcon>
              ) : (
                <p className="border-2 block absolute bottom-4 zindex99 right-5 bg-success w-5 h-5 rounded-full"></p>
              )}
            </div>
            <div className="lg:mb-4 md:mb-0 lg:text-left md:text-left text-center">
              <h2 className="lg:pl-2 md:pl-2 fsize24 font-semibold">
                {`${
                  selectedTrainer.userId &&
                  selectedTrainer.userId.length > 0 &&
                  selectedTrainer.userId[0].firstName
                    ? selectedTrainer.userId[0].firstName
                    : ""
                } ${
                  selectedTrainer.userId &&
                  selectedTrainer.userId.length > 0 &&
                  selectedTrainer.userId[0].lastName
                    ? selectedTrainer.userId[0].lastName
                    : ""
                }`}
              </h2>
              <div className="lg:pl-2 md:pl-2 flex lg:flex-row md:flex-col flex-col lg:justify-center justify-between lg:items-center w-full">
                <p className="uppercase clr-9c9c9c fsize16 sm-fsize14  font-medium">
                  {/* {selectedTrainer.userId &&
                    selectedTrainer.userId.length > 0 &&
                    selectedTrainer.userId[0].role} */}
                  {selectedTrainer.location
                    ? selectedTrainer.location
                    : "Singapore"}
                </p>
                <div className="hr1 lg:block md:hidden hidden"></div>

                <div className="flex items-center lg:justify-start md:justify-start justify-center lg:gap-0 gap-2">
                  <div className="flex items-center">
                    <p className="clr-9c9c9c fsize16 sm-fsize14  font-medium">
                      {selectedTrainer.rating}{" "}
                    </p>
                    <ReactStars
                      count={1}
                      value={1}
                      size={15}
                      edit={false}
                      activeColor="#ffd700"
                      classNames="mt-2px"
                    />
                  </div>
                  <p className="lg:ml-2 uppercase clr-9c9c9c fsize16 sm-fsize14  font-medium">
                    {selectedTrainer.testimonials &&
                    selectedTrainer.testimonials.length > 0
                      ? selectedTrainer.testimonials.length
                      : 0}{" "}
                    Review
                  </p>
                  <p className="uppercase flex items-center justify-center gap-2 lg:ml-4 clr-9c9c9c fsize16 sm-fsize14  font-medium">
                    <FaEye className="clr-ec1c24" />{" "}
                    {selectedTrainer.viewCount ? selectedTrainer.viewCount : 0}{" "}
                    Profile views
                  </p>
                </div>
              </div>
              <div className="flex lg:justify-start md:justify-start justify-center">
                <ShareSection selectedTrainer={selectedTrainer} />
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row md:flex-col lg:justify-center md:justify-center gap-4 lg:mb-4">
            {/* <Button
              width="180px"
              md-width="120px"
              height="50px"
              variant="outline"
              colorScheme="primary"
              className="lg:mt-8 md:mt-0 mt-6 fsize14"
              // onClick={onOpenPost}
            >
              Book Appointment
            </Button> */}

            {/* <Modal isOpen={isOpenPost} onClose={onClosePost}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>CREATE POST</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel className="fsize14">Select Type</FormLabel>
                    <Select onSelect={selectedValue} onChange={handleValue}>
                      <option value="text">Text</option>
                      <option value="image">Image</option>
                    </Select>
                  </FormControl>
                  {selectedValue === "text" && (
                    <FormControl mt={4}>
                      <FormLabel className="fsize14">Description</FormLabel>
                      <Textarea placeholder="Type here" />
                    </FormControl>
                  )}

                  {selectedValue === "image" && (
                    <>
                      <FormControl mt={4}>
                        <FormLabel className="fsize14">Caption</FormLabel>
                        <Textarea placeholder="Type here" />
                      </FormControl>
                      <div className="mt-4">
                        <UploaderBox />
                      </div>
                    </>
                  )}
                </ModalBody>

                <ModalFooter className="w-full flex justify-center ">
                  <Button colorScheme="primary" className="w-full" mr={3}>
                    POST
                  </Button>
                  <Button
                    colorScheme="primary"
                    className="w-full"
                    variant="outline"
                    onClick={onClosePost}
                  >
                    CANCEL
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal> */}
            {/* {selectedTrainer?.userId?.length > 0 &&
              selectedTrainer?.userId[0]?._id !== userData?._id && (
                <Button
                  width="180px"
                  height="50px"
                  colorScheme="tertiary"
                  className="lg:mt-8 md:mt-0 mt-6 fsize14"
                  onClick={() => handleChat()}
                >
                  Chat
                </Button>
              )} */}
          </div>
        </div>
      </section>

      {/* usercard */}
      <section className="lg:mt-12 md:mt-12 mt-8">
        <div className="w-full lg:flex md:flex ">
          <div className="min-w-65 sm-w-full lg:pr-8 md:pr-8">
            <div className="lg:hidden md:hidden block text-right mb-2">
              <div className="flex justify-between mb-6">
                {/* <div className="flex gap-2">
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image src={Youtube} alt="youtube" className="w-8 h-8" />
                  </a>
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={Instagram}
                      alt="instagram"
                      className="w-8 h-8"
                    />
                  </a>
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image src={Facebook} alt="facebook" className="w-8 h-8" />
                  </a>
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image src={X} alt="x" className="w-8 h-8" />
                  </a>
                </div> */}
                {/* <p
                  className="text-gray flex justify-end items-center fsize18 gap-1"
                  ref={btnRef}
                  colorScheme="teal"
                  onClick={onOpenDrawer}
                >
                  Filter <FeatherIcon icon="filter" />
                </p> */}
              </div>
              <Drawer
                isOpen={isOpenDrawer}
                placement="right"
                onClose={onCloseDrawer}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Categories</DrawerHeader>
                  <DrawerBody className="sm-drawer-padding">
                    <div className="w-full ">
                      <div className="flex flex-col gap-4">
                        <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                          <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                            specialisation
                          </h2>
                          <div className="py-2 px-2">
                            <div className="flex flex-wrap gap-4">
                              {buttonLabels.map((label, index) => (
                                <Button key={index} className="fsize14 bg-fff">
                                  {label}
                                </Button>
                              ))}
                            </div>
                          </div>
                        </div>
                        {/* <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                          <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                            prefferred time
                          </h2>
                          <div className="py-2 px-2">
                            <div className="flex flex-wrap gap-4">
                              {timeSlots.map((timeSlot, index) => (
                                <Button
                                  key={index}
                                  variant="outline"
                                  colorScheme="primary"
                                  className="fsize12px bg-fff"
                                >
                                  {timeSlot}
                                </Button>
                              ))}
                            </div>
                          </div>
                        </div> */}
                        <div className="rounded-lg bg-f4f4f4 p-1 sm-ml-8px">
                          <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                            language
                          </h2>
                          <div className="py-2 px-2">
                            <div className="flex flex-wrap gap-4">
                              {langaugeSlots.map((langaugeSlots, index) => (
                                <Button
                                  key={index}
                                  variant="outline"
                                  colorScheme="primary"
                                  className="fsize12px bg-fff"
                                >
                                  {langaugeSlots}
                                </Button>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="rounded-lg bg-f4f4f4 p-1">
                          <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                            Fitness Categories
                          </h2>
                          <div className="py-2 px-2">
                            <div className="flex flex-wrap gap-4">
                              {selectedTrainer.fitnessCategories &&
                              selectedTrainer.fitnessCategories.length > 0
                                ? selectedTrainer.fitnessCategories.map((e) => {
                                    return (
                                      <Button className="fsize14 bg-fff">
                                        {e.categoryName}
                                      </Button>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </div>
            <Tabs variant="soft-rounded" colorScheme="green">
              <div className="flex justify-between items-center bottom-border-ebebeb">
                <TabList className=" pb-2">
                  <Tab className="lg:px-4  rounded-sm">About me</Tab>
                  <Tab className="lg:px-4   rounded-sm">Activity</Tab>
                  <Tab className="lg:px-4   rounded-sm">Reviews</Tab>
                </TabList>
                {/* <div className="flex mob-hidden gap-2">
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image src={Youtube} alt="youtube" className="w-8 h-8" />
                  </a>
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={Instagram}
                      alt="instagram"
                      className="w-8 h-8"
                    />
                  </a>
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image src={Facebook} alt="facebook" className="w-8 h-8" />
                  </a>
                  <a href="http://" target="_blank" rel="noopener noreferrer">
                    <Image src={X} alt="x" className="w-8 h-8" />
                  </a>
                </div> */}
              </div>

              <TabPanels>
                <TabPanel className="px-0px">
                  <div className="flex flex-col gap-4">
                    <div className="rounded-lg bg-f4f4f4 p-1">
                      <p className="text-black px-2 py-1.5 uppercase font-semibold">
                        Why hire me!
                      </p>
                      <div className="bg-white  rounded-lg">
                        <p className="clr-515151 px-2 py-1 fsize14 lh-26px sm-lh-22px">
                          {selectedTrainer && selectedTrainer
                            ? selectedTrainer.about
                            : "no-data"}
                        </p>
                      </div>
                    </div>

                    <div className="rounded-lg bg-f4f4f4  p-2">
                      <p className="text-black px-2 py-1.5 uppercase font-semibold">
                        Contact Details
                      </p>
                      <div className="rounded-lg bg-white">
                        <div className="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-4 p-2">
                          <div>
                            <h2 className="font-bold text-gray fsize12 uppercase">
                              Email ID
                            </h2>
                            <div className=" mt-2">
                              <p className="inline-flex items-center gap-2 fsize12 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                <MdMail className="fsize16" />{" "}
                                {selectedTrainer &&
                                  selectedTrainer.userId &&
                                  selectedTrainer.userId.length > 0 &&
                                  selectedTrainer.userId[0] &&
                                  selectedTrainer.userId[0].email &&
                                  hideContent(
                                    selectedTrainer.userId[0].email,
                                    3,
                                    8
                                  )}
                              </p>
                            </div>
                          </div>
                          <div>
                            <h2 className="font-bold text-gray fsize12 uppercase">
                              Phone Number
                            </h2>
                            <div className=" mt-2">
                              <p className=" inline-flex items-center gap-2 fsize12 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                <FaPhoneAlt /> +{" "}
                                {`${
                                  selectedTrainer &&
                                  selectedTrainer.userId &&
                                  selectedTrainer.userId.length > 0 &&
                                  selectedTrainer.userId[0].countryCode
                                } ${
                                  selectedTrainer &&
                                  selectedTrainer.userId &&
                                  selectedTrainer.userId.length > 0 &&
                                  selectedTrainer.userId[0] &&
                                  selectedTrainer.userId[0].mobile &&
                                  hideContent(
                                    selectedTrainer.userId[0].mobile,
                                    3,
                                    5
                                  )
                                } `}
                              </p>
                            </div>
                          </div>
                          <div>
                            <h2 className="font-bold text-gray fsize12 uppercase">
                              Location
                            </h2>
                            <div className=" mt-2">
                              <p className="inline-flex items-center gap-2 fsize12 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                <FaMapMarkerAlt />
                                {selectedTrainer.location}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-lg bg-f4f4f4 p-1">
                      <p className="text-black px-2 py-1.5 uppercase font-semibold">
                        Other Details
                      </p>
                      <div className="rounded-lg bg-white">
                        <div
                          className="grid lg:grid-cols-2 md:grid-cols-1
                       grid-cols-1 gap-4 p-2"
                        >
                          <div>
                            <h2 className="font-bold text-gray fsize12 uppercase">
                              Preferred Location
                            </h2>
                            <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                              {selectedTrainer &&
                              selectedTrainer.trainingLocation &&
                              selectedTrainer.trainingLocation.length > 0 ? (
                                selectedTrainer.trainingLocation.map((tl) => (
                                  <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                    {tl}
                                  </p>
                                ))
                              ) : (
                                <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                  No Preferred Location
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-2">
                            <div>
                              <h2 className="font-bold text-gray fsize12 uppercase">
                                Training Type
                              </h2>
                              <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                {selectedTrainer &&
                                selectedTrainer.trainingType &&
                                selectedTrainer.trainingType.length > 0 ? (
                                  selectedTrainer.trainingType.map((ty) => (
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      {ty}
                                    </p>
                                  ))
                                ) : (
                                  <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                    No Training Type
                                  </p>
                                )}
                              </div>
                            </div>

                            <div>
                              <h2 className="font-bold text-gray fsize12 uppercase">
                                Experience
                              </h2>
                              <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                  {selectedTrainer && selectedTrainer.experience
                                    ? selectedTrainer.experience
                                    : "No Experience"}
                                </p>
                              </div>
                            </div>
                            {selectedTrainer &&
                              selectedTrainer.userId &&
                              selectedTrainer.userId.length > 0 &&
                              selectedTrainer.userId[0].isDOBHide === false && (
                                <div>
                                  <h2 className="font-bold text-gray fsize12 uppercase">
                                    Age
                                  </h2>
                                  <div className="lg:flex md:flex gap-2 flex-wrap mt-2">
                                    <p className="fsize10 font-semibold rounded-md px-2 py-2 bg-f4f5f6 ">
                                      {selectedTrainer &&
                                      selectedTrainer.userId &&
                                      selectedTrainer.userId.length > 0 &&
                                      selectedTrainer.userId[0].dob
                                        ? calculateAge(
                                            selectedTrainer.userId[0].dob
                                          )
                                        : "No Age"}
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedTrainer.qualification &&
                      selectedTrainer.qualification.length > 0 && (
                        <div className="rounded-lg bg-f4f4f4 p-1">
                          <p className="text-black px-2 py-1.5 uppercase font-semibold">
                            Qualification
                          </p>
                          <div className="rounded-lg bg-white">
                            <div
                              className="grid lg:grid-cols-2 md:grid-cols-1
     grid-cols-1 gap-4 p-2"
                            >
                              {selectedTrainer.qualification &&
                              selectedTrainer.qualification.length > 0
                                ? selectedTrainer.qualification.map((e) => {
                                    return (
                                      <div className="flex gap-2">
                                        <div>
                                          <Image
                                            src={
                                              e.media ? getImage(e.media) : null
                                            }
                                            fallbackSrc="https://nimbusclustersg.blob.core.windows.net/server01/fit2go/asset/graduate.png"
                                            alt="logo"
                                            className="w-16 h-16 object-cover rounded-sm"
                                          />
                                        </div>
                                        <div>
                                          <h2 className="font-bold text-black uppercase">
                                            {e.typeName}
                                          </h2>
                                          <p className="fsize12 font-semibold text-black">
                                            {e.type}
                                          </p>
                                          <span className="fsize12 pr-2 text-gray">
                                            <b className="text-black">Start</b>{" "}
                                            :{" "}
                                            {new Date(
                                              e.startDate
                                            ).toLocaleDateString("en-IN")}
                                          </span>

                                          <span className="fsize12 text-gray">
                                            <b className="text-black">End</b> :{" "}
                                            {new Date(
                                              e.endDate
                                            ).toLocaleDateString("en-IN")}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}

                    {selectedTrainer.certification &&
                      selectedTrainer.certification.length > 0 && (
                        <div className="rounded-lg bg-f4f4f4 p-1">
                          <p className="text-black px-2 py-1.5 uppercase font-semibold">
                            certifcation
                          </p>
                          <div className="bg-white rounded-lg p-2">
                            <Swiper
                              spaceBetween={25}
                              slidesPerView={3.2}
                              observer={true}
                              observeParents={true}
                              modules={[Navigation]}
                              className="mySwiper"
                              autoplay={{
                                delay: "1000",
                              }}
                              loop
                              navigation={{
                                nextEl: ".image-swiper-button-next",
                                prevEl: ".image-swiper-button-prev",
                              }}
                              breakpoints={{
                                1536: {
                                  slidesPerView: 3.3,
                                  spaceBetween: 25,
                                },
                                1280: {
                                  slidesPerView: 3.3,
                                  spaceBetween: 25,
                                },
                                1024: {
                                  slidesPerView: 3.3,
                                  spaceBetween: 25,
                                },
                                991: {
                                  slidesPerView: 2.8,
                                  spaceBetween: 25,
                                },
                                767: {
                                  slidesPerView: 2,
                                  spaceBetween: 25,
                                },
                                640: {
                                  slidesPerView: 1.2,
                                  spaceBetween: 25,
                                },
                                425: {
                                  slidesPerView: 1.2,
                                  spaceBetween: 25,
                                },
                                320: {
                                  slidesPerView: 1.2,
                                  spaceBetween: 25,
                                },
                              }}
                            >
                              {selectedTrainer.certification &&
                              selectedTrainer.certification.length > 0
                                ? selectedTrainer.certification.map((e) => {
                                    return (
                                      <SwiperSlide>
                                        <div className="flex flex-col">
                                          <a
                                            href={
                                              e.media ? getImage(e.media) : ""
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            className="cursor-pointer"
                                          >
                                            <h2 className="fsize16 uppercase flex items-end gap-1 font-semibold">
                                              {e.courseName}
                                              <ExternalLinkIcon
                                                w={4}
                                                h={4}
                                                color="black"
                                              />
                                            </h2>
                                          </a>

                                          <p className="mt-1 fsize12 clr-6f6f6f">
                                            {e.orgName}
                                          </p>
                                          {/* <div className="w-full mt-2">
                                            <Image
                                              fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                              src={`${
                                                process.env
                                                  .REACT_APP_STORAGE_URL
                                              }${
                                                selectedTrainer.certification &&
                                                selectedTrainer.certification
                                                  .length > 0 &&
                                                selectedTrainer.certification[0]
                                                  .media
                                              }`}
                                              alt="card"
                                              className="w-full rounded-md h-32"
                                            />
                                          </div> */}
                                        </div>
                                      </SwiperSlide>
                                    );
                                  })
                                : ""}
                            </Swiper>
                          </div>
                        </div>
                      )}
                  </div>
                </TabPanel>
                <TabPanel className="px-0px w-full">
                  <div className="flex flex-col gap-4">
                    {postData &&
                    postData.result &&
                    postData.result.length > 0 ? (
                      postData.result.map((post) => (
                        <>
                          <div className="rounded-lg bg-f4f4f4  p-2">
                            <div className="flex items-center justify-between gap-2 lg:mb-0 md:mb-0 mb-2">
                              <div className="flex items-center gap-2">
                                <div className="lg:pt-0 md:pt-4 pt-0">
                                  <Image
                                    fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                    src={`${process.env.REACT_APP_STORAGE_URL}${
                                      post.userId &&
                                      post.userId.length > 0 &&
                                      post.userId[0] &&
                                      post.userId[0].userImage
                                        ? post.userId[0].userImage
                                        : null
                                    }`}
                                    alt="profile"
                                    className="mx-auto object-cover relative z-10 rounded-full border3px-fff lg:h-16 md:h-16  lg:w-16 md:w-16 w-12 h-12"
                                  />
                                </div>
                                <div className="text-left flex flex-col gap-1 justify-between">
                                  <h2 className="fsize18 sm-fsize16 font-semibold">
                                    {post.userId &&
                                    post.userId.length > 0 &&
                                    post.userId[0] &&
                                    post.userId[0].firstName
                                      ? post.userId[0].firstName
                                      : ""}{" "}
                                    {post.userId &&
                                    post.userId.length > 0 &&
                                    post.userId[0] &&
                                    post.userId[0].lastName
                                      ? post.userId[0].lastName
                                      : ""}
                                    {/* {post?.createdAt &&
                                      new Date(
                                        post.createdAt
                                      ).toLocaleDateString("en-IN")} */}
                                  </h2>

                                  <p className="clr-9c9c9c fsize14 md-fsize18px   font-medium">
                                    {post.trainerId &&
                                    post.trainerId.length > 0 &&
                                    post.trainerId[0] &&
                                    post.trainerId[0].location
                                      ? post.trainerId[0].location
                                      : ""}
                                  </p>
                                </div>
                              </div>

                              {userData &&
                                userData._id &&
                                post.userId[0] &&
                                post.userId[0]._id &&
                                userData._id !== post.userId[0]._id && (
                                  <Menu width="30">
                                    <MenuButton
                                      px={0}
                                      py={2}
                                      transition="all 0.2s"
                                      borderRadius="md"
                                      borderWidth="0px"
                                    >
                                      <FeatherIcon icon="more-vertical" />
                                    </MenuButton>
                                    <MenuList>
                                      <MenuItem
                                        onClick={() => {
                                          setPostReportId(post._id);
                                          onOpenReport();
                                        }}
                                      >
                                        {" "}
                                        Report
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                )}
                              <Modal
                                isOpen={isOpenReport}
                                onClose={onCloseReport}
                              >
                                {/* <ModalOverlay /> */}
                                <ModalContent>
                                  <ModalHeader>Reason to Report</ModalHeader>
                                  <ModalCloseButton />
                                  <ModalBody>
                                    <div>
                                      <RadioGroup
                                        onChange={(reason) =>
                                          setReportReason(reason)
                                        }
                                        value={reportReason}
                                      >
                                        <Stack
                                          spacing={4}
                                          direction="column"
                                          className="radio"
                                        >
                                          <Radio
                                            value="Inappropriate Content"
                                            colorScheme="primary"
                                          >
                                            Inappropriate Content
                                          </Radio>
                                          <Radio
                                            value="Misinformation or False Information"
                                            colorScheme="primary"
                                          >
                                            Misinformation or False Information
                                          </Radio>
                                          <Radio
                                            value="Copyright Infrigement"
                                            colorScheme="primary"
                                          >
                                            Copyright Infrigement
                                          </Radio>
                                          <Radio
                                            value="Spam or Phishing"
                                            colorScheme="primary"
                                          >
                                            Spam or Phishing
                                          </Radio>
                                          <Radio
                                            value="Impersonation"
                                            colorScheme="primary"
                                          >
                                            Impersonation
                                          </Radio>
                                          <Radio
                                            value="Other"
                                            colorScheme="primary"
                                          >
                                            Other
                                          </Radio>
                                        </Stack>
                                      </RadioGroup>
                                      {reportReason === "Other" && (
                                        <div className="my-3">
                                          <h2 className="fsize16 font-semibold">
                                            Please explain your reason*
                                          </h2>
                                          <Textarea
                                            value={otherReason}
                                            onChange={(e) =>
                                              setOtherReason(e.target.value)
                                            }
                                            placeholder="Explain your Reason.."
                                            className="my-1 fsize14"
                                          />
                                        </div>
                                      )}
                                      {!(reportReason === "Other"
                                        ? otherReason
                                        : reportReason) && (
                                        <p className="fsize12 mt-2 text-danger">
                                          Reason is required
                                        </p>
                                      )}
                                      <p className="fsize12 m-3">
                                        I agree to the{" "}
                                        <span className="font-semibold underline">
                                          Conditions of Use.
                                        </span>{" "}
                                        The data I'm submiting is true and not
                                        copyrighted by a third party.
                                      </p>
                                    </div>
                                    <Button
                                      isLoading={reportLoading}
                                      spinner={
                                        <ThreeDots
                                          visible={true}
                                          color="white"
                                          radius={3}
                                        />
                                      }
                                      onClick={() => handleReport()}
                                      colorScheme="primary"
                                      className="w-100per mb-4"
                                    >
                                      Submit
                                    </Button>
                                  </ModalBody>
                                </ModalContent>
                              </Modal>
                            </div>
                            <p className="fsize16 p-1 text-black">
                              {post.caption ? post.caption : ""}
                            </p>
                            {post.mediaType && post.mediaType === "Text" ? (
                              <div className="bg-white  m-1">
                                <p className="px-2 py-2 fsize14 w-full maxheight500 object-contain overflow-auto">
                                  {/* {post.message ? post.message : ""} */}
                                  <ReactQuill
                                    theme="bubble"
                                    readOnly
                                    value={post.message}
                                  />
                                </p>
                              </div>
                            ) : post.mediaType === "Image" ? (
                              <div className="bg-white  m-1">
                                <Image
                                  src={`${process.env.REACT_APP_STORAGE_URL}${
                                    post.media ? post.media : null
                                  }`}
                                  fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                  alt="card-images"
                                  className="w-full maxheight500 object-contain"
                                />
                              </div>
                            ) : post.mediaType === "Video" ? (
                              <div className="bg-white  m-1 flex justify-center">
                                <video
                                  height="360"
                                  controls
                                  src={`${process.env.REACT_APP_STORAGE_URL}${
                                    post.media ? post.media : null
                                  }`}
                                  className="w-full"
                                />
                              </div>
                            ) : post.mediaType === "Poll" ? (
                              <div className="bg-white p-4 m-1">
                                <RadioGroup
                                  isDisabled={
                                    getPollSelected(post.pollOptions)
                                      ? true
                                      : false
                                  }
                                  value={
                                    post.pollOptions &&
                                    post.pollOptions.length > 0
                                      ? getPollSelected(post.pollOptions)
                                      : ""
                                  }
                                  onChange={(pollOptionId) =>
                                    handlePollVote(pollOptionId, post)
                                  }
                                >
                                  {post.pollOptions &&
                                    post.pollOptions.length > 0 &&
                                    post.pollOptions.map((pollOption) => (
                                      <div className="relative prog mb-4">
                                        <Radio
                                          // isDisabled={
                                          //   !pollOption.userSelected
                                          // }
                                          className="radio"
                                          colorScheme="primary "
                                          value={pollOption._id}
                                        >
                                          {pollOption.message
                                            ? pollOption.message
                                            : ""}
                                        </Radio>
                                        {ProgressComponent(
                                          pollOption.voteCount,
                                          post.totalVotes,
                                          getPollSelected(post.pollOptions),
                                          pollOption
                                        )}
                                      </div>
                                    ))}
                                </RadioGroup>
                              </div>
                            ) : (
                              "No Media Found"
                            )}

                            <p className="py-2 lg:px-8 md:px-8 px-4 flex gap-8">
                              <div className="flex items-center gap-1 cursor-pointer">
                                <Popover placement="top-start">
                                  <PopoverTrigger>
                                    <h2 px={0} py={0} className="btnn">
                                      {post.likes &&
                                      post.likes.length > 0 &&
                                      post.likes[0] &&
                                      post.likes[0].emojiId &&
                                      emojiData &&
                                      emojiData.result &&
                                      emojiData.result.length > 0 ? (
                                        getLikedEmoji(post, emojiData)
                                      ) : (
                                        <div className="flex gap-1 items-center fsize12 cursor-pointer">
                                          <FeatherIcon
                                            icon="thumbs-up"
                                            colorScheme="primary"
                                            size={14}
                                          />{" "}
                                          Likes
                                        </div>
                                      )}
                                    </h2>
                                  </PopoverTrigger>
                                  <PopoverContent
                                    className="fitcontent"
                                    border={0}
                                  >
                                    <PopoverBody padding={0} border={0}>
                                      {/* <EmojiPicker
                                          reactionsDefaultOpen={true}
                                          onReactionClick={handleReaction}
                                          className="w-full"
                                        /> */}
                                      <div className="flex p1020 gap-2">
                                        {emojiData &&
                                        emojiData.result &&
                                        emojiData.result.length > 0
                                          ? emojiData.result.map((emoji) => (
                                              <div>
                                                <Image
                                                  onClick={async () => {
                                                    let success =
                                                      await handlePostLike(
                                                        post,
                                                        emoji
                                                      );

                                                    if (success) {
                                                      await handleSinglePost(
                                                        post
                                                      );
                                                    }
                                                  }}
                                                  src={`${
                                                    process.env
                                                      .REACT_APP_STORAGE_URL
                                                  }${
                                                    emoji.media
                                                      ? emoji.media
                                                      : null
                                                  }`}
                                                  alt={emoji.name}
                                                  className="w-5 h-5 rounded-full cursor-pointer"
                                                />
                                              </div>
                                            ))
                                          : "No Emoji"}
                                      </div>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                                <p className="fsize12">
                                  {post.totalLikes ? post.totalLikes : ""}
                                </p>
                              </div>
                              <div
                                className="flex gap-1 items-center fsize12 cursor-pointer"
                                onClick={() => handleComment(post)}
                              >
                                <FeatherIcon
                                  icon="message-circle"
                                  colorScheme="primary"
                                  size={14}
                                />{" "}
                                Comments
                                <div>
                                  {post.totalComments ? post.totalComments : ""}
                                </div>
                              </div>
                            </p>
                            {fetchCommentLoading ? (
                              <div className="flex justify-center">
                                {isCommentOpen &&
                                  isCommentOpen === post._id && (
                                    <ThreeDots
                                      visible={true}
                                      color="#ed1b24"
                                      radius={3}
                                    />
                                  )}
                              </div>
                            ) : (
                              <>
                                {isCommentOpen && isCommentOpen === post._id && (
                                  <>
                                    <div className="p-1">
                                      <InputGroup size="md">
                                        <Input
                                          pr="7rem"
                                          placeholder="Enter comment"
                                          className="bg-fff fsize14"
                                          value={commentMessage}
                                          onChange={(e) =>
                                            setCommentMessage(e.target.value)
                                          }
                                          maxLength={250}
                                          onKeyDown={(e) =>
                                            e.key === "Enter"
                                              ? handleSubmitComment(post)
                                              : ""
                                          }
                                        />
                                        <InputRightElement width="6.5rem">
                                          <Button
                                            isLoading={commentLoading}
                                            spinner={
                                              <ThreeDots
                                                visible={true}
                                                color="#ed1b24"
                                                radius={3}
                                              />
                                            }
                                            h="1.75rem"
                                            size="sm"
                                            fontSize={12}
                                            mr={1}
                                            onClick={() =>
                                              handleSubmitComment(post)
                                            }
                                          >
                                            COMMENT
                                          </Button>
                                        </InputRightElement>
                                      </InputGroup>
                                    </div>
                                    {commentData &&
                                      commentData.result &&
                                      commentData.result.length > 0 &&
                                      commentData.result.map((comment) => (
                                        <div className="p-1 lg:flex md:flex flex-start w-full gap-3">
                                          <div className="w-full">
                                            <div className="bg-f4f4f4 p-2 rounded-lg">
                                              <div className="flex gap-2">
                                                <Image
                                                  src={`${
                                                    process.env
                                                      .REACT_APP_STORAGE_URL
                                                  }${
                                                    comment &&
                                                    comment.userId &&
                                                    comment.userId[0] &&
                                                    comment.userId[0].userImage
                                                      ? comment.userId[0]
                                                          .userImage
                                                      : null
                                                  }`}
                                                  fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                                  alt="logo"
                                                  className="mt-1 w-8 h-8 rounded-full "
                                                />
                                                <div className="w-full ">
                                                  <div className="bg-fbfbfb flex gap-2 w-full justify-between p-2 rounded-md ">
                                                    <div>
                                                      <h2 className="font-semibold fsize16 sm-fsize14 uppercase">
                                                        {comment.userId &&
                                                        comment.userId.length >
                                                          0 &&
                                                        comment.userId[0] &&
                                                        comment.userId[0]
                                                          .firstName
                                                          ? comment.userId[0]
                                                              .firstName
                                                          : ""}{" "}
                                                        {comment.userId &&
                                                        comment.userId.length >
                                                          0 &&
                                                        comment.userId[0] &&
                                                        comment.userId[0]
                                                          .lastName
                                                          ? comment.userId[0]
                                                              .lastName
                                                          : ""}
                                                      </h2>
                                                      <p className="fsize14 sm-fsize12 mb-2 ">
                                                        {comment.message}
                                                      </p>
                                                    </div>
                                                    {comment?.userId[0]?._id ===
                                                      userData._id && (
                                                      <DeleteIcon
                                                        onClick={async () => {
                                                          Swal.fire({
                                                            title: "warning",
                                                            text: "Are you sure?",
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor:
                                                              "#2bc370",
                                                            cancelButtonColor:
                                                              "#d33",
                                                            confirmButtonText:
                                                              "Yes, delete it!",
                                                          }).then(
                                                            async (result) => {
                                                              if (
                                                                result.isConfirmed
                                                              ) {
                                                                await handleDeleteComment(
                                                                  comment,
                                                                  post
                                                                );
                                                              }
                                                            }
                                                          );
                                                        }}
                                                        className="fsize14 mt-1 mr-1 cursor-pointer"
                                                      />
                                                    )}
                                                  </div>
                                                  {/* <div className="flex items-center gap-4 p-1">
                                                        <div className="flex gap-1 text-gray items-center fsize12 cursor-pointer">
                                                          <FeatherIcon
                                                            icon="thumbs-up"
                                                            size={12}
                                                          />{" "}
                                                          (1)
                                                        </div>
                                                        <div className="flex gap-1 text-gray items-center fsize12 cursor-pointer">
                                                          <FeatherIcon
                                                            icon="message-circle"
                                                            size={12}
                                                          />{" "}
                                                          (0)
                                                        </div>
                                                      </div> */}
                                                  <div className=" gap-4 px-2 pt-2">
                                                    <p className="flex gap-8">
                                                      <div className="flex items-center gap-1 cursor-pointer">
                                                        <Popover placement="top-start">
                                                          <PopoverTrigger>
                                                            <h2
                                                              px={0}
                                                              py={0}
                                                              className="btnn"
                                                            >
                                                              {comment.likes &&
                                                              comment.likes
                                                                .length > 0 &&
                                                              comment
                                                                .likes[0] &&
                                                              comment.likes[0]
                                                                .emojiId &&
                                                              emojiData &&
                                                              emojiData.result &&
                                                              emojiData.result
                                                                .length > 0 ? (
                                                                getLikedEmoji(
                                                                  comment,
                                                                  emojiData
                                                                )
                                                              ) : (
                                                                <div className="flex gap-1 items-center fsize12 cursor-pointer">
                                                                  <FeatherIcon
                                                                    icon="thumbs-up"
                                                                    colorScheme="primary"
                                                                    size={14}
                                                                  />{" "}
                                                                  Likes
                                                                </div>
                                                              )}
                                                            </h2>
                                                          </PopoverTrigger>
                                                          <PopoverContent
                                                            className="fitcontent"
                                                            border={0}
                                                          >
                                                            <PopoverBody
                                                              padding={0}
                                                              border={0}
                                                            >
                                                              <div className="flex p1020 gap-2">
                                                                {emojiData &&
                                                                emojiData.result &&
                                                                emojiData.result
                                                                  .length > 0
                                                                  ? emojiData.result.map(
                                                                      (
                                                                        emoji
                                                                      ) => (
                                                                        <div>
                                                                          <Image
                                                                            onClick={async () => {
                                                                              let success =
                                                                                await handleCommentLike(
                                                                                  comment,
                                                                                  emoji
                                                                                );

                                                                              if (
                                                                                success
                                                                              ) {
                                                                                await handleSingleComment(
                                                                                  comment,
                                                                                  post
                                                                                );
                                                                              }
                                                                            }}
                                                                            src={`${
                                                                              process
                                                                                .env
                                                                                .REACT_APP_STORAGE_URL
                                                                            }${
                                                                              emoji.media
                                                                                ? emoji.media
                                                                                : null
                                                                            }`}
                                                                            alt={
                                                                              emoji.name
                                                                            }
                                                                            className="w-5 w-5"
                                                                          />
                                                                        </div>
                                                                      )
                                                                    )
                                                                  : "No Emoji"}
                                                              </div>
                                                            </PopoverBody>
                                                          </PopoverContent>
                                                        </Popover>
                                                        <p className="fsize12">
                                                          {comment.totalLikes
                                                            ? comment.totalLikes
                                                            : ""}
                                                        </p>
                                                      </div>

                                                      <div
                                                        className="flex gap-1 items-center fsize12 cursor-pointer"
                                                        onClick={() =>
                                                          handleReply(
                                                            comment,
                                                            post
                                                          )
                                                        }
                                                      >
                                                        <FeatherIcon
                                                          icon="message-circle"
                                                          colorScheme="primary"
                                                          size={14}
                                                        />{" "}
                                                        Reply{" "}
                                                        <div>
                                                          {comment.totalReplies >
                                                          0
                                                            ? comment.totalReplies
                                                            : 0}
                                                        </div>
                                                      </div>
                                                    </p>

                                                    {fetchReplyLoading ? (
                                                      <div className="flex justify-center">
                                                        {isReplyOpen &&
                                                          isReplyOpen ===
                                                            comment._id && (
                                                            <ThreeDots
                                                              visible={true}
                                                              color="#ed1b24"
                                                              radius={3}
                                                            />
                                                          )}
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {isReplyOpen &&
                                                          isReplyOpen ===
                                                            comment._id && (
                                                            <>
                                                              <div className="pt-4 pl-2">
                                                                <InputGroup size="md">
                                                                  <Input
                                                                    pr="7rem"
                                                                    placeholder="Enter comment"
                                                                    className="bg-fff fsize14"
                                                                    value={
                                                                      replyMessage
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setReplyMessage(
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    maxLength={
                                                                      250
                                                                    }
                                                                    onKeyDown={(
                                                                      e
                                                                    ) =>
                                                                      e.key ===
                                                                      "Enter"
                                                                        ? handleSubmitReply(
                                                                            comment,
                                                                            post
                                                                          )
                                                                        : ""
                                                                    }
                                                                  />
                                                                  <InputRightElement width="4.5rem">
                                                                    <Button
                                                                      isLoading={
                                                                        replyLoading
                                                                      }
                                                                      spinner={
                                                                        <ThreeDots
                                                                          visible={
                                                                            true
                                                                          }
                                                                          color="#ed1b24"
                                                                          radius={
                                                                            3
                                                                          }
                                                                        />
                                                                      }
                                                                      h="1.75rem"
                                                                      size="sm"
                                                                      fontSize={
                                                                        10
                                                                      }
                                                                      mr={1}
                                                                      onClick={() =>
                                                                        handleSubmitReply(
                                                                          comment,
                                                                          post
                                                                        )
                                                                      }
                                                                    >
                                                                      REPLY
                                                                    </Button>
                                                                  </InputRightElement>
                                                                </InputGroup>
                                                              </div>
                                                              {replyData &&
                                                                replyData.result &&
                                                                replyData.result
                                                                  .length > 0 &&
                                                                replyData.result.map(
                                                                  (reply) => (
                                                                    <div className="pt-2 pl-0 lg:flex md:flex flex-start w-full gap-3">
                                                                      <div className="w-full">
                                                                        <div className="bg-f4f4f4 p-2 rounded-lg">
                                                                          <div className="flex gap-2 pl-2">
                                                                            <Image
                                                                              src={`${
                                                                                process
                                                                                  .env
                                                                                  .REACT_APP_STORAGE_URL
                                                                              }${
                                                                                reply &&
                                                                                reply.userId &&
                                                                                reply
                                                                                  .userId[0] &&
                                                                                reply
                                                                                  .userId[0]
                                                                                  .userImage
                                                                                  ? reply
                                                                                      .userId[0]
                                                                                      .userImage
                                                                                  : null
                                                                              }`}
                                                                              fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                                                              alt="logo"
                                                                              className="mt-1 w-8 h-8 rounded-full "
                                                                            />
                                                                            <div className="w-full ">
                                                                              <div className="bg-fbfbfb flex gap-2 w-full justify-between p-2 rounded-md">
                                                                                <div>
                                                                                  <h2 className="font-semibold fsize14 sm-fsize12 uppercase">
                                                                                    {reply.userId &&
                                                                                    reply
                                                                                      .userId
                                                                                      .length >
                                                                                      0 &&
                                                                                    reply
                                                                                      .userId[0] &&
                                                                                    reply
                                                                                      .userId[0]
                                                                                      .firstName
                                                                                      ? reply
                                                                                          .userId[0]
                                                                                          .firstName
                                                                                      : ""}{" "}
                                                                                    {reply.userId &&
                                                                                    reply
                                                                                      .userId
                                                                                      .length >
                                                                                      0 &&
                                                                                    reply
                                                                                      .userId[0] &&
                                                                                    reply
                                                                                      .userId[0]
                                                                                      .lastName
                                                                                      ? reply
                                                                                          .userId[0]
                                                                                          .lastName
                                                                                      : ""}
                                                                                  </h2>
                                                                                  <p className="fsize12 mb-2 ">
                                                                                    {
                                                                                      reply.message
                                                                                    }
                                                                                  </p>
                                                                                </div>
                                                                                {reply
                                                                                  ?.userId[0]
                                                                                  ?._id ===
                                                                                  userData._id && (
                                                                                  <DeleteIcon
                                                                                    onClick={async () => {
                                                                                      Swal.fire(
                                                                                        {
                                                                                          title:
                                                                                            "warning",
                                                                                          text: "Are you sure?",
                                                                                          icon: "warning",
                                                                                          showCancelButton: true,
                                                                                          confirmButtonColor:
                                                                                            "#2bc370",
                                                                                          cancelButtonColor:
                                                                                            "#d33",
                                                                                          confirmButtonText:
                                                                                            "Yes, delete it!",
                                                                                        }
                                                                                      ).then(
                                                                                        async (
                                                                                          result
                                                                                        ) => {
                                                                                          if (
                                                                                            result.isConfirmed
                                                                                          ) {
                                                                                            await handleDeleteReply(
                                                                                              reply,
                                                                                              comment,
                                                                                              post
                                                                                            );
                                                                                          }
                                                                                        }
                                                                                      );
                                                                                    }}
                                                                                    className="fsize14 mt-1 mr-1 cursor-pointer"
                                                                                  />
                                                                                )}
                                                                              </div>
                                                                              <div className="flex items-center gap-4 pt-2 pl-1">
                                                                                <Popover placement="top-start">
                                                                                  <PopoverTrigger>
                                                                                    <h2
                                                                                      px={
                                                                                        0
                                                                                      }
                                                                                      py={
                                                                                        0
                                                                                      }
                                                                                      className="btnn"
                                                                                    >
                                                                                      {reply.likes &&
                                                                                      reply
                                                                                        .likes
                                                                                        .length >
                                                                                        0 &&
                                                                                      reply
                                                                                        .likes[0] &&
                                                                                      reply
                                                                                        .likes[0]
                                                                                        .emojiId &&
                                                                                      emojiData &&
                                                                                      emojiData.result &&
                                                                                      emojiData
                                                                                        .result
                                                                                        .length >
                                                                                        0 ? (
                                                                                        getLikedEmoji(
                                                                                          reply,
                                                                                          emojiData
                                                                                        )
                                                                                      ) : (
                                                                                        <div className="flex gap-1 items-center fsize12 cursor-pointer">
                                                                                          <FeatherIcon
                                                                                            icon="thumbs-up"
                                                                                            colorScheme="primary"
                                                                                            size={
                                                                                              14
                                                                                            }
                                                                                          />{" "}
                                                                                          Likes
                                                                                        </div>
                                                                                      )}
                                                                                    </h2>
                                                                                  </PopoverTrigger>
                                                                                  <PopoverContent
                                                                                    className="fitcontent"
                                                                                    border={
                                                                                      0
                                                                                    }
                                                                                  >
                                                                                    <PopoverBody
                                                                                      padding={
                                                                                        0
                                                                                      }
                                                                                      border={
                                                                                        0
                                                                                      }
                                                                                    >
                                                                                      <div className="flex p1020 gap-2">
                                                                                        {emojiData &&
                                                                                        emojiData.result &&
                                                                                        emojiData
                                                                                          .result
                                                                                          .length >
                                                                                          0
                                                                                          ? emojiData.result.map(
                                                                                              (
                                                                                                emoji
                                                                                              ) => (
                                                                                                <div>
                                                                                                  <Image
                                                                                                    onClick={async () => {
                                                                                                      let success =
                                                                                                        await handleReplyLike(
                                                                                                          reply,
                                                                                                          emoji
                                                                                                        );

                                                                                                      if (
                                                                                                        success
                                                                                                      ) {
                                                                                                        await handleSingleReply(
                                                                                                          reply,
                                                                                                          comment,
                                                                                                          post
                                                                                                        );
                                                                                                      }
                                                                                                    }}
                                                                                                    src={`${
                                                                                                      process
                                                                                                        .env
                                                                                                        .REACT_APP_STORAGE_URL
                                                                                                    }${
                                                                                                      emoji.media
                                                                                                        ? emoji.media
                                                                                                        : null
                                                                                                    }`}
                                                                                                    alt={
                                                                                                      emoji.name
                                                                                                    }
                                                                                                    className="w-5 h-5 rounded-full cursor-pointer"
                                                                                                  />
                                                                                                </div>
                                                                                              )
                                                                                            )
                                                                                          : "No Emoji"}
                                                                                      </div>
                                                                                    </PopoverBody>
                                                                                  </PopoverContent>
                                                                                </Popover>
                                                                                <div>
                                                                                  <p className="fsize12">
                                                                                    {reply.totalLikes
                                                                                      ? reply.totalLikes
                                                                                      : ""}
                                                                                  </p>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                )}
                                                            </>
                                                          )}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="text-center">
                        <p>No Post</p>
                      </div>
                    )}
                  </div>
                </TabPanel>
                <TabPanel className="px-0px w-full">
                  <div className="pt-0">
                    <div className="flex w-full justify-between items-center">
                      <p className="flex items-center font-semibold uppercase fsize18 sm-fsize16">
                        {`[${selectedTrainer.rating}]`}{" "}
                        <ReactStars
                          count={1}
                          value={1}
                          size={20}
                          edit={false}
                          activeColor="#ffd700"
                        />
                      </p>
                      <Button
                        width="120px"
                        md-width="90px"
                        height="40px"
                        variant="outline"
                        colorScheme="primary"
                        className="fsize14"
                        onClick={() => {
                          if (userData && userData._id) {
                            formik.handleReset();
                            onOpenReview();
                            return;
                          }
                          toast.warning(`Please login`);
                          navigate("/login");
                        }}
                      >
                        Add Review
                      </Button>
                      <Modal isOpen={isOpenReview} onClose={onCloseReview}>
                        <ModalOverlay />
                        <ModalContent>
                          <form onSubmit={formik.handleSubmit}>
                            <ModalHeader>Add a Review</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                              <div className="flex w-full gap-4">
                                <FormControl
                                  isInvalid={
                                    !!formik.errors.firstName &&
                                    formik.touched.firstName
                                  }
                                >
                                  <FormLabel>First name</FormLabel>
                                  <Input
                                    disabled
                                    name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={handleTrim}
                                    placeholder="First Name"
                                  />
                                  {formik.touched.firstName &&
                                    formik.errors.firstName && (
                                      <FormErrorMessage>
                                        {formik.errors.firstName}
                                      </FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl
                                  isInvalid={
                                    !!formik.errors.lastName &&
                                    formik.touched.lastName
                                  }
                                >
                                  <FormLabel>Last name</FormLabel>
                                  <Input
                                    disabled
                                    name="lastName"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={handleTrim}
                                    placeholder="Last Name"
                                  />
                                  {formik.touched.lastName &&
                                    formik.errors.lastName && (
                                      <FormErrorMessage>
                                        {formik.errors.lastName}
                                      </FormErrorMessage>
                                    )}
                                </FormControl>
                              </div>

                              <FormControl
                                mt={4}
                                isInvalid={
                                  !!formik.errors.testimonial &&
                                  formik.touched.testimonial
                                }
                              >
                                <FormLabel>Testimonial</FormLabel>
                                <Textarea
                                  name="testimonial"
                                  value={formik.values.testimonial}
                                  onChange={formik.handleChange}
                                  onBlur={handleTrim}
                                  placeholder="Here is a sample placeholder"
                                />
                                {formik.touched.testimonial &&
                                  formik.errors.testimonial && (
                                    <FormErrorMessage>
                                      {formik.errors.testimonial}
                                    </FormErrorMessage>
                                  )}
                              </FormControl>

                              <FormControl
                                mt={4}
                                isInvalid={
                                  !!formik.errors.rating &&
                                  formik.touched.rating
                                }
                              >
                                <FormLabel>Rating</FormLabel>
                                {/* <Input placeholder="Last name" /> */}
                                <Rating
                                  name="rating"
                                  onChange={(e) =>
                                    formik.setFieldValue("rating", e)
                                  }
                                  initialRating={formik.values.rating}
                                  emptySymbol={
                                    <FeatherIcon
                                      icon="star"
                                      className="mr-1"
                                      size={22}
                                    />
                                  }
                                  fullSymbol={
                                    <FeatherIcon
                                      icon="star"
                                      className="mr-1 fillstroke"
                                      size={22}
                                    />
                                  }
                                />
                                {formik.touched.rating &&
                                  formik.errors.rating && (
                                    <FormErrorMessage>
                                      {formik.errors.rating}
                                    </FormErrorMessage>
                                  )}
                              </FormControl>
                            </ModalBody>

                            <ModalFooter justifyContent="center">
                              <Button type="submit" colorScheme="primary">
                                Submit
                              </Button>
                            </ModalFooter>
                          </form>
                        </ModalContent>
                      </Modal>
                    </div>
                    <div className="pt-3">
                      {selectedTrainer.testimonials &&
                      selectedTrainer.testimonials.length > 0 ? (
                        selectedTrainer.testimonials.map((e) => {
                          return (
                            <div className="mb-6 lg:flex md:flex flex-start w-full gap-3">
                              <div className="w-full">
                                <div className="bg-f4f4f4 p-2 rounded-lg">
                                  <div className="flex justify-between">
                                    <div className="flex items-center gap-2">
                                      <Image
                                        fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                                        src={`${
                                          process.env.REACT_APP_STORAGE_URL
                                        }${
                                          e.userId &&
                                          e.userId.length > 0 &&
                                          e.userId[0].userImage
                                        }`}
                                        alt="logo"
                                        className="w-6 h-6 rounded-full "
                                      />
                                      <h2 className="font-semibold fsize16 uppercase">
                                        {e.name ? e.name : "Unknown"}
                                      </h2>
                                      <div className="flex items-center gap-1">
                                        <span className="fsize6 text-gray">
                                          &#9679;
                                        </span>
                                        <p className="text-gray fsize12 mr-2 pt-1">
                                          {e.createdAt &&
                                            new Date(
                                              e.createdAt
                                            ).toLocaleDateString("en-IN", {
                                              month: "long",
                                              day: "numeric",
                                              year: "numeric",
                                            })}
                                        </p>
                                      </div>
                                    </div>
                                    <p className="flex items-center">
                                      <ReactStars
                                        count={5}
                                        value={e.rating ? e.rating : "0"}
                                        size={20}
                                        edit={false}
                                        activeColor="#ffd700"
                                      />
                                    </p>
                                  </div>

                                  <p className="fsize14 mt-1">
                                    {e.testimonial
                                      ? e.testimonial
                                      : "No testimonial"}{" "}
                                  </p>
                                </div>
                                {/* <div className="flex items-center gap-8 mt-2">
                                  <div className="flex gap-2">
                                    <FaRegThumbsUp />
                                    <p className="fsize12">Helpful(0)</p>
                                  </div>
                                  <p className="fsize12">Reply (0)</p>
                                </div> */}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center">No Reviews</p>
                      )}
                    </div>
                    {/* {selectedTrainer.testimonials &&
                      selectedTrainer.testimonials.length > 0 && (
                        <h2
                          onClick={loadMoreContents}
                          className="cursor-pointer text-primary uppercase fsize16 md:fsize14 sm-fsize14 underline"
                        >
                          LOAD MORE COMMENTS
                        </h2>
                      )} */}
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
          <div className="w-35 lg:mt-0 md:mt-0 mt-6 lg:block md:block hidden">
            <div className="flex flex-col gap-4">
              <div className="rounded-lg bg-f4f4f4 p-1">
                <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                  specialisation
                </h2>
                <div className="py-2 px-2">
                  <div className="flex flex-wrap gap-4">
                    {selectedTrainer.specialization &&
                    selectedTrainer.specialization.length > 0
                      ? selectedTrainer.specialization.map((e) => {
                          return (
                            <Button className="fsize14 bg-fff">{e}</Button>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              {/* <div className="rounded-lg bg-f4f4f4 p-1">
                <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                  prefferred time
                </h2>
                <div className="py-2 px-2">
                  <div className="flex flex-wrap gap-4">
                    {selectedTrainer.preferredTime &&
                    selectedTrainer.preferredTime.length > 0
                      ? selectedTrainer.preferredTime.map((e) => {
                          return (
                            <Button
                              variant="outline"
                              colorScheme="primary"
                              className="fsize12px bg-fff"
                            >
                              {e}
                            </Button>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div> */}
              <div className="rounded-lg bg-f4f4f4 p-1">
                <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                  language
                </h2>
                <div className="py-2 px-2">
                  <div className="flex flex-wrap gap-4">
                    {selectedTrainer.language &&
                    selectedTrainer.language.length > 0
                      ? selectedTrainer.language.map((e) => {
                          return (
                            <Button className="fsize14 bg-fff">{e}</Button>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-f4f4f4 p-1">
                <h2 className="clr-848484  px-2 py-1.5 uppercase font-semibold">
                  Fitness Categories
                </h2>
                <div className="py-2 px-2">
                  <div className="flex flex-wrap gap-4">
                    {selectedTrainer.fitnessCategories &&
                    selectedTrainer.fitnessCategories.length > 0
                      ? selectedTrainer.fitnessCategories.map((e) => {
                          return (
                            <Button className="fsize14 bg-fff">
                              {e.categoryName}
                            </Button>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserProfile;
