const initialState = {
  Comment: {},
};

const Comment = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMMENT":
      return { ...state, Comment: action.payload };
    case "SET_SINGLE_COMMENT":
      if (action.payload.result && action.payload.result.length > 0) {
        let comment = action.payload.result[0];
        let index = state.Comment.result.findIndex(
          (co) => co._id === comment._id
        );
        if (index !== -1) {
          const newComment = [...state.Comment.result];
          newComment[index] = comment;
          return {
            ...state,
            Comment: { ...state.Comment, result: newComment },
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default Comment;
