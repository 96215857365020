const initialState = {
  Reply: {},
};

const Reply = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REPLY":
      return { ...state, Reply: action.payload };
    case "SET_SINGLE_REPLY":
      if (action.payload.result && action.payload.result.length > 0) {
        let reply = action.payload.result[0];
        let index = state.Reply.result.findIndex((re) => re._id === reply._id);
        if (index !== -1) {
          const newReply = [...state.Reply.result];
          newReply[index] = reply;
          return {
            ...state,
            Reply: { ...state.Reply, result: newReply },
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default Reply;
