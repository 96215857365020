import React from 'react';
import { EffectCoverflow,Navigation } from "swiper/modules";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
const Testimonial1 = () => {
  return (
    <section>
      <div className="container mx-auto my-20 flex gap-8">
        <div className="lg:w-2/5 lg:pr-8">
          <h3 className="fsize24 font-semibold clr-ddd9d8 lg:mb-2">
            Lorem Ipsum
          </h3>
          <h1 className="fsize40 font-semibold lg:mb-8">Lorem Ipsum</h1>
          <p className="fsize18">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
        <div className="lg:w-3/5">
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper container relative px-2 overflow-x-hidden pb-2 newswiper"
            autoplay={{
              delay: '1000',
            }}
            navigation={{
              nextEl: '.image-swiper-button-nexts',
              prevEl: '.image-swiper-button-prevs',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1280: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 12,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prevs cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-nexts cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="testimonial-card ">
                <p className="fsize18 pb-8">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent augue ipsum, pellentesque sit amet viverra nec,
                  tempus at odio. Curabitur tempor molestie enim eget tristique.
                  Sed mi libero, semper quis lorem a, ultrices molestie arcu.
                </p>
                <div className="flex items-center gap-6  w-full">
                  <div className="testimonial-div flex items-center">
                    <img
                      src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                      alt=""
                      className="testimonial-img rounded-full object-cover"
                    />
                  </div>
                  <div className="w-full text-start">
                    <h2 className="fsize24 font-semibold ">John Deo</h2>
                    <p className="fsize18">no data</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonial1;
