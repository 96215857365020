import React from 'react';

const Home4Section4 = () => {
  return (
    <section className="container mx-auto">
      <div className="text-center  flex flex-col justify-center lg:mb-16">
        <h3 className="letter-spacing4px fsize18 font-semibold clr-fff ">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold clr-fff lg:mb-4">Lorem Ipsum</h1>
        <div className="clr-fff">
          <p className="w-50 mx-auto fsize18 text-center">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 lg:gap-8 w-full lg:px-20 md:px-16">
        <div className="lg:col-span-2 md:col-span-2 w-full">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
        <div className="w-full">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
        <div className="w-full">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
        <div className="lg:col-span-2 md:col-span-2 w-full">
          <img
            src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
            alt="imagee"
            className="w-full object-cover shadow-ffffff br-16px h-280px"
          />
        </div>
      </div>
    </section>
  );
};

export default Home4Section4;
