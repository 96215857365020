import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import {
  CreateNewPassword,
  ToggleAccount,
} from "../../../../redux/auth/auth-action";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import FeatherIcon from "feather-icons-react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const ManageAccount = () => {
  let userDetail = useSelector((state) => state.AuthDetails.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentPasswordShow, setCurrentPasswordShow] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [deactivatePasswordShow, setDeactivatePasswordShow] = useState(false);
  const [deletePasswordShow, setDeletePasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deactivateField, setDeactivateField] = useState(false);

  const [deactivatePassword, setDeactivatePassword] = useState("");
  const [deletePassword, setDeletePassword] = useState("");

  const handleCurrentPasswordShow = () =>
    setCurrentPasswordShow((state) => !state);
  const handlePasswordShow = () => setPasswordShow((state) => !state);
  const handleConfirmPasswordShow = () =>
    setConfirmPasswordShow((state) => !state);
  const handleDeactivatePasswordShow = () =>
    setDeactivatePasswordShow((state) => !state);
  const handleDeletePasswordShow = () =>
    setDeletePasswordShow((state) => !state);

  const initialValues = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const validationRules = Yup.object({
    currentPassword: Yup.string()
      // .min(8, "Current password must be at least 8 characters")
      .required("Current password is required"),
    password: Yup.string()
      .min(8, "New password must be at least 8 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Confirm password must match"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      email: userDetail.email.toLowerCase(),
      password: values.currentPassword,
      newPassword: values.password,
    };
    try {
      setIsLoading(true);
      let data = await dispatch(CreateNewPassword(payload));
      console.log(data);
      if (data && data.code == 200) {
        formik.handleReset();
        toast.success(`Password reset successfully`);
      } else {
        if (data.message) {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeactivateAccount = async () => {
    if (deactivatePassword !== "") {
      Swal.fire({
        // title: "warning",
        text: "Are you sure, you want to deactivate your account?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2bc370",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (!deactivatePassword) {
            return;
          }
          let payload = {
            email: userDetail.email.toLowerCase(),
            password: deactivatePassword,
          };
          try {
            let data = await dispatch(ToggleAccount(payload));
            console.log(data);
            if (data && data.code == 200) {
              formik.handleReset();
              navigate("/login");
              toast.success(`Account deactivated successfully`);
            } else {
              if (data.message) {
                toast.error(data.message);
              }
            }
          } catch (error) {
            toast.error("Something went wrong!");
          }
        }
      });
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <div className="lg:p-6 md:p-6 lg:border md:border rounded-3px">
      <div className="bottom-border-ebebeb">
        <h2 className=" fsize20 font-semibold pb-4  clr-606060">
          Manage Account
        </h2>
      </div>
      <div className="w-full lg:flex md:flex gap-4 py-6">
        <div className="lg:w-1/2 border rounded-lg px-3 py-2 lg:mb-0 md:mb-0 mb-4">
          <h2 className="fsize16 font-semibold">
            {/* Activite/ */}
            Deactivate account
          </h2>
          <p className="fsize12 clr-848484 my-1">
            {/* Activite/ */}
            Account Deactivation will not delete your account it will hide your
            profile from public domain until you will not activate it yourself.
          </p>
          <div className="mt-4">
            {deactivateField ? (
              <>
                <InputGroup>
                  <Input
                    autoComplete={false}
                    name="deactivatePassword"
                    value={deactivatePassword}
                    onChange={(e) => setDeactivatePassword(e.target.value)}
                    onBlur={(e) => setDeactivatePassword(e.target.value.trim())}
                    type={deactivatePasswordShow ? "text" : "password"}
                    placeholder="Enter your password"
                    className="mb-2"
                  />
                  <InputRightElement paddingTop="0">
                    <div
                      onClick={() => {
                        handleDeactivatePasswordShow();
                      }}
                    >
                      {deactivatePasswordShow ? (
                        <FeatherIcon icon="eye" size={16} />
                      ) : (
                        <FeatherIcon size={16} icon="eye-off" />
                      )}
                    </div>
                  </InputRightElement>
                </InputGroup>
                <div className="flex gap-2 justify-end">
                  {/* <p
                    className="text-primary cursor-pointer fsize12 font-semibold"
                    onClick={() => handleDeactivateAccount()}
                  >
                    Submit
                  </p> */}
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    py="4px"
                    height="fit-content"
                    className="text-secondary hoverr cursor-pointer fsize12 font-semibold"
                    onClick={() => setDeactivateField(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    py="4px"
                    height="fit-content"
                    colorScheme="primary"
                    onClick={() => handleDeactivateAccount()}
                    className={
                      deactivatePassword !== ""
                        ? "text-white border-primary cursor-pointer fsize12 font-semibold"
                        : "text-white border-primary cursor-nodrop fsize12 font-semibold"
                    }
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              <p
                className="text-primary cursor-pointer fsize12 font-semibold"
                // onClick={() => handleDeactivateAccount()}
                onClick={() => setDeactivateField(true)}
              >
                Deactivate
              </p>
            )}
          </div>
        </div>

        {/* <div className="lg:w-full border rounded-lg px-3 py-2">
          <h2 className="fsize16 font-semibold">Delete account</h2>
          <p class="fsize12 clr-848484 my-1">
            Account Deactivation will not delete your account it will hide your
            profile from public domain will not activate it yourself.
          </p>
          <div className="mt-4">
            {" "}
            <InputGroup>
              <Input
                autoComplete={false}
                name="deletePassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type={deletePasswordShow ? "text" : "password"}
                placeholder="Enter your password"
                className="mb-2"
              />
              <InputRightElement paddingTop="0">
                <div
                  onClick={() => {
                    handleDeletePasswordShow();
                  }}
                >
                  {deletePasswordShow ? (
                    <FeatherIcon icon="eye" size={16} />
                  ) : (
                    <FeatherIcon size={16} icon="eye-off" />
                  )}
                </div>
              </InputRightElement>
            </InputGroup>
            <p className="text-primary fsize12 font-semibold">Delete</p>
          </div>
        </div> */}
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="bottom-border-ebebeb">
          <h2 className=" fsize20 font-semibold pb-4  clr-606060">
            Manage Password
          </h2>
        </div>
        <div className="w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 py-6">
          <FormControl
            isInvalid={
              !!formik.errors.currentPassword && formik.touched.currentPassword
            }
          >
            <FormLabel className="fsize14">Enter Current Password</FormLabel>
            <InputGroup>
              <Input
                autoComplete={false}
                name="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type={currentPasswordShow ? "text" : "password"}
                placeholder="Enter your current password"
              />
              <InputRightElement paddingTop="0">
                <div
                  onClick={() => {
                    handleCurrentPasswordShow();
                  }}
                >
                  {currentPasswordShow ? (
                    <FeatherIcon icon="eye" size={16} />
                  ) : (
                    <FeatherIcon size={16} icon="eye-off" />
                  )}
                </div>
              </InputRightElement>
            </InputGroup>
            {formik.touched.currentPassword &&
              formik.errors.currentPassword && (
                <FormErrorMessage>
                  {formik.errors.currentPassword}
                </FormErrorMessage>
              )}
          </FormControl>
          <FormControl
            isInvalid={!!formik.errors.password && formik.touched.password}
          >
            <FormLabel className="fsize14">Enter New Password</FormLabel>
            <InputGroup>
              <Input
                autoComplete={false}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                placeholder="Enter your new password"
                type={passwordShow ? "text" : "password"}
              />
              <InputRightElement paddingTop="0">
                <div
                  onClick={() => {
                    handlePasswordShow();
                  }}
                >
                  {passwordShow ? (
                    <FeatherIcon icon="eye" size={16} />
                  ) : (
                    <FeatherIcon size={16} icon="eye-off" />
                  )}
                </div>
              </InputRightElement>
            </InputGroup>
            {formik.touched.password && formik.errors.password && (
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isInvalid={
              !!formik.errors.confirmPassword && formik.touched.confirmPassword
            }
          >
            <FormLabel className="fsize14">Confirm New Password</FormLabel>
            <InputGroup>
              <Input
                autoComplete={false}
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={handleTrim}
                type={confirmPasswordShow ? "text" : "password"}
                placeholder="Enter your new password again"
              />
              <InputRightElement paddingTop="0">
                <div
                  onClick={() => {
                    handleConfirmPasswordShow();
                  }}
                >
                  {confirmPasswordShow ? (
                    <FeatherIcon icon="eye" size={16} />
                  ) : (
                    <FeatherIcon size={16} icon="eye-off" />
                  )}
                </div>
              </InputRightElement>
            </InputGroup>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <FormErrorMessage>
                  {formik.errors.confirmPassword}
                </FormErrorMessage>
              )}
          </FormControl>
          <div className="flex items-center lg:pt-4 md:pt-4">
            <p className="fsize14 md-fsize12  sm-fsize12  fweight-500 mr-1">
              Forgot your password?{" "}
            </p>
            <span className="text-primary font-semibold fsize14 cursor-pointer">
              <Link to="/forgot password">Click here</Link>
            </span>
          </div>
        </div>
        <div className="mt-4">
          <Button
            spinner={<ThreeDots visible={true} color="white" radius={3} />}
            isLoading={isLoading}
            type="submit"
            colorScheme="primary"
            px="8"
            py="4"
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ManageAccount;
