import { Button } from "@chakra-ui/button";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "../../../src/redux/payment/stripe-actions";
import { useEffect } from "react";

const Payment = ({
  button,
  itemList,
  successUrl,
  cancelUrl,
  buttonClass,
  buttonColor,
  planId,
  userId,
  email,
  isDisabled = false,
  noButton = false,
  onClick = false,
}) => {
  let gateway = useSelector((state) => state.gateways.gateway);
  const dispatch = useDispatch();

  useEffect(() => {
    if (onClick) {
      handlePayment();
    }
  }, [onClick]);

  async function handlePayment() {
    // Use the Stripe.js methods to handle the payment
    const stripe = await loadStripe(gateway.clientId);
    let payload = {
      items: itemList, //---------->   // [
      //              //   {
      //     name: "Test",
      //     quantity: 1,
      //     price: 1,
      //   },
      // ]

      currency: "SGD",
      successUrl: `${process.env.REACT_APP_FRONTEND_URL}/${successUrl}`,
      cancelUrl: `${process.env.REACT_APP_FRONTEND_URL}/${cancelUrl}`,
    };

    if (planId) {
      Object.assign(payload, { planId: planId });
    }
    if (userId) {
      Object.assign(payload, { userId: userId });
    }
    if (email) {
      Object.assign(payload, { email: email });
    }

    let { data } = await dispatch(createCheckoutSession(payload));
    console.log(data);
    const result = stripe.redirectToCheckout({
      sessionId: data.id,
    });

    if (result.error) {
      console.log(result.error);
    }
  }

  return (
    <>
      {!noButton && (
        <section>
          <Button
            px="8"
            py="6"
            isDisabled={isDisabled}
            colorScheme={buttonColor}
            className={buttonClass}
            onClick={() => handlePayment()}
          >
            {button}
          </Button>
        </section>
      )}
    </>
  );
};

export default Payment;
