import API from "../repositoryFactory";

const createFitnessCategory = "create";
const getFitnessCategory = "get";
const updateFitnessCategory = "update";
const deleteFitnessCategory = "delete";

const fitnessCategoryRoute = (route) => {
  return `fit2go/fitnessCategory/${route}`;
};

export default {
  createFitnessCategory(payload) {
    return API.post(fitnessCategoryRoute(createFitnessCategory), payload);
  },
  getFitnessCategory(payload) {
    return API.post(fitnessCategoryRoute(getFitnessCategory), payload);
  },
  updateFitnessCategory(payload) {
    return API.post(fitnessCategoryRoute(updateFitnessCategory), payload);
  },
  deleteFitnessCategory(payload) {
    return API.post(fitnessCategoryRoute(deleteFitnessCategory), payload);
  },
};
