import social from "./social";
import subscription from "./subscription";
import testimonial from "./testimonial";
import trainer from "./trainer";
import fitnessCategory from "./fitnessCategory";

export default {
  ...social,
  ...subscription,
  testimonial: testimonial,
  trainer: trainer,
  fitnessCategory: fitnessCategory,
};
