//CUSTOM
import CustomApi from "./custom/index";

import brandprofile from "./brandProfile";
import cms from "./cms";
import fileUpload from "./fileUpload";
import menu from "./menu";
import marcom from "./marcom";
import auth from "./auth";
import blogs from "./blogs";
import user from "./user";
import newsletter from "./newsletter";
import stripe from "./stripe";
import gateway from "./gateway";
import transaction from "./transaction";
import chat from "./chat";

export default {
  //CUSTOM
  ...CustomApi,

  brandprofile: brandprofile,
  cms: cms,
  fileUpload: fileUpload,
  getmenu: menu,
  marcom: marcom,
  auth: auth,
  blogs: blogs,
  user: user,
  newsletter: newsletter,
  stripe: stripe,
  gateway: gateway,
  transaction: transaction,
  chat: chat,
};
