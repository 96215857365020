import { Button } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getLog } from "redux/custom/subscription/log/log-actions";
import html2pdf from "html2pdf.js";
import { ThreeDots } from "react-loader-spinner";
const numberToWords = require("number-to-words");
const currencyCodes = require("currency-codes");
const MMLogo = require("../../../assets/images/custom/MM_fit2go.png");
const fit2goLogo = require("../../../assets/images/custom/fit2goLogo.png");

const Invoice = () => {
  const dispatch = useDispatch();
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let selectedLogData = useSelector((state) => state.log.Log);

  const [searchParams, setSearchParams] = useSearchParams();
  const [log, setLog] = useState(null);
  const [price, setPrice] = useState(0);
  const [gst, setGST] = useState(9);
  const [isLoading, setsLoading] = useState(false);

  const htmlContentRef = useRef(null);

  useEffect(() => {
    getQueryData();
  }, []);

  const convertCurrencyToWords = (amount, currencyCode) => {
    currencyCode = currencyCode.toUpperCase();
    const currencyName = currencyCodes.code(currencyCode).currency;
    const amountWords = numberToWords.toWords(amount);
    return `${amountWords} ${currencyName}`;
  };

  const handleDownloadPDF = () => {
    setsLoading(true);
    const htmlContent = htmlContentRef.current;
    let fileName = "Subscription Invoice.pdf";

    html2pdf(htmlContent, {
      margin: 0.1,
      filename: fileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 4,
        windowWidth: Window.innerWidth,
      },
      jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    })
      .then((res) => {
        setsLoading(false);
        console.log("download complete");
      })
      .catch((error) => {
        console.log(error);
        setsLoading(false);
      });

    // return new Promise((resolve, reject) => {
    //   html2pdf()
    //     .from(htmlContent)
    //     .toPdf()
    //     .get("pdf")
    //     .then((pdf) => {
    //       pdf.setProperties({
    //         title: fileName,
    //       });
    //       pdf.save(`${fileName}.pdf`);
    //       resolve();
    //     })
    //     .catch((error) => {
    //       console.error("Error generating PDF:", error);
    //       reject(error);
    //     });
    // });
  };

  const getQueryData = async () => {
    let logId = searchParams.get("si");
    if (logId) {
      let data = await dispatch(getLog({ logId: logId }));

      if (
        data &&
        data.code == 200 &&
        data.data.length > 0 &&
        data.data[0].result &&
        data.data[0].result.length > 0
      ) {
        setLog(data.data[0].result[0]);
        setPrice(
          data.data[0].result[0] &&
            data.data[0].result[0].planObject &&
            data.data[0].result[0].planObject.price
            ? data.data[0].result[0].planObject.price
            : 0
        );
      }
    }
  };

  function getPrimaryImage(value) {
    return value
      ? value.primaryLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.primaryLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  return (
    <div class="">
      <div id="pdf" ref={htmlContentRef} class="p-4 container mx-auto bg-white">
        <div class="w-full border-2 bc-clr bg-white">
          <div class="flex items-center borderfty pb-3 justify-center">
            <p class="text-xl font-semibold overall-col">TAX INVOICE</p>
          </div>
          <div class="flex w-full justify-between border-b-2 bc-clr px-3">
            <div class="flex items-center gap-2">
              <div class="flex px-4 py-5 h-full borderight">
                <img
                  // src="https://nexusclustersg.blob.core.windows.net/server01/fit2go/asset/MM_fit2go.png"
                  src={MMLogo}
                  alt="logo"
                  class="object-contain h-20"
                />
              </div>
              <div class="p-2">
                <h6 class="text-xl uppercase font-semibold text-primary mb-1">
                  muscles in motion pte ltd
                </h6>

                <p class="text-sm overall-col">
                  102F Pasir Panjang Road Citilink Warehouse Complex, #06-01
                  Singapore 118530
                </p>
                <p class="text-sm overall-col">UEN: 202029571E</p>
                <p class="text-sm overall-col">
                  {"www." + process.env.REACT_APP_FRONTEND_URL.split("//")[1]}
                </p>
              </div>
            </div>
            <div className="p-2">
              <img
                // src={getPrimaryImage(brandProfile)}
                src={fit2goLogo}
                alt="logo"
                className="w-24 h-18 object-contain"
              />
            </div>
          </div>
          <div class="grid grid-cols-2 bc-clr border-b-2">
            <div class="bc-clr border-r-2">
              <div class="flex borderfty px-2 w-full">
                <div class="w-1/2 py-4 borderight">
                  <p class="overall-col">Subscription No.:</p>
                </div>
                <div class="w-1/2 py-4 px-2">
                  <p class="font-semibold text-dark">
                    {log && log.invoiceId ? log.invoiceId : ""}
                  </p>
                </div>
              </div>
              <div class="flex borderfty px-2 w-full">
                <div class="w-1/2 py-4 borderight">
                  <p class="overall-col">Date:</p>
                </div>
                <div class="w-1/2 py-4 px-2">
                  <p class="font-semibold overall-col">
                    {log && log.createdAt
                      ? new Date(log.createdAt).toLocaleDateString("en-IN")
                      : ""}
                  </p>
                </div>
              </div>
              <div class="flex px-2 w-full">
                <div class="w-1/2 py-4 borderight">
                  <p class="overall-col">Place of Supply:</p>
                </div>
                <div class="w-1/2 py-4 px-2">
                  <p class="font-semibold overall-col">Singapore</p>
                </div>
              </div>
            </div>
            <div class="px-3 py-1">
              <div class="w-full">
                <div>
                  {/* <div class="w-1/2">
                    <p class="overall-col">Kind Att:</p>
                  </div> */}
                  {/* <div>
                    <p class="overall-col">full name</p>
                  </div> */}
                </div>
                <div class="w-1/2">
                  <p class="overall-col">Bill to:</p>
                </div>
                <div class="">
                  <p class="overall-col">
                    {log && log.userObject && log.userObject.firstName
                      ? log.userObject.firstName + " "
                      : ""}
                    {log && log.userObject && log.userObject.lastName
                      ? log.userObject.lastName
                      : ""}
                  </p>
                  {/* <p class="overall-col" >
                    lastname,
                  </p> */}
                  <p class="overall-col">
                    Email:{" "}
                    {log && log.userObject && log.userObject.email
                      ? log.userObject.email
                      : ""}
                  </p>
                  <p class="overall-col">
                    Phone:{" "}
                    {log && log.userObject && log.userObject.mobile
                      ? log.userObject.mobile
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table class="nowpdf w-full">
              <tr class="lastpdfg">
                <th class="new-pdfg">#</th>
                <th class="pdf">Item</th>
                <th class="pdf">Expiry Date</th>
                <th class="pdf">Quantity</th>
                <th class="pdf">Amount</th>
                <th class="pdf">Rate (without gst.)</th>
                <th class="pdf">GST %</th>
                <th class="pdf">GST Amt</th>
              </tr>

              <tr class="lastpdfg">
                <td class="lastpdf">1</td>
                <td class="pdf lastpdf fsize14 overall-col">
                  <p class="text-dark">
                    {" "}
                    {log && log.planObject && log.planObject.name
                      ? log.planObject.name
                      : ""}
                  </p>
                </td>
                <td class="pdf lastpdf">
                  <p class="text-dark">
                    {log && log.planObject && log.planObject.validity
                      ? log.planObject.validity + " days"
                      : ""}
                  </p>
                </td>
                <td class="pdf lastpdf">
                  <p class="text-dark">1</p>
                </td>
                <td class="pdf lastpdf">
                  <p class="text-dark">{price ? "S$ " + price : 0}</p>
                </td>
                <td class="pdf lastpdf">
                  <p class="text-dark">
                    {"S$ " + Math.abs(price - price * (gst / 100)).toFixed(2)}
                  </p>
                </td>
                <td class="pdf lastpdf">
                  <p class="text-dark overall-col">{gst}%</p>
                </td>
                <td class="pdf lastpdf">
                  <p class="text-dark">
                    {"S$ " + Math.abs(price * (gst / 100)).toFixed(2)}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div class="flex w-full border-t-2 bc-clr">
            <div class="w-3/5 p-3">
              <p class="text-sm overall-col">Total In Words:</p>
              <p class="font-semibold capitalize overall-col text-sm">
                {convertCurrencyToWords(
                  log && log.planObject && log.planObject.price
                    ? log.planObject.price
                    : 0,
                  "SGD"
                )}{" "}
                Only/-
              </p>
              <p class="mt-2 uppercase overall-col text-sm">
                Looking Forward for your business
              </p>
              <p class="mt-2 text-sm overall-col">Terms & Condition applied</p>
            </div>
            <div class="w-2/5">
              <div class="px-3 py-1 border-l-2 border-b-2 bc-clr flex w-full justify-between">
                <p class="overall-col">Total Amount</p>
                <p class="font-semibold overall-col">
                  {log && log.planObject && log.planObject.price
                    ? "S$ " + log.planObject.price + "/-"
                    : ""}
                </p>
              </div>
              <div class="border-l-2 bc-clr">
                <div class="flex justify-between items-center w-full px-5 h-16">
                  {/* <img
                    src="@/assets/conference/logo.png"
                    class="w-32 object-contain h-16"
                    alt="logo"
                  />
                  <img
                    src="@/assets/conference/sign.png"
                    class="w-full object-contain h-16"
                    alt="logo"
                  /> */}
                </div>
                <p class="px-3 overall-col py-1 text-center">
                  Authorised Signature
                </p>
              </div>
            </div>
          </div>
          <div class="w-full items-center gap-3 flex border-t-2 bc-clr">
            <div class="flex px-4 py-16 h-full borderight">
              <p class=" overall-col underline">Bank Details:</p>
            </div>
            <div class="">
              {/* <div class="flex w-full gap-2 items-center mt-1">
                <p class="text-sm overall-col">Company's PAN:</p>
                <p class="font-semibold text-sm overall-col">AAATN1283C</p>
              </div> */}
              <div class="flex w-full gap-2 items-center mt-2">
                <p class="text-sm overall-col">Beneficery Name:</p>
                <p class="font-semibold text-sm overall-col">
                  Muscles In Motion Pte Ltd
                </p>
              </div>
              <div class="flex w-full gap-2 items-center mt-2">
                <p class="text-sm overall-col">Bank Name:</p>
                <p class="font-semibold text-sm overall-col">DBS Bank Ltd</p>
              </div>
              <div class="flex w-full gap-2 items-center mt-2">
                <p class="text-sm overall-col">Account No.:</p>
                <p class="font-semibold text-sm overall-col">8852-152-4559</p>
              </div>
              <div class="flex w-full gap-2 items-center mt-2">
                <div className="flex gap-2 items-center">
                  <p class="text-sm overall-col">Bank Code:</p>
                  <p class="font-semibold text-sm overall-col">7171</p>
                </div>
                <div className="flex gap-2 items-center">
                  <p class="text-sm overall-col">Branch Code:</p>
                  <p class="font-semibold text-sm overall-col">001</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center my-8">
        <Button
          spinner={
            <ThreeDots
              visible={true}
              wrapperClass="isloading-cs"
              color="white"
              radius={3}
            />
          }
          isLoading={isLoading}
          onClick={handleDownloadPDF}
          class="px-12 bg-primary py-3  rounded-lg"
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default Invoice;
