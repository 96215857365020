// import { ColorModeScript } from '@chakra-ui/react';
import React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "../src/assets/css/styles/tailwind.css";
import "../src/assets/css/styles/tailwind.generated.css";
import "../src/assets/css/Index.scss";
import "../src/assets/css/Font.scss";
import "../src/assets/css/cms.css";
import "../src/assets/css/custom.scss";
import "../src/assets/css/swiper.css";
import { HelmetProvider } from "react-helmet-async";
import ToastContainerWrapper from "components/ToastContainerWrapper";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

const helmetContext = {};

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {/* <ColorModeScript initialColorMode={theme.config.initalColorMode} /> */}
      <HelmetProvider context={helmetContext}>
        <ToastContainerWrapper />
        <App />
      </HelmetProvider>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
