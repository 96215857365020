import API from "../../repositoryFactory";

const createComment = "create";
const getComment = "get";
const updateComment = "update";
const deleteComment = "delete";

const commentRoute = (route) => {
  return `fit2go/social/comment/${route}`;
};

export default {
  createComment(payload) {
    return API.post(commentRoute(createComment), payload);
  },
  getComment(payload) {
    return API.post(commentRoute(getComment), payload);
  },
  updateComment(payload) {
    return API.post(commentRoute(updateComment), payload);
  },
  deleteComment(payload) {
    return API.post(commentRoute(deleteComment), payload);
  },
};
