import React, { useEffect } from "react";
import { Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import fit2GoAsia from "assets/images/custom/fitasia.png";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const HeroSection3 = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  const navigate = useNavigate();

  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  return (
    <section className="relative">
      {" "}
      <div className="lg:flex md:flex container items-center mx-auto  sm-reverse  lg:py-16 md:py-16 lg:px-12 md:px-8 px-5">
        <div className="lg:w-1/2 md:w-1/2 lg:py-12 md:py-0 py-8 relative zindex9">
          <Fade left>
            <h2
              id="2491847836"
              className="dynamicStyle lg:text-4xl md:text-2xl text-xl text-black font-bold"
            >
              {data
                ? data["2491847836"]
                : "FINED YOUR BEST COACH NEAREST TO YOU AND BOOK YOUR SLOT EASILY"}
            </h2>
            <p
              id="8410324712"
              dangerouslySetInnerHTML={{
                __html: data ? data["8410324712"] : "no-data",
              }}
              className="dynamicStyle lg:text-base md:text-base text-sm lg:mt-4 md:mt-4 mt-2 text030303  lg:leading-7 md:leading-7 leading-6"
            ></p>

            <Link to="/Coach#user-card">
              <div id="8752237435" className="dynamicStyle">
                <Button
                  width="180px"
                  height="50px"
                  className="bg-primary lg:mt-8 md:mt-8 zindex9 relative mt-6 textwhite sm-btntextwh"
                >
                  {data ? data["8752237435"] : "Find Coach"}
                </Button>
              </div>
            </Link>
          </Fade>
        </div>
        <div className="lg:w-1/2 md:w-1/2 lg:pl-8 md:pl-8  lg:mt-0 md:mt-0 mt-12">
          <div id="8498811174" className="dynamicStyle2 relative zindex9">
            <Image
              src={data ? getImage(data["8498811174"]) : ""}
              alt="heroImage"
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              className="w-full sm-h250px minh450px object-contain"
            />
          </div>
        </div>
      </div>
      <Image src={fit2GoAsia} alt="image" className="fit2goAsialeft-text" />
    </section>
  );
};

export default HeroSection3;
