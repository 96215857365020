import { Button } from "@chakra-ui/react";
import React from "react";

const Home4Section2 = () => {
  return (
    <section>
      <div className="lg:flex md:flex gap-4 block container mx-auto lg:pt-32 lg:pb-16">
        <div className="lg:w-1/3 md:w-1/3 w-full  lg:flex flex-col justify-center lg:px-4">
          <p className="fsize18 lg:mb-2 clr-ddd9d8 letter-spacing4px font-semibold">
            Lorem ipsum
          </p>
          <p className="fsize18 leading-6">
            publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
        <div className="lg:w-1/3 md:w-1/3 w-full h-680px">
          <img
            src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
            alt="hero"
            className="br-40px w-full h-full object-cover bg-ddd9d8 border-6px"
          />
        </div>
        <div className="lg:w-1/3 md:w-1/3 w-full  lg:flex flex-col justify-center lg:px-4">
          <p className="fsize56 leading-10 font-semibold lg:mb-4 md:mb-4 mb-2">
            Lorem Ipsum generate
          </p>
          <p className="fsize18 leading-6 lg:mb-6 md:mb-6 mb-4">
            publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
          <Button width="180px" height="50px" colorScheme="primary">
            Enquire Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Home4Section2;
