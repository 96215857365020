import React, { useEffect, useState } from "react";
import { Image, Badge, Card } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import imageVector from "assets/images/custom/Vector5.png";
import imageVectorBottom from "assets/images/custom/Vector5.png";
// import { Autoplay } from 'swiper';
import Fade from "react-reveal/Fade";
import { EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllBlogs, getBlogs } from "../../../redux/blogs/blogs-actions";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const CardImage = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let Blogs = useSelector((state) => state.BlogDetails.Blogs);
  const [isLoading, setIsLoading] = useState(false);

  console.log("blogdata", Blogs);
  useEffect(() => {
    handleGetAllBlog();
  }, []);

  const handleGetAllBlog = async () => {
    try {
      setIsLoading(true);
      let payload = {
        isActive: true,
        offset: 0,
        limit: 12,
      };
      await dispatch(getBlogs(payload));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlogDetail = (blogId) => {
    navigate(`/blog detail/${blogId}`);
  };

  return (
    <section className="relative">
      <Image src={imageVector} alt="image" className="homebanner-c" />
      <div className=" container mx-auto relative zindex9 lg:py-16 md:py-8 py-8 lg:px-12 md:px-8 px-5">
        <Fade top>
          <div className="text-center lg:mb-12 md:mb-6 mb-0">
            <h2
              id="0579988455"
              className="dynamicStyle lg:text-4xl md:text-2xl text-2xl text-black font-semibold"
            >
              {data ? data["0579988455"] : "BLOGS"}
            </h2>
            <p
              id="8568575289"
              className=" dynamicStyle  text-gray lg:mt-4 md:mt-2 mt-2 lg:text-base md:text-base text-sm "
            >
              {data
                ? data["8568575289"]
                : "Visualize Your Fitness Journey with Fit2GoAsia"}
            </p>
          </div>
        </Fade>
        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {Blogs && Blogs.result && Blogs.result.length > 0 ? (
              <Swiper
                spaceBetween={25}
                slidesPerView={3.2}
                observer={true}
                observeParents={true}
                modules={[Navigation]}
                className="mySwiper p-2"
                autoplay={{
                  delay: "1000",
                }}
                loop
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                }}
                breakpoints={{
                  1536: {
                    slidesPerView: 3.2,
                    spaceBetween: 25,
                  },
                  1280: {
                    slidesPerView: 3.2,
                    spaceBetween: 25,
                  },
                  1024: {
                    slidesPerView: 3.1,
                    spaceBetween: 25,
                  },
                  991: {
                    slidesPerView: 2.8,
                    spaceBetween: 25,
                  },
                  767: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                  },
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                  },
                }}
              >
                {Blogs.result.map((e, i) => {
                  return (
                    <SwiperSlide key={e._id}>
                      {" "}
                      <Fade bottom>
                        <Card
                          p="2"
                          className="cursor-pointer"
                          onClick={() => handleBlogDetail(e._id)}
                        >
                          <Image
                            height="275px"
                            src={process.env.REACT_APP_STORAGE_URL + e.image}
                            alt="heroImage"
                            className="w-full object-cover rounded-xl sm-h250px md-h200px w-full"
                          />
                          <div className="p-2 lg:mt-4 md:mt-4">
                            <div className="flex items-center justify-between mb-4">
                              <Badge
                                borderRadius="full"
                                px="3"
                                py="0.5"
                                colorScheme="teal"
                              >
                                {e.categoryID &&
                                e.categoryID.length > 0 &&
                                e.categoryID[0].categoryName
                                  ? e.categoryID[0].categoryName
                                  : ""}
                              </Badge>
                              <p className="text-gray text-sm">
                                {new Date(e.createdAt).toDateString()}
                              </p>
                            </div>

                            <h4 className="font-semibold text-lg lineClamp2">
                              {e.title}
                            </h4>
                            <p
                              dangerouslySetInnerHTML={{ __html: e.content }}
                              className="mt-2 text8787 line-clamp-1 lg:text-sm md:text-sm text-sm"
                            ></p>
                          </div>
                        </Card>
                      </Fade>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <div className="flex justify-center align-center">
                No Blog Found
              </div>
            )}
          </>
        )}
      </div>
      <Image src={imageVectorBottom} alt="image" className="homevectorbottom" />
    </section>
  );
};

export default CardImage;
