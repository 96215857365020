import React from "react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonial3 = () => {
  return (
    <div className="container mx-auto flex items-center  ">
      <div className="w-40 ">
        <p className="fsize24 letter-spacing4px font-semibold clr-ddd9d8">
          Lorem Ipsum
        </p>
        <h1 className="fsize40  font-semibold ">Lorem Ipsum</h1>
        <p className="fsize18 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla cursus
          consectetur euismod aenean.
        </p>
      </div>
      <div className="w-60">
        <Swiper
          spaceBetween={30}
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper container relative mx-auto overflow-x-hidden pb-2 newswiper"
          autoplay={{
            delay: "1000",
          }}
          navigation={{
            nextEl: ".image-swiper-button-nexts",
            prevEl: ".image-swiper-button-prevs",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
          }}
        >
          <div className="swiper-button image-swiper-button-prevs bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div className="swiper-button image-swiper-button-nexts bg-white rounded-full p-1 cursor-pointer">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <div className="lg:p-6 border custShadow m-1 rounded-lg">
              <div className="flex justify-center w-full text-center">
                <div>
                  <div className="w-full flex items-center h-28">
                    <img
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="test-img"
                      className="w-24 h-24 mx-auto bg-d9d9d9 rounded-full"
                    />
                  </div>
                  <div>
                    <h2 className="fsize24 font-semibold lg:mb-2">Leo</h2>
                    <p className="fsize18">CEO</p>
                    <p className="fsize18 lg:mb-6">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eius consequatur qui amet expedita perferendis pariatur
                      obcaecati, illum sequi repellendus voluptates eligendi
                      beatae, quam a.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="lg:p-6 border custShadow m-1 rounded-lg">
              <div className="flex justify-center w-full text-center">
                <div>
                  <div className="w-full flex items-center h-28">
                    <img
                      src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                      alt="test-img"
                      className="w-24 h-24 mx-auto bg-d9d9d9 rounded-full"
                    />
                  </div>
                  <div>
                    <h2 className="fsize24 font-semibold lg:mb-2">Leo</h2>
                    <p className="fsize18">CEO</p>
                    <p className="fsize18 lg:mb-6">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eius consequatur qui amet expedita perferendis pariatur
                      obcaecati, illum sequi repellendus voluptates eligendi
                      beatae, quam a.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial3;
