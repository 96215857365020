import API from "../../repositoryFactory";

const createReport = "create";
const getReport = "get";
const updateReport = "update";
const deleteReport = "delete";

const reportRoute = (route) => {
  return `fit2go/social/report/${route}`;
};

export default {
  createReport(payload) {
    return API.post(reportRoute(createReport), payload);
  },
  getReport(payload) {
    return API.post(reportRoute(getReport), payload);
  },
  updateReport(payload) {
    return API.post(reportRoute(updateReport), payload);
  },
  deleteReport(payload) {
    return API.post(reportRoute(deleteReport), payload);
  },
};
