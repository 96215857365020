import React from "react";

const Testimonial2 = () => {
  return (
    <section>
      <div className="text-center container mx-auto flex flex-col justify-center lg:mb-16">
        <h3 className="letter-spacing4px fsize24 font-semibold clr-ddd9d8 ">
          Lorem Ipsum
        </h3>
        <h1 className="fsize40 font-semibold ">Lorem Ipsum</h1>
        <div>
          <p className=" fsize18 text-center">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
      <div className="container mx-auto grid lg:grid-cols-2 mt-5 w-full lg:gap-4 md:gap-4 gap-3">
        <div className="testimonial-card border rounded-lg  ">
          <div className="w-full">
            <div className="testimonial-div ">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="testimonial-img rounded-full object-cover"
              />
            </div>
            <div className="w-full text-start">
              <h2 className="fsize24 font-semibold ">John Deo</h2>
              <p className="fsize18">CEO & Co Founder</p>
            </div>
          </div>
          <p className="fsize18 pt-4 pb-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            augue ipsum, pellentesque sit amet viverra nec, tempus at odio.
            Curabitur tempor molestie enim eget tristique. Sed mi libero, semper
            quis lorem a, ultrices molestie arcu.
          </p>
        </div>
        <div className="testimonial-card border rounded-lg  ">
          <div className="w-full">
            <div className="testimonial-div ">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="testimonial-img rounded-full object-cover"
              />
            </div>
            <div className="w-full text-start">
              <h2 className="fsize24 font-semibold ">John Deo</h2>
              <p className="fsize18">CEO & Co Founder</p>
            </div>
          </div>
          <p className="fsize18 pt-4 pb-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            augue ipsum, pellentesque sit amet viverra nec, tempus at odio.
            Curabitur tempor molestie enim eget tristique. Sed mi libero, semper
            quis lorem a, ultrices molestie arcu.
          </p>
        </div>
        <div className="testimonial-card border rounded-lg  ">
          <div className="w-full">
            <div className="testimonial-div ">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="testimonial-img rounded-full object-cover"
              />
            </div>
            <div className="w-full text-start">
              <h2 className="fsize24 font-semibold ">John Deo</h2>
              <p className="fsize18">CEO & Co Founder</p>
            </div>
          </div>
          <p className="fsize18 pt-4 pb-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            augue ipsum, pellentesque sit amet viverra nec, tempus at odio.
            Curabitur tempor molestie enim eget tristique. Sed mi libero, semper
            quis lorem a, ultrices molestie arcu.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonial2;
