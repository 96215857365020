const initialState = {
  Like: {},
};

const Like = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LIKE":
      return { ...state, Like: action.payload };
    default:
      return state;
  }
};

export default Like;
