import {
  Button,
  Select,
  Input,
  Image,
  Textarea,
  Divider,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  setIsEditUserProfile,
  SaveBasicProfileDetails,
  getUserByToken,
  generateOTP,
  UpdateEmailMobile,
} from "../../../../redux/auth/auth-action";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux/es/exports";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploaderBox from "components/UploaderBox";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import Helper from "../../../../helper";
import OTPInput, { ResendOTP } from "otp-input-react";

const EditProfile = () => {
  const dispatch = useDispatch();
  let { IsEditUserProfile, user } = useSelector((state) => state.AuthDetails);
  // let IsEditUserProfile = useSelector(
  //   (state) => state.AuthDetails.IsEditUserProfile
  // );
  const setIsEditUserProfileHandler = () => {
    dispatch(setIsEditUserProfile(!IsEditUserProfile));
  };

  const [selectValue, setSelectvalue] = useState([]);
  const [userMobileCountry, setUserMobileCountry] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [userImagePreview, setUserImagePreview] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [DOBDates, setDOBDates] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 10))
      .toISOString()
      .split("T")[0]
  );

  const [userLoading, setUserLoading] = useState(false);
  const [isShowEmailOTP, SetShowEmailOTP] = useState(false);

  const [fitnessGoalOptions, setFitnessGoalOptions] = useState([
    { name: "Lose weight", id: 1 },
    { name: "Build muscle", id: 2 },
    { name: "Improve cardiovascular endurance", id: 3 },
    { name: "Increase flexibility", id: 4 },
    { name: "Enhance agility and balance", id: 5 },
    { name: "Reduce body fat percentage", id: 6 },
    { name: "Improve posture", id: 7 },
    { name: "Enhance athletic performance", id: 8 },
    { name: "Boost overall fitness level", id: 9 },
    { name: "Other", id: 10 },
  ]);

  const onSelect = (selectedList, selectedItem) => {
    // Handle the select event
    console.log("onSelect:", selectedList);
    setSelectvalue(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    // Handle the remove event
    console.log("onRemove:", selectedList);
    setSelectvalue(selectedList);
  };

  useEffect(() => {
    populateData();
    return () => {
      setIsEditUserProfileHandler();
    };
  }, [user]);

  const populateData = async () => {
    if (user.userImage) {
      setFieldHandler("userImagePreview", user.userImage);
    }
    if (user.coverImage) {
      setFieldHandler("coverImagePreview", user.coverImage);
    }
    await formik.setFieldValue(
      "firstName",
      user.firstName ? user.firstName : ""
    );
    await formik.setFieldValue("lastName", user.lastName ? user.lastName : "");
    await formik.setFieldValue("gender", user.gender ? user.gender : "");
    await formik.setFieldValue("dob", user.dob ? formatDate(user.dob) : null);
    await formik.setFieldValue("email", user.email ? user.email : "");
    await formik.setFieldValue("bio", user.bio ? user.bio : "");
    await formik.setFieldValue("city", user.city ? user.city : "");
    await formik.setFieldValue(
      "countryCode",
      user.countryCode ? user.countryCode : ""
    );
    await formik.setFieldValue("mobile", user.mobile ? user.mobile : "");
    await formik.setFieldValue("fitnessGoal", filterFitnessGoalData(user));

    setUserMobileCountry(`${user.countryCode}${user.mobile}`);
  };

  const filterFitnessGoalData = (user) => {
    if (user.fitnessGoal && user.fitnessGoal.length > 0) {
      let filterData = fitnessGoalOptions.filter((fgo) =>
        user.fitnessGoal.includes(fgo.name)
      );
      return filterData;
    }
  };

  const formatDate = (date) => {
    const [day, month, year] = new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
      .format(new Date(date))
      .split("/");

    return `${year}-${month}-${day}`;
  };

  const GetEmailOTP = async () => {
    let emailValid = validateField("email", formik.values.email);
    let mobileValid = validateField("mobile", formik.values.mobile);
    if (!(emailValid && mobileValid)) {
      return;
    }
    let payload = {
      mobile: user.mobile,
      email: user.email.toLowerCase(),
      type: "UpdateAccount",
    };
    try {
      let data = await dispatch(generateOTP(payload));
      if (data && data.code === 200) {
        SetShowEmailOTP(true);
        toast.success("OTP successfully sent to your email ID");
      } else {
        if (data.message) {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const handleUpdateEmailMobile = async () => {
    let payload = {};
    if (user.mobile) {
      payload = {
        otp: formik.values.otp,
        mobile: user.mobile,
        newMobile: formik.values.mobile,
        newCountryCode: formik.values.countryCode,
      };
    }
    try {
      let data = await dispatch(UpdateEmailMobile(payload));
      if (data && data.code === 200) {
        await dispatch(getUserByToken({ token: user.token }));
        toast.success("Mobile Number Updated");
      } else {
        if (data.message) {
          toast.error(data.message);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const {
    isOpen: isOpenCover,
    onOpen: onOpenCover,
    onClose: onCloseCover,
  } = useDisclosure();
  const {
    isOpen: isOpenImage,
    onOpen: onOpenImage,
    onClose: onCloseImage,
  } = useDisclosure();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    countryCode: "",
    gender: "",
    bio: "",
    dob: null,
    city: "",

    otp: "",

    userImage: null,
    coverImage: null,
    preference: [],
    fitnessGoal: [],

    coverImagePreview: null,
    userImagePreview: null,
  };

  const validationRules = Yup.object({
    firstName: Yup.string()
      .trim()
      // .min(3, "First name must be at least 3 characters")
      .max(25, "First name must be at most 25 characters")
      .required("First name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    lastName: Yup.string()
      .trim()
      // .min(3, "Last name must be at least 3 characters")
      .max(25, "Last name must be at most 25 characters")
      .required("Last name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 100 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    mobile: Yup.string().required("Mobile number is required"),
    countryCode: Yup.string().required("Country code is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date().required("DOB is required"),
    fitnessGoal: Yup.array().min(1, "Fitness goal is required"),
    bio: Yup.string()
      .trim()
      // .min(3, "Bio must be at least 3 characters")
      .max(500, "Bio must be at most 500 characters")
      .required("Bio is required"),
    userImage: Yup.mixed()
      // .required("Image is required")
      .test("fileType", "User image invalid file type", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "User image size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .nullable(),
    coverImage: Yup.mixed()
      // .required("Image is required")
      .test("fileType", "Cover image invalid file type", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.type && value.type.startsWith("image/")) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .test("fileSize", "Cover image size is too large (Max: 5MB)", (value) => {
        if (!value) return true;
        return (
          value &&
          ((value.size && value.size <= 5242880) ||
            (typeof value == "string" && value.includes("fit2go/fit2go/")))
        );
      })
      .nullable(),
  });

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
    setUserMobileCountry(`${dialCountryCode}${phoneNumber}`);
    validateField("mobile", formik.values.mobile);
  };

  const validateField = (fieldName, value) => {
    try {
      validationRules.fields[fieldName].validateSync(value, {
        abortEarly: false,
      });
      formik.setFieldError(fieldName, undefined);
      formik.setFieldTouched(fieldName, true);
      return true;
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        formik.setFieldError(fieldName, error.errors[0]);
      }
      formik.setFieldTouched(fieldName, true);
      return false;
    }
  };

  const handleOnSubmit = async (values) => {
    let payload = new FormData();

    if (formik.values.firstName) {
      payload.append("firstName", formik.values.firstName);
    }
    if (formik.values.lastName) {
      payload.append("lastName", formik.values.lastName);
    }
    // if (formik.values.email) {
    //   payload.append("email", formik.values.email);
    // }
    // if (formik.values.mobile) {
    //   payload.append("mobile", formik.values.mobile);
    // }
    // if (formik.values.countryCode) {
    //   payload.append("countryCode", formik.values.countryCode);
    // }
    if (formik.values.gender) {
      payload.append("gender", formik.values.gender);
    }
    if (formik.values.dob) {
      payload.append("dob", formik.values.dob);
    }
    if (formik.values.city) {
      payload.append("city", formik.values.city);
    }
    if (formik.values.userImage) {
      payload.append("userImage", formik.values.userImage);
    }
    if (formik.values.coverImage) {
      payload.append("coverImage", formik.values.coverImage);
    }
    if (formik.values.fitnessGoal && formik.values.fitnessGoal.length > 0) {
      let value = formik.values.fitnessGoal.map((val) => val.name);
      payload.append("fitnessGoal", JSON.stringify(value));
    }
    if (formik.values.preference) {
      payload.append("preference", formik.values.preference);
    }
    if (formik.values.bio) {
      payload.append("bio", formik.values.bio);
    }

    if (user._id) {
      payload.append("userId", user._id);
    }
    console.log(payload);
    try {
      setUserLoading(true);
      let data = await dispatch(SaveBasicProfileDetails(payload));

      setUserLoading(false);
      if (data && data.code == 200) {
        await dispatch(getUserByToken({ token: user.token }));
        // Swal.fire({
        //   title: "Success",
        //   text: `User updated successfully`,
        //   icon: "success",
        //   timer: "2500",
        // });
        toast.success(`User updated successfully`);
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    } finally {
      setUserLoading(false);
    }
  };

  const handleTrim = (event) => {
    formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const setFieldHandler = async (field, value) => {
    await formik.setFieldValue(field, value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  console.log(formik.errors, "formik.errors");

  const getImage = (image) => {
    if (image.includes("data:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  };

  return (
    <div>
      {/* editcoverpopup */}
      <form className="w-full" onSubmit={formik.handleSubmit}>
        <Modal isOpen={isOpenCover} onClose={onCloseCover}>
          <ModalOverlay />

          <ModalContent>
            <ModalHeader> Upload Cover Image </ModalHeader>

            <ModalBody>
              <div>
                <FormControl
                  mt={4}
                  isInvalid={
                    !!formik.errors.coverImage && formik.touched.coverImage
                  }
                >
                  {!formik.values.coverImage && (
                    <UploaderBox
                      accept="image/*"
                      onFileSelect={async (file) => {
                        setFieldHandler("coverImage", file);
                        validateField("coverImage", file);
                      }}
                      onPreview={async (prev) =>
                        setFieldHandler("coverImagePreview", prev)
                      }
                    />
                  )}
                  {formik.values.coverImage &&
                    formik.values.coverImagePreview && (
                      <div className="relative">
                        <Image
                          src={
                            formik.values.coverImagePreview
                              ? getImage(formik.values.coverImagePreview)
                              : null
                          }
                          fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}fit2go/undefined/primaryLogo-DpYo0uWB2xJu3JgeKKl92-1701935536`}
                          className="object-contain w-full h-200px"
                        />
                        <FeatherIcon
                          onClick={() => {
                            setFieldHandler("coverImage", null);
                            setFieldHandler("coverImagePreview", null);
                            formik.setFieldError("coverImage", undefined);
                            formik.setFieldTouched("coverImage", false);
                          }}
                          icon="x"
                          className="border bg-fff rounded-full absolute top-2 right-4 cursor-pointer"
                          size={16}
                        />
                      </div>
                    )}
                  <div className="flex justify-center align-center">
                    {formik.touched.coverImage && formik.errors.coverImage && (
                      <FormErrorMessage>
                        {formik.errors.coverImage}
                      </FormErrorMessage>
                    )}
                  </div>
                </FormControl>
              </div>
            </ModalBody>

            <ModalFooter className="mt-4">
              <Button
                variant="outline"
                colorScheme="secondary"
                mr={3}
                onClick={() => {
                  onCloseCover();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpenImage} onClose={onCloseImage}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader> Upload User Image </ModalHeader>

            <ModalBody>
              <div>
                <FormControl
                  mt={4}
                  isInvalid={
                    !!formik.errors.userImage && formik.touched.userImage
                  }
                >
                  {!formik.values.userImage && (
                    <UploaderBox
                      accept="image/*"
                      onFileSelect={async (file) => {
                        setFieldHandler("userImage", file);
                        validateField("userImage", file);
                      }}
                      onPreview={async (prev) =>
                        setFieldHandler("userImagePreview", prev)
                      }
                    />
                  )}
                  {formik.values.userImage &&
                    formik.values.userImagePreview && (
                      <div className="relative">
                        {" "}
                        <Image
                          src={
                            formik.values.userImagePreview
                              ? getImage(formik.values.userImagePreview)
                              : null
                          }
                          className="object-contain w-full h-200px"
                        />
                        <FeatherIcon
                          onClick={() => {
                            setFieldHandler("userImage", null);
                            setFieldHandler("userImagePreview", null);
                            formik.setFieldError("userImage", undefined);
                            formik.setFieldTouched("userImage", false);
                          }}
                          icon="x"
                          className="border bg-fff rounded-full absolute top-2 right-4 cursor-pointer"
                          size={16}
                        />
                      </div>
                    )}
                  <div className="flex justify-center align-center">
                    {formik.touched.userImage && formik.errors.userImage && (
                      <FormErrorMessage>
                        {formik.errors.userImage}
                      </FormErrorMessage>
                    )}
                  </div>
                </FormControl>
              </div>
            </ModalBody>

            <ModalFooter className="mt-4">
              <Button
                variant="outline"
                colorScheme="secondary"
                mr={3}
                onClick={() => {
                  onCloseImage();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <div className="border rounded-3px py-4 px-6">
          <h2 className="fsize18 font-semibold pb-4 ">Edit User Profile</h2>
          <div className="w-full relative">
            <Image
              src={
                formik.values.coverImagePreview
                  ? getImage(formik.values.coverImagePreview)
                  : null
              }
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL}fit2go/undefined/primaryLogo-DpYo0uWB2xJu3JgeKKl92-1701935536`}
              alt="user-background"
              className="lg:rounded-lg md:rounded-lg w-full object-cover h-300px "
            />
            <FeatherIcon
              onClick={onOpenCover}
              icon="edit"
              className="cursor-pointer absolute right-2 top-2 w-6 h-6 edit_icon  p-1 rounded-full"
            />
          </div>
          <div className="lg:flex md:flex justify-between items-end pb-6 minus3rem-mtop">
            <div className="py-2 relative w-max lg:mx-0 md:mx-0 mx-auto lg:ml-8">
              <Image
                src={
                  formik.values.userImagePreview
                    ? getImage(formik.values.userImagePreview)
                    : null
                }
                fallbackSrc="https://nimbuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                alt="profile-pic"
                className="w-32 h-32 rounded-full bg-6f6f6f text-center border3px-fff"
              />
              <FeatherIcon
                onClick={onOpenImage}
                icon="edit"
                className="cursor-pointer absolute right-1 bottom-5 w-6 h-6 edit_icon p-1 rounded-full"
              />
            </div>
            <div className="flex gap-4 justify-center ">
              <Button
                isLoading={userLoading}
                spinner={<ThreeDots visible={true} color="white" radius={3} />}
                type="submit"
                colorScheme="primary"
                className="fsize14 clr-fff"
                onClick={() =>
                  formik.errors && !Helper.Object.isEmpty(formik.errors)
                    ? toast.warning(
                        formik.errors[Object.keys(formik.errors)[0]]
                      )
                    : ""
                }
              >
                Save changes
              </Button>
              <Button
                onClick={() => setIsEditUserProfileHandler()}
                colorScheme="secondary"
                className="fsize14 clr-fff"
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="py-4">
            <h2 className="fsize18 font-semibold mb-4 clr-606060">
              Basic Details
            </h2>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <FormControl
                  isInvalid={
                    !!formik.errors.firstName && formik.touched.firstName
                  }
                >
                  <label className="clr-7b7b7b fsize14">First Name</label>
                  <Input
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    type="text"
                    className="w-full border-6d6d6d rounded-lg px-4 py-3 mt-2 fsize14"
                    placeholder="Enter your first name"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <FormErrorMessage>
                      {formik.errors.firstName}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl
                  isInvalid={
                    !!formik.errors.lastName && formik.touched.lastName
                  }
                  className="mb-4"
                >
                  <label className="clr-7b7b7b fsize14">Last Name</label>
                  <Input
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    type="text"
                    className="w-full border-6d6d6d rounded-lg px-4 mt-2 py-3 fsize14"
                    placeholder="Enter your last name"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <FormErrorMessage>
                      {formik.errors.lastName}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.mobile && formik.touched.mobile}
                >
                  <label className="clr-7b7b7b fsize14">Mobile Number</label>
                  <div className="relative">
                    <PhoneInput
                      disabled={isShowEmailOTP}
                      name="mobile"
                      country={"sg"}
                      enableSearch={true}
                      className="mt-2 phoneinput w-full mt-2 themefamily"
                      value={userMobileCountry}
                      onChange={handlePhoneChange}
                    />
                    <div className="absolute top-0 right-0">
                      {userMobileCountry &&
                        user.countryCode &&
                        user.mobile &&
                        userMobileCountry !==
                          `${user.countryCode}${user.mobile}` &&
                        !isShowEmailOTP && (
                          <Button
                            onClick={GetEmailOTP}
                            colorScheme="primary"
                            className="w-full py-1 fsize13"
                          >
                            Send OTP
                          </Button>
                        )}
                    </div>
                  </div>
                  {isShowEmailOTP && (
                    <div>
                      <div className="mt-4 resendotp-t">
                        <p className=" fsize14 sm-fsize12 text333 font-semibold mb-1">
                          {"Enter OTP"}
                        </p>
                        <FormControl
                          isInvalid={!!formik.errors.otp && formik.touched.otp}
                        >
                          <OTPInput
                            name="otp"
                            value={formik.values.otp}
                            onChange={(e) => {
                              formik.setFieldValue("otp", e);
                            }}
                            OTPLength={6}
                            otpType="number"
                            className="numb-otp"
                            inputclassName="themefamily"
                          />
                          {formik.touched.otp && formik.errors.otp && (
                            <FormErrorMessage>
                              {formik.errors.otp}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <div className="mt-2">
                          <ResendOTP onResendClick={() => GetEmailOTP()} />
                        </div>
                        {formik.values.otp &&
                          formik.values.otp.toString().length === 6 && (
                            <Button
                              onClick={handleUpdateEmailMobile}
                              colorScheme="primary"
                              className="w-full py-1 mt-2 fsize13"
                            >
                              Update Mobile Number
                            </Button>
                          )}
                      </div>
                    </div>
                  )}
                  {formik.touched.mobile && formik.errors.mobile && (
                    <FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.email && formik.touched.email}
                  className="mb-4"
                >
                  <label className="clr-7b7b7b fsize14">Email Address</label>
                  <Input
                    readOnly
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14 cursor-not-allowed
                      "
                    placeholder="Enter your email address"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="lg:flex md:flex lg:flex-row md:flex-row flex flex-col w-full lg:gap-6 md:gap-6 gap-4 mb-6">
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.dob && formik.touched.dob}
                >
                  <label className="clr-7b7b7b fsize14">Date of Birth</label>
                  <Input
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    type="date"
                    max={DOBDates}
                    className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                    placeholder="DD/MM/YYYY"
                  />
                  {formik.touched.dob && formik.errors.dob && (
                    <FormErrorMessage>{formik.errors.dob}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl
                  isInvalid={!!formik.errors.gender && formik.touched.gender}
                >
                  <label className="clr-7b7b7b fsize14">Gender</label>
                  <Select
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={handleTrim}
                    className="text-gray mt-2"
                    placeholder="Select option"
                  >
                    <option> Male</option>
                    <option> Female</option>
                    <option>Other </option>
                  </Select>
                  {formik.touched.gender && formik.errors.gender && (
                    <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
                  )}
                </FormControl>
              </div>
            </div>

            <Divider className="mt-8" />

            <h2 className="fsize18 font-semibold mb-4 clr-606060">
              Other Details
            </h2>

            <div>
              <FormControl
                isInvalid={!!formik.errors.bio && formik.touched.bio}
              >
                <label className="clr-7b7b7b fsize14">Bio</label>
                <Textarea
                  value={formik.values.bio}
                  onChange={formik.handleChange}
                  onBlur={handleTrim}
                  name="bio"
                  className="w-full border-6d6d6d rounded-lg mt-2 px-4 py-3 fsize14"
                  placeholder="Type here"
                  rows="4"
                ></Textarea>
                {formik.touched.bio && formik.errors.bio && (
                  <FormErrorMessage>{formik.errors.bio}</FormErrorMessage>
                )}
              </FormControl>
            </div>

            <Divider className="mt-8" />

            <div>
              <h2 className="fsize18 font-semibold clr-606060 mb-4">
                Your Fitness Goals
              </h2>
              <FormControl
                isInvalid={
                  !!formik.errors.fitnessGoal && formik.touched.fitnessGoal
                }
              >
                {/* <label className="clr-7b7b7b fsize14 ">Select Goals</label> */}
                <Multiselect
                  name="fitnessGoal"
                  className="w-full  rounded-lg  fsize14 mt-2 cust-multiselect"
                  options={fitnessGoalOptions}
                  selectedValues={formik.values.fitnessGoal}
                  onSelect={async (value) => {
                    await setFieldHandler("fitnessGoal", value);
                    // setFitnessGoalData(value);
                  }}
                  onRemove={async (value) => {
                    await setFieldHandler("fitnessGoal", value);
                    // setFitnessGoalData(value);
                  }}
                  displayValue="name"
                />{" "}
                {formik.touched.fitnessGoal && formik.errors.fitnessGoal && (
                  <FormErrorMessage>
                    {formik.errors.fitnessGoal}
                  </FormErrorMessage>
                )}
              </FormControl>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
