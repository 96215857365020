import React from 'react'

const HeroSection12 = () => {
  return (
    <section className=" ">
      <div className=" container mx-auto  h-580px">
        <div className="h-580px w-60 mx-auto flex flex-col justify-center items-center">
          <p className="fsize18 letter-spacing4px font-semibold clr-fff mb-2">
            Blog
          </p>
          <h1 className="fsize64 font-bold mb-4 clr-fff text-center">Read Coffee Story on our Blog</h1>

          <p className="fsize18 clr-fff text-center ">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>
      </div>
    </section>
  )
}

export default HeroSection12
