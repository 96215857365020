const initialState = {
  Trainers: {},
  TrainersLoadMore: {},
  SelectedTrainer: "",
  IsEditProfile: false,
  SelectedTrainerData: {},
  SaveTrainerData: null,
};

const Trainer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TRAINER":
      return { ...state, Trainers: action.payload };
    case "SET_TRAINER_LOAD_MORE":
      return { ...state, TrainersLoadMore: action.payload };
    case "SET_SELECTED_TRAINER":
      return { ...state, SelectedTrainer: action.payload };
    case "SET_SAVE_TRAINER_DATA":
      return { ...state, SaveTrainerData: action.payload };
    case "SET_ISEDITPROFILE":
      return { ...state, IsEditProfile: action.payload };
    case "SET_SELECTED_TRAINER_DATA":
      return { ...state, SelectedTrainerData: action.payload };
    default:
      return state;
  }
};

export default Trainer;
