import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContentUpdate from "../../cms/ContentUpdate";
import FeatherIcon from "feather-icons-react";
import { Container, Draggable } from "react-smooth-dnd";

//child components
import Header2 from "../header/Header2";
import Footer from "../footer/Footer";
import Dialog from "../../cms/Dialog";
import OtpDialog from "../../cms/OTPDialog";
import HeroSection from "./components/HeroSection1";
import {
  verifySession,
  changeIndex,
  deleteSection,
  closeSession,
  getSections,
  getMetaByPageName,
} from "../../redux/cms/cms-actions";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import SEO from "../../cms/SEO";
import Footer1 from "pages/footer/Footer1";

const Master = ({ routeName }) => {
  const dispatch = useDispatch();
  let css = useSelector((state) => state.cms.allSectionsCss[routeName]);
  let classList = useSelector((state) => state.cms.allSectionsClass[routeName]);
  const [mounted, setMounted] = useState(false);
  const [cmsActive, setCmsActive] = useState(false);
  const [component, setComponent] = useState([]);
  const [compChange, setCompChange] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isOtpDialog, setOtpIsDialog] = useState(false);
  const [isOtpDialogClosed, setOtpIsDialogClosed] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const [isCheckSession, isSetCheckSession] = useState(false);
  const [changedSections, isChangedSections] = useState([]);
  const [isComponentChanged, setIsComponentChanged] = useState(false);
  const [selectedSection, setSelectedSection] = useState({});
  const [renderSwitch, setRenderSwitch] = useState(false);
  const [metaData, setMetaData] = useState({});

  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //===========SEO============
  useEffect(() => {
    const getSEO = async () => {
      let data = await dispatch(getMetaByPageName({ pageName: routeName }));
      if (data && data.code === 200) {
        setMetaData(data.data);
      }
    };
    getSEO();
    if (routeName) {
      if (mounted) {
        setMounted(false);
      }
      mount();
    }
  }, [routeName]);
  //===========SEO============
  let sections = useSelector((state) =>
    state.cms && state.cms.sectionNames
      ? state.cms.sectionNames[`${routeName}`]
      : []
  );

  const { token } = useParams();
  useEffect(() => {
    async function defineSection() {
      let comps = [];
      if (!mounted) {
        // isChangedSections()
        let paths = [];
        console.log("mount2 outside section");
        if (sections) {
          console.log("mount2 inside section");
          paths = await Promise.all(
            sections.map((element) => {
              if (element.path) return element.path;
            })
          );
        }
        console.log(paths, sections, "mount2");
        comps = await Promise.all(
          paths.map(async (x) => {
            console.log("mount 1");
            try {
              const module = await import(`${x}`);
              return module.default;
            } catch (error) {
              console.log(error.message);
            }
          })
        );
        comps = comps.filter((com) => com !== undefined);
        console.log(comps, "mount");
        setComponent(comps);
        isChangedSections(sections);
        setMounted(true);
      }
      if (compChange) setCompChange(false);
      if (isComponentChanged) setIsComponentChanged(false);
      addStyling();
    }
    checkSession();
    defineSection();
  }, [component, mounted, sections, css, classList]);
  console.log(sections, component, changedSections, "mpomountednted");

  function mount() {
    if (token) {
      if (!isOtpDialog && !isOtpDialogClosed) {
        localStorage.setItem("cmsToken", token);
        console.log("otp");
        openOtpDialog();
      }
    } else if (!cmsActive) {
      removeClass();
    }
  }

  async function updateIndex() {
    try {
      let data = await dispatch(changeIndex({ sections: changedSections }));
      if (data && data.code === 200) {
        setIsComponentChanged(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function closeSessionfn() {
    try {
      // sesssion close popup
      Swal.fire({
        title: "warning",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2bc370",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, close it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = await dispatch(closeSession());
          if (data && data.code === 200 && data.data.success) {
            localStorage.removeItem("cmsToken");
            setCmsActive(false);
            removeClass();
            setIsComponentChanged(false);
            Swal.fire({
              title: "Session!",
              text: "Session closed",
              icon: "success",
              confirmButtonColor: "#2bc370",
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteIndex(index) {
    Swal.fire({
      title: "warning",
      text: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2bc370",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let section = changedSections[index];
        let data = await dispatch(
          deleteSection({
            id: section._id,
            pageId: section.pageId,
            pageName: section.pageName,
            sectionId: section.sectionId,
          })
        );
        if (data && data.code === 200) {
          await dispatch(getSections());
          setMounted(false);
          Swal.fire({
            title: "Section!",
            text: `${
              section.section &&
              section.section.toLowerCase().includes("section")
                ? section.section
                : section.sectionName
                ? `${section.sectionName} section`
                : `section`
            } deleted`,
            icon: "success",
            confirmButtonColor: "#2bc370",
          });
        }
      }
    });
  }

  async function swap(obj) {
    let tempCompArray = component;
    let temp = changedSections;

    [temp[obj.removedIndex], temp[obj.addedIndex]] = [
      temp[obj.addedIndex],
      temp[obj.removedIndex],
    ];

    [tempCompArray[obj.removedIndex], tempCompArray[obj.addedIndex]] = [
      tempCompArray[obj.addedIndex],
      tempCompArray[obj.removedIndex],
    ];
    isChangedSections(temp);
    setComponent(tempCompArray);
    setCompChange(true);
    setIsComponentChanged(true);
    setRenderSwitch((state) => !state);
    console.log("swap", temp);
  }

  function addStyling() {
    let classData = document.getElementsByClassName("dynamicStyle");
    console.log(classData, "data", "PATHSINDEX");

    let deleteStyle = document.querySelector(
      `#${process.env.REACT_APP_COMMUNITY_NAME}`
    );

    if (deleteStyle) {
      deleteStyle.parentNode.removeChild(deleteStyle);
    }
    let styleElement = document.createElement("style");
    styleElement.id = process.env.REACT_APP_COMMUNITY_NAME;
    styleElement.type = "text/css";
    let classString = "";
    if (classData && classData.length > 0) {
      for (let i = 0; i < classData.length; i++) {
        if (
          (classData && classData[i].id && css && css[classData[i].id]) ||
          (css && css[classData[i].id] === "")
        ) {
          classData[i].style.cssText = css[classData[i].id];

          console.log(classData[i].style.color, "styling");
        }
        classData[i].classList.add(`dynamic_${classData[i].id}`);
        if (
          classData &&
          classData[i].id &&
          classList &&
          classList[classData[i].id]
        ) {
          classString =
            classString + classList[classData[i].id].replace(/"/g, "");
          classString = classString.replace(/,/g, "");
        }
      }
    }
    styleElement.textContent = classString;
    document.head.appendChild(styleElement);
  }

  function removeClass() {
    let classData = document.getElementsByClassName("dynamicStyle");
    let classData2 = document.getElementsByClassName("dynamicStyle2");
    for (let i = 0; i < classData2.length; i++) {
      classData2[i].classList.remove("imagecms-plus");
    }
    for (let i = 0; i < classData.length; i++) {
      classData[i].classList.remove("dynamicStyle");
    }
  }

  function addClass() {
    let classData = document.getElementsByClassName("dynamicStyle");
    let classData2 = document.getElementsByClassName("dynamicStyle2");
    console.log(classData, "class data");

    for (let i = 0; i < classData2.length; i++) {
      if (classData2[i]) {
        classData2[i].addEventListener("click", () => {
          console.log("clicked");
          updateSelectedData(classData2[i].id);
          setIsShow(true);
        });
      }
      classData2[i].classList.add("imagecms-plus");
    }
    for (let i = 0; i < classData.length; i++) {
      if (classData[i]) {
        console.log("clicked", classData[i].id);
        classData[i].addEventListener("click", () => {
          console.log("clicked");
          updateSelectedData(classData[i].id);
          setIsShow(true);
        });
      }
      classData[i].classList.add("elementui-css");
    }
  }

  const isSideBarOpen = async (id) => {
    if (localStorage.getItem("cmsToken")) {
      let data = await dispatch(verifySession());
      if (data && data.data.success) {
        updateSelectedData(id);
        setIsShow(true);
      } else removeClass();
    }
  };

  function closeSidebar() {
    updateSelectedData("");
    setIsShow(false);
  }

  async function closeDialog() {
    updateSelectedData("");
    let data = await dispatch(getSections());
    // setTimeout(() => {
    console.log(data, "mount close dialog");
    console.log(sections, "mount close dialog1");
    setMounted(false);
    // }, 100);

    setIsDialog(false);
  }

  function openDialog(index) {
    changedSections[index]["index"] = index;
    setSelectedSection(changedSections[index]);
    setIsDialog(true);
  }

  function closeOtpDialog() {
    setOtpIsDialogClosed(true);
    setOtpIsDialog(false);
    isSetCheckSession(true);
    checkSession();
  }

  function openOtpDialog() {
    setOtpIsDialogClosed(false);
    setOtpIsDialog(true);
  }

  async function checkSession() {
    if (localStorage.getItem("cmsToken")) {
      let data = await dispatch(verifySession());
      if (data && data.data.success) {
        setTimeout(() => {
          addClass();
          setCmsActive(true);
        }, 100);
      } else removeClass();
    }
  }

  function enableCms() {
    isSetCheckSession(true);
    closeOtpDialog();
  }
  function getFavicon(value) {
    return value
      ? value.favIcon
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.favIcon
        : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png"
      : "https://cloudstoragecluster.blob.core.windows.net/storage02/Quarkly/default/logo.png";
  }
  // mount();
  return (
    <>
      <SEO metaData={metaData} />
      <section>
        <Helmet>
          <title>Fit2Go</title>
          <meta name="description" content="sections" />
          <meta name="keyword" content="sections" />
          <link
            rel={"shortcut icon"}
            href={getFavicon(brandProfile)}
            type={"image/x-icon"}
          />
        </Helmet>

        {isShow ? (
          <ContentUpdate
            selectedElement={selectedData}
            pageName={routeName}
            isShow={isShow}
            closeSidebar={closeSidebar}
          />
        ) : (
          ""
        )}
        {isDialog ? (
          <>
            <div className="flex justify-center items-center popup fixed left-0">
              <div className="bg-white rounded-xl w-popup lg:py-6 md:py-6 lg:px-8 md:px-8 p-4 relative">
                <Dialog
                  selectedSection={selectedSection}
                  closePopup={() => closeDialog()}
                  selectedRoute={routeName}
                />
                <div className="absolute right-8 top-8">
                  <FeatherIcon
                    icon="x"
                    className="cursor-pointer"
                    onClick={() => {
                      closeDialog();
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {isOtpDialog ? (
          <>
            <div className="flex justify-center items-center  popup fixed left-0">
              <div className="bg-white rounded-xl w-popuptr  relative">
                <div className="flex items-center justify-between px-4 py-3 auth-popupsetP">
                  <h5 className="fsize25 text-bold ">Authentication</h5>

                  <FeatherIcon
                    icon="x"
                    className="cursor-pointer"
                    onClick={() => {
                      closeOtpDialog();
                    }}
                  />
                </div>

                <OtpDialog token={token} enableCms={enableCms} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {/* <DragComponent /> */}
        {/* <button onClick={addStyling}>Click here</button> */}

        {/* <HeroSection1 /> */}
        {/* <Box className="fixed loding-bg top-0 zindex-9 flex justify-center items-center left-0 h-full w-full">
        <p className="fsize20 font-semibold fsize18"> Loading...</p>
      </Box> */}

        <Header2 />

        <div className="lg:mt-20">
          {cmsActive ? (
            <Container onDrop={(e) => swap(e)}>
              {component.map((Path, index) => {
                console.log(component);
                if (Path) {
                  return (
                    <Draggable>
                      <div>
                        <Path
                          key={index}
                          onLoad={() => mount()}
                          isShow={isShow}
                          isSideBarOpen={isSideBarOpen}
                          pageName={routeName}
                        />
                        <div className="zindex9 relative">
                          <div className="flex items-center justify-center">
                            <div className="drag-seccss flex justify-center items-center cursor-allscroll">
                              <FeatherIcon icon="grid" />
                            </div>
                          </div>
                          <p className="dragcss"></p>
                          <div className="flex items-center justify-center">
                            <div className="flex gap-3 justify-center items-center groupicons px-2">
                              <FeatherIcon
                                icon="plus-circle"
                                size={18}
                                className="cursor-pointer"
                                onClick={() => {
                                  openDialog(index);
                                }}
                              />
                              <FeatherIcon
                                icon="copy"
                                size={18}
                                className="cursor-pointer"
                              />
                              <FeatherIcon
                                icon="trash"
                                size={18}
                                className="cursor-pointer"
                                onClick={() => deleteIndex(index)}
                              />
                              <FeatherIcon
                                icon="x"
                                size={18}
                                className="cursor-pointer"
                                onClick={() => closeSessionfn()}
                              />
                              {isComponentChanged ? (
                                <FeatherIcon
                                  icon="check"
                                  size={18}
                                  onClick={() => updateIndex()}
                                  className="cursor-pointer"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Draggable>
                  );
                }
              })}{" "}
            </Container>
          ) : (
            component.map((Path, index) => {
              if (Path) {
                return (
                  <Path
                    key={index}
                    onLoad={() => mount()}
                    pageName={routeName}
                  />
                );
              }
            })
          )}
        </div>

        {/* <Footer /> */}
        <Footer1 />

        {/* <HeroSection1 />
      <div>
        <div className="flex items-center justify-center">
          <div className="drag-seccss flex justify-center items-center cursor-allscroll">
            <FeatherIcon icon="grid" />
          </div>
        </div>
        <p className="dragcss"></p>
        <div className="flex items-center justify-center">
          <div className="flex gap-4 justify-center items-center groupicons">
            <FeatherIcon
              icon="plus-circle"
              size={18}
              className="cursor-pointer"
            />
            <FeatherIcon icon="copy" size={18} className="cursor-pointer" />
            <FeatherIcon icon="x-circle" size={18} className="cursor-pointer" />
          </div>
        </div>
      </div>
      <AboutSection1 />
      <FeatureShowcase1 />
      <ServicesSection1 />
      <BannerSection1 />
      <TestimonialSection1 />
      <AboutSection2 />*/}
      </section>
    </>
  );
};

export default Master;
