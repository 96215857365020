import React from "react";
import { FaTwitter, FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Helper from "../../helper";
const Footer1 = () => {
  const navigate = useNavigate();
  let menuData = useSelector((state) => state.menuState.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);
  let User = useSelector((state) => state.AuthDetails.user);
  function getSecondaryImage(value) {
    return value
      ? value.alternateLogo
        ? process.env.REACT_APP_STORAGE_URL + brandProfile.alternateLogo
        : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
      : "https://nimbuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  return (
    <section className="bggradient-left">
      <div className="container mx-auto lg:px-12 md:px-12 px-4 lg:py-10 py-8">
        <div className="lg:flex md:block block justify-between lg:gap-8 md:gap-6">
          <div className="lg:w-1/2 md:w-1/2 w-full">
            <div className="w-230px tb-wfull sm-wfull">
              <a href="/">
                <img
                  src={getSecondaryImage(brandProfile)}
                  className="w-60 sm-w-80 h-20 object-contain"
                  alt="footer-logo"
                />
              </a>
              <div className="my-4">
                {/* <p className="lg:mb-8 md:mb-6 mb-4 fsize14">
                  The fastest and easiest Data Prediction tool in the world.
                </p> */}
                <span className="clr-d9d9d9 fsize14">
                  © Muscles In Motion Pte Ltd 2021. All right reserved
                </span>
              </div>
              <div className="icons flex gap-4 my-4">
                <a
                  href="https://www.youtube.com/@fit2goasia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="clr-d9d9d9" />
                </a>
                <a
                  href="https://www.instagram.com/fit2go.asia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className="clr-d9d9d9" />
                </a>
                <a
                  href="https://www.facebook.com/Fit2Go.Asia/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="clr-d9d9d9" />
                </a>
              </div>
              <div className="lg:mt-0  md:lg-mt-0 mt-4">
                {project.poweredBy ? (
                  <p className="fsize14 text-white sm-fsize14 ">
                    powered by BRAINED
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="footer-menu flex justify-between lg:w-1/2 md:w-full w-full lg:mt-0 md:mt-6 mt-6">
            <div>
              {/* <h3 className="fsize16 font-semibold lh24px text-white">
                Website
              </h3> */}
              <div className="my-4">
                {menuData.map((e, i) => {
                  return (
                    <p className="text-white py-1 custcolor">
                      <Link to={`/${e.pageData[0].page}`}>{e.menuName}</Link>
                    </p>
                  );
                })}
              </div>
            </div>
            <div>
              {/* <h3 className="fsize16 font-semibold text-white">Support</h3> */}
              <div className="my-4">
                <p className="fsize16 lh24px py-1 text-white custcolor">
                  <Link to="/user-agreement">User Agreement</Link>
                </p>
                <p className="fsize16 lh24px py-1 text-white custcolor">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
                <p className="fsize16 lh24px py-1 text-white custcolor">
                  <Link to="/terms-conditions">Community Guidelines</Link>
                </p>
                {/* <p className="fsize16 lh24px py-1 text-white">Gaming</p> */}
              </div>
            </div>
            <div>
              {/* <h3 className="fsize16 font-semibold text-white">Resources</h3> */}
              <div className="my-4">
                <p
                  onClick={() => {
                    if (User && User._id && User.trainerId) {
                      if (User.role === "Trainer") {
                        toast.warning("Already a trainer");
                      } else {
                        toast.warning("Already submitted trainer form");
                      }
                      return;
                    } else if (
                      User &&
                      User._id &&
                      User.role === "TrainerApplicant"
                    ) {
                      navigate("/becameacoach");
                    } else if (
                      User &&
                      User._id &&
                      User.role !== "TrainerApplicant"
                    ) {
                      toast.warning("Only trainer applicant are allowed");
                    } else if (Helper.Object.isEmpty(User)) {
                      console.log("No User");
                      toast.warning("Please login");
                      navigate("/login");
                    }
                  }}
                  className="fsize16 lh24px py-1 text-white custcolor"
                >
                  <Link>Become A Coach</Link>
                </p>
                <p className="fsize16 lh24px py-1 text-white custcolor">
                  <Link to="/subscription">Subscription</Link>
                </p>
                {/* <p className="fsize16 lh24px py-1 text-white">Brand assets</p>
                <p className="fsize16 lh24px py-1 text-white">Events</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer1;
