import React, { useEffect, useState } from "react";
import "swiper/css";
import { Button, Image } from "@chakra-ui/react";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import FeatherIcon from "feather-icons-react";
import { Navigation } from "swiper/modules";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux/es/exports";
import { ThreeDots } from "react-loader-spinner";
import { getAllPlan } from "redux/custom/subscription/plan/plan-actions";
import PaymentCheckout from "../../../components/Payment/Payment";
const SubscriptionGradient = ({ pageName }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector((state) => state.cms.allSections[pageName]);

  let allPlan = useSelector((state) => state.plan.AllPlan);

  useEffect(() => {
    dispatch(getAllPlan());
  }, []);

  function getItemList(item) {
    return [
      {
        name: item.name,
        quantity: 1,
        price: parseInt(item.price),
      },
    ];
  }
  return (
    <section id="planDetails" className="bggradient-50 relative">
      <div className="container mx-auto zindex9 relative lg:py-16 md:py-16 py-8 lg:px-12 md:px-8 px-5">
        <div className="text-center lg:mb-12 md:mb-12 mb-12 lg:mt-0 md:mt-0 mt-4">
          <h2
            id="4105908131"
            className="
           dynamicStyle text-white lg:text-4xl text-2xl md:text-3xl font-semibold"
          >
            {data ? data["4105908131"] : "Get Listed On Coach Community"}
          </h2>
          <p
            id="7079468661"
            className="dynamicStyle mt-4 text-white"
            dangerouslySetInnerHTML={{
              __html: data ? data["7079468661"] : "no-data",
            }}
          ></p>
        </div>

        {isLoading ? (
          <div className="flex justify-center align-center">
            <ThreeDots visible={true} color="#ed1b24" radius={9} />
          </div>
        ) : (
          <>
            {allPlan && allPlan.length > 0 ? (
              <Fade bottom>
                <Swiper
                  spaceBetween={20}
                  modules={[Navigation]}
                  autoplay={{
                    delay: "1000",
                  }}
                  navigation={{
                    nextEl: ".test-swiper-button-next",
                    prevEl: ".test-swiper-button-prev",
                  }}
                  className="mySwiper"
                  breakpoints={{
                    1536: {
                      slidesPerView: 4.2,
                      spaceBetween: 20,
                    },
                    1280: {
                      slidesPerView: 4.2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3.5,
                      spaceBetween: 20,
                    },
                    991: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 10,
                    },
                    425: {
                      slidesPerView: 1.2,
                      spaceBetween: 10,
                    },
                    325: {
                      slidesPerView: 1.2,
                      spaceBetween: 10,
                    },
                    300: {
                      slidesPerView: 1.2,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {allPlan.map((item) => {
                    return (
                      <SwiperSlide className="bg-plancard px-6 py-4 height-30rem ">
                        <div>
                          <div className="flex justify-between">
                            <p className="text-white border fsize12 p-1 fitcontent">
                              {item.name}
                            </p>
                            <p className="text-white fsize12 p-1 fitcontent">
                              {item.type ? `For ${item.type}` : ""}
                            </p>
                          </div>
                          <h2 className="text-primary lg:text-3xl md:text-3xl text-2xl font-semibold mt-4 lg:mb-3 md:mb-3 mb-4">
                            {item.price <= 0 ? "Free" : `S$ ${item.price}/-`}
                            {/* <span className="fsize16">Month </span> */}
                          </h2>
                          <p className="text-white fsize12 lg:mb-4 md:mb-4 mb-4">
                            Validity:{" "}
                            {item.validity <= 0
                              ? "Forever"
                              : `${item.validity} days`}
                          </p>
                          <p className="text-white fsize12 lg:mb-4 md:mb-4 mb-4">
                            {item.description}{" "}
                          </p>

                          {/* <Button
                          colorScheme="primary"
                          className="w-full font-semibold fsize14"
                        >
                          Subscribe
                        </Button> */}
                          {/* <PaymentCheckout
                          button={"Subscribe"}
                          buttonClass={"w-full font-semibold fsize14"}
                          buttonColor={"primary"}
                          itemList={getItemList(item)}
                          successUrl={"home"}
                          cancelUrl={"home"}
                        ></PaymentCheckout> */}
                          <div>
                            <div className=" lg:mt-6 md:mt-6 mt-4 ">
                              {item.features &&
                                item.features.length > 0 &&
                                item.features.map((item) => {
                                  return (
                                    <div className=" mb-4 flex items-center gap-2">
                                      <div className="w-1/12">
                                        <FeatherIcon
                                          size={18}
                                          icon="check"
                                          className="cursor-pointer text-white"
                                        />
                                      </div>
                                      <div className="w-11/12">
                                        <p className="text-white fsize12">
                                          {item.title}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}

                              {item.featuresExcluded &&
                                item.featuresExcluded.length > 0 &&
                                item.featuresExcluded.map((item) => {
                                  return (
                                    <div className=" mb-4 flex items-center gap-2">
                                      <div className="w-1/12">
                                        <FeatherIcon
                                          size={18}
                                          icon="x"
                                          className="cursor-pointer text-white"
                                        />
                                      </div>
                                      <div className="w-11/12">
                                        <p className="text-white fsize12">
                                          {item.title}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          {/* <Image
                   
                   fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
                   src={e.image}
                 
                 className="rounded-full roundetest cursor-pointer activeuser"
                   alt="heroImage"
                 /> */}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Fade>
            ) : (
              <div className="flex justify-center align-center">
                No Subscription Found
              </div>
            )}
          </>
        )}

        <div className="lg:mt-12 flex justify-center md:mt-12 mt-8">
          <Button
            id="9123214596"
            className="dynamicStyle trans-btn  rounded-full fsize14 text-center "
            py="4"
          >
            <Link to="/subscription">
              {data ? data["9123214596"] : "no-data"}
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionGradient;
