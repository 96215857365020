import React from 'react';
import { EffectCoverflow,Navigation } from "swiper/modules";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

const Clients = () => {
  return (
    <section>
      <div className="container mx-auto ">
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
          className="mySwiper"
          breakpoints={{
            1536: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 25,
            },
            991: {
              slidesPerView: 4.5,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            425: {
              slidesPerView: 2.2,
              spaceBetween: 15,
            },
            325: {
              slidesPerView: 2.2,
              spaceBetween: 15,
            },
          }}
        >
          <SwiperSlide>
            <div className="w-full">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-contain d-block img-custom"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-contain d-block img-custom"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-contain d-block img-custom"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-contain d-block img-custom"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-contain d-block img-custom"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-contain d-block img-custom"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full">
              <img
                src="https://nimbuscluster.blob.core.windows.net/server01/Quarkly/default/1285952.jpg"
                alt=""
                className="object-contain d-block img-custom"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Clients;
