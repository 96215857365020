import React from 'react';
import { EffectCoverflow,Navigation } from "swiper/modules";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

const Teams2 = () => {
  return (
    <section>
      <div className="container mx-auto flex items-center  ">
        <div className="w-40 lg:px-5">
          <Swiper
            spaceBetween={30}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            className="mySwiper container relative overflow-x-hidden"
            autoplay={{
              delay: '1000',
            }}
            navigation={{
              nextEl: '.image-swiper-button-next',
              prevEl: '.image-swiper-button-prev',
            }}
            breakpoints={{
              1536: {
                slidesPerView: 1.5,
                spaceBetween: 60,
              },
              1280: {
                slidesPerView: 1.5,
                spaceBetween: 60,
              },
              1024: {
                slidesPerView: 1.5,
                spaceBetween: 60,
              },
              991: {
                slidesPerView: 1.5,
                spaceBetween: 25,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
              325: {
                slidesPerView: 1,
                spaceBetween: 25,
              },
            }}
          >
            <div className="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
              </svg>
            </div>
            <div className="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
              </svg>
            </div>
            <SwiperSlide>
              <div className="  border p-2 w-full rounded-lg">
                <div className="overflow-hidden">
                  <img
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p className="fsize18 font-semibold text-center lg:mb-2">
                  John Deo
                </p>
                <p className="fsize14 text-center">Designation</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="  border p-2 w-full rounded-lg">
                <div className="overflow-hidden">
                  <img
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p className="fsize18 font-semibold text-center lg:mb-2">
                  John Deo
                </p>
                <p className="fsize14 text-center">Designation</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="  border p-2 w-full rounded-lg">
                <div className="overflow-hidden">
                  <img
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p className="fsize18 font-semibold text-center lg:mb-2">
                  John Deo
                </p>
                <p className="fsize14 text-center">Designation</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="  border p-2 w-full rounded-lg">
                <div className="overflow-hidden">
                  <img
                    src="https://nexuscluster.blob.core.windows.net/server01/default/images/default2.jpg"
                    alt="teams-card"
                    className="h-200px zoom w-full object-cover rounded-lg lg:mb-6"
                  />
                </div>

                <p className="fsize18 font-semibold text-center lg:mb-2">
                  John Deo
                </p>
                <p className="fsize14 text-center">Designation</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="w-60 lg:px-12 ms:px-12 px-5">
          <h1 className="fsize40 leading-10 lg:w-4/5 md:4/5 font-semibold mb-1">
            Meet Aur Professional teams
          </h1>
          <p className="fsize18 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            cursus consectetur euismod aenean.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Teams2;
