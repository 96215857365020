import API from "../../repositoryFactory";

const createLog = "create";
const getLog = "get";
const updateLog = "update";
const deleteLog = "delete";
const createPlanSubscription = "createPlanSubscription";

const reportRoute = (route) => {
  return `fit2go/subscription/log/${route}`;
};

export default {
  createLog(payload) {
    return API.post(reportRoute(createLog), payload);
  },
  createPlanSubscription(payload) {
    return API.post(reportRoute(createPlanSubscription), payload);
  },
  getLog(payload) {
    return API.post(reportRoute(getLog), payload);
  },
  updateLog(payload) {
    return API.post(reportRoute(updateLog), payload);
  },
  deleteLog(payload) {
    return API.post(reportRoute(deleteLog), payload);
  },
};
