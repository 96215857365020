import apis from "../../../apis/client";

export const addTrainer = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.addTrainer(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const updateTrainer = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.updateTrainer(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getTrainer = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.getTrainer(payload);

      if (data) {
        dispatch(setTrainer(data.data));
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getTrainersLoadMore = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.getTrainer(payload);

      if (data) {
        // dispatch(setTrainer(data.data));
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getTrainerById = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.getTrainerById(payload);

      if (data) {
        // dispatch(setTrainer(data.data));
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const deleteTrainer = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.deleteTrainer(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const getTrainerLocations = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.getTrainerLocations(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

//Email
export const trainerPaymentSuccessEmail = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.trainerPaymentSuccessEmail(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const trainerPaymentFailedEmail = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.trainerPaymentFailedEmail(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

//Qualification
export const createQualification = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.createQualification(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const editQualification = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.editQualification(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const deleteQualification = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.deleteQualification(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

//Certification
export const createCertification = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.createCertification(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const editCertification = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.editCertification(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};
export const deleteCertification = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.deleteCertification(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

//Media Upload
export const uploadMedia = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.uploadMedia(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

export const UpdateUserView = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.trainer.UpdateUserView(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(console);
      return error.response.data;
    }
    return record;
  };
};

export const setTrainer = (data) => {
  return {
    type: "SET_TRAINER",
    payload: data,
  };
};

export const setTrainersLoadMore = (data) => {
  return {
    type: "SET_TRAINER_LOAD_MORE",
    payload: data,
  };
};

export const setSelectedTrainer = (data) => {
  return {
    type: "SET_SELECTED_TRAINER",
    payload: data,
  };
};

export const setIsEditProfile = (data) => {
  return {
    type: "SET_ISEDITPROFILE",
    payload: data,
  };
};
export const setSelectedTrainerData = (data) => {
  return {
    type: "SET_SELECTED_TRAINER_DATA",
    payload: data,
  };
};
export const setSaveTrainerData = (data) => {
  return {
    type: "SET_SAVE_TRAINER_DATA",
    payload: data,
  };
};
