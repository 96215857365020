import API from "../repositoryFactory";

const createTestimonial = "create";
const getTestimonial = "get";
const updateTestimonial = "update";
const deleteTestimonial = "delete";

const testimonialRoute = (route) => {
  return `fit2go/testimonial/${route}`;
};

export default {
  createTestimonial(payload) {
    return API.post(testimonialRoute(createTestimonial), payload);
  },
  getTestimonial(payload) {
    return API.post(testimonialRoute(getTestimonial), payload);
  },
  updateTestimonial(payload) {
    return API.post(testimonialRoute(updateTestimonial), payload);
  },
  deleteTestimonial(payload) {
    return API.post(testimonialRoute(deleteTestimonial), payload);
  },
};
